import {
  BASIS_POINTS_DIVISOR,
  MARGIN_FEE_BASIS_POINTS,
  getPositionKey,
  getPositionContractKey,
  getLeverage,
  getDeltaStr,
  getFundingFee,
  getLeverageStr,
} from "lib/legacy";
import { getConstant } from "config/chains";
import { getContractAddress } from "config/contracts";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify } from "lib/numbers";
import { applyPendingChanges } from "./applyPendingChanges";
import { Position } from "domain/positions/types";

const UPDATED_POSITION_VALID_DURATION = 20 * 1000;

export function getPositions(
  chainId,
  positionQuery,
  positionData,
  infoTokens,
  includeDelta,
  showPnlAfterFees,
  account,
  pendingPositions,
  updatedPositions
) {
  const propsLength = getConstant(chainId, "positionReaderPropsLength");
  const positions: Position[] = [];
  const positionsMap: Record<string, Position> = {};
  if (!positionData) {
    return { positions, positionsMap };
  }

  const { collateralTokens, indexTokens, isLong } = positionQuery;
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(
      infoTokens,
      collateralTokens[i],
      true,
      getContractAddress(chainId, "NATIVE_TOKEN")
    );
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContractAddress(chainId, "NATIVE_TOKEN"));
    const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    let contractKey;
    if (account) {
      contractKey = getPositionContractKey(account, indexTokens[i], isLong[i]);
    }

    const position: Partial<Position> = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: isLong[i] ? indexToken?.cumulativeFundingRateLong : indexToken?.cumulativeFundingRateShort,
      fundingRate: isLong[i] ? indexToken?.fundingRateLong : indexToken?.fundingRateShort,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
      hasProfit: positionData[i * propsLength + 7].eq(1),
      delta: positionData[i * propsLength + 8],
      markPrice: isLong[i] ? indexToken?.minPrice : indexToken?.maxPrice,
    };

    if (
      updatedPositions &&
      updatedPositions[key] &&
      updatedPositions[key].updatedAt &&
      updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
    ) {
      const updatedPosition = updatedPositions[key];
      position.size = updatedPosition.size;
      position.collateral = updatedPosition.collateral;
      position.averagePrice = updatedPosition.averagePrice;
      position.entryFundingRate = updatedPosition.entryFundingRate;
    }

    let fundingFee = getFundingFee(position as any);
    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0);
    position.collateralAfterFee = position.collateral!.sub(position.fundingFee!);

    position.closingFee = position.size!.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    position.positionFee = position.size!.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR);
    position.totalFees = position.fundingFee;

    position.pendingDelta = position.delta;

    if (position.collateral!.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) || position.size!.div(position.collateralAfterFee.abs()).gt(50);

      if (position.averagePrice && position.averagePrice.gt(0) && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice);
        position.pendingDelta = position.size!.mul(priceDelta).div(position.averagePrice);

        position.delta = position.pendingDelta;

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice);
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice);
        }
      }

      position.deltaPercentage = position.pendingDelta!.mul(BASIS_POINTS_DIVISOR).div(position.collateral!);

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit,
      });

      position.deltaStr = deltaStr;
      position.deltaPercentageStr = deltaPercentageStr;
      position.deltaBeforeFeesStr = deltaStr;

      let hasProfitAfterFees;
      let pendingDeltaAfterFees;

      if (position.hasProfit) {
        if (position.pendingDelta!.gt(position.closingFee!.add(position.totalFees!))) {
          hasProfitAfterFees = true;
          pendingDeltaAfterFees = position.pendingDelta!.sub(position.closingFee!).sub(position.totalFees!);
        } else {
          hasProfitAfterFees = false;
          pendingDeltaAfterFees = position.closingFee!.sub(position.pendingDelta!).add(position.totalFees!);
        }
      } else {
        hasProfitAfterFees = false;
        pendingDeltaAfterFees = position.pendingDelta!.add(position.closingFee!).add(position.totalFees!);
      }

      position.hasProfitAfterFees = hasProfitAfterFees;
      position.pendingDeltaAfterFees = pendingDeltaAfterFees;
      // while calculating delta percentage after fees, we need to add opening fee (which is equal to closing fee) to collateral
      position.deltaPercentageAfterFees = position
        .pendingDeltaAfterFees!.mul(BASIS_POINTS_DIVISOR)
        .div(position.collateral!.add(position.closingFee));

      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees,
      });

      position.deltaAfterFeesStr = deltaAfterFeesStr;
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

      let netValue = position.hasProfit
        ? position.collateral!.add(position.pendingDelta!)
        : position.collateral!.sub(position.pendingDelta!);

      position.netValue = netValue;
      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr;
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
        position.netValue = netValue.sub(position.fundingFee!).sub(position.closingFee);
      }
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
    } as any);
    position.leverageStr = getLeverageStr(position.leverage);

    positionsMap[key] = position as Position;

    applyPendingChanges(position, pendingPositions);

    if (position.size?.gt(0) || position.hasPendingChanges) {
      positions.push(position as Position);
    }
  }

  return { positions, positionsMap };
}

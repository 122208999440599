import { t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import React from "react";
import TextInput from "pages/Referrals/components/TextInput";
import { useWeb3React } from "@web3-react/core";
import { useDebounce } from "lib/useDebounce";
import { setTraderReferralCodeByUser, validateReferralCodeExists } from "domain/referrals";
import { REFERRAL_CODE_REGEX } from "pages/Referrals/referralsHelper";

type Props = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  callAfterSuccess?: () => void;
  type: "join" | "edit";
  userReferralCodeString: string;
  setPendingTxns?: any;
  pendingTxns?: any;
};

export function EnterReferralCodeModal(props: Props) {
  const [referralCode, setReferralCode] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const debouncedReferralCode = useDebounce(referralCode, 300);
  const { account, chainId, provider } = useWeb3React();
  const [isValidating, setIsValidating] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [referralCodeExists, setReferralCodeExists] = React.useState(true);

  const primaryText = React.useMemo(() => {
    const isEdit = props.type === "edit";

    if (isEdit && debouncedReferralCode === props.userReferralCodeString) {
      return t`Same as current active code`;
    }
    if (isEdit && isSubmitting) {
      return t`Updating...`;
    }

    if (isSubmitting) {
      return t`Adding...`;
    }
    if (debouncedReferralCode === "") {
      return isEdit ? t`Edit Referral Code` : t`Enter Referral Code`;
    }
    if (isValidating) {
      return t`Checking code...`;
    }
    if (!referralCodeExists) {
      return t`Referral Code does not exist`;
    }

    return isEdit ? t`Update` : t`Submit`;
  }, [props.type, debouncedReferralCode, isSubmitting, isValidating, props.userReferralCodeString, referralCodeExists]);

  const isPrimaryEnabled = React.useMemo(() => {
    if (
      debouncedReferralCode === "" ||
      isSubmitting ||
      isValidating ||
      !referralCodeExists ||
      debouncedReferralCode === props.userReferralCodeString
    ) {
      return false;
    }
    return true;
  }, [debouncedReferralCode, isSubmitting, isValidating, referralCodeExists, props.userReferralCodeString]);

  async function handleSubmit() {
    const isEdit = props.type === "edit";

    setIsSubmitting(true);

    try {
      const tx = await setTraderReferralCodeByUser(chainId, referralCode, provider, {
        account,
        successMsg: isEdit ? t`Referral code updated!` : t`Referral code added!`,
        failMsg: isEdit ? t`Referral code updated failed.` : t`Adding referral code failed.`,
        setPendingTxns: props.setPendingTxns,
        pendingTxns: props.pendingTxns,
      });

      const receipt = await tx.wait();
      if (receipt.status === 1) {
        setReferralCode("");
      }

      props.callAfterSuccess?.();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setIsValidating(false);
    }
  }

  React.useEffect(() => {
    let cancelled = false;
    async function checkReferralCode() {
      if (debouncedReferralCode === "" || !REFERRAL_CODE_REGEX.test(debouncedReferralCode)) {
        setIsValidating(false);
        setReferralCodeExists(false);
        return;
      }

      setIsValidating(true);
      const codeExists = await validateReferralCodeExists(debouncedReferralCode, chainId);
      if (!cancelled) {
        setReferralCodeExists(codeExists);
        setIsValidating(false);
      }
    }
    checkReferralCode();
    return () => {
      cancelled = true;
    };
  }, [debouncedReferralCode, chainId]);

  React.useEffect(() => {
    if (props.isVisible) {
      reset();
    }
  }, [props.isVisible]);

  const reset = () => {
    setReferralCode("");

    inputRef.current?.focus();
  };

  // State to manage if the input is focused
  const [isInputFocused, setInputFocused] = React.useState(false);

  // Handle input focus
  const handleInputFocus = () => {
    setInputFocused(true);
  };

  // Handle input blur
  const handleInputBlur = () => {
    setInputFocused(false);
  };

  return (
    <Modal
      isVisible={props.isVisible}
      setIsVisible={props.setIsVisible}
      label={props.type === "edit" ? t`Edit Referral Code` : t`Enter Referral Code`}
      isInputFocused={isInputFocused}
    >
      <form onSubmit={handleSubmit} noValidate>
        <fieldset className="flex flex-col items-stretch border-none">
          <TextInput
            ref={inputRef}
            value={referralCode}
            onChange={setReferralCode}
            label={t`Enter Referral Code`}
            placeholder={t`Foxify`}
            disabled={isSubmitting}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />

          <Button
            variant="primary-action"
            size="lg"
            className="w-full mt-5"
            disabled={!isPrimaryEnabled}
            onClick={handleSubmit}
          >
            {primaryText}
          </Button>
        </fieldset>
      </form>
    </Modal>
  );
}

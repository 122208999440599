import { FaCheck } from "react-icons/fa";
import { Trans } from "@lingui/macro";

import Button from "components/Button/Button";

import Cashout from "./Cashout";
import { BigNumber } from "ethers";
import { FundedLevels } from "components/Funded/types";

export default function ChallengeMaintained({
  cashout,
  isLoading,
  challengeRank,
  onCashout,
  onRestart,
}: {
  cashout: BigNumber;
  isLoading: boolean;
  challengeRank: number;
  onCashout: () => void;
  onRestart: () => void;
}) {
  const fundedLevel = FundedLevels[challengeRank];

  const handleRenderButton = () => {
    return (
      <>
        {!cashout.eq(0) && (
          <Button variant="green" onClick={onCashout} className="w-full rounded !text-black">
            <Trans>Cashout</Trans>
          </Button>
        )}

        <Button variant="green" onClick={onRestart} className="w-full rounded !text-black">
          <Trans>Restart</Trans>
        </Button>
      </>
    );
  };
  return (
    <div>
      <div className="flex items-center text-green text-[2rem] gap-x-3">
        <div className="w-[3.5rem] h-[3.5rem] flex items-center justify-center rounded-full bg-green/[0.15]">
          <FaCheck />
        </div>
        <Trans>Good work!</Trans>
      </div>
      <div className="text-inactive text-[1.4rem] mb-[9.7rem] mt-4">
        <Trans>You can cashout or rollover, you maintain {fundedLevel}.</Trans>
      </div>
      <Cashout cashout={cashout} isLoading={isLoading} />
      <div className="flex gap-x-[0.6rem] mt-4">{handleRenderButton()}</div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { t, Trans } from "@lingui/macro";
import useSWR from "swr";
import cx from "classnames";
import { HiChevronDown, HiChevronUp, HiChevronRight, HiChevronLeft, HiQuestionMarkCircle } from "react-icons/hi";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import "./StakeV3.scss";
import StakeModal, {
  STAKE_ACTION,
  UNSTAKE_ACTION,
  FOX_TOKEN,
  EBFOX_TOKEN,
  BUY_STAKE_ACTION,
  UNSTAKE_NFT_ACTION,
} from "./StakeModal";
import VestModal from "./VestModal";
import docsIcon from "img/docsOrange.svg";
import swapCurrency from "img/swapCurrency.svg";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { formatAmount, expandDecimals } from "lib/numbers";
import { useChainId } from "lib/chains";
import { useQuickContracts } from "lib/contracts";
import { FOX_STABLE_DECIMALS, FOX_DECIMALS } from "lib/constants";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import foxLogo from "img/Fox_Logo.svg";
import { useMedia } from "react-use";
import isEqual from "lodash/isEqual";
import { useTokenPrices } from "hooks/useStakingTokensPrices";
import { useStaking } from "hooks/useStaking";
import { formatBigAmount } from "lib/formatAmount";
import EbFox from "abis/common/EbFox.json";
import FoxifyMaxi from "abis/common/FoxifyMaxi.json";
import { getProvider } from "lib/rpc";
import { getContractAddress } from "config/contracts";
import useThemeSwitcher from "lib/useThemeSwitcher";
import { useLocalStorageByChainId } from "../../lib/localStorage";
import Tab from "../../components/Tab/Tab";
import { StakeNFT } from "./StakeNFT";
import { getEbFoxStakingData, getFoxifyMaxiStakingData } from "./utilts";
import { Vesting } from "../Vesting/Vesting";
import { useNFT } from "../../hooks/useNFT";

export default function StakeV3(props) {
  const [openFoxAccordion, setopenFoxAccordion] = useState(true);
  const [isUserHasNFT, setIsUserHasNFT] = useState(false);
  const [openEbFoxAccordion, setopenEbFoxAccordion] = useState(false);
  const [openChartAccordion, setOpenChartAccordion] = useState(false);
  const [openStakeModal, setOpenStakeModal] = useState(false);
  const [stakedAmountForTheModal, setStakedAmountForTheModal] = useState(BigNumber.from(0));
  const [openVestModal, setOpenVestModal] = useState(false);
  const [stakeModalData, setStakeModalData] = useState({ token: null, action: null });
  const [vestModalData] = useState(null);
  const [apyEbFox, setApyEbFox] = useState(0);
  const [apyFoxifyMaxi, setApyFoxifyMaxi] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentBlockNumber, setCurrentBlockNumber] = useState(0);
  const [isEndListReached, setIsEndListReached] = useState(false);
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const { chainId } = useChainId();
  const { isActive, account, provider } = useWeb3React();

  const { ebFoxToken, foxifyMaxiToken } = useStaking(account);
  const { totalClaimableAmount } = useNFT();

  const handleStakeModal = (token, action) => {
    setStakeModalData({
      token,
      action,
    });
    setOpenStakeModal(true);
    token === FOX_TOKEN && action !== UNSTAKE_NFT_ACTION
      ? setStakedAmountForTheModal(maxWithdrawableTokens)
      : setStakedAmountForTheModal(ebFoxUserStaked);
    token === FOX_TOKEN && action === UNSTAKE_NFT_ACTION && setStakedAmountForTheModal(totalClaimableAmount);
  };

  const ebFoxStakingData = getEbFoxStakingData(ebFoxToken);
  const foxifyMaxiStakingData = getFoxifyMaxiStakingData(foxifyMaxiToken);

  const [switchCurrency, setSwitchCurrency] = useState([false, false, false, false]);

  const quickContracts = useQuickContracts();

  const handleSwitchCurrency = (index) => {
    const updatedSwitchCurrency = [...switchCurrency];
    updatedSwitchCurrency[index] = !updatedSwitchCurrency[index];
    setSwitchCurrency(updatedSwitchCurrency);
  };

  const { foxPrice, ethPrice, isPriceLoading } = useTokenPrices();

  const { data: totalFoxSupply } = useSWR(
    ["Fox", "totalFoxSupply", isActive, chainId],
    () => quickContracts.fetch("Fox", "totalSupply", []),
    {
      compare: isEqual,
    }
  );
  const { data: foxBalance } = useSWR(
    ["Fox", "balanceOf", isActive, chainId],
    () => quickContracts.fetch("Fox", "balanceOf", [account?.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const { data: pendingClaim } = useSWR(
    ["VestingSchedule", "pendingClaim", isActive, chainId],
    () => quickContracts.fetch("VestingSchedule", "pendingClaim", [account?.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const { data: NFTbalanceOf } = useSWR(
    ["FoxifyVestedNFT", "NFTbalanceOf", isActive, chainId],
    () => quickContracts.fetch("FoxifyVestedNFT", "balanceOf", [account?.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const { data: totalRewardsEbFox } = useSWR(
    ["EbFox", "getTotalProfits", isActive, chainId],
    () => quickContracts.fetch("EbFox", "getTotalProfits", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );
  const { data: totalRewardsFoxifyMaxi } = useSWR(
    ["FoxifyMaxi", "getTotalProfits", isActive, chainId],
    () => quickContracts.fetch("FoxifyMaxi", "getTotalProfits", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const { data: usersEbFoxStakedAmount } = useSWR(
    ["EbFox", "balanceOf", isActive, chainId],
    () => quickContracts.fetch("EbFox", "balanceOf", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );
  const { data: usersFoxifyMaxiStakedAmount } = useSWR(
    ["FoxifyMaxi", "balanceOf", isActive, chainId],
    () => quickContracts.fetch("FoxifyMaxi", "balanceOf", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const { data: maxWithdrawableTokens } = useSWR(
    ["FoxifyMaxi", "maxWithdrawableTokens", isActive, chainId],
    () => quickContracts.fetch("FoxifyMaxi", "maxWithdrawableTokens", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const ebFoxUserStaked = usersEbFoxStakedAmount ? usersEbFoxStakedAmount : BigNumber.from(0);
  const foxifyMaxiUserStaked = usersFoxifyMaxiStakedAmount ? usersFoxifyMaxiStakedAmount : BigNumber.from(0);

  const foxPriceBigNum = BigNumber.from(String(Math.floor(foxPrice * 10 ** 18)));

  const foxBalanceUsd = foxBalance
    ? foxBalance.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);

  const ebFoxTotalStakedUsd = ebFoxStakingData?.totalSupply
    ? ebFoxStakingData.totalSupply.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);
  const ebFoxUserStakedUsd = ebFoxUserStaked
    ? ebFoxUserStaked.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);

  const foxifyMaxiTotalStakedUsd = foxifyMaxiStakingData?.totalSupply
    ? foxifyMaxiStakingData.totalSupply.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);
  const foxifyMaxiUserStakedUsd = foxifyMaxiUserStaked
    ? foxifyMaxiUserStaked.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);

  const maxWithdrawableTokensUsd = maxWithdrawableTokens
    ? maxWithdrawableTokens.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);

  const rewardsEbFoxUsd =
    totalRewardsEbFox && foxPriceBigNum
      ? totalRewardsEbFox.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
      : BigNumber.from(0);
  const rewardsFoxifyMaxiUsd =
    totalRewardsFoxifyMaxi && foxPriceBigNum
      ? totalRewardsFoxifyMaxi.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
      : BigNumber.from(0);
  const totalRewardsEbFoxUsd =
    totalRewardsEbFox && foxPriceBigNum
      ? totalRewardsEbFox.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
      : BigNumber.from(0);
  const totalRewardsFoxifyMaxiUsd =
    totalRewardsFoxifyMaxi && foxPriceBigNum
      ? totalRewardsFoxifyMaxi.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
      : BigNumber.from(0);

  const ebFoxTotalStakedFormatted = `${
    switchCurrency[0] && ebFoxStakingData?.totalSupply
      ? formatAmount(ebFoxTotalStakedUsd, FOX_STABLE_DECIMALS, 2, true)
      : formatAmount(ebFoxStakingData?.totalSupply, FOX_DECIMALS, 2, true)
  } ${switchCurrency[0] ? "$" : "FOX"}`;
  const foxifyMaxiTotalStakedFormatted = `${
    switchCurrency[0] && foxifyMaxiStakingData?.totalSupply
      ? formatAmount(foxifyMaxiTotalStakedUsd, FOX_STABLE_DECIMALS, 2, true)
      : formatAmount(foxifyMaxiStakingData?.totalSupply, FOX_DECIMALS, 2, true)
  } ${switchCurrency[0] ? "$" : "FOX"}`;

  // APY calculation
  useEffect(() => {
    if (provider) {
      const init = async () => {
        const blockNumber = Number(await provider.getBlockNumber());
        setCurrentBlockNumber(blockNumber);
        setCurrentTime(new Date());
      };
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApyInfo = async (apyProvider) => {
    if (!apyProvider) return;
    const getPastBlockNumberRelativeToMidnight = (daysAgo) => {
      const averageBlockTime = 0.3; // 0.3 seconds per block, see https://arbiscan.io/ - latest block section
      const numberOfSecondsPerDay = 86400; //24 * 60 * 60;
      const mostRecentMidnight = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        0,
        0,
        0
      );
      const timeDiffInMS = currentTime.getTime() - mostRecentMidnight.getTime();
      const timeDiffInSecs = daysAgo === 0 ? timeDiffInMS / 1000 : numberOfSecondsPerDay;
      const numberOfBlocks = (timeDiffInSecs / averageBlockTime) * (daysAgo === 0 ? 1 : daysAgo);
      return Math.round(currentBlockNumber - numberOfBlocks);
    };

    const dailyToYearlyApy = (dailyPriceDifference, initialPrice) =>
      ((dailyPriceDifference / initialPrice + 1) ** 365 - 1) * 100;

    const averageDailyToYearlyApy = (dailyTokenValues, initialPrice) => {
      const apys = [];
      const dailyPriceDifferences = [];
      let averageApy = 0.0;

      if (dailyTokenValues.length === 1) {
        return dailyToYearlyApy(dailyTokenValues[0], initialPrice);
      } else if (dailyTokenValues.length === 0) {
        return;
      }

      for (let i = 0; i < dailyTokenValues.length - 1; i++) {
        dailyPriceDifferences.push(dailyTokenValues[i + 1] - dailyTokenValues[i]);
        apys.push(dailyToYearlyApy(dailyPriceDifferences[i], dailyTokenValues[i]));

        averageApy += apys[i];
      }
      averageApy = averageApy / dailyPriceDifferences.length;
      return averageApy;
    };

    const initialPriceEbFox = 1.338;
    const dailyTokenValues = (contract) => {
      return Promise.allSettled(
        Array(7)
          .fill(0)
          .map((_, i) => i)
          .map(async (daysAgo) => {
            const blockNum = getPastBlockNumberRelativeToMidnight(daysAgo);
            return await contract.calculatePrice({ blockTag: blockNum });
          })
      );
    };

    const ebFoxAddress = getContractAddress(chainId, "EbFox");
    const foxifyMaxiAddress = getContractAddress(chainId, "FoxifyMaxi");
    const ebFoxContract = new ethers.Contract(ebFoxAddress, EbFox.abi, apyProvider);
    const foxifyMaxiContract = new ethers.Contract(foxifyMaxiAddress, FoxifyMaxi.abi, apyProvider);

    const resolvedTokenValuesEbFox = await dailyTokenValues(ebFoxContract);
    const resolvedTokenValuesFoxifyMaxi = await dailyTokenValues(foxifyMaxiContract);

    const formattedTokenValuesEbFox = resolvedTokenValuesEbFox
      .map((x) => {
        return x?.value && +ethers.utils.formatEther(x.value?.toString());
      })
      .reverse();
    const formattedTokenValuesFoxifyMaxi = resolvedTokenValuesFoxifyMaxi
      .map((x) => {
        return x?.value && +ethers.utils.formatEther(x.value?.toString());
      })
      .reverse();
    setApyEbFox(averageDailyToYearlyApy(formattedTokenValuesEbFox, initialPriceEbFox) * 3);
    setApyFoxifyMaxi(averageDailyToYearlyApy(formattedTokenValuesFoxifyMaxi, initialPriceEbFox) * 3);
  };

  const isScrollableList = (element) => {
    return element.scrollWidth > element.clientWidth;
  };
  const handleScroll = () => {
    const listWrapper = document.querySelector(".Stake-chart-list");
    const scrollThreshold = 10;
    if (listWrapper) {
      const maxScrollLeft = listWrapper.scrollWidth - listWrapper.clientWidth;
      const currentScrollLeft = listWrapper.scrollLeft;
      const isEndListReached = maxScrollLeft - currentScrollLeft <= scrollThreshold;

      if (isEndListReached) {
        listWrapper.scrollTo({
          left: 0,
          behavior: "smooth",
        });
        setIsEndListReached(false);
      } else {
        listWrapper.scrollTo({
          left: maxScrollLeft,
          behavior: "smooth",
        });
        setIsEndListReached(true);
      }
    }
  };

  const checkScrollButtonVisibility = () => {
    const listWrapper = document.querySelector(".Stake-chart-list");
    if (listWrapper) {
      const scrollable = isScrollableList(listWrapper);
      setIsScrollButtonVisible(scrollable);
    }
  };

  useEffect(() => {
    (async () => {
      const apyProvider = getProvider(undefined, chainId);
      await getApyInfo(apyProvider);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkScrollButtonVisibility();
    window.addEventListener("resize", checkScrollButtonVisibility);
    return () => {
      window.removeEventListener("resize", checkScrollButtonVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (NFTbalanceOf && NFTbalanceOf.gt(0)) {
      setIsUserHasNFT(NFTbalanceOf);
    }
  }, [NFTbalanceOf]);

  const totalRewardsForBothPools =
    totalRewardsFoxifyMaxi && totalRewardsEbFox ? totalRewardsFoxifyMaxi.add(totalRewardsEbFox) : BigNumber.from(0);

  const tvl =
    ebFoxStakingData?.totalSupply && foxifyMaxiStakingData?.totalSupply
      ? ebFoxStakingData?.totalSupply
          .add(foxifyMaxiStakingData?.totalSupply)
          .mul(foxPriceBigNum)
          .div(expandDecimals(1, FOX_STABLE_DECIMALS))
      : BigNumber.from(0);

  // Statistics

  const totalStakedStatistic =
    foxifyMaxiStakingData?.totalSupply && ebFoxStakingData?.totalSupply
      ? foxifyMaxiStakingData?.totalSupply.add(ebFoxStakingData?.totalSupply)
      : BigNumber.from(0);
  const totalStakedStatisticInUsd = totalStakedStatistic
    ? totalStakedStatistic.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);

  const totalFoxNotStakedAmount =
    totalFoxSupply?.gt(0) && totalStakedStatistic?.gt(0) ? totalFoxSupply.sub(totalStakedStatistic) : BigNumber.from(0);
  const totalFoxNotStakedAmountInUsd = totalFoxNotStakedAmount
    ? totalFoxNotStakedAmount.mul(foxPriceBigNum).div(expandDecimals(1, FOX_STABLE_DECIMALS))
    : BigNumber.from(0);

  // const buyBackAmount = BigNumber.from(0);

  // const total = totalStakedStatistic?.add(totalFoxNotStakedAmount)?.add(buyBackAmount);
  const total = totalStakedStatistic?.add(totalFoxNotStakedAmount);
  const percentStaked =
    total && total.gt(0)
      ? totalStakedStatistic.mul(100).mul(expandDecimals(1, 30)).div(total).div(expandDecimals(1, 28)).toNumber()
      : 50;
  const percentNotStaked =
    total && total.gt(0)
      ? totalFoxNotStakedAmount.mul(100).mul(expandDecimals(1, 30)).div(total).div(expandDecimals(1, 28)).toNumber()
      : 50;
  // const percentBuyBack =
  //   total && total.gt(0)
  //     ? buyBackAmount.mul(100).mul(expandDecimals(1, 30)).div(total).div(expandDecimals(1, 28)).toNumber()
  //     : 30;

  const chartData = [
    {
      id: 1,
      name: "Staked",
      value: percentStaked || 0,
      color: "url(#gradient1)",
    },
    {
      id: 2,
      name: "Not Staked",
      value: percentNotStaked || 0,
      color: "url(#gradient2)",
    },
    // {
    //   id: 3,
    //   name: "Buyback amount",
    //   value: percentBuyBack || 0,
    //   color: "url(#gradient3)",
    // },
  ];

  const isMobile = useMedia("(max-width: 767px)");

  const LIST_SECTIONS = ["Stake", pendingClaim && pendingClaim.gt(0) ? "Vesting" : ""].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-stake-vesting-tabs", LIST_SECTIONS[0]);

  const LIST_SECTIONS_LABELS = {
    Stake: t`Stake`,
    Vesting: t`Vesting`,
  };

  return (
    <SEO title={getPageTitle("Stake FOX")}>
      <div className="Stake-page page-layout Stake-default-container tailwind pt-[9.5rem]">
        <div className="Stake-container">
          <div className="Main-content">
            <div
              className={cx("Stake-card Stake-chart-container", {
                "grid-rows-[repeat(2,_auto)]": openChartAccordion && isMobile,
              })}
            >
              <div
                className={cx({
                  "Accordion-head": isMobile,
                  "flex-col": isMobile,
                  "items-center": isMobile,
                  "Stake-chart-title": !isMobile,
                })}
              >
                <div className="Stake-card-title">
                  <Trans>Fox staking</Trans>
                </div>
                <div className="Stake-card-subtitle">
                  <Trans>Stake FOX to earn a share of the protocol's revenue.</Trans>
                </div>

                <div
                  className="Chevron-icon-container shrink-0 flex md:hidden"
                  onClick={() => setOpenChartAccordion(!openChartAccordion)}
                >
                  {openChartAccordion ? <HiChevronUp /> : <HiChevronDown />}
                </div>
              </div>

              {isMobile ? (
                <div
                  className={cx("Accordion-body", {
                    openChartAccordion: openChartAccordion,
                  })}
                >
                  <StakingChart chartData={chartData} price={foxPrice} isPriceLoading={isPriceLoading} />

                  <div className="Stake-chart-docs text-right">
                    <div className="Stake-card-title">
                      <Trans>
                        $
                        {tvl &&
                          formatBigAmount(tvl, FOX_DECIMALS, {
                            mantissa: 1,
                            average: true,
                            totalLength: 2,
                            optionalMantissa: true,
                          })}
                        TVL
                      </Trans>
                    </div>
                    <a
                      href="https://docs.foxify.trade/"
                      target="_blank"
                      rel="noreferrer"
                      className="Stake-card-docs-button transition-effect"
                    >
                      <img src={docsIcon} alt="docs" />{" "}
                      <span>
                        <Trans>View Documentation</Trans>
                      </span>
                    </a>
                  </div>

                  <div className="Stake-chart-data">
                    <div className="Stake-chart-list-wrapper relative">
                      <ul className="flex flex-wrap gap-[1.6rem] xl:gap-[3.6rem] Stake-chart-list">
                        <li className="Stake-chart-list-item">
                          <span className="w-[2rem] h-[0.5rem] inline-block rounded-[500px] Staked-token-color mb-[1rem]"></span>
                          <div className="Description-reward-amount-usd">Staked Tokens</div>
                          <div className="Description-reward-amount">
                            {totalStakedStatistic && formatAmount(totalStakedStatistic, FOX_DECIMALS, 2, true)} FOX
                          </div>
                          <div className="Description-reward-amount-usd">
                            ~$
                            {totalStakedStatisticInUsd &&
                              formatAmount(totalStakedStatisticInUsd, FOX_DECIMALS, 2, true)}
                          </div>
                        </li>
                        <li className="Stake-chart-list-item">
                          <span className="w-[2rem] h-[0.5rem] inline-block rounded-[500px] Not-staked-token-color mb-[1rem]"></span>
                          <div className="Description-reward-amount-usd">Not Staked Tokens</div>
                          <div className="Description-reward-amount">
                            {totalFoxNotStakedAmount && formatAmount(totalFoxNotStakedAmount, FOX_DECIMALS, 0, true)}{" "}
                            FOX
                          </div>
                          <div className="Description-reward-amount-usd">
                            ~$
                            {totalFoxNotStakedAmountInUsd &&
                              formatAmount(totalFoxNotStakedAmountInUsd, FOX_DECIMALS, 2, true)}
                          </div>
                        </li>
                        {/* <li className="Stake-chart-list-item">
                          <span className="w-[2rem] h-[0.5rem] inline-block rounded-[500px] Buyback-amount-color mb-[1rem]"></span>
                          <div className="Description-reward-amount-usd">Buyback Amount</div>
                          <div className="Description-reward-amount">0.00 FOX</div>
                          <div className="Description-reward-amount-usd">~$0.0</div>
                        </li> */}
                      </ul>
                      {isScrollButtonVisible && (
                        <button className="scroll-button" onClick={handleScroll}>
                          {isEndListReached ? <HiChevronLeft /> : <HiChevronRight />}
                        </button>
                      )}
                    </div>

                    <a
                      className="inline-block text-main px-[2rem] py-[1rem] rounded-[1.5rem] mt-[3rem] Stake-chart-buy-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://app.balancer.fi/#/arbitrum/swap"
                    >
                      Buy FOX
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <div className="Stake-chart-docs text-right">
                    <div className="Stake-card-title">
                      <Trans>
                        $
                        {tvl &&
                          formatBigAmount(tvl, FOX_DECIMALS, {
                            mantissa: 1,
                            average: true,
                            totalLength: 2,
                            optionalMantissa: true,
                          })}{" "}
                        TVL
                      </Trans>
                    </div>
                    <a
                      href="https://docs.foxify.trade/"
                      target="_blank"
                      rel="noreferrer"
                      className="Stake-card-docs-button transition-effect"
                    >
                      <img src={docsIcon} alt="docs" />{" "}
                      <span>
                        <Trans>View Documentation</Trans>
                      </span>
                    </a>
                  </div>
                  <StakingChart chartData={chartData} price={foxPrice} isPriceLoading={isPriceLoading} />

                  <div className="Stake-chart-data">
                    <ul className="flex flex-wrap gap-[1.6rem] xl:gap-[3rem] Stake-chart-list ">
                      <li>
                        <span className="w-[2rem] h-[0.5rem] inline-block rounded-[500px] Staked-token-color mb-[1rem]"></span>
                        <div className="Description-reward-amount-usd">Staked Tokens</div>
                        <div className="Description-reward-amount">
                          {totalStakedStatistic?.gt(0)
                            ? formatAmount(totalStakedStatistic, FOX_DECIMALS, 0, true)
                            : "0.00"}{" "}
                          FOX
                        </div>
                        <div className="Description-reward-amount-usd">
                          ~$
                          {totalStakedStatisticInUsd && formatAmount(totalStakedStatisticInUsd, FOX_DECIMALS, 2, true)}
                        </div>
                      </li>
                      <li>
                        <span className="w-[2rem] h-[0.5rem] inline-block rounded-[500px] Not-staked-token-color mb-[1rem]"></span>
                        <div className="Description-reward-amount-usd">Not Staked Tokens</div>
                        <div className="Description-reward-amount">
                          {totalFoxNotStakedAmount?.gt(0)
                            ? formatAmount(totalFoxNotStakedAmount, FOX_DECIMALS, 0, true)
                            : "0.00"}{" "}
                          FOX
                        </div>
                        <div className="Description-reward-amount-usd">
                          ~$
                          {totalFoxNotStakedAmountInUsd &&
                            formatAmount(totalFoxNotStakedAmountInUsd, FOX_DECIMALS, 2, true)}
                        </div>
                      </li>
                      {/* <li>
                        <span className="w-[2rem] h-[0.5rem] inline-block rounded-[500px] Buyback-amount-color mb-[1rem]"></span>
                        <div className="Description-reward-amount-usd">Buyback Amount</div>
                        <div className="Description-reward-amount">0.00 FOX</div>
                        <div className="Description-reward-amount-usd">~$0.00</div>
                      </li> */}
                    </ul>

                    <a
                      className="inline-block text-main px-[2rem] py-[1rem] rounded-[1.5rem] mt-[3rem] Stake-chart-buy-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://app.balancer.fi/#/arbitrum/swap"
                    >
                      Buy FOX
                    </a>
                  </div>
                </>
              )}
            </div>

            <div className="Description-card">
              <div className="Decription-card-page-title-wrapper">
                <div className="Stake-card-title">
                  <Trans>My Total Rewards</Trans>
                </div>
                <div className="Description-card-page-subtitle">
                  <Trans>Your reward details</Trans>
                </div>
              </div>

              <div className="Description-card-bottom">
                <div className="Description-card-rewards-wrapper">
                  <div className="Description-rewards-infos">
                    <div className="Description-reward-title">
                      <Trans>FOX Rewards</Trans>
                    </div>
                    <div className="Description-reward-amount">
                      <span>
                        {totalRewardsFoxifyMaxi ? formatAmount(totalRewardsFoxifyMaxi, FOX_DECIMALS, 2, true) : "0.00"}{" "}
                        FOX
                      </span>
                    </div>
                    <div className="Description-reward-amount-usd">
                      ~${formatAmount(totalRewardsFoxifyMaxiUsd, FOX_DECIMALS, 2, true)}
                    </div>
                  </div>
                  <div className="Description-rewards-infos">
                    <div className="Description-reward-title">
                      <Trans>ebFOX Rewards</Trans>
                    </div>
                    <div className="Description-reward-amount">
                      <span>
                        {totalRewardsEbFox ? formatAmount(totalRewardsEbFox, FOX_DECIMALS, 2, true) : "0.00"} FOX
                      </span>
                    </div>
                    <div className="Description-reward-amount-usd">
                      ~${formatAmount(totalRewardsEbFoxUsd, FOX_DECIMALS, 2, true)}
                    </div>
                  </div>
                </div>
                <div className="Description-card-claim-wrapper justify-center">
                  <Trans>
                    In Total {totalRewardsForBothPools && formatAmount(totalRewardsForBothPools, FOX_DECIMALS, 2, true)}{" "}
                    FOX
                  </Trans>
                </div>
              </div>
            </div>
          </div>

          <div className="Earn-tabs">
            <Tab
              options={LIST_SECTIONS}
              optionLabels={LIST_SECTIONS_LABELS}
              option={listSection}
              onChange={(section) => setListSection(section)}
              type="table"
              className="Exchange-list-tabs gap-[1rem] md:gap-[0]"
            />
            {listSection === "Stake" && (
              <div className="Tab-content flex-column">
                <div className="inactive-text Earn-tab-description">
                  <Trans>Stake FOX to earn rewards.</Trans>
                </div>
                <div
                  className={cx({
                    "bg-[rgba(246,_107,_48,_0.10)]": isUserHasNFT,
                    "Stake-card-wrapper": isUserHasNFT,
                  })}
                >
                  {!isMobile ? (
                    <div className="Stake-top-container">
                      {NFTbalanceOf && NFTbalanceOf?.gt(0) && <StakeNFT handleStakeModal={handleStakeModal} />}
                      <div className="Stake-card flex-column">
                        <div className="Accordion-head">
                          <div className="PLPAccordion">
                            <div className="info-block-logo gap-[2rem]">
                              <div className="inline-block w-[4.4rem] h-[4.4rem] bg-black rounded-full shrink-0 flex justify-center items-center">
                                <img src={foxLogo} alt="Fox logo" className="Token-icon-wrapper" />
                              </div>
                              <div className="info-block">
                                <div className="Stake-card-title  accordion-title">
                                  <Trans>FOX</Trans>
                                </div>
                                <div className=" currency-price">
                                  ${foxPrice ? parseFloat(foxPrice).toFixed(3) : "0.00"}
                                </div>
                              </div>
                            </div>

                            <div className="two-column-row">
                              <div className="info-block">
                                <div className="gap-container">
                                  <Tooltip
                                    position="center-bottom"
                                    renderContent={() => {
                                      return (
                                        <div className="Stake-card-infobox-tooltip">
                                          <Trans>
                                            APYs are updated daily and will depend on the fees collected for the day.
                                          </Trans>
                                        </div>
                                      );
                                    }}
                                  >
                                    <div className="Tooltip-handle-wrapper">
                                      <div className="inactive-text">APY</div>
                                      <HiQuestionMarkCircle className="inactive-text" />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className="white-text">{apyFoxifyMaxi ? apyFoxifyMaxi.toFixed(2) : "0.00"}%</div>
                              </div>
                              <div className="info-block">
                                <div className="inactive-text">
                                  <Trans>Total Staked</Trans>
                                </div>
                                <div className="gap-container">
                                  <div className="white-text currency-text">{foxifyMaxiTotalStakedFormatted}</div>
                                  <div className="usd-tag" onClick={() => handleSwitchCurrency(0)}>
                                    <img src={swapCurrency} alt="swap" />
                                    {switchCurrency[0] ? "FOX" : "USD"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="two-column-row">
                              {/* <div className="info-block">
                          <div className="gap-container">
                            <Tooltip
                              position="center-bottom"
                              renderContent={() => {
                                return (
                                  <div className="Stake-card-infobox-tooltip">
                                    <Trans>Total earnings of all users together</Trans>
                                  </div>
                                );
                              }}
                            >
                              <div className="Tooltip-handle-wrapper">
                                <div className="inactive-text">
                                  <Trans>Total Earned</Trans>
                                </div>
                                <HiQuestionMarkCircle className="inactive-text" />
                              </div>
                            </Tooltip>
                          </div>
                          <div className="white-text">$0.00</div>
                        </div> */}
                              <div className="info-block">
                                <div className="gap-container">
                                  <Tooltip
                                    position="center-bottom"
                                    renderContent={() => {
                                      return (
                                        <div className="Stake-card-infobox-tooltip">
                                          <Trans>Stake fees are paid when depositing.</Trans>
                                        </div>
                                      );
                                    }}
                                  >
                                    <div className="Tooltip-handle-wrapper">
                                      <div className="inactive-text">
                                        <Trans>Stake Fee</Trans>
                                      </div>
                                      <HiQuestionMarkCircle className="inactive-text" />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className="white-text">0.00%</div>
                              </div>

                              <div className="info-block">
                                <div className="gap-container">
                                  <Tooltip
                                    position="center-bottom"
                                    renderContent={() => {
                                      return (
                                        <div className="Stake-card-infobox-tooltip">
                                          <Trans>Unstake Fee will be paid when withdrawing</Trans>
                                        </div>
                                      );
                                    }}
                                  >
                                    <div className="Tooltip-handle-wrapper">
                                      <div className="inactive-text">
                                        <Trans>Unstake Fee</Trans>
                                      </div>
                                      <HiQuestionMarkCircle className="inactive-text" />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className="white-text">0.00%</div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="Chevron-icon-container shrink-0"
                            onClick={() => setopenFoxAccordion(!openFoxAccordion)}
                          >
                            {openFoxAccordion ? <HiChevronUp /> : <HiChevronDown />}
                          </div>
                        </div>

                        <div
                          className={cx("Accordion-body", {
                            openFoxAccordion: openFoxAccordion,
                          })}
                        >
                          <div className="Card-divider"></div>
                          <div className="flex-row overflow-x-auto pb-[2rem] Accordion-body-scroll">
                            <div className="info-block">
                              <div className="inactive-text">
                                <Trans>Wallet</Trans>
                              </div>
                              <div className="white-text-big">
                                {foxBalance ? formatAmount(foxBalance, FOX_DECIMALS, 2, true) : "0.00"} FOX
                              </div>
                              <div className="inactive-text-small">
                                ~${foxBalanceUsd ? formatAmount(foxBalanceUsd, FOX_DECIMALS, 2, true) : "0.00"}
                              </div>
                              <a
                                className="Stake-button Stake-button-white"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://app.balancer.fi/#/arbitrum/swap"
                              >
                                <Trans>Buy FOX</Trans>
                              </a>
                            </div>
                            <div className="info-block">
                              <div className="inactive-text">
                                <Trans>Staked Amount</Trans>
                              </div>
                              <div className="white-text-big">
                                {foxifyMaxiUserStaked
                                  ? formatAmount(foxifyMaxiUserStaked, FOX_DECIMALS, 2, true)
                                  : "0.00"}{" "}
                                FOX
                              </div>
                              <div className="inactive-text-small">
                                ~$
                                {foxifyMaxiUserStakedUsd
                                  ? formatAmount(foxifyMaxiUserStakedUsd, FOX_DECIMALS, 2, true)
                                  : "0.00"}
                              </div>
                              <div className="gap-container">
                                <button
                                  className="Stake-button Stake-button-white"
                                  onClick={() => {
                                    handleStakeModal(FOX_TOKEN, STAKE_ACTION);
                                  }}
                                >
                                  <Trans>+ Stake</Trans>
                                </button>
                                <button
                                  className="Stake-button Stake-button-white round-button"
                                  onClick={() => {
                                    handleStakeModal(FOX_TOKEN, UNSTAKE_ACTION);
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            </div>

                            <div className="info-block">
                              <div className="inactive-text">
                                <Trans>Withdrawable balance</Trans>
                              </div>
                              <div className="white-text-big">
                                {maxWithdrawableTokens
                                  ? formatAmount(maxWithdrawableTokens, FOX_DECIMALS, 2, true)
                                  : "0.00"}{" "}
                                FOX
                              </div>
                              <div className="inactive-text-small">
                                ~$
                                {maxWithdrawableTokensUsd
                                  ? formatAmount(maxWithdrawableTokensUsd, FOX_DECIMALS, 2, true)
                                  : "0.00"}
                              </div>
                            </div>

                            <div className="info-block w-[18rem]">
                              <div className="inactive-text">
                                <Trans>My Total Rewards</Trans>
                              </div>
                              <div className="white-text-big">
                                {totalRewardsFoxifyMaxi
                                  ? formatAmount(totalRewardsFoxifyMaxi, FOX_DECIMALS, 2, false)
                                  : "0.00"}{" "}
                                FOX
                              </div>
                              <div className="inactive-text-small">
                                ~$
                                {rewardsFoxifyMaxiUsd
                                  ? formatAmount(rewardsFoxifyMaxiUsd, FOX_DECIMALS, 2, false)
                                  : "0.00"}
                              </div>
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>Auto-Compound Tooltip</Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="Description-card-claim-wrapper justify-center mt-4 h-16 px-5">
                                    <Trans>Auto-Compound</Trans>
                                    <HiQuestionMarkCircle className="inactive-text ml-3" />
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {NFTbalanceOf && NFTbalanceOf?.gt(0) && <StakeNFT handleStakeModal={handleStakeModal} />}
                      <div className="Stake-card flex-column">
                        <div className="Accordion-head-mobile">
                          <div className="info-block-logo gap-[2rem]">
                            <div className="inline-block w-[4.4rem] h-[4.4rem] bg-black rounded-full shrink-0 flex justify-center items-center">
                              <img src={foxLogo} alt="Fox logo" className="Token-icon-wrapper" />
                            </div>
                            <div className="info-block">
                              <div className="Stake-card-title  accordion-title">
                                <Trans>FOX</Trans>
                              </div>
                              <div className=" currency-price">
                                ${foxPrice ? parseFloat(foxPrice).toFixed(3) : "0.00"}
                              </div>
                            </div>
                          </div>

                          <div className="info-block apr">
                            <div className="gap-container">
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>
                                        APYs are updated daily and will depend on the fees collected for the day.
                                      </Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="inactive-text">APY</div>
                                  <HiQuestionMarkCircle className="inactive-text" />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="white-text">{apyFoxifyMaxi ? apyFoxifyMaxi.toFixed(2) : "0.00"}%</div>
                          </div>
                          <div className="info-block total-staked">
                            <div className="inactive-text">
                              <Trans>Total Staked</Trans>
                            </div>
                            <div className="gap-container">
                              <div className="white-text currency-text">{foxifyMaxiTotalStakedFormatted}</div>
                              <div className="usd-tag" onClick={() => handleSwitchCurrency(0)}>
                                <img src={swapCurrency} alt="swap" />
                                {switchCurrency[0] ? "FOX" : "USD"}
                              </div>
                            </div>
                          </div>
                          {/* <div className="info-block total-earned">
                      <div className="gap-container">
                        <Tooltip
                          position="center-bottom"
                          renderContent={() => {
                            return (
                              <div className="Stake-card-infobox-tooltip">
                                <Trans>Total earnings of all users together</Trans>
                              </div>
                            );
                          }}
                        >
                          <div className="Tooltip-handle-wrapper">
                            <div className="inactive-text">
                              <Trans>Total Earned</Trans>
                            </div>
                            <HiQuestionMarkCircle className="inactive-text" />
                          </div>
                        </Tooltip>
                      </div>
                      <div className="white-text">$0.00</div>
                    </div> */}
                          <div className="info-block stake-fee">
                            <div className="gap-container">
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>Stake fees are paid when depositing.</Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="inactive-text">
                                    <Trans>Stake Fee</Trans>
                                  </div>
                                  <HiQuestionMarkCircle className="inactive-text" />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="white-text">0.00%</div>
                          </div>

                          <div className="info-block unstake-fee">
                            <div className="gap-container">
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>Unstake Fee will be paid when withdrawing</Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="inactive-text">
                                    <Trans>Unstake Fee</Trans>
                                  </div>
                                  <HiQuestionMarkCircle className="inactive-text" />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="white-text">0.00%</div>
                          </div>
                          <div
                            className="Chevron-icon-container mob shrink-0"
                            onClick={() => setopenFoxAccordion(!openFoxAccordion)}
                          >
                            {openFoxAccordion ? (
                              <>
                                <HiChevronUp />
                                <span>Show less</span>
                              </>
                            ) : (
                              <>
                                <HiChevronDown />
                                <span>Show more</span>
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          className={cx("Accordion-body", {
                            openFoxAccordion: openFoxAccordion,
                          })}
                        >
                          <div
                            className={cx("Card-divider", {
                              "mb-0": openFoxAccordion,
                            })}
                          ></div>
                          <div className="mob">
                            <div className="info-block wallet">
                              <div className="inactive-text">
                                <Trans>Wallet</Trans>
                              </div>
                              <div className="white-text-big">
                                {foxBalance ? formatAmount(foxBalance, FOX_DECIMALS, 2, true) : "0.00"} FOX
                              </div>
                              <div className="inactive-text-small">
                                ~${foxBalanceUsd ? formatAmount(foxBalanceUsd, FOX_DECIMALS, 2, true) : "0.00"}
                              </div>
                              <a
                                className="Stake-button Stake-button-white w-full"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://app.balancer.fi/#/arbitrum/swap"
                              >
                                <Trans>Buy FOX</Trans>
                              </a>
                            </div>
                            <div className="info-block staked-amount">
                              <div className="inactive-text">
                                <Trans>Staked Amount</Trans>
                              </div>
                              <div className="white-text-big">
                                {foxifyMaxiUserStaked
                                  ? formatAmount(foxifyMaxiUserStaked, FOX_DECIMALS, 2, true)
                                  : "0.00"}{" "}
                                FOX
                              </div>
                              <div className="inactive-text-small">
                                ~$
                                {foxifyMaxiUserStakedUsd
                                  ? formatAmount(foxifyMaxiUserStakedUsd, FOX_DECIMALS, 2, true)
                                  : "0.00"}
                              </div>
                              <div className="gap-container w-full">
                                <button
                                  className="Stake-button Stake-button-white grow"
                                  onClick={() => {
                                    handleStakeModal(FOX_TOKEN, STAKE_ACTION);
                                  }}
                                >
                                  <Trans>+ Stake</Trans>
                                </button>
                                <button
                                  className="Stake-button Stake-button-white round-button"
                                  onClick={() => {
                                    handleStakeModal(FOX_TOKEN, UNSTAKE_ACTION);
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            </div>

                            <div className="info-block">
                              <div className="inactive-text">
                                <Trans>Withdrawable balance</Trans>
                              </div>
                              <div className="white-text-big">
                                {maxWithdrawableTokens
                                  ? formatAmount(maxWithdrawableTokens, FOX_DECIMALS, 2, true)
                                  : "0.00"}{" "}
                                FOX
                              </div>
                              <div className="inactive-text-small">
                                ~$
                                {maxWithdrawableTokensUsd
                                  ? formatAmount(maxWithdrawableTokensUsd, FOX_DECIMALS, 2, true)
                                  : "0.00"}
                              </div>
                            </div>

                            <div className="info-block total-rewards">
                              <div className="inactive-text">
                                <Trans>My Total Rewards</Trans>
                              </div>
                              <div className="white-text-big">
                                {totalRewardsFoxifyMaxi
                                  ? formatAmount(totalRewardsFoxifyMaxi, FOX_DECIMALS, 2, false)
                                  : "0.00"}{" "}
                                FOX
                              </div>
                              <div className="inactive-text-small">
                                ~$
                                {rewardsFoxifyMaxiUsd
                                  ? formatAmount(rewardsFoxifyMaxiUsd, FOX_DECIMALS, 2, false)
                                  : "0.00"}
                              </div>
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>Auto-Compound Tooltip</Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="Description-card-claim-wrapper justify-center mt-4 h-16 px-5">
                                    <Trans>Auto-Compound</Trans>
                                    <HiQuestionMarkCircle className="inactive-text ml-3" />
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {!isMobile ? (
                  <div className="Stake-card flex-column">
                    <div className="Accordion-head">
                      <div className="PLPAccordion">
                        <div className="info-block-logo gap-[2rem]">
                          <div className="inline-block w-[4.4rem] h-[4.4rem] bg-black rounded-full shrink-0 flex justify-center items-center">
                            <img src={foxLogo} alt="Fox logo" className="Token-icon-wrapper" />
                          </div>
                          <div className="info-block">
                            <div className="Stake-card-title  accordion-title">
                              <Trans>ebFOX</Trans>
                            </div>
                            <div className=" currency-price">
                              ${foxPrice ? parseFloat(foxPrice).toFixed(3) : "0.00"}
                            </div>
                          </div>
                        </div>

                        <div className="two-column-row">
                          <div className="info-block">
                            <div className="gap-container">
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>
                                        APYs are updated daily and will depend on the fees collected for the day.
                                      </Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="inactive-text">APY</div>
                                  <HiQuestionMarkCircle className="inactive-text" />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="white-text">{apyEbFox ? apyEbFox.toFixed(2) : "0.00"}%</div>
                          </div>
                          <div className="info-block">
                            <div className="inactive-text">
                              <Trans>Total Staked</Trans>
                            </div>
                            <div className="gap-container">
                              <div className="white-text currency-text">{ebFoxTotalStakedFormatted}</div>
                              <div className="usd-tag" onClick={() => handleSwitchCurrency(0)}>
                                <img src={swapCurrency} alt="swap" />
                                {switchCurrency[0] ? "FOX" : "USD"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="two-column-row">
                          {/* <div className="info-block">
                        <div className="gap-container">
                          <Tooltip
                            position="center-bottom"
                            renderContent={() => {
                              return (
                                <div className="Stake-card-infobox-tooltip">
                                  <Trans>Total earnings of all users together</Trans>
                                </div>
                              );
                            }}
                          >
                            <div className="Tooltip-handle-wrapper">
                              <div className="inactive-text">
                                <Trans>Total Earned</Trans>
                              </div>
                              <HiQuestionMarkCircle className="inactive-text" />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="white-text">$0.00</div>
                      </div> */}
                          <div className="info-block">
                            <div className="gap-container">
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>Stake fees are paid when depositing.</Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="inactive-text">
                                    <Trans>Stake Fee</Trans>
                                  </div>
                                  <HiQuestionMarkCircle className="inactive-text" />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="white-text">
                              {ebFoxStakingData?.entryFee
                                ? formatAmount(ebFoxStakingData.entryFee, 1, 1, false)
                                : "0.00"}
                              %
                            </div>
                          </div>

                          <div className="info-block">
                            <div className="gap-container">
                              <Tooltip
                                position="center-bottom"
                                renderContent={() => {
                                  return (
                                    <div className="Stake-card-infobox-tooltip">
                                      <Trans>Unstake Fee will be paid when withdrawing</Trans>
                                    </div>
                                  );
                                }}
                              >
                                <div className="Tooltip-handle-wrapper">
                                  <div className="inactive-text">
                                    <Trans>Unstake Fee</Trans>
                                  </div>
                                  <HiQuestionMarkCircle className="inactive-text" />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="white-text">
                              {ebFoxStakingData?.exitFee ? formatAmount(ebFoxStakingData.exitFee, 1, 1, false) : "0.00"}
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="Chevron-icon-container shrink-0"
                        onClick={() => setopenEbFoxAccordion(!openEbFoxAccordion)}
                      >
                        {openEbFoxAccordion ? <HiChevronUp /> : <HiChevronDown />}
                      </div>
                    </div>

                    <div
                      className={cx("Accordion-body", {
                        openEbFoxAccordion: openEbFoxAccordion,
                      })}
                    >
                      <div className="Card-divider"></div>
                      <div className="flex-row overflow-x-auto pb-[2rem] Accordion-body-scroll">
                        <div className="info-block">
                          <div className="inactive-text">
                            <Trans>Wallet</Trans>
                          </div>
                          <div className="white-text-big">
                            {foxBalance ? formatAmount(foxBalance, FOX_DECIMALS, 2, true) : "0.00"} FOX
                          </div>
                          <div className="inactive-text-small">
                            ~${foxBalanceUsd ? formatAmount(foxBalanceUsd, FOX_DECIMALS, 2, true) : "0.00"}
                          </div>
                          <button
                            className="Stake-button Stake-button-white"
                            onClick={() => {
                              handleStakeModal(EBFOX_TOKEN, BUY_STAKE_ACTION);
                            }}
                          >
                            <Trans>Buy & Stake</Trans>
                          </button>
                        </div>
                        <div className="info-block">
                          <div className="inactive-text">
                            <Trans>Staked Amount</Trans>
                          </div>
                          <div className="white-text-big">
                            {ebFoxUserStaked ? formatAmount(ebFoxUserStaked, FOX_DECIMALS, 2, true) : "0.00"} FOX
                          </div>
                          <div className="inactive-text-small">
                            ~${ebFoxUserStakedUsd ? formatAmount(ebFoxUserStakedUsd, FOX_DECIMALS, 2, true) : "0.00"}
                          </div>
                          <div className="gap-container">
                            <button
                              className="Stake-button Stake-button-white"
                              onClick={() => {
                                handleStakeModal(EBFOX_TOKEN, STAKE_ACTION);
                              }}
                            >
                              <Trans>+ Stake</Trans>
                            </button>
                            <button
                              className="Stake-button Stake-button-white round-button"
                              onClick={() => {
                                handleStakeModal(EBFOX_TOKEN, UNSTAKE_ACTION);
                              }}
                            >
                              -
                            </button>
                          </div>
                        </div>

                        <div className="info-block w-[18rem]">
                          <div className="inactive-text">
                            <Trans>My Total Rewards</Trans>
                          </div>
                          <div className="white-text-big">
                            {totalRewardsEbFox ? formatAmount(totalRewardsEbFox, FOX_DECIMALS, 2, false) : "0.00"} FOX
                          </div>
                          <div className="inactive-text-small">
                            ~$
                            {rewardsEbFoxUsd ? formatAmount(rewardsEbFoxUsd, FOX_DECIMALS, 2, false) : "0.00"}
                          </div>
                          <Tooltip
                            position="center-bottom"
                            renderContent={() => {
                              return (
                                <div className="Stake-card-infobox-tooltip">
                                  <Trans>Auto-Compound Tooltip</Trans>
                                </div>
                              );
                            }}
                          >
                            <div className="Tooltip-handle-wrapper">
                              <div className="Description-card-claim-wrapper justify-center mt-4 h-16 px-5">
                                <Trans>Auto-Compound</Trans>
                                <HiQuestionMarkCircle className="inactive-text ml-3" />
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="Stake-card flex-column">
                    <div className="Accordion-head-mobile">
                      <div className="info-block-logo gap-[2rem]">
                        <div className="inline-block w-[4.4rem] h-[4.4rem] bg-black rounded-full shrink-0 flex justify-center items-center">
                          <img src={foxLogo} alt="Fox logo" className="Token-icon-wrapper" />
                        </div>
                        <div className="info-block">
                          <div className="Stake-card-title  accordion-title">
                            <Trans>ebFOX</Trans>
                          </div>
                          <div className=" currency-price">${foxPrice ? parseFloat(foxPrice).toFixed(3) : "0.00"}</div>
                        </div>
                      </div>

                      <div className="info-block apr">
                        <div className="gap-container">
                          <Tooltip
                            position="center-bottom"
                            renderContent={() => {
                              return (
                                <div className="Stake-card-infobox-tooltip">
                                  <Trans>
                                    APYs are updated daily and will depend on the fees collected for the day.
                                  </Trans>
                                </div>
                              );
                            }}
                          >
                            <div className="Tooltip-handle-wrapper">
                              <div className="inactive-text">APY</div>
                              <HiQuestionMarkCircle className="inactive-text" />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="white-text">{apyEbFox ? apyEbFox.toFixed(2) : "0.00"}%</div>
                      </div>
                      <div className="info-block total-staked">
                        <div className="inactive-text">
                          <Trans>Total Staked</Trans>
                        </div>
                        <div className="gap-container">
                          <div className="white-text currency-text">{ebFoxTotalStakedFormatted}</div>
                          <div className="usd-tag" onClick={() => handleSwitchCurrency(0)}>
                            <img src={swapCurrency} alt="swap" />
                            {switchCurrency[0] ? "FOX" : "USD"}
                          </div>
                        </div>
                      </div>
                      {/* <div className="info-block total-earned">
                    <div className="gap-container">
                      <Tooltip
                        position="center-bottom"
                        renderContent={() => {
                          return (
                            <div className="Stake-card-infobox-tooltip">
                              <Trans>Total earnings of all users together</Trans>
                            </div>
                          );
                        }}
                      >
                        <div className="Tooltip-handle-wrapper">
                          <div className="inactive-text">
                            <Trans>Total Earned</Trans>
                          </div>
                          <HiQuestionMarkCircle className="inactive-text" />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="white-text">$0.00</div>
                  </div> */}
                      <div className="info-block stake-fee">
                        <div className="gap-container">
                          <Tooltip
                            position="center-bottom"
                            renderContent={() => {
                              return (
                                <div className="Stake-card-infobox-tooltip">
                                  <Trans>Stake fees are paid when depositing.</Trans>
                                </div>
                              );
                            }}
                          >
                            <div className="Tooltip-handle-wrapper">
                              <div className="inactive-text">
                                <Trans>Stake Fee</Trans>
                              </div>
                              <HiQuestionMarkCircle className="inactive-text" />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="white-text">
                          {ebFoxStakingData?.entryFee ? formatAmount(ebFoxStakingData.entryFee, 1, 1, false) : "0.00"}%
                        </div>
                      </div>

                      <div className="info-block unstake-fee">
                        <div className="gap-container">
                          <Tooltip
                            position="center-bottom"
                            renderContent={() => {
                              return (
                                <div className="Stake-card-infobox-tooltip">
                                  <Trans>Unstake Fee will be paid when withdrawing</Trans>
                                </div>
                              );
                            }}
                          >
                            <div className="Tooltip-handle-wrapper">
                              <div className="inactive-text">
                                <Trans>Unstake Fee</Trans>
                              </div>
                              <HiQuestionMarkCircle className="inactive-text" />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="white-text">
                          {ebFoxStakingData?.entryFee ? formatAmount(ebFoxStakingData.exitFee, 1, 1, false) : "0.00"}%
                        </div>
                      </div>
                      <div
                        className="Chevron-icon-container mob shrink-0"
                        onClick={() => setopenEbFoxAccordion(!openEbFoxAccordion)}
                      >
                        {openEbFoxAccordion ? (
                          <>
                            <HiChevronUp />
                            <span>Show less</span>
                          </>
                        ) : (
                          <>
                            <HiChevronDown />
                            <span>Show more</span>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      className={cx("Accordion-body", {
                        openEbFoxAccordion: openEbFoxAccordion,
                      })}
                    >
                      <div
                        className={cx("Card-divider", {
                          "mb-0": openFoxAccordion,
                        })}
                      ></div>
                      <div className="mob">
                        <div className="info-block wallet">
                          <div className="inactive-text">
                            <Trans>Wallet</Trans>
                          </div>
                          <div className="white-text-big">
                            {foxBalance ? formatAmount(foxBalance, FOX_DECIMALS, 2, true) : "0.00"} FOX
                          </div>
                          <div className="inactive-text-small">
                            ~${foxBalanceUsd ? formatAmount(foxBalanceUsd, FOX_DECIMALS, 2, true) : "0.00"}
                          </div>
                          <button
                            className="Stake-button Stake-button-white"
                            onClick={() => {
                              handleStakeModal(EBFOX_TOKEN, BUY_STAKE_ACTION);
                            }}
                          >
                            <Trans>Buy & Stake</Trans>
                          </button>
                        </div>
                        <div className="info-block staked-amount">
                          <div className="inactive-text">
                            <Trans>Staked Amount</Trans>
                          </div>
                          <div className="white-text-big">
                            {ebFoxUserStaked ? formatAmount(ebFoxUserStaked, FOX_DECIMALS, 2, true) : "0.00"} FOX
                          </div>
                          <div className="inactive-text-small">
                            ~$
                            {ebFoxUserStakedUsd
                              ? formatAmount(ebFoxUserStakedUsd, FOX_STABLE_DECIMALS, 2, true)
                              : "0.00"}
                          </div>
                          <div className="gap-container w-full">
                            <button
                              className="Stake-button Stake-button-white grow"
                              onClick={() => {
                                handleStakeModal(EBFOX_TOKEN, STAKE_ACTION);
                              }}
                            >
                              <Trans>+ Stake</Trans>
                            </button>
                            <button
                              className="Stake-button Stake-button-white round-button"
                              onClick={() => {
                                handleStakeModal(EBFOX_TOKEN, UNSTAKE_ACTION);
                              }}
                            >
                              -
                            </button>
                          </div>
                        </div>

                        <div className="info-block total-rewards">
                          <div className="inactive-text">
                            <Trans>My Total Rewards</Trans>
                          </div>
                          <div className="white-text-big">
                            {totalRewardsEbFox ? formatAmount(totalRewardsEbFox, FOX_DECIMALS, 2, false) : "0.00"} FOX
                          </div>
                          <div className="inactive-text-small">
                            ~$
                            {rewardsEbFoxUsd ? formatAmount(rewardsEbFoxUsd, FOX_DECIMALS, 2, false) : "0.00"}
                          </div>
                          <Tooltip
                            position="center-bottom"
                            renderContent={() => {
                              return (
                                <div className="Stake-card-infobox-tooltip">
                                  <Trans>Auto-Compound Tooltip</Trans>
                                </div>
                              );
                            }}
                          >
                            <div className="Tooltip-handle-wrapper">
                              <div className="Description-card-claim-wrapper justify-center mt-4 h-16 px-5">
                                <Trans>Auto-Compound</Trans>
                                <HiQuestionMarkCircle className="inactive-text ml-3" />
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {listSection === "Vesting" && <Vesting pendingClaim={pendingClaim} />}
          </div>
        </div>
      </div>
      <StakeModal
        open={openStakeModal}
        foxBalance={foxBalance}
        setOpen={setOpenStakeModal}
        foxStaked={stakedAmountForTheModal}
        setPendingTxns={props.setPendingTxns}
        connectWallet={props.connectWallet}
        stakeModalData={stakeModalData}
        foxPrice={foxPrice}
        ethPrice={ethPrice}
        entryFee={ebFoxStakingData?.entryFee || BigNumber.from(0)}
        exitFee={ebFoxStakingData?.exitFee || BigNumber.from(0)}
      />
      <VestModal open={openVestModal} setOpen={setOpenVestModal} token={vestModalData} />
    </SEO>
  );
}

const StakingChart = ({ chartData, price, isPriceLoading }) => {
  const { isDarkTheme } = useThemeSwitcher();
  const renderLabel = () => {
    const labelBgColor = isDarkTheme ? "rgba(255, 255, 255, 0.08)" : "#f1f2f6";
    const labelPriceColor = isDarkTheme ? "#fff" : "#000";

    return (
      <g>
        <circle cx="50%" cy="50%" r="75" fill={labelBgColor} />
        <text x="50%" y="38%" textAnchor="middle" fontSize="14px" fill="#6c7284">
          Token Value
        </text>
        <text x="50%" y="52%" textAnchor="middle" fontSize="24px" fontWeight="600" fill={labelPriceColor}>
          {price && !isPriceLoading ? parseFloat(price).toFixed(2) : "-"}
        </text>
        <text x="50%" y="64%" textAnchor="middle" fontSize="14px" fill="#6c7284">
          USD
        </text>
      </g>
    );
  };
  return (
    <>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#fa4949" />
            <stop offset="100%" stopColor="#fabfa3" />
          </linearGradient>
        </defs>
      </svg>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#dee377" />
            <stop offset="100%" stopColor="#f4d434" />
          </linearGradient>
        </defs>
      </svg>
      {/* <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="gradient3" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f46930" />
            <stop offset="100%" stopColor="#cc390c" />
          </linearGradient>
        </defs>
      </svg> */}
      <ResponsiveContainer aspect={1} width={200}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={82}
            outerRadius={90}
            paddingAngle={8}
            labelLine={false}
            cornerRadius={15}
            activeShape={false}
          >
            {chartData.map((entry) => (
              <Cell key={entry.id} fill={entry.color} style={{ outline: "none" }} stroke="none" />
            ))}
            <Label content={renderLabel} position="center" />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

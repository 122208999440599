import { useChainId } from "lib/chains";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";
import { useTokenContract } from "pages/Event/hooks/blockchain/contracts/useTokenContract";
import { BigNumber } from "ethers";
import React from "react";

export const useTokenAllowance = (tokenAddress: string, holderAddress: string, spenderAddress: string) => {
  const { chainId } = useChainId();
  const { isActive, account } = useWeb3React();
  const tokenContract = useTokenContract(tokenAddress);
  const [nonce, setNonce] = React.useState(0);
  const [staleFetcherKey, setStaleFetcherKey] = React.useState<any>(null);
  const [isMutating, setIsMutating] = React.useState(false);

  const fetcherKey = React.useMemo(() => {
    return account ? [`Token:allowance:${isActive}`, chainId, tokenAddress, holderAddress, spenderAddress, nonce] : null;
  }, [account, isActive, chainId, tokenAddress, holderAddress, spenderAddress, nonce]);

  const isPending = React.useMemo(() => {
    return staleFetcherKey !== fetcherKey || isMutating;
  }, [staleFetcherKey, fetcherKey, isMutating]);

  const query = useSWR<{ allowance: BigNumber }>(
    fetcherKey,
    async ([_key, _chainId, _tokenAddress, _holderAddress, _spenderAddress, _nonce]: [
      key: string,
      chainId: number,
      tokenAddress: string,
      holderAddress: string,
      spenderAddress: string,
      nonce: number
    ]) => {
      const tempFetcherKey = fetcherKey;

      const [allowance] = await Promise.all([tokenContract.allowance(holderAddress, spenderAddress)]);

      setStaleFetcherKey(tempFetcherKey);

      return {
        allowance,
      };
    }
  );

  const haveEnoughAllowance = (requiredAllowance: BigNumber) => {
    if (requiredAllowance.eq(0)) {
      return true;
    }
    if (isPending || !query.data) {
      return false;
    }
    return requiredAllowance.lte(query.data.allowance);
  };

  const haveEnoughBalanceToApprove = (balance: BigNumber, requiredAllowance: BigNumber) => {
    if (haveEnoughAllowance(requiredAllowance)) {
      return true;
    }
    if (requiredAllowance.eq(0)) {
      return true;
    }
    return balance.gte(requiredAllowance);
  };

  const approve = async (amount: BigNumber) => {
    try {
      setIsMutating(true);

      const tx = await tokenContract.approve(spenderAddress, amount);
      await tx.wait();

      setNonce(tx.nonce);

      return tx;
    } finally {
      setIsMutating(false);
    }
  };

  return {
    allowance: query.data?.allowance,
    isPending,

    haveEnoughAllowance,
    haveEnoughBalanceToApprove,
    approve,
  };
};

import React from "react";
import cx from "classnames";
import { haveReactChildren } from "lib/react/haveReactChildren";

type ChildrenProps = {
  tableClassName: string;
};

type Props = {
  className?: string;
  children?: ((props: ChildrenProps) => React.ReactNode | undefined) | never[];
};

export const ActionsTableScrollLayout = (props: Props) => {
  return (
    <div
      className={cx(
        "max-h-[45rem] overflow-y-auto scroll-smooth isolate whitespace-nowrap hidden-scrollbar min-h-[28.85rem]",
        props.className
      )}
    >
      {haveReactChildren(props.children) && props.children({ tableClassName: "w-full min-h-0" })}
    </div>
  );
};

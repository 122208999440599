import React, { useState, useCallback } from "react";
import cx from "classnames";
import "./ChartTokenSelector.css";
import { LONG, SHORT } from "lib/legacy";
import { getWhitelistedTokens } from "config/tokens";
import { t } from "@lingui/macro";
import { getTokenInfo } from "domain/tokens/utils";
import { USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import { useChartPrices } from "domain/legacy";
import { ReactComponent as ShevronDownChart } from "img/shevron-down-chart.svg";
import { ReactComponent as FavoriteActive } from "img/favoriteActive.svg";
import { ReactComponent as FavoriteInactive } from "img/favoriteInactive.svg";
import { useLocalStorageReactive } from "lib/localStorage";
import { FAVORITES_LOCAL_STORAGE_KEY } from "config/localStorage";
import { useMedia } from "react-use";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import { TokenUtils } from "components/TokenUtils/index.tsx";
import ExchangeModal from "components/Modal/ExchangeModal";
import searchSymbol from "img/searchSymbol.svg";

function ChartTokenOption(props) {
  const { option, infoTokens, chainId, onSelect, selectedToken, favorites, toggleFavorite } = props;
  const tmp = getTokenInfo(infoTokens, option.address);

  const currentAveragePrice = tmp.maxPrice && tmp.minPrice ? tmp.maxPrice.add(tmp.minPrice).div(2) : null;
  const [priceData] = useChartPrices(chainId, TokenUtils.getSymbol(tmp), tmp.isStable, "1h", currentAveragePrice);

  let deltaPrice;
  let deltaPercentageStr;
  let delta;
  let deltaPercentage;

  const now = parseInt(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  if (priceData) {
    for (let i = priceData.length - 1; i > 0; i--) {
      const price = priceData[i];
      if (price.time < timeThreshold) {
        break;
      }

      deltaPrice = price.open;
    }
  }

  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
    delta = average - deltaPrice;
    deltaPercentage = (delta * 100) / average;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  return (
    <div className="ChartTokenOption tailwind">
      <div
        className={cx("menu-item", { active: selectedToken.name === option.name })}
        onClick={() => {
          onSelect(option);
        }}
      >
        <div className="menu-item-left">
          {favorites.includes(option.address) ? (
            <div>
              <FavoriteActive
                className="menu-item-favorite"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(option);
                }}
              />
            </div>
          ) : (
            <div>
              <FavoriteInactive
                className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(option);
                }}
              />
            </div>
          )}
          <TokenUtils.Logo token={option} className="w-[3rem] mr-[0.8rem]"></TokenUtils.Logo>

          <div className="token-label-wrapper">
            <span className="token-label">{TokenUtils.getSymbol(option)}/USD</span>
            <span className="token-label-name">{TokenUtils.getName(option)}</span>
          </div>
        </div>
        <div className="menu-item-right">
          <span style={{ marginLeft: 5 }} className="token-price">
            {tmp.maxPrice && formatAmount(tmp.maxPrice, USD_DECIMALS, option.priceDecimals, true)}
          </span>
          <span
            className={cx("token-price-delta", {
              positive: deltaPercentage > 0,
              negative: deltaPercentage < 0,
            })}
          >
            {!deltaPercentageStr && "-"}
            {deltaPercentageStr && deltaPercentageStr}
          </span>
        </div>
      </div>
    </div>
  );
}

export default function ChartTokenSelector(props) {
  const { chainId, selectedToken, onSelectToken, swapOption, infoTokens } = props;
  const [searchKeyword, setSearchKeyword] = useState("");
  const [favorites, setFavorites] = useLocalStorageReactive(FAVORITES_LOCAL_STORAGE_KEY, []);
  const [selectMarketMobileModal, setSelectMarketMobileModal] = useState(false);

  const [activeFilter, setActiveFilter] = useState("all");

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;

  let options;
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isNative);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);

  if (isLong) {
    options = indexTokens;
  }
  if (isShort) {
    options = shortableTokens;
  }

  const onSelect = async (token) => {
    onSelectToken(token);
    setIsTokenSelectorVisible(false);
  };

  var value = selectedToken;

  const onSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const visibleTokens = TokenUtils.filterVisible(options);

  const filteredTokens = visibleTokens.filter((item) => {
    const matchesSearch =
      TokenUtils.getName(item).toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      TokenUtils.getSymbol(item).toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1;

    if (activeFilter === "favorites") {
      return favorites.includes(item.address) && matchesSearch;
    }

    return matchesSearch;
  });

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0]);
    }
  };

  const toggleFavorite = (option) => {
    const favoriteArray = [...favorites];

    const updatedFavorites = favoriteArray.includes(option.address)
      ? favoriteArray.filter((item) => item !== option.address)
      : [...favoriteArray, option.address];

    setFavorites(updatedFavorites);
  };

  const isMobile = useMedia("(max-width: 1100px)");
  const [isTokenSelectorVisible, setIsTokenSelectorVisible] = useState(false);

  const CustomHeader = useCallback(() => {
    return (
      <div className="custom-header-wrapper">
        <button className={cx("App-cta small transparent chart-token-selector")}>
          {value && <TokenUtils.Logo token={value} className="w-[3rem] mr-[0.8rem]"></TokenUtils.Logo>}
          <span className="chart-token-selector--current">{TokenUtils.getSymbol(value)}/USD</span>
        </button>
        <div className="custom-header-close">
          <span className="muted">Close</span>
          {<ShevronDownChart className="stroke-inactive" />}
        </div>
      </div>
    );
  }, [value]);

  return (
    <div className="ChartTokenSelector-wrapper">
      {!isMobile && (
        <div className="ChartTokenSelector">
          <div className="custom-header-wrapper" onClick={() => setIsTokenSelectorVisible(true)}>
            <button className={cx("App-cta small transparent chart-token-selector")}>
              {value && <TokenUtils.Logo token={value} className="w-[3rem] mr-[0.8rem]"></TokenUtils.Logo>}
              <span className="chart-token-selector--current">{TokenUtils.getSymbol(value)}/USD</span>
            </button>
            <div className="custom-header-close">
              <span className="muted">All Markets</span>
              {<ShevronDownChart className="stroke-inactive" />}
            </div>
          </div>

          <ExchangeModal
            isVisible={isTokenSelectorVisible}
            setIsVisible={setIsTokenSelectorVisible}
            label={t`Connecting...`}
            customHeader={<CustomHeader />}
            disableBodyScrollLock={true}
          >
            <div className="chart-token-menu-items">
              <div className="Token-input-container">
                <img src={searchSymbol} alt="search" />

                <input
                  type="text"
                  className="Token-input"
                  placeholder={t`Search`}
                  value={searchKeyword}
                  onChange={(e) => onSearchKeywordChange(e)}
                  onKeyDown={_handleKeyDown}
                />
              </div>
              <div className="line"></div>

              <div className="market-filter-wrapper">
                <button
                  className={cx("market-filter", { active: activeFilter === "all" })}
                  onClick={() => handleFilterChange("all")}
                >
                  All
                </button>
                <button
                  className={cx("market-filter", { active: activeFilter === "favorites" })}
                  onClick={() => handleFilterChange("favorites")}
                >
                  Favorites
                </button>
              </div>
              <div className="line" />
              <div className="Chart-token-options">
                {filteredTokens.map((option, index) => (
                  <ChartTokenOption
                    key={index}
                    option={option}
                    infoTokens={infoTokens}
                    chainId={chainId}
                    onSelect={onSelect}
                    selectedToken={selectedToken}
                    favorites={favorites}
                    toggleFavorite={toggleFavorite}
                  />
                ))}
              </div>
            </div>
          </ExchangeModal>
        </div>
      )}
      {isMobile && (
        <div>
          <button
            className={cx("App-cta small transparent chart-token-selector")}
            onClick={() => setSelectMarketMobileModal(true)}
          >
            {value && <TokenUtils.Logo token={value} className="w-[3rem] mr-[0.8rem]"></TokenUtils.Logo>}

            <span className="chart-token-selector--current">{TokenUtils.getSymbol(value)}/USD</span>

            {<ShevronDownChart className="stroke-textColor" />}
          </button>

          <ModalWithPortal
            className="Connecting-wallet-modal"
            isVisible={selectMarketMobileModal}
            setIsVisible={setSelectMarketMobileModal}
            label={t`Select Market`}
          >
            <div className="menu-items-mobile chart-token-menu-items tailwind">
              <input
                type="text"
                className="Token-input"
                placeholder={t`Search`}
                value={searchKeyword}
                onChange={(e) => onSearchKeywordChange(e)}
                onKeyDown={_handleKeyDown}
              />

              <div className="market-filter-wrapper">
                <button
                  className={cx("market-filter", { active: activeFilter === "all" })}
                  onClick={() => handleFilterChange("all")}
                >
                  All {visibleTokens.length}
                </button>
                <button
                  className={cx("market-filter", { active: activeFilter === "favorites" })}
                  onClick={() => handleFilterChange("favorites")}
                >
                  Favorites ({favorites.length})
                </button>
              </div>

              {filteredTokens.map((option, index) => (
                <div onClick={() => setSelectMarketMobileModal(false)}>
                  <ChartTokenOption
                    key={index}
                    option={option}
                    infoTokens={infoTokens}
                    chainId={chainId}
                    onSelect={onSelect}
                    selectedToken={selectedToken}
                    favorites={favorites}
                    toggleFavorite={toggleFavorite}
                  />
                </div>
              ))}
            </div>
          </ModalWithPortal>
        </div>
      )}
    </div>
  );
}

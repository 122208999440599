import { formatAmount } from "../../../lib/numbers";
import { USD_DECIMALS } from "../../../lib/constants";
import React, { useEffect, useState } from "react";
import { getTokenInfo, TokenInfo } from "../../../domain/tokens";
import { useSwapOption, useToTokenAddress } from "../../../pages/Exchange/state";
import { getChartToken } from "../ExchangeTVChart";
import { TokenUtils } from "../../TokenUtils";
import { cx } from "../../../lib/cx";
import { CalculateDeltaPercentage } from "./CalculateDeltaPercentage";
import { useHistory, useLocation } from "react-router-dom";

const checkIfTradePage = (path: string) =>{
  return ['/trade'].includes(path)
}

export const Market = (props) => {
  const { option, infoTokens, chainId, showSymbol, showPrice } = props;
  const nav = useHistory()
  const {pathname} = useLocation()

  const { deltaPercentage, deltaPercentageStr } = CalculateDeltaPercentage(option, infoTokens, chainId);

  const formattedDelta = formatAmount(option.maxPrice, USD_DECIMALS, option.priceDecimals, true);

  const [, setChartToken] = useState<TokenInfo>();
  const [toTokenAddress, setToTokenAddress] = useToTokenAddress();

  const fromToken = getTokenInfo(infoTokens, option.address);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);
  const [swapOption] = useSwapOption();


  const onSelectToken = (token) => {
    const tmp = getTokenInfo(infoTokens, token.address);
    setChartToken(tmp);
    setToTokenAddress(swapOption, token.address);

    if(!checkIfTradePage(pathname)){
      nav.push('/#/trade')
    }
  };

  useEffect(() => {
    setChartToken(getChartToken(fromToken, toToken));
  }, [fromToken, toToken]);


  return (
    <div className="Market">
      <div className="MarketInformation-market" onClick={() => onSelectToken(option)}>
        {showSymbol && <TokenUtils.Logo token={option} className="h-[1.6rem] w-[1.6rem] mr-[1rem]"></TokenUtils.Logo>}
        {TokenUtils.getSymbol(option)}
        <span className="muted">/USDC</span>
      </div>
      {showPrice ? (
        <span
          className={cx("token-price-delta", {
            positive: deltaPercentage > 0,
            negative: deltaPercentage < 0
          })}
        >
          {formattedDelta && formattedDelta} $
        </span>
      ) : (
        <span
          className={cx("token-price-delta", {
            positive: deltaPercentage > 0,
            negative: deltaPercentage < 0
          })}
        >
          {!deltaPercentageStr && "-"}
          {deltaPercentageStr && deltaPercentageStr}
        </span>
      )}
    </div>
  );
};

import React, { useState } from "react";
import { useMedia } from "react-use";
import { format, fromUnixTime } from "date-fns";
import { USD_DECIMALS } from "lib/legacy";
import "./TradeHistory.css";
import { formatAmount } from "lib/numbers";
import { t, Trans } from "@lingui/macro";
import cx from "classnames";
import EmptyRow from "./table/EmptyRow";
import ExchangeTable from "./table/ExchangeTable";
import ExchangeRow from "./table/ExchangeRow";
import ExchangeTokenInfo from "./table/ExchangeTokenInfo";
import TradeHistoryDetailsModal from "./TradeHistoryDetailsModal";
import { ActionsTableScrollLayout } from "components/Exchange/ActionsTableScrollLayout";
import { getTokenInfo } from "../../domain/tokens";
import { getAddress } from "ethers/lib/utils";

export default function TradeHistory(props) {
  const { infoTokens, connectWallet, tradeHistory, isExchange } = props;
  const isMobile = useMedia("(max-width: 1100px)");
  const [pickedTrade, setPickedTrade] = useState();
  const [detailsOpen, setDetailsOpen] = useState(false);

  const pickTrade = (trade) => {
    setPickedTrade(trade);
    setDetailsOpen(true);
  };

  const renderHead = () => {
    return (
      <tr className={cx("Exchange-list-header", { "Exchange-list-header-portfolio": !isExchange })}>
        <th className=" Start">
          <div>
            <Trans>Position</Trans>
          </div>
        </th>

        <th className="Exchange-list-header-side">
          <div>
            <Trans>Side</Trans>
          </div>
        </th>

        {!isMobile && (
          <th>
            <div>
              <Trans>Action</Trans>
            </div>
          </th>
        )}

        <th className={cx({ End: isMobile })}>
          <div>
            <Trans>Type</Trans>
          </div>
        </th>
        {!isMobile && (
          <>
            <th>
              <div>
                <Trans>Price</Trans>
              </div>
            </th>
            <th>
              <div>
                <Trans>Size</Trans>
              </div>
            </th>
            <th className="End">
              <div>
                <Trans>Date</Trans>
              </div>
            </th>
          </>
        )}
      </tr>
    );
  };

  return (
    <div className={cx("tailwind", { TradeHistory: isExchange })}>
      <ActionsTableScrollLayout>
        {({ tableClassName }) => (
          <ExchangeTable className={tableClassName}>
            {renderHead()}
            <EmptyRow
              connectWallet={connectWallet}
              items={tradeHistory}
              msg={t`You don't have any trading activity.`}
            />
            {tradeHistory.slice(0, isExchange ? 50 : undefined).map((item) => {
              const indexToken = getTokenInfo(infoTokens, getAddress(item.token));
              return (
                <ExchangeRow key={`${item.isLong}-${item.type}-${item.id}`} onClick={() => pickTrade(item)}>
                  <td className="Exchange-list-item-type" key={`${item.type}-${item.id}`}>
                    <ExchangeTokenInfo infoTokens={infoTokens} token={item.token} leverage={item.leverage} />
                  </td>
                  <td className="Exchange-list-item-type-Side Exchange-list-item-side-positionlist">
                    <div>
                      <span className={cx({ long: item.isLong, short: !item.isLong })}>
                        {item.isLong ? t`Long` : t`Short`}
                      </span>
                    </div>
                  </td>
                  <td>{item.action}</td>
                  {!isMobile && <td className="Exchange-list-item-type">{item.type}</td>}
                  {!isMobile && (
                    <>
                      <td>${formatAmount(item.price, USD_DECIMALS, indexToken?.priceDecimals, true)}</td>
                      <td>${formatAmount(item.size, USD_DECIMALS, 4, true)}</td>
                      <td>{format(fromUnixTime(item.timestamp), "PPpp")}</td>
                    </>
                  )}
                </ExchangeRow>
              );
            })}
          </ExchangeTable>
        )}
      </ActionsTableScrollLayout>

      {isMobile && (
        <TradeHistoryDetailsModal
          open={detailsOpen}
          setIsVisible={setDetailsOpen}
          trade={pickedTrade}
          infoTokens={infoTokens}
        />
      )}
    </div>
  );
}

import Select from "react-select";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { NFTRenderItem } from "./NFTRenderItem";

import NftImage from "../../../../img/nft.png";
import { MergeNFTModal } from "./MergeModal";

import "./NFTStaking.css";

const mockOptions = [
  {
    value: "Rarity",
    label: "Rarity",
  },
  {
    value: "Common",
    label: "Common",
  },
  {
    value: "Legendary",
    label: "Legendary",
  },
  {
    value: "Epic",
    label: "Epic",
  },
];
// const versionOptions = [
//   {
//     value: "Version",
//     label: "Version",
//   },
//   {
//     value: "Common",
//     label: "Common",
//   },
//   {
//     value: "Legendary",
//     label: "Legendary",
//   },
//   {
//     value: "Epic",
//     label: "Epic",
//   },
// ];

const mockNfts = [
  { name: "Foxify664", img: NftImage, id: "#1,440" },
  { name: "Foxify664", img: NftImage, id: "#1,440" },
  { name: "Foxify664", img: NftImage, id: "#1,440" },
  { name: "Foxify664", img: NftImage, id: "#1,440" },
  { name: "Foxify664", img: NftImage, id: "#1,440" },
  { name: "Foxify664", img: NftImage, id: "#1,440" },
  { name: "Foxify664", img: NftImage, id: "#1,440" },
  { name: "Foxify664", img: NftImage, id: "#1,440" },
];

export const NFTStakingTab = () => {
  const [selectRarity, setSelectRarity] = useState("Rarity");
  // const [selectVersion, setSelectVersion] = useState("Version");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isBuyOpen, setIsBuyOpen] = useState(false);

  const handleRarityChange = (selectedOptions) => {
    setSelectRarity(selectedOptions.value);
  };
  // const handleVersionChange = (selectedOptions) => {
  //   setSelectVersion(selectedOptions.value);
  // };

  return (
    <>
      <div className="Tab-content flex-column z-30">
        <div className="inactive-text Fund-tab-description">
          <Trans>
            A collection of NFTs by Foxify that you own may qualify you for additional collateral by staking.
          </Trans>
        </div>

        <div className="flex justify-between items-center mb-6">
          {/* <div className="flex gap-2"> */}
          <Select
            value={mockOptions.find((option) => option.value === selectRarity)}
            onChange={handleRarityChange}
            options={mockOptions}
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "16.3rem",
                height: "3.5rem",
                borderRadius: "0.8rem",
                backgroundColor: "var(--pallete-background-3)",
                border: "1px solid var(--pallete-background-3)",
                color: "var(--pallete-border)",
                boxShadow: "none",
              }),

              menu: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-3)",
                color: "textColor",
                border: "1px solid var(--pallete-border)",
              }),
              option: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-2)",
                color: "textColor",
                cursor: "pointer",
                "&:active": {
                  backgroundColor: "var(--pallete-background-3)",
                  outline: "none",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "var(--pallete-inactive)",
              }),
            }}
          />
           {/* <Select
            value={versionOptions.find((option) => option.value === selectVersion)}
            onChange={handleVersionChange}
            options={versionOptions}
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "16.3rem",
                height: "3.5rem",
                borderRadius: "0.8rem",
                backgroundColor: "var(--pallete-background-3)",
                border: "1px solid var(--pallete-background-3)",
                color: "var(--pallete-border)",
                boxShadow: "none",
              }),

              menu: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-3)",
                color: "textColor",
                border: "1px solid var(--pallete-border)",
              }),
              option: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-2)",
                color: "textColor",
                cursor: "pointer",
                "&:active": {
                  backgroundColor: "var(--pallete-background-3)",
                  outline: "none",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "var(--pallete-inactive)",
              }),
            }}
          /> */}
          {/* </div> */}
          {/* <div className="flex gap-2">
          <button
            // onClick={() => setIsBuyOpen((prev) => !prev)}
            className="w-[10rem] text-main px-[1.7rem] py-[1rem] Create-funded-btn rounded-[1.5rem] h-[4.5rem]"
          >
            <Trans>Buy NFTs</Trans>
          </button> */}
          <button
            onClick={() => setIsModalOpen((prev) => !prev)}
            className="w-[15rem] text-main px-[1.7rem] py-[1rem] Create-funded-btn rounded-[1.5rem] h-[4.5rem]"
          >
            <Trans>Merge NFTs</Trans>
          </button>
          {/* </div> */}
        </div>

        {mockNfts && (
          <div className="flex flex-wrap gap-8 justify-center">
            {mockNfts.map((nft, index) => (
              <NFTRenderItem key={index} name={nft.name} img={nft.img} id={nft.id} />
            ))}
          </div>
        )}
      </div>

      {isModalOpen && <MergeNFTModal open={isModalOpen} setOpen={setIsModalOpen} mockNfts={mockNfts} />}
    </>
  );
};

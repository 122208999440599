import cx from "classnames";
import { t } from "@lingui/macro";
import { ethers } from "ethers";
import Slider from "rc-slider";

import TokenSelector from "components/Exchange/TokenSelector";
import type { Token } from "domain/tokens";
import { formatBigAmount } from "lib/formatAmount";

type SwapInputRangeProps = {
  inputActive: string;
  fromValue: string;
  chainId: any;
  fromTokenAddress: string | any;
  fromTokens: any;
  infoTokens: any;
  isLong: boolean;
  isShort: boolean;
  toValue: string;
  toTokenAddress: any;
  toTokens: any;
  showTriggerPriceSection: boolean;
  isMobile: boolean;
  triggerPriceValue: string;
  currentBalance: ethers.BigNumber | undefined;
  fromToken: Token;
  fromValuePercentage: number;
  fromValueChange: (value: any) => void;
  setInputActive: (value: any) => void;
  onSelectFromToken: (token: any) => void;
  onToValueChange: (value: any) => void;
  onSelectToToken: (value: any) => void;
  onTriggerPriceChange: (value: any) => void;
  onBlurTrigger: () => void;
  setFromValueFromButtons: (value: any) => void;
  balanceSliderHandle: (props: any) => JSX.Element;
};

export const SwapInputRange = (props: SwapInputRangeProps) => {
  const {
    inputActive,
    fromValue,
    fromTokens,
    chainId,
    fromTokenAddress,
    infoTokens,
    isLong,
    isShort,
    toValue,
    toTokenAddress,
    toTokens,
    showTriggerPriceSection,
    isMobile,
    triggerPriceValue,
    currentBalance,
    fromToken,
    fromValuePercentage,
    fromValueChange,
    setInputActive,
    onSelectFromToken,
    onToValueChange,
    onSelectToToken,
    onTriggerPriceChange,
    onBlurTrigger,
    setFromValueFromButtons,
    balanceSliderHandle,
  } = props;

  return (
    <>
      <div
        className={cx("Exchange-swap-section-container transition-effect", {
          inputActive: inputActive === "usdc-swapbox",
        })}
      >
        <div className="Exchange-swap-section-container-bottom ">
          <div className="Exchange-swap-input-container">
            <input
              type="number"
              min="0"
              placeholder="Pay"
              className="Exchange-swap-input"
              value={fromValue}
              onChange={(e) => fromValueChange(e)}
              onFocus={() => setInputActive("usdc-swapbox")}
              onBlur={() => setInputActive("")}
            />
          </div>
          <TokenSelector
            label={t`Pay`}
            chainId={chainId}
            tokenAddress={fromTokenAddress}
            onSelectToken={onSelectFromToken}
            tokens={fromTokens}
            infoTokens={infoTokens}
            showTokenImgInDropdown={true}
          />
        </div>
      </div>
      <div
        className={cx("Exchange-swap-section-container", {
          inputActive: inputActive === "currency-swapbox",
        })}
      >
        <div className="Exchange-swap-section-container-bottom">
          <div>
            <input
              type="number"
              min="0"
              placeholder={isLong ? t`Long` : t`Short`}
              className="Exchange-swap-input"
              value={toValue}
              onChange={onToValueChange}
              onFocus={() => setInputActive("currency-swapbox")}
              onBlur={() => setInputActive("")}
            />
          </div>

          <TokenSelector
            label={isLong ? t`Long` : t`Short`}
            chainId={chainId}
            tokenAddress={toTokenAddress}
            onSelectToken={onSelectToToken}
            tokens={toTokens}
            infoTokens={infoTokens}
            showTokenImgInDropdown={true}
          />
        </div>
      </div>
      {showTriggerPriceSection && (
        <div
          className={cx("Exchange-swap-section-container", {
            inputActive: inputActive === "markprice-swapbox",
          })}
        >
          <div className="Exchange-swap-section-container-bottom">
            <div className="Exchange-swap-input-container">
              <input
                type="number"
                min="0"
                placeholder="Price"
                className="Exchange-swap-input"
                value={triggerPriceValue}
                onChange={onTriggerPriceChange}
                onFocus={() => setInputActive("markprice-swapbox")}
                onBlur={() => onBlurTrigger}
              />
            </div>
            <div className="TokenSelector">
              <div className="TokenSelector-box">USD</div>
            </div>
          </div>
        </div>
      )}

      <div
        className={cx("Exchange-leverage-slider", "App-slider", {
          positive: isLong,
          negative: isShort,
        })}
      >
        <Slider
          min={0}
          max={100}
          step={1}
          handle={balanceSliderHandle}
          onChange={(value) => setFromValueFromButtons(value)}
          value={fromValuePercentage}
        />
      </div>
      {!isMobile && (
        <div className="Exchange-leverage-buttons-container">
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(25)}>
            25%
          </button>
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(50)}>
            50%
          </button>
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(75)}>
            75%
          </button>
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(100)}>
            MAX
          </button>
        </div>
      )}

      {isMobile && (
        <div className="Exchange-leverage-buttons-container">
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(20)}>
            20%
          </button>
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(40)}>
            40%
          </button>
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(60)}>
            60%
          </button>
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(80)}>
            80%
          </button>
          <button className="Exchange-leverage-button muted" onClick={() => setFromValueFromButtons(100)}>
            MAX
          </button>
        </div>
      )}

      <div className="Exchange-leverage-slider-settings">
        <div className="muted">Available balance</div>
        <p>{formatBigAmount(currentBalance, fromToken.decimals)} USDC</p>
      </div>
    </>
  );
};

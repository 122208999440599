import React, { useState, useEffect } from "react";
import "./Orders.css";
import Select from "react-select";
import PaginationRightIcon from "img/PaginationRight.svg";
import PaginationLeftIcon from "img/PaginationLeft.svg";
import { ReactComponent as Filtericon } from "img/Filtericon.svg";
import { t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import OrdersList from "components/Exchange/OrdersList";

const Orders = ({
  account,
  active,
  library,
  positions,
  infoTokens,
  positionsMap,
  connectWallet,
  orders,
  totalTokenWeights,
  savedShouldDisableValidationForTesting,
  chainId,
  pendingTxns,
  setPendingTxns,
  whitelistedTokens,
  isCancelMultipleOrderProcessing,
  cancelOrderIdList,
  setCancelOrderIdList,
  onMultipleCancelClick,
}) => {
  const [selectedToken, setSelectedToken] = useState("All");
  const [selectedIsLong, setSelectedIsLong] = useState("All");
  const [isFilterVisible, setFilterVisible] = useState(false);

  const [firstFilterActive, setFirstFilterActive] = useState(0);
  const [secondFilterActive, setSecondFilterActive] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 5;
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setFilterCount(firstFilterActive + secondFilterActive);
  }, [selectedToken, selectedIsLong, firstFilterActive, secondFilterActive]);

  useEffect(() => {
    const filtered = orders.filter((order) => {
      if (selectedToken !== "All" && order.indexToken !== selectedToken) {
        return false;
      }
      if (selectedIsLong !== "All" && order.isLong !== (selectedIsLong === "true")) {
        return false;
      }
      return true;
    });
    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset the current page when the filters change
  }, [orders, selectedToken, selectedIsLong]);

  const totalOrders = filteredOrders.length;

  useEffect(() => {
    if (totalOrders > 0) {
      setTotalPages(Math.ceil(totalOrders / ordersPerPage));
    } else {
      setTotalPages(1);
    }
  }, [totalOrders, ordersPerPage]);

  const handleTokenChange = (selectedOption) => {
    if (selectedOption.value === "All") {
      setFirstFilterActive(0);
    } else {
      setFirstFilterActive(1);
    }
    setSelectedToken(selectedOption?.value || "All");
  };

  const handleIsLongChange = (selectedOption) => {
    if (selectedOption.value === "All") {
      setSecondFilterActive(0);
    } else {
      setSecondFilterActive((prevCount) => prevCount + 1);
    }
    setSelectedIsLong(selectedOption?.value || "All");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const ordersForCurrentPage = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);

  const options = [
    { value: "All", label: "All" },
    ...indexTokens.map((token, index) => ({
      value: token.address,
      label: token.name,
    })),
  ];

  const isLongOptions = [
    { value: "All", label: "All" },
    { value: "true", label: "Long" },
    { value: "false", label: "Short" },
  ];

  const pageOptions = Array.from({ length: totalPages }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));

  const handlePageSelect = (selectedOption) => {
    setCurrentPage(selectedOption?.value || 1);
  };

  return (
    <div className="default-container tailwind w-full pt-[5rem] flex flex-col pb-[10rem] gap-[2rem] h-full overflow-y-auto">
      <div className="flex flex-col gap-[0.5rem]">
        <div className="flex gap-[0.5rem] items-center">
          <span className="text-textColor text-[2.4rem]">Open Orders</span>
          {orders.length > 0 && (
            <div className="h-[2.2rem] min-w-[2.2rem] bg-background-3 rounded-full text-center items-center flex justify-center text-sm muted">
              <span>{orders.length}</span>
            </div>
          )}
        </div>
        <span className="muted">View your open orders.</span>
      </div>

      <div className="flex md:hidden w-full">
        <button
          className="w-full bg-background-3 h-[4rem] rounded-full flex flex-row items-center px-[2rem] justify-between"
          onClick={() => {
            setFilterVisible(true);
          }}
        >
          <span className="muted">{filterCount} Filtes Applied</span>
          <Filtericon className="fill-inactive h-[1.4rem]" />
        </button>

        {cancelOrderIdList.length > 0 && (
          <button className="bg-background-3 border text-textColor w-[14rem] h-[3.5rem]">
            Cancel {cancelOrderIdList.length} Orders
          </button>
        )}
      </div>

      <div className="hidden md:flex flex-row gap-[2rem]">
        <Select
          value={options.find((option) => option.value === selectedToken)}
          onChange={handleTokenChange}
          options={options}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (provided) => ({
              ...provided,
              width: "16.3rem",
              height: "3.5rem",
              borderRadius: "0.8rem",
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-background-3)",
              color: "var(--pallete-border)",
              boxShadow: "none",
            }),

            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",
              color: "textColor",
              border: "1px solid var(--pallete-border)",
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-2)",
              color: "textColor",
              cursor: "pointer",
              "&:active": {
                backgroundColor: "var(--pallete-background-3)",
                outline: "none",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--pallete-inactive)",
            }),
          }}
        />
        <Select
          value={isLongOptions.find((option) => option.value === selectedIsLong)}
          onChange={handleIsLongChange}
          options={isLongOptions}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (provided) => ({
              ...provided,
              width: "16.3rem",
              height: "3.5rem",
              borderRadius: "0.8rem",
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-background-3)",
              color: "var(--pallete-border)",
              boxShadow: "none",
            }),

            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",
              color: "textColor",
              border: "1px solid var(--pallete-border)",
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-2)",

              color: "textColor",
              cursor: "pointer",
              "&:active": {
                backgroundColor: "var(--pallete-background-3)",
                outline: "none",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--pallete-inactive)",
            }),
          }}
        />
      </div>

      <Modal
        className="Connecting-wallet-modal"
        isVisible={isFilterVisible}
        setIsVisible={setFilterVisible}
        label={t`Filters`}
      >
        <div className="flex flex-col gap-[1rem] min-h-[25rem]">
          <span className="muted">Market</span>
          <Select
            value={options.find((option) => option.value === selectedToken)}
            onChange={handleTokenChange}
            options={options}
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100%",
                height: "3.5rem",
                borderRadius: "var(--border-radius-sm)",
                backgroundColor: "var(--pallete-background-3)",
                border: "1px solid var(--pallete-border)",
                color: "var(--pallete-border)",
                boxShadow: "none",
              }),

              menu: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-3)",
                color: "textColor",
                border: "1px solid var(--pallete-border)",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-2)",

                color: "textColor",
                cursor: "pointer",
                "&:active": {
                  backgroundColor: "var(--pallete-background-3)",
                  outline: "none",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "var(--pallete-inactive)",
              }),
            }}
          />
          <span className="muted">Side</span>
          <Select
            value={isLongOptions.find((option) => option.value === selectedIsLong)}
            onChange={handleIsLongChange}
            options={isLongOptions}
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100%",
                height: "3.5rem",
                borderRadius: "var(--border-radius-sm)",
                backgroundColor: "var(--pallete-background-3)",
                border: "1px solid var(--pallete-border)",
                color: "var(--pallete-border)",
                boxShadow: "none",
              }),

              menu: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-3)",
                color: "textColor",
                border: "1px solid var(--pallete-border)",
              }),
              option: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-2)",
                color: "textColor",
                cursor: "pointer",
                "&:active": {
                  backgroundColor: "var(--pallete-background-3)",
                  outline: "none",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "var(--pallete-inactive)",
              }),
            }}
          />
        </div>
      </Modal>

      <OrdersList
        account={account}
        active={active}
        library={library}
        positions={positions}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        infoTokens={infoTokens}
        positionsMap={positionsMap}
        chainId={chainId}
        orders={ordersForCurrentPage}
        totalTokenWeights={totalTokenWeights}
        savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
        cancelOrderIdList={cancelOrderIdList}
        setCancelOrderIdList={setCancelOrderIdList}
        connectWallet={connectWallet}
      />

      <div className="w-full flex flex-row items-center justify-between">
        <span className="muted">Total: {totalPages} pages</span>

        <div className="flex gap-[1rem]">
          <div className="flex items-center gap-[1rem]">
            <span className="muted hidden md:flex">The page you are on</span>
            <Select
              value={pageOptions.find((option) => option.value === currentPage)}
              onChange={handlePageSelect}
              options={pageOptions}
              className="hidden md:flex"
              components={{ IndicatorSeparator: () => null }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "7rem",
                  height: "2.5rem",
                  backgroundColor: "var(--pallete-background-1)",
                  border: "1px solid var(--pallete-border)",
                  borderRadius: "var(--border-radius-sm)",
                  color: "var(--pallete-border)",
                  boxShadow: "none",
                }),
                option: (provided) => ({
                  ...provided,
                  backgroundColor: "var(--pallete-border)",
                  cursor: "pointer",
                  color: "textColor",
                  "&:active": {
                    backgroundColor: "var(--pallete-background-1)",
                    outline: "none",
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: "var(--pallete-background-1)",
                  color: "textColor",
                  border: "1px solid var(--pallete-border)",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "var(--pallete-inactive)",
                }),
              }}
            />
          </div>

          <div className="flex items-center gap-[1rem]">
            <button
              className="bg-transparent border-none"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src={PaginationLeftIcon} alt="pagination" />
            </button>
            <span>{currentPage}</span>
            <button
              className="bg-transparent border-none"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src={PaginationRightIcon} alt="pagination" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;

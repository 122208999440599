export const FundedLevels = ["Qualification", "Silver", "Gold", "Platinum"];

export const MaxTradeSizes = [20000, 50000, 125000, 250000];

export enum ChallengeResults {
  RESET,
  DEMOTION,
  MAINTAIN,
  PROMOTION,
}

import foxLogo from "../../img/Fox_Logo.svg";
import { Trans } from "@lingui/macro";
import { Tooltip } from "../../components/TooltipV2/Tooltip";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { useMedia } from "react-use";
import { useTokenPrices } from "../../hooks/useStakingTokensPrices";
import useSWR from "swr";
import isEqual from "lodash/isEqual";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "../../lib/chains";
import { useQuickContracts } from "../../lib/contracts";
import { formatAmount } from "../../lib/numbers";
import { FOX_DECIMALS } from "../../lib/constants";
import Button from "../../components/Button/Button";
import { useVestingClaim } from "./hooks/useVestingClaim";
import { format } from "date-fns";
import { BigNumber } from "ethers";

export const Vesting = ({ pendingClaim }) => {
  const { foxPrice } = useTokenPrices();
  const { isActive, account } = useWeb3React();
  const { chainId } = useChainId();
  const quickContracts = useQuickContracts();
  const eventClaim = useVestingClaim();

  const isMobile = useMedia("(max-width: 767px)");

  const { data: userInfo } = useSWR(
    ["VestingSchedule", "userInfo", isActive, chainId],
    () => quickContracts.fetch("VestingSchedule", "userInfo", [account?.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const isRemainingVestingAvailable =
    userInfo && userInfo?.totalToVest && userInfo?.totalPaidOut && userInfo.totalToVest.gt(0) && userInfo.totalToVest.gt(userInfo.totalPaidOut);

  const remainingVest = isRemainingVestingAvailable
    ? userInfo.totalToVest.sub(userInfo.totalPaidOut)
    : BigNumber.from(0);

  const formattedEndOfVestingTime = () => {
    const endOfVestingPeriod = userInfo?.startTime.add(userInfo?.duration);
    const timestamp = endOfVestingPeriod?.toNumber();
    return timestamp ? format(timestamp * 1000, "dd.MM.yyyy") : "-";
  };

  return (
    <div className="Tab-content flex-column">
      {!isMobile ? (
        <div className="Stake-card flex-column">
          <div className="PLPAccordion">
            <div className="info-block-logo gap-[2rem]">
              <div className="inline-block w-[4.4rem] h-[4.4rem] bg-black rounded-full shrink-0 flex justify-center items-center">
                <img src={foxLogo} alt="Fox logo" className="Token-icon-wrapper" />
              </div>
              <div className="info-block">
                <div className="Stake-card-title  accordion-title">
                  <Trans>FOX</Trans>
                </div>
                <div className=" currency-price">${foxPrice ? parseFloat(foxPrice).toFixed(3) : "0.00"}</div>
              </div>
            </div>
            <div className="two-column-row">
              <div className="info-block">
                <div className="gap-container">
                  <Tooltip
                    position="center-bottom"
                    renderContent={() => {
                      return (
                        <div className="Stake-card-infobox-tooltip">
                          <Trans>Remain vesting</Trans>
                        </div>
                      );
                    }}
                  >
                    <div className="Tooltip-handle-wrapper">
                      <div className="inactive-text">
                        <Trans>Remain vesting</Trans>
                      </div>
                      <HiQuestionMarkCircle className="inactive-text" />
                    </div>
                  </Tooltip>
                </div>
                <div className="white-text">
                  {remainingVest ? formatAmount(remainingVest, FOX_DECIMALS, 2, true) : "0.00"} FOX
                </div>
              </div>
              <div className="info-block">
                <div className="gap-container">
                  <Tooltip
                    position="center-bottom"
                    renderContent={() => {
                      return (
                        <div className="Stake-card-infobox-tooltip">
                          <Trans>End of vesting period</Trans>
                        </div>
                      );
                    }}
                  >
                    <div className="Tooltip-handle-wrapper">
                      <div className="inactive-text">
                        <Trans>End of vesting period</Trans>
                      </div>
                      <HiQuestionMarkCircle className="inactive-text" />
                    </div>
                  </Tooltip>
                </div>
                <div className="white-text">{formattedEndOfVestingTime()}</div>
              </div>
            </div>
            <Button
              variant="lime"
              onClick={() => eventClaim.claim()}
              size="lg"
              className="transition-colors cursor-pointer inline-flex gap-2 items-center justify-center no-underline py-4 px-8 ml-auto"
              disabled={!pendingClaim?.gt(0) || eventClaim.isMutating}
            >
              <Trans>Claim</Trans>
            </Button>
          </div>
        </div>
      ) : (
        <div className="Stake-card flex-column">
          <div className="Accordion-head-mobile vesting">
            <div className="info-block-logo gap-[2rem]">
              <div className="inline-block w-[4.4rem] h-[4.4rem] bg-black rounded-full shrink-0 flex justify-center items-center">
                <img src={foxLogo} alt="Fox logo" className="Token-icon-wrapper" />
              </div>
              <div className="info-block">
                <div className="Stake-card-title  accordion-title">
                  <Trans>FOX</Trans>
                </div>
                <div className=" currency-price">${foxPrice ? parseFloat(foxPrice).toFixed(3) : "0.00"}</div>
              </div>
            </div>
            <div className="info-block">
              <div className="gap-container">
                <Tooltip
                  position="center-bottom"
                  renderContent={() => {
                    return (
                      <div className="Stake-card-infobox-tooltip">
                        <Trans>Remain vesting</Trans>
                      </div>
                    );
                  }}
                >
                  <div className="Tooltip-handle-wrapper">
                    <div className="inactive-text">
                      <Trans>Remain vesting</Trans>
                    </div>
                    <HiQuestionMarkCircle className="inactive-text" />
                  </div>
                </Tooltip>
              </div>
              <div className="white-text">
                {remainingVest ? formatAmount(remainingVest, FOX_DECIMALS, 2, true) : "0.00"} FOX
              </div>
            </div>

            <div className="info-block">
              <div className="gap-container">
                <Tooltip
                  position="center-bottom"
                  renderContent={() => {
                    return (
                      <div className="Stake-card-infobox-tooltip">
                        <Trans>End of vesting period</Trans>
                      </div>
                    );
                  }}
                >
                  <div className="Tooltip-handle-wrapper">
                    <div className="inactive-text">
                      <Trans>End of vesting period</Trans>
                    </div>
                    <HiQuestionMarkCircle className="inactive-text" />
                  </div>
                </Tooltip>
              </div>
              <div className="white-text">{formattedEndOfVestingTime()}</div>
            </div>
          </div>
          <Button
              variant="lime"
              onClick={() => eventClaim.claim()}
              size="lg"
              className="transition-colors cursor-pointer inline-flex gap-2 items-center justify-center no-underline py-4 px-8 ml-auto"
              disabled={!pendingClaim?.gt(0) || eventClaim.isMutating}
            >
              <Trans>Claim</Trans>
            </Button>
        </div>
      )}
    </div>
  );
};

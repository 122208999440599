import { ChainData } from "@0xsquid/squid-types/dist/chains";
import { Token } from "@0xsquid/squid-types/dist/tokens";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { ReactComponent as ShevronDown } from "img/arrowDownWhite.svg";
import "./Bridge.css";
import { executeRouteRequest, fetchData } from "./helpers/squidSDK";
import { fetchBalance } from "./helpers/squidSDK";
import { BigNumber, utils } from "ethers";
import { sendRouteRequest } from "./helpers/squidSDK";
import Button from "components/Button/Button";
import { useChainId } from "lib/chains";
import { helperToast } from "lib/helperToast";
import Modal from "components/Modal/Modal";
import { useMedia } from "react-use";
import squidIcon from "img/Squid.svg";
import axelarIcon from "img/Axelar.svg";
import { cx } from "lib/cx";
import SimpleLoadingAnimation from "components/LoadingAnimation/SimpleLoadingAnimation";

function BrideSquid({ onFocus, onBlur }) {
  const [squidFromChains, setSquidFromChains] = useState<ChainData[]>([]);
  const [squidFromTokens, setSquidFromTokens] = useState<Token[]>();
  const [filteredSquidFromTokens, setFilteredSquidFromTokens] = useState<Token[]>();
  const [gasToken, setGasToken] = useState<Token>();
  const [squidToChains, setSquidToChains] = useState<ChainData>();
  const [squidToTokens, setSquidToTokens] = useState<Token[]>();

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      try {
        const squid = await fetchData(); // Call the fetchData function
        setSquidFromChains(squid.chains);
        setSquidFromTokens(squid.tokens);

        // Filter tokens based on chainId: '42161' and specific addresses USDC and ARB ETH
        const addressesToFilter = [
          "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
          "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        ];
        const squidToTokensFiltered = squid.tokens.filter(
          (token) => token.chainId === "42161" && addressesToFilter.includes(token.address)
        );
        setSquidToTokens(squidToTokensFiltered);
        setSelectedToToken(squidToTokensFiltered[0]);

        setSquidToChains(squid.chains.find((chain) => chain.chainId === "42161"));
      } catch (error) {
        // Handle error if fetchData fails
        // eslint-disable-next-line no-console
        console.error("Error fetching data:", error);
      }
    };
    fetchDataAndSetState();
  }, []);

  const { account, provider } = useWeb3React();

  let signer = provider?.getSigner();

  const [searchQueryBridge, setSearchQueryBridge] = useState("");
  const [searchQueryToken, setSearchQueryToken] = useState("");

  const handleSearchInputChangeBridge = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQueryBridge(query);
  };
  const handleSearchInputChangeToken = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQueryToken(query);
  };
  const filterOptionsChain = (options) => {
    return options.filter((option) => option.axelarChainName.toLowerCase().includes(searchQueryBridge));
  };

  const filterOptionsToken = (options) => {
    return options.filter((option) => option.name.toLowerCase().includes(searchQueryToken));
  };

  const [selectedFromChain, setSelectedFromChain] = useState<ChainData>();
  const [selectedFromToken, setSelectedFromToken] = useState<Token>();
  const [selectedToToken, setSelectedToToken] = useState<Token>();

  const [bridgeFromAmount, setBridgeFromAmount] = useState("");
  const [bridgeFromAmountWei, setBridgeFromAmountWei] = useState<BigNumber>();
  const [bridgeToAmount, setBridgeToAmount] = useState("");

  useEffect(() => {
    if (selectedFromChain) {
      const selectedChainId = selectedFromChain.chainId;
      // Filter tokens based on the selected chainId
      const filteredTokens = squidFromTokens?.filter((token) => token.chainId === selectedChainId);
      // Update the state variable holding the filtered tokens
      setFilteredSquidFromTokens(filteredTokens);
      const gasToken = filteredTokens?.find((token) => token.address === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE");
      setGasToken(gasToken);
    }
  }, [squidFromTokens, selectedFromChain]);

  let typingTimeout;
  let routeFetched = false;

  const onInputChangeBridge = (event) => {
    routeFetched = false;
    const inputAmount = event?.target.value;

    setBridgeFromAmount(inputAmount);

    if (inputAmount === "") {
      setIsButtonDisabled(true);
      setButtonMessage("Bridge");
      return;
    }

    // Clear previous timeout if exists
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const toToken = selectedToToken;

    // Set a new timeout after user stops typing
    typingTimeout = setTimeout(async () => {
      if (selectedFromToken?.decimals && inputAmount !== "0" && toToken) {
        const bridgeFromAmountWei = convertToWei(inputAmount, selectedFromToken.decimals);
        setBridgeFromAmountWei(bridgeFromAmountWei);
        const weiString = bridgeFromAmountWei.toString();

        if (bridgeFromAmountWei.gt(0)) {
          fetchRoute(weiString, toToken);
        }
      }
    }, 500); // Adjust this timeout duration as needed
  };

  const fetchRoute = async (bridgeFromAmountWei, toToken) => {
    if (
      selectedFromChain &&
      bridgeFromAmountWei &&
      selectedFromToken &&
      squidToChains &&
      toToken &&
      account &&
      !routeFetched
    ) {
      try {
        const params = {
          fromChain: selectedFromChain.chainId as string,
          fromAmount: bridgeFromAmountWei,
          fromToken: selectedFromToken!.address as string,
          toChain: squidToChains!.chainId as string,
          toToken: toToken!.address as string,
          userAddress: account as string,
        };
        setButtonMessage("Fetching Route...");
        setIsButtonDisabled(true);
        setRouteResponse(undefined);
        setIsLoading(true);

        const route = await sendRouteRequest(
          params.fromChain,
          params.fromAmount,
          params.fromToken,
          params.toChain,
          params.toToken,
          params.userAddress
        );

        // Set Bridge To Amount

        if (route && route.route) {
          const bridgeToAmount = convertAmount(route.route.estimate.toAmount, route.route.estimate.toToken.decimals);
          setBridgeToAmount(bridgeToAmount.toString());

          // Set Gas Costs

          const gasCost = route.route.estimate.gasCosts[0].amount;
          const convertGasCost = convertAmount(gasCost, gasToken?.decimals as number);

          // Check if Selected token is gastoken
          if (selectedFromToken === gasToken) {
            const gasBalance = await getGasBalance(gasToken);
            const gasBalanceConvert = convertAmount(gasBalance, gasToken?.decimals as number);
            setGasCost(convertGasCost);
            const gasCostNumberWei = parseInt(gasCost);
            const gasBalanceNumberWei = parseInt(gasBalance as string);
            const amountToSend = gasCostNumberWei + bridgeToAmount;
            if (convertGasCost > gasBalanceConvert) {
              setButtonMessage("Not enough funds for Gas");
              setIsButtonDisabled(true);
              routeFetched = false;
              setRouteResponse(route);
              setIsLoading(false);
              return;
            }
            // Check if amount we are sending is greater then the users funds

            if (gasBalanceNumberWei < amountToSend) {
              setRouteResponse(route);
              setButtonMessage("Not enough funds");
              setIsButtonDisabled(true);
              routeFetched = false;
              setIsLoading(false);
              return;
            }

            routeFetched = true;
            setRouteResponse(route);
            setButtonMessage("Execute Bridge");
            setIsButtonDisabled(false);
            setIsLoading(false);
            return;
          } else {
            const gasBalance = await getGasBalance(gasToken);
            const gasBalanceConvert = convertAmount(gasBalance, gasToken?.decimals as number);
            setGasCost(convertGasCost);

            const fundsBalance = await getBalance(selectedFromToken);
            const fundsBalanceNumber = convertAmount(fundsBalance as string, selectedFromToken.decimals);
            const bridgeFromAmount = convertAmount(bridgeFromAmountWei, selectedFromToken.decimals);

            if (convertGasCost > gasBalanceConvert) {
              setButtonMessage("Not enough funds for Gas");
              setIsButtonDisabled(true);
              routeFetched = false;
              setRouteResponse(route);
              setIsLoading(false);
              return;
            }
            // Check if amount we are sending is greater then the users funds

            if (fundsBalanceNumber < bridgeFromAmount) {
              setRouteResponse(route);
              setButtonMessage("Not enough funds");
              setIsButtonDisabled(true);
              routeFetched = false;
              setIsLoading(false);
              return;
            }

            routeFetched = true;
            setRouteResponse(route);
            setButtonMessage("Execute Bridge");
            setIsButtonDisabled(false);
            setIsLoading(false);
            return;
          }

          // Check if user has enough gas funds to cover the tx
        } else {
          setButtonMessage("error fetching route");
          setIsButtonDisabled(true);
          setIsLoading(false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching route:", error);
        setButtonMessage("error fetching route");
        setIsButtonDisabled(true);
        setIsLoading(false);
        // Handle error here, you can set appropriate error message or take other actions
      }
    }
  };

  const [isFromBridgeDropdownOpen, setIsFromBridgeDropdownOpen] = useState(false);
  const [isFromTokenDropdownOpen, setIsFromTokenDropdownOpen] = useState(false);
  const [isToTokenDropdownOpen, setIsToTokenDropdownOpen] = useState(false);
  const [isSummaryOpen, setSummaryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDropdown = (dropdownType) => {
    switch (dropdownType) {
      case "fromBridge":
        if (isMobile) {
          setIsMobileFromBridgeVisible(true);
        } else {
          setIsFromBridgeDropdownOpen(!isFromBridgeDropdownOpen);
          setIsFromTokenDropdownOpen(false);
        }

        break;
      case "fromToken":
        if (isMobile) {
          setIsMobileFromTokenVisible(true);
        } else {
          setIsFromTokenDropdownOpen(!isFromTokenDropdownOpen);
          setIsFromBridgeDropdownOpen(false);
          setIsToTokenDropdownOpen(false);
        }

        break;
      case "toToken":
        if (isMobile) {
          setIsMobileToTokenVisible(true);
        } else {
          setIsToTokenDropdownOpen(!isToTokenDropdownOpen);
          setIsFromBridgeDropdownOpen(false);
          setIsFromTokenDropdownOpen(false);
        }
        break;

      default:
        break;
    }
  };

  const handleSelectChangeBridge = (chain: ChainData) => {
    setSelectedFromChain(chain);
    setIsFromBridgeDropdownOpen(false);
    setSelectedFromToken(undefined);
    setFromTokenBalance(0);
    if (isMobile) {
      setIsMobileFromBridgeVisible(false);
    }
  };
  const handleFromTokenChange = async (token: Token) => {
    setSelectedFromToken(token);
    setIsFromTokenDropdownOpen(false);
    const tokenBalance = await getBalance(token);
    const convertedBalance = convertAmount(tokenBalance as string, token.decimals);
    setFromTokenBalance(convertedBalance);
    if (isMobile) {
      setIsMobileFromTokenVisible(false);
    }
  };

  const handleToTokenChange = (token: Token) => {
    setSelectedToToken(token);
    setIsToTokenDropdownOpen(false);
    if (isMobile) {
      setIsMobileToTokenVisible(false);
    }
    if (bridgeFromAmountWei?.gt(0)) {
      setBridgeToAmount("");
      setRouteResponse(undefined);
      fetchRoute(bridgeFromAmountWei.toString(), token);
    }
  };

  const [fromTokenBalance, setFromTokenBalance] = useState(0);

  const convertAmount = (amount: any, decimals: number) => {
    const amountInWei = amount / 10 ** decimals;
    const formatedAmount = parseFloat(amountInWei.toFixed(4));
    return formatedAmount;
  };

  const convertToWei = (amount, decimals) => {
    const amountInWei = utils.parseUnits(amount, decimals);
    return amountInWei;
  };

  const getBalance = async (token) => {
    const chainIdAsNum = parseInt(selectedFromChain?.chainId as string, 10);
    const fromTokenBalance = await fetchBalance(account as string, chainIdAsNum, token?.address as string);

    // Convert balance from Wei to Readable number

    if (fromTokenBalance) {
      const ethBalance = fromTokenBalance[0].balance;
      return ethBalance;
    }
  };

  const getGasBalance = async (token) => {
    const chainIdAsNum = parseInt(selectedFromChain?.chainId as string, 10);
    const fromTokenBalance = await fetchBalance(account as string, chainIdAsNum, token?.address as string);

    if (fromTokenBalance) {
      const ethBalance = fromTokenBalance[0].balance;
      return ethBalance;
    }
  };

  const [routeResponse, setRouteResponse] = useState<any>(null);
  const [gasCost, setGasCost] = useState(0);

  const { chainId } = useChainId();

  const [buttonMessage, setButtonMessage] = useState("Bridge");
  const [isButtondisabled, setIsButtonDisabled] = useState(true);

  const actionBridge = () => {
    // We pass the chain id that needs to be switched to if chain is not ARBITRUM
    if (chainId.toString() !== selectedFromChain?.chainId && routeResponse.route) {
      executeRouteRequest(signer, routeResponse.route, chainId, selectedFromChain?.chainId);
    }
    if (chainId.toString() === selectedFromChain?.chainId && routeResponse.route) {
      executeRouteRequest(signer, routeResponse.route, chainId);
    }
    helperToast.info(`Continue in your wallet`);
  };

  const isTokenSelectionDisabled = selectedFromChain ? false : true;

  const isMobile = useMedia("(max-width: 1100px)");

  const [isMobileFromBridgeVisible, setIsMobileFromBridgeVisible] = useState(false);
  const [isMobileFromTokenVisible, setIsMobileFromTokenVisible] = useState(false);
  const [isMobileToTokenVisible, setIsMobileToTokenVisible] = useState(false);

  const [inputActive, setInputActive] = useState("");

  return (
    <div className="tailwind">
      <div className="Wallet-btns">
        <div className="BridgeItemWrapper">
          <div className="Bridge-dropdown-wrapper">
            <div className="Bridge-from-wrapper">
              <p>Bridge from</p>
              {selectedFromChain ? (
                <button onClick={() => toggleDropdown("fromBridge")} className="bridge-button-selector">
                  <img src={selectedFromChain?.chainIconURI} alt="logo" className="bridge-image" />

                  {selectedFromChain?.networkName}
                  <ShevronDown className="stroke-textColor" />
                </button>
              ) : (
                <button onClick={() => toggleDropdown("fromBridge")} className="bridge-button-selector !pl-[1rem]">
                  <p>Select</p>

                  <ShevronDown className="stroke-textColor" />
                </button>
              )}
            </div>
            {isFromBridgeDropdownOpen && !isMobile && (
              <div className="dropdown-wrapper">
                <div className="dropdown-options">
                  <div className="dropdown-search">
                    <input
                      type="text"
                      placeholder="Search chains..."
                      value={searchQueryBridge}
                      onChange={handleSearchInputChangeBridge}
                      className="Bridge-search-input"
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                    <div className="line"></div>
                  </div>
                  <ul>
                    {filterOptionsChain(squidFromChains).map((chain, index) => (
                      <li key={index} onClick={() => handleSelectChangeBridge(chain)}>
                        {chain.chainIconURI && <img src={chain.chainIconURI} alt="logo" className="bridge-image" />}
                        {chain.networkName
                          .split(" ")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ")}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <Modal
              className="App-settings tailwind"
              isVisible={isMobileFromBridgeVisible}
              setIsVisible={setIsMobileFromBridgeVisible}
              label={`Select Blockchain`}
              placement={isMobile ? "center" : "right"}
              disableBodyScrollLock={true}
              isSecondModal={true}
            >
              <div className="w-full flex flex-col">
                <div className="dropdown-search">
                  <input
                    type="text"
                    placeholder="Search chains..."
                    value={searchQueryBridge}
                    onChange={handleSearchInputChangeBridge}
                    className="Bridge-search-input text-textColor"
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                  <div className="line"></div>
                </div>
                <ul className="flex flex-col gap-[0.5rem]">
                  {filterOptionsChain(squidFromChains).map((chain, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelectChangeBridge(chain)}
                      className="flex items-center gap-[1rem] h-[4rem]"
                    >
                      {chain.chainIconURI && <img src={chain.chainIconURI} alt="logo" className="w-[2rem] h-[2rem]" />}
                      <p className="text-[1.6rem]">
                        {chain.networkName
                          .split(" ")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ")}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </Modal>
          </div>

          <div className="Bridge-from-section">
            <div
              className={cx("Bridge-swap-section currency-swapbox relative transition-effect", {
                inputActive: inputActive === "fromToken-input",
              })}
            >
              <div className="Bridge-swap-section-bottom ">
                <input
                  type="number"
                  placeholder="Send"
                  className="Bridge-swap-input"
                  value={bridgeFromAmount}
                  onChange={onInputChangeBridge}
                  onFocus={() => setInputActive("fromToken-input")}
                  onBlur={() => setInputActive("")}
                />

                {selectedFromToken ? (
                  <button onClick={() => toggleDropdown("fromToken")} className="bridge-button">
                    {selectedFromToken.symbol}
                    <ShevronDown className="stroke-textColor w-[1rem]" />
                  </button>
                ) : (
                  <button
                    onClick={() => toggleDropdown("fromToken")}
                    className="bridge-button"
                    disabled={isTokenSelectionDisabled}
                  >
                    Select
                    <ShevronDown className="stroke-textColor w-[1rem]" />
                  </button>
                )}
              </div>
              <div className="absolute rounded-[1rem] top-[7.8rem] left-[7rem]">
                {isFromTokenDropdownOpen && !isMobile && (
                  <div className="dropdown-options">
                    <div className="dropdown-search">
                      <input
                        type="text"
                        placeholder="Search tokens..."
                        value={searchQueryToken}
                        onChange={handleSearchInputChangeToken}
                        className="Bridge-search-input"
                        onFocus={onFocus}
                        onBlur={onBlur}
                      />
                      <div className="line"></div>
                    </div>
                    <ul>
                      {filterOptionsToken(filteredSquidFromTokens).map((token, index) => (
                        <li key={index} onClick={() => handleFromTokenChange(token)}>
                          {token.logoURI && <img src={token.logoURI} alt="logo" className="w-[2rem] h-[2rem]" />}
                          <p>{token.symbol}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <Modal
                  className="App-settings tailwind"
                  isVisible={isMobileFromTokenVisible}
                  setIsVisible={setIsMobileFromTokenVisible}
                  label={`Select Token`}
                  placement={isMobile ? "center" : "right"}
                  disableBodyScrollLock={true}
                  isSecondModal={true}
                >
                  <div className="w-full flex flex-col">
                    <div className="dropdown-search">
                      <input
                        type="text"
                        placeholder="Search chains..."
                        value={searchQueryToken}
                        onChange={handleSearchInputChangeToken}
                        className="Bridge-search-input text-textColor"
                        onFocus={onFocus}
                        onBlur={onBlur}
                      />
                      <div className="line"></div>
                    </div>
                    <ul className="flex flex-col gap-[0.5rem]">
                      {filteredSquidFromTokens &&
                        filterOptionsToken(filteredSquidFromTokens).map((token, index) => (
                          <li
                            key={index}
                            onClick={() => handleFromTokenChange(token)}
                            className="flex items-center gap-[1rem] h-[4rem]"
                          >
                            {token.logoURI && <img src={token.logoURI} alt="logo" className="bridge-image" />}
                            {token.symbol}
                          </li>
                        ))}
                    </ul>
                  </div>
                </Modal>
              </div>
            </div>

            <div className="Bridge-swap-section-bottom-info">
              <div className="muted Exchange-swap-usd">Available Balance</div>

              <div className="align-right clickable">
                {fromTokenBalance} {selectedFromToken?.symbol}
              </div>
            </div>
          </div>
        </div>

        <div className="line mb-[1rem]" />
        <div className="BridgeItemWrapper">
          <div className="Bridge-dropdown-wrapper">
            <div className="Bridge-from-wrapper">
              <p>Bridge to</p>
              <button className="bridge-button-selector">
                <img src={squidToChains?.chainIconURI} alt="" className="bridge-image" />

                {squidToChains?.axelarChainName}
              </button>
            </div>
          </div>
        </div>
        <div
          className={cx("Bridge-swap-section currency-swapbox relative transition-effect", {
            inputActive: inputActive === "toToken-input",
          })}
        >
          <div className="Bridge-swap-section-bottom">
            <input
              type="number"
              placeholder="0.0"
              className="Bridge-swap-input"
              disabled
              value={bridgeToAmount}
              onFocus={() => setInputActive("toToken-input")}
              onBlur={() => setInputActive("")}
            />

            <button onClick={() => toggleDropdown("toToken")} className="bridge-button">
              {selectedToToken?.symbol}
              <ShevronDown className="stroke-textColor h-[1rem]" />
            </button>
          </div>
          <div className="absolute rounded-[1rem] top-[3.6rem] left-[6rem]">
            {isToTokenDropdownOpen && !isMobile && (
              <div className="dropdown-wrapper">
                <div className="dropdown-options">
                  <ul>
                    {squidToTokens?.map((token, index) => (
                      <li key={index} onClick={() => handleToTokenChange(token)}>
                        {token.logoURI && <img src={token.logoURI} alt="logo" className="bridge-image" />}
                        {token.symbol}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <Modal
              className="App-settings tailwind"
              isVisible={isMobileToTokenVisible}
              setIsVisible={setIsMobileToTokenVisible}
              label={`Select Token`}
              placement={isMobile ? "center" : "right"}
              disableBodyScrollLock={true}
              isSecondModal={true}
            >
              <div className="w-full flex flex-col">
                <ul className="flex flex-col gap-[0.5rem]">
                  {squidToTokens?.map((token, index) => (
                    <li
                      key={index}
                      onClick={() => handleToTokenChange(token)}
                      className="flex items-center gap-[1rem] h-[4rem]"
                    >
                      {token.logoURI && <img src={token.logoURI} alt="logo" className="w-[2rem] h-[2rem]" />}
                      <p className="text-[1.6rem]">{token.symbol}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </Modal>
          </div>
        </div>

        <div className="line" />
        <div className="Bridge-information-wrapper">
          <div className="Bridge-information-header" onClick={() => setSummaryOpen(!isSummaryOpen)}>
            <p>Bridge Summary</p>
            <ShevronDown
              className="stroke-textColor h-[1rem]"
              style={{
                transform: isSummaryOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
                height: "auto",
              }}
            />
          </div>

          <div
            style={{
              maxHeight: isSummaryOpen ? "90rem" : "0",
              overflow: "hidden",
              transition: "max-height 0.6s ease-in-out",
            }}
            className="gap-[0.5rem] w-full"
          >
            <div className="Bridge-info-item">
              <div className="Bridge-info-left">
                <p className="muted">Est. Gas</p>
              </div>

              <div className="Bridge-info-right">
                {routeResponse ? (
                  <div className="flex flex-row gap-[0.5rem] items-center">
                    <p>{gasCost}</p>

                    <img src={gasToken?.logoURI} alt="" className="bridge-image" />
                  </div>
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
            <div className="Bridge-info-item">
              <div className="Bridge-info-left">
                <p className="muted">Est. Time</p>
              </div>

              <div className="Bridge-info-right">
                {routeResponse ? (
                  <div className="flex flex-row gap-[0.5rem] items-center">
                    <p>~20 seconds</p>
                  </div>
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
            <div className="Bridge-info-item">
              <div className="Bridge-info-left">
                <p className="muted">Est. Receive</p>
              </div>

              <div className="Bridge-info-right">
                {routeResponse ? (
                  <div className="flex flex-row gap-[0.5rem] items-center">
                    <p>
                      ~{bridgeToAmount} {selectedToToken?.symbol}
                    </p>
                  </div>
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <Button
          type="button"
          variant="primary-action"
          className="w-full capitalize"
          disabled={isButtondisabled}
          onClick={() => actionBridge()}
        >
          <div className="flex items-center justify-center flex-row h-full w-full">
            {isLoading && <SimpleLoadingAnimation />}
            {buttonMessage}
          </div>
        </Button>

        <div className="flex w-full items-center justify-center text-[1.4rem] gap-[0.5rem] mt-[2.5rem]">
          <p>Powered by</p>
          <img src={squidIcon} alt="squid" className=" w-[4rem]" />
          <p>+</p>
          <img src={axelarIcon} alt="axelar" className="w-[5rem]" />
        </div>
      </div>
    </div>
  );
}

export default BrideSquid;

import { TESTNET, ETH_MAINNET, MAINNET, ARBITRUM, ARBITRUM_TESTNET } from "./chains";
import { isDevelopment } from "./env";
import { getSubgraphUrlKey } from "./localStorage";

export type SupportedChainSubgraph = "stats" | "raw" | "referrals" | "nissohVault";

export type EthereumChainSubgraph = "chainLink";

type SubgraphUrls =
  | {
      [Key in Exclude<number, typeof ETH_MAINNET>]: Record<SupportedChainSubgraph, string>;
    }
  | {
      [Key in typeof ETH_MAINNET]: Record<EthereumChainSubgraph, string>;
    };

export const SUBGRAPH_URLS = {
  [MAINNET]: {
    stats:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-stats/bsc_mainnet/gn",
    raw: "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-raw/bsc_mainnet/gn",
    referrals:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-referrals/bsc_mainnet/gn",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },

  [TESTNET]: {
    stats:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-stats/bsc_dev/gn",
    raw: "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-raw/bsc_dev/gn",
    referrals:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-referrals/bsc_dev/gn",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },
  [ARBITRUM]: {
    stats:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-stats/arbitrum_mainnet/gn",
    raw: "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-raw/arbitrum_mainnet/gn",
    referrals:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-referrals/arbitrum_mainnet/gn",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },

  [ARBITRUM_TESTNET]: {
    stats:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-stats/arbitrum_dev/gn",
    raw: "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-raw/arbitrum_dev/gn",
    referrals:
      "https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-referrals/arbitrum_dev/gn",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },
  [ETH_MAINNET]: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
} satisfies SubgraphUrls;

export function getSubgraphUrl<
  TChainId extends keyof typeof SUBGRAPH_URLS,
  TSubgraph extends TChainId extends typeof ETH_MAINNET ? EthereumChainSubgraph : SupportedChainSubgraph
>(chainId: TChainId, subgraph: TSubgraph): string {
  if (isDevelopment()) {
    const localStorageKey = getSubgraphUrlKey(chainId, subgraph);
    const url = localStorage.getItem(localStorageKey);
    if (url) {
      // eslint-disable-next-line no-console
      console.warn("%s subgraph on chain %s url is overriden: %s", subgraph, chainId, url);
      return url;
    }
  }

  return (SUBGRAPH_URLS[chainId] as any)[subgraph];
}

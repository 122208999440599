import { FaCheck } from "react-icons/fa";
import { Trans } from "@lingui/macro";

import Button from "components/Button/Button";

import Cashout from "./Cashout";
import { BigNumber } from "ethers";
import { FundedLevels, MaxTradeSizes } from "components/Funded/types";
import { formatAmount } from "lib/formatAmount";

export default function ChallengePromoted({
  cashout,
  isLoading,
  challengeNumber,
  onCashout,
  onRollover,
}: {
  cashout: BigNumber;
  isLoading: boolean;
  challengeNumber: number;
  onCashout: () => void;
  onRollover: () => void;
}) {
  return (
    <div>
      <div className="flex items-center text-green text-[2rem] gap-x-3">
        <div className="w-[3.5rem] h-[3.5rem] flex items-center justify-center rounded-full bg-green/[0.15]">
          <FaCheck />
        </div>
        <Trans>Congratulations!</Trans>
      </div>
      <div className="text-inactive text-[1.4rem] mb-[9.7rem] mt-4">
        <Trans>
          You are moving up to the big league, you now qualify for ${formatAmount(MaxTradeSizes[challengeNumber])} max
          trade at the {FundedLevels[challengeNumber]} level.
        </Trans>
      </div>
      <Cashout cashout={cashout} isLoading={isLoading} />
      <div className="flex gap-x-[0.6rem] mt-4">
        {cashout.gt(0) && (
          <Button variant="green" onClick={onCashout} className="w-full rounded !text-black">
            <Trans>Cashout</Trans>
          </Button>
        )}

        <Button variant={cashout.gt(0) ? "white" : "green"} onClick={onRollover} className="w-full rounded !text-black">
          {cashout.gt(0) ? <Trans>Rollover</Trans> : <Trans>Start next challenge</Trans>}
        </Button>
      </div>
    </div>
  );
}

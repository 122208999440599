import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useIsTradePage = () => {
  const location = useLocation();
  const [isTradePage, setIsTradePage] = useState(false);

  useEffect(() => {
    if (location.pathname === "/trade") {
      setIsTradePage(true);
    } else {
      setIsTradePage(false);
    }
  }, [location.pathname]);

  return isTradePage;
};

export const useIsPortfolioPage = () => {
  const location = useLocation();
  const [isPortfolioPage, setIsPortfolioPage] = useState(false);

  useEffect(() => {
    if (location.pathname === "/portfolio") {
      setIsPortfolioPage(true);
    } else {
      setIsPortfolioPage(false);
    }
  }, [location.pathname]);

  return isPortfolioPage;
};

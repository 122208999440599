import { ethers } from "ethers";
import { getContractAddress } from "config/contracts";
import EbFox from "abis/common/EbFox.json";
import FoxifyMaxi from "abis/common/FoxifyMaxi.json";

export const getApyInfo = async (apyProvider, chainId, blockNumber) => {
  const dailyToYearlyApy = (dailyPriceDifference, initialPrice) =>
    ((dailyPriceDifference / initialPrice + 1) ** 365 - 1) * 100;

  const averageDailyToYearlyApy = (dailyTokenValues, initialPrice) => {
    const apys = [];
    const dailyPriceDifferences = [];
    let averageApy = 0.0;

    if (dailyTokenValues.length === 1) {
      return dailyToYearlyApy(dailyTokenValues[0], initialPrice);
    } else if (dailyTokenValues.length === 0) {
      return;
    }

    for (let i = 0; i < dailyTokenValues.length - 1; i++) {
      dailyPriceDifferences.push(dailyTokenValues[i + 1] - dailyTokenValues[i]);
      apys.push(dailyToYearlyApy(dailyPriceDifferences[i], dailyTokenValues[i]));

      averageApy += apys[i];
    }
    averageApy = averageApy / dailyPriceDifferences.length;
    return averageApy;
  };

  const currentTime = new Date();

  if (!apyProvider) return;
  const getPastBlockNumberRelativeToMidnight = (daysAgo) => {
    const averageBlockTime = 0.3; // 0.3 seconds per block, see https://arbiscan.io/ - latest block section
    const numberOfSecondsPerDay = 86400; //24 * 60 * 60;
    const mostRecentMidnight = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      0,
      0,
      0
    );
    const timeDiffInMS = currentTime.getTime() - mostRecentMidnight.getTime();
    const timeDiffInSecs = daysAgo === 0 ? timeDiffInMS / 1000 : numberOfSecondsPerDay;
    const numberOfBlocks = (timeDiffInSecs / averageBlockTime) * (daysAgo === 0 ? 1 : daysAgo);
    return Math.round(blockNumber - numberOfBlocks);
  };

  const initialPriceEbFox = 1.338;
  const dailyTokenValues = (contract) => {
    return Promise.allSettled(
      Array(7)
        .fill(0)
        .map((_, i) => i)
        .map(async (daysAgo) => {
          const blockNum = getPastBlockNumberRelativeToMidnight(daysAgo);
          return await contract.calculatePrice({ blockTag: blockNum });
        })
    );
  };

  const ebFoxAddress = getContractAddress(chainId, "EbFox");
  const foxifyMaxiAddress = getContractAddress(chainId, "FoxifyMaxi");
  const ebFoxContract = new ethers.Contract(ebFoxAddress, EbFox.abi, apyProvider);
  const foxifyMaxiContract = new ethers.Contract(foxifyMaxiAddress, FoxifyMaxi.abi, apyProvider);

  const resolvedTokenValuesEbFox = await dailyTokenValues(ebFoxContract);
  const resolvedTokenValuesFoxifyMaxi = await dailyTokenValues(foxifyMaxiContract);

  const formattedTokenValuesEbFox =
    resolvedTokenValuesEbFox
      ?.map((x) => {
        return x?.status === "fulfilled" && x.value && +ethers.utils.formatEther(x.value.toString());
      })
      .reverse() ?? [];

  const formattedTokenValuesFoxifyMaxi =
    resolvedTokenValuesFoxifyMaxi
      ?.map((x) => {
        return x?.status === "fulfilled" && x.value && +ethers.utils.formatEther(x.value.toString());
      })
      .reverse() ?? [];

  const apyEbFox = averageDailyToYearlyApy(formattedTokenValuesEbFox, initialPriceEbFox) * 3;

  const apyFox = averageDailyToYearlyApy(formattedTokenValuesFoxifyMaxi, initialPriceEbFox) * 3;

  return { apyEbFox, apyFox };
};

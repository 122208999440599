import React from "react";
import cx from "classnames";

export const GradientContainer = (props: { children?: React.ReactNode; className?: string, wrapperClassName?: string }) => {
  return (
    <div className={cx([props.className, "relative flex flex-col items-stretch"])}>
      <div className="bg-gradient-to-bl from-[#F66B31] to-[#e88054] rounded-[2.5rem] relative flex-grow flex flex-col items-stretch">
        <div className="absolute inset-0 rounded-[2.5rem] border pointer-events-none" />

        <div className="absolute inset-[0.5rem] rounded-[2rem] bg-black bg-opacity-[0.15] pointer-events-none" />

        <div className={cx("relative flex-grow bg-opacity-100", props.wrapperClassName)}>{props.children}</div>
      </div>
    </div>
  );
};

import { useState, useCallback, useEffect, useMemo } from "react";
import cx from "classnames";
import { ethers } from "ethers";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import { BigNumber } from "@ethersproject/bignumber";
import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  DECREASE,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  OrderOption,
  MAX_ALLOWED_LEVERAGE,
  USDC_DECIMALS,
} from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder } from "domain/legacy";
import { getContractAddress } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
// import Tab from "../Tab/Tab";
import ExchangeInfoRow from "./ExchangeInfoRow";
import { Tooltip } from "components/TooltipV2/Tooltip";
import "./PositionSeller.css";
import { callContract, useQuickContracts } from "lib/contracts";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Button from "components/Button/Button";
import FeesTooltip from "./FeesTooltip";
import { useAppUpdate } from "lib/useAppUpdate";
import { TokenUtils } from "components/TokenUtils";
import { PositionModalType } from "./constants";
import { useFundedTrader } from "hooks/useFundedTrader";
import TraderContractAbi from "../../abis/Trader.json";
import { formatBigAmount } from "lib/formatAmount";
import useSWR from "swr";
import { debounce } from "lodash";

const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

const HIGH_SPREAD_THRESHOLD = expandDecimals(1, USD_DECIMALS).div(100); // 1%;

function applySpread(amount, spread) {
  if (!amount || !spread) {
    return amount;
  }
  return amount.sub(amount.mul(spread).div(PRECISION));
}

export default function PositionSeller(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    isVisible,
    setIsVisible,
    account,
    library,
    infoTokens,
    setPendingTxns,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    isWaitingForPluginApproval,
    isPluginApproving,
    orderBookApproved,
    setOrdersToaOpen,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    minExecutionFee,
    minExecutionFeeErrorMessage,
    modalType,
    refetchBalance,
    isFundedTrading,
    expectedMinTradeSize,
  } = props;
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [fromValue, setFromValue] = useState("");
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const positionRouterAddress = getContractAddress(chainId, "PositionRouter");
  const longOrShortText = position?.isLong ? t`Long` : t`Short`;
  const quickContracts = useQuickContracts();
  const [debouncedCollateralDelta, setDebouncedCollateralDelta] = useState<BigNumber>(BigNumber.from(0));
  const { proxyTraderContractAddress } = useFundedTrader();

  const allowedSlippage = isHigherSlippageAllowed ? DEFAULT_HIGHER_SLIPPAGE_AMOUNT : savedSlippageAmount;

  const ORDER_OPTIONS = [OrderOption.Market, OrderOption.Stop] satisfies OrderOption[];
  // const ORDER_OPTION_LABELS: Record<(typeof ORDER_OPTIONS)[number], string> = {
  //   [OrderOption.Market]: t`Market`,
  //   [OrderOption.Stop]: t`Trigger`,
  // };

  let [orderOption] = useState<(typeof ORDER_OPTIONS)[number] | null>(
    modalType === PositionModalType.CLOSE ? null : OrderOption.Stop
  );

  if (!flagOrdersEnabled) {
    orderOption = OrderOption.Market;
  }

  const needPositionRouterApproval = !positionRouterApproved && orderOption === OrderOption.Market;

  // const onOrderOptionChange = (option) => {
  //   setOrderOption(option);
  // };

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
  };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [inputActive, setInputActive] = useState("");

  const triggerPriceUsd =
    orderOption === OrderOption.Market ? BigNumber.from(0) : parseValue(triggerPriceValue, USD_DECIMALS);

  const [nextDelta, nextHasProfit = BigNumber.from(0)] = useMemo(() => {
    if (!position) {
      return [BigNumber.from(0), false];
    }

    if (orderOption !== OrderOption.Stop && modalType === PositionModalType.ADD) {
      return [position.delta, position.hasProfit, position.deltaPercentage];
    }

    if (!triggerPriceUsd) {
      return [BigNumber.from(0), false];
    }

    const { delta, hasProfit, deltaPercentage } = calculatePositionDelta(triggerPriceUsd, position);
    return [delta, hasProfit, deltaPercentage];
  }, [position, orderOption, modalType, triggerPriceUsd]);

  const existingOrders = useMemo<any[]>(() => {
    if (orderOption === OrderOption.Stop && (!triggerPriceUsd || triggerPriceUsd.eq(0))) {
      return [];
    }
    if (!orders || !position) {
      return [];
    }

    const ret: any[] = [];
    for (const order of orders) {
      // only Stop orders can't be executed without corresponding opened position
      if (order.type !== DECREASE) continue;

      // if user creates Stop-Loss we need only Stop-Loss orders and vice versa
      if (orderOption === OrderOption.Stop) {
        const triggerAboveThreshold = (triggerPriceUsd ?? BigNumber.from(0)).gt(position.markPrice);
        if (triggerAboveThreshold !== order.triggerAboveThreshold) continue;
      }

      const sameToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address;
      if (order.isLong === position.isLong && sameToken) {
        ret.push(order);
      }
    }
    return ret;
  }, [position, orders, triggerPriceUsd, orderOption, nativeTokenAddress]);

  const existingOrder = existingOrders[0];

  const needOrderBookApproval = orderOption === OrderOption.Stop && !orderBookApproved;

  const appUpdate = useAppUpdate();

  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;

  let nextLeverage;
  let liquidationPrice;
  let nextLiquidationPrice;
  let isClosing;
  let sizeDelta: BigNumber | undefined;

  let nextCollateral;
  let collateralDelta = BigNumber.from(0);
  let receiveUsd = BigNumber.from(0);
  let adjustedDelta = BigNumber.from(0);

  let isNotEnoughReceiveTokenLiquidity;
  let isCollateralPoolCapacityExceeded;

  let title;
  let fundingFee = BigNumber.from(0);
  let positionFee = BigNumber.from(0);
  let totalFees = BigNumber.from(0);

  let executionFee =
    orderOption === OrderOption.Stop ? getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE") : minExecutionFee;
  let executionFeeUsd = getUsd(executionFee, nativeTokenAddress, false, infoTokens) || BigNumber.from(0);

  const collateralToken = position.collateralToken;
  const collateralTokenInfo = getTokenInfo(infoTokens, collateralToken.address);

  if (position) {
    fundingFee = position.fundingFee;
    fromAmount = parseValue(fromValue, USD_DECIMALS);
    sizeDelta = fromAmount;

    title =
      modalType === PositionModalType.CLOSE
        ? t`Close ${longOrShortText} ${TokenUtils.getSymbol(position.indexToken)}`
        : t`Set TP / SL`;
    liquidationPrice = getLiquidationPrice(position);

    if (fromAmount) {
      isClosing = position.size.sub(fromAmount).lt(DUST_USD);
      positionFee = getMarginFee(fromAmount);
    }

    if (isClosing) {
      sizeDelta = position.size;
      receiveUsd = position.collateral;
    } else if (orderOption === OrderOption.Stop && sizeDelta && existingOrders.length > 0) {
      let residualSize = position.size;
      for (const order of existingOrders) {
        residualSize = residualSize.sub(order.sizeDelta);
      }
      if (residualSize.sub(sizeDelta).abs().lt(ORDER_SIZE_DUST_USD)) {
        sizeDelta = residualSize;
      }
    }

    if (sizeDelta && position.size.gt(0)) {
      adjustedDelta = nextDelta.mul(sizeDelta).div(position.size);
    }

    if (nextHasProfit) {
      receiveUsd = receiveUsd.add(adjustedDelta);
    } else {
      if (receiveUsd.gt(adjustedDelta)) {
        receiveUsd = receiveUsd.sub(adjustedDelta);
      } else {
        receiveUsd = BigNumber.from(0);
      }
    }

    if (keepLeverage && sizeDelta && !isClosing) {
      collateralDelta = sizeDelta.mul(BigNumber.from(BASIS_POINTS_DIVISOR)).div(position.leverage);

      // if the position will be realising a loss then reduce collateralDelta by the realised loss
      if (!nextHasProfit) {
        const deductions = adjustedDelta.add(positionFee).add(fundingFee);
        if (collateralDelta.gt(deductions)) {
          collateralDelta = collateralDelta.sub(deductions);
        } else {
          collateralDelta = BigNumber.from(0);
        }
      }
    }

    maxAmount = position.size;
    maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
    maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);

    totalFees = totalFees.add(positionFee).add(fundingFee).add(executionFeeUsd);

    receiveUsd = receiveUsd.add(collateralDelta);

    if (sizeDelta) {
      if (receiveUsd.gt(totalFees)) {
        receiveUsd = receiveUsd.sub(totalFees);
      } else {
        receiveUsd = BigNumber.from(0);
      }
    }

    receiveUsd = applySpread(receiveUsd, collateralTokenInfo?.spread);

    if (isClosing) {
      nextCollateral = BigNumber.from(0);
    } else {
      if (position.collateral) {
        nextCollateral = position.collateral;
        if (collateralDelta && collateralDelta.gt(0)) {
          nextCollateral = position.collateral.sub(collateralDelta);
          if (!nextHasProfit) {
            nextCollateral = position.collateral.sub(collateralDelta).sub(positionFee).sub(fundingFee);
          }
        } else if (position.delta && position.delta.gt(0) && sizeDelta) {
          if (!position.hasProfit) {
            nextCollateral = nextCollateral.sub(adjustedDelta);
          }
        }
      }
    }

    if (fromAmount) {
      if (!isClosing && !keepLeverage) {
        const increaseSize = false;
        const increaseCollateral = false;

        nextLeverage = getLeverage({
          size: position.size,
          sizeDelta,
          increaseSize,
          collateral: position.collateral,
          collateralDelta,
          increaseCollateral,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          hasProfit: nextHasProfit,
          delta: nextDelta,
          includeDelta: savedIsPnlInLeverage,
        });
        nextLiquidationPrice = getLiquidationPrice({
          isLong: position.isLong,
          size: position.size,
          sizeDelta,
          collateral: position.collateral,
          averagePrice: position.averagePrice,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          delta: nextDelta,
          hasProfit: nextHasProfit,
          includeDelta: true,
        });
      }
    }
  }

  const [deltaStr, deltaPercentageStr] = useMemo(() => {
    if (!position || !position.markPrice || position.collateral.eq(0)) {
      return ["-", "-"];
    }
    if (orderOption !== OrderOption.Stop) {
      const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
        position.markPrice,
        position,
        fromAmount
      );
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: pendingDelta,
        deltaPercentage: pendingDeltaPercentage,
        hasProfit,
      });
      return [deltaStr, deltaPercentageStr];
    }
    if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      triggerPriceUsd,
      position,
      fromAmount
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, orderOption, triggerPriceUsd, fromAmount]);

  const debouncedUpdater = debounce(setDebouncedCollateralDelta, 500);

  useEffect(() => {
    if (!debouncedCollateralDelta.eq(collateralDelta)) {
      debouncedUpdater(collateralDelta);
    }
  }, [collateralDelta, debouncedCollateralDelta, debouncedUpdater]);

  const { data: collateralDeltaUsdc } = useSWR(["usdToCollateralTokenMin", debouncedCollateralDelta.toString()], () => {
    return quickContracts.fetch("Vault", "usdToCollateralTokenMin", [debouncedCollateralDelta]);
  });
  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (orderOption === OrderOption.Stop)
        return [t`Trigger order disabled, pending ${getChainName(chainId)} upgrade`];
      return [t`Position close disabled, pending ${getChainName(chainId)} upgrade`];
    }
    if (appUpdate.haveAppUpdate) {
      return t`Page outdated, please refresh`;
    }
    if (!fromAmount) {
      return t`Enter an amount`;
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return t`Enter an amount`;
    }
    if (orderOption === OrderOption.Stop) {
      if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
        return t`Enter Price`;
      }
      if (position.isLong && triggerPriceUsd.lte(liquidationPrice)) {
        return t`Price below Liq. Price`;
      }
      if (!position.isLong && triggerPriceUsd.gte(liquidationPrice)) {
        return t`Price above Liq. Price`;
      }

      if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
        return t`Invalid price, see warning`;
      }
    }

    if (isNotEnoughReceiveTokenLiquidity) {
      return t`Insufficient receive token liquidity`;
    }

    if (isCollateralPoolCapacityExceeded) {
      return t`${TokenUtils.getSymbol(collateralToken)} pool exceeded, can only Receive ${TokenUtils.getSymbol(
        collateralToken
      )}`;
    }

    if (!isClosing && position && position.size && fromAmount) {
      if (position.size.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))) {
        return t`Leftover position below 10 USD`;
      }
      if (nextCollateral && nextCollateral.lt(expandDecimals(5, USD_DECIMALS))) {
        return t`Leftover collateral below 5 USD`;
      }
    }

    if (position && position.size && position.size.lt(fromAmount)) {
      return t`Max close amount exceeded`;
    }

    if (nextLeverage && nextLeverage.lt(1.0 * BASIS_POINTS_DIVISOR)) {
      return t`Min leverage: 1.0x`;
    }

    let maxLeverage = BigNumber.from(MAX_ALLOWED_LEVERAGE);
    if (existingOrder) {
      const indexToken = getTokenInfo(infoTokens, existingOrder.indexToken);
      if (indexToken.maxLeverage) {
        maxLeverage = indexToken.maxLeverage;
      }
    }
    if (nextLeverage && nextLeverage.gt(maxLeverage)) {
      return t`Max leverage: ${maxLeverage && (maxLeverage.toNumber() / BASIS_POINTS_DIVISOR).toFixed(1)}x`;
    }

    if (!isClosing && isFundedTrading) {
      const formattedMinTradeSize = formatBigAmount(expectedMinTradeSize, USDC_DECIMALS);
      if (collateralDeltaUsdc && isFundedTrading && collateralDeltaUsdc.lt(expectedMinTradeSize)) {
        return t`Min collateral close amount: ${formattedMinTradeSize} USDC`;
      }

      if (
        !collateralDeltaUsdc &&
        collateralDelta &&
        isFundedTrading &&
        collateralDelta.lt(expectedMinTradeSize.mul(expandDecimals(1, USD_DECIMALS - USDC_DECIMALS)))
      ) {
        return t`Min collateral close amount: ${formattedMinTradeSize} USDC`;
      }

      // @todo Need to confirm nextCollateral calculation considering profit and fee
      /* if (position.size.sub(sizeDelta).div(nextCollateral).lt(10)) {
        return t`Min leverage: 10.0x`;
      } */
    }

    if (
      hasPendingProfit &&
      orderOption !== OrderOption.Stop &&
      !isProfitWarningAccepted &&
      modalType === PositionModalType.ADD
    ) {
      return t`Forfeit profit not checked`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if (isPluginApproving) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }

    return true;
  };

  const hasPendingProfit = MIN_PROFIT_TIME > 0 && position.delta.eq(0) && position.pendingDelta.gt(0);

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (orderOption === OrderOption.Stop) {
      if (isSubmitting) return t`Creating Order...`;

      if (needOrderBookApproval && isWaitingForPluginApproval) {
        return t`Enabling Orders...`;
      }
      if (isPluginApproving) {
        return t`Enabling Orders...`;
      }
      if (needOrderBookApproval) {
        return t`Enable Orders`;
      }

      return t`Create Order`;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling Leverage...`;
    }

    if (isPositionRouterApproving) {
      return t`Enabling Leverage...`;
    }

    if (needPositionRouterApproval) {
      return t`Enable Leverage`;
    }

    if (hasPendingProfit) {
      return t`Close without profit`;
    }
    return isSubmitting ? t`Closing...` : t`Close`;
  };

  const resetForm = () => {
    setFromValue("");
    setIsProfitWarningAccepted(false);
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const receiveSpreadInfo = useMemo(() => {
    if (!collateralTokenInfo) {
      return null;
    }

    return {
      value: collateralTokenInfo.spread,
      isHigh: (collateralTokenInfo.spread ?? BigNumber.from(0)).gt(HIGH_SPREAD_THRESHOLD),
    };
  }, [collateralTokenInfo]);

  const showReceiveSpread = (receiveSpreadInfo?.value ?? BigNumber.from(0)).gt(0);

  const renderReceiveSpreadWarning = useCallback(() => {
    if (receiveSpreadInfo && receiveSpreadInfo.isHigh) {
      return (
        <div className="Confirmation-box-warning">
          <Trans>
            Transacting with a depegged stable coin is subject to spreads reflecting the worse of current market price
            or $1.00, with transactions involving multiple stablecoins may have multiple spreads.
          </Trans>
        </div>
      );
    }
  }, [receiveSpreadInfo]);

  const onClickPrimary = async () => {
    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: t`Enable leverage sent.`,
        failMsg: t`Enable leverage failed.`,
      });
      return;
    }

    setIsSubmitting(true);

    const indexTokenAddress = position.indexToken.isNative ? nativeTokenAddress : position.indexToken.address;

    if (orderOption === OrderOption.Stop) {
      const triggerAboveThreshold = (triggerPriceUsd ?? BigNumber.from(0)).gt(position.markPrice);

      createDecreaseOrder(
        chainId,
        library,
        indexTokenAddress,
        sizeDelta,
        collateralDelta,
        position.isLong,
        triggerPriceUsd,
        triggerAboveThreshold,
        proxyTraderContractAddress,
        {
          sentMsg: t`Order submitted!`,
          successMsg: t`Order created!`,
          failMsg: t`Order creation failed.`,
          setPendingTxns,
          txnSuccessCallback: () => {
            setTimeout(refetchBalance, 5000);
          },
        }
      )
        .then(() => {
          setFromValue("");
          setIsVisible(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      return;
    }

    const priceBasisPoints = position.isLong
      ? BASIS_POINTS_DIVISOR - (allowedSlippage ?? 0)
      : BASIS_POINTS_DIVISOR + (allowedSlippage ?? 0);
    const refPrice = position.isLong ? position.indexToken.minPrice : position.indexToken.maxPrice;
    let priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);
    const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;
    const minProfitTimeExpired = Date.now() / 1000 > minProfitExpiration;

    if (nextHasProfit && !minProfitTimeExpired && !isProfitWarningAccepted) {
      if ((position.isLong && priceLimit.lt(profitPrice)) || (!position.isLong && priceLimit.gt(profitPrice))) {
        priceLimit = profitPrice;
      }
    }

    const params = [
      indexTokenAddress, // _indexToken
      collateralDelta, // _collateralDelta
      sizeDelta, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      AddressZero, // _callbackTarget
    ];
    const sizeDeltaUsd = formatAmount(sizeDelta, USD_DECIMALS, 2);
    const successMsg = t`Requested decrease of ${TokenUtils.getSymbol(
      position.indexToken
    )} ${longOrShortText} by ${sizeDeltaUsd} USD.`;

    const contract = proxyTraderContractAddress
      ? new ethers.Contract(proxyTraderContractAddress, TraderContractAbi.abi, library.getSigner())
      : new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());

    callContract(chainId, contract, "createDecreasePosition", params, {
      value: minExecutionFee,
      sentMsg: t`Close submitted!`,
      successMsg,
      failMsg: t`Close failed.`,
      setPendingTxns,
      txnSuccessCallback: refetchBalance,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async (res) => {
        setFromValue("");
        setIsVisible(false);

        let nextSize = position.size.sub(sizeDelta);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const renderExistingOrderWarning = useCallback(() => {
    if (!existingOrder) {
      return;
    }
    const indexToken = getTokenInfo(infoTokens, existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    );
    const prefix = existingOrder.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
    return (
      <div className="Confirmation-box-warning">
        <Trans>
          You have an active order to decrease {longOrShortText} {sizeInToken} {TokenUtils.getSymbol(indexToken)} ($
          {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) at {prefix}{" "}
          {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
        </Trans>
      </div>
    );
  }, [existingOrder, infoTokens, longOrShortText]);

  function renderMinProfitWarning() {
    if (MIN_PROFIT_TIME === 0) {
      return null;
    }

    const indexToken = getTokenInfo(infoTokens, existingOrder.indexToken);

    if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
      const minProfitExpiration = (position.lastIncreasedTime as number) + MIN_PROFIT_TIME;

      if (orderOption === OrderOption.Market && modalType === PositionModalType.ADD) {
        return (
          <div className="Confirmation-box-warning">
            <Trans>
              Reducing the position at the current price will forfeit a&nbsp;
              <ExternalLink href="https://docs.foxify.trade/">pending profit</ExternalLink> of {deltaStr}. <br />
            </Trans>
            <Trans>
              <br />
              Profit price: {position.isLong ? ">" : "<"} $
              {formatAmount(profitPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}. This rule applies for the next{" "}
              {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
            </Trans>
          </div>
        );
      }
      return (
        <div className="Confirmation-box-warning">
          <Trans>
            This order will forfeit a&nbsp;
            <ExternalLink href="https://docs.foxify.trade/">profit</ExternalLink> of {deltaStr}. <br />
          </Trans>
          <Trans>
            Profit price: {position.isLong ? ">" : "<"} $
            {formatAmount(profitPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}. This rule applies for the next{" "}
            {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
          </Trans>
        </div>
      );
    }
  }

  const profitPrice = getProfitPrice(
    orderOption === OrderOption.Market ? position.markPrice : triggerPriceUsd,
    position
  );

  let triggerPricePrefix;
  if (triggerPriceUsd) {
    triggerPricePrefix = triggerPriceUsd.gt(position.markPrice) ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
  }

  const shouldShowExistingOrderWarning = false;

  if (orderOption === OrderOption.Stop && !triggerPriceUsd) {
    receiveUsd = BigNumber.from(0);
  }

  return (
    <div className="PositionEditor">
      {position && (
        <ModalWithPortal
          className="PositionSeller-modal"
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={title}
          allowContentTouchMove
        >
          {/* {flagOrdersEnabled && ORDER_OPTIONS.length >= 2 && modalType === PositionModalType.ADD && (
            <Tab
              type="order"
              options={ORDER_OPTIONS}
              option={orderOption}
              optionLabels={ORDER_OPTION_LABELS}
              onChange={onOrderOptionChange}
            />
          )} */}
          <div
            className={cx("Exchange-swap-section-container transition-effect !mb-[0.5rem]", {
              inputActive: inputActive === "amount-input",
            })}
          >
            <div className="Exchange-swap-section-container-bottom ">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="Amount"
                  className="Exchange-swap-input"
                  value={fromValue}
                  onChange={(e) => setFromValue(e.target.value)}
                  onFocus={() => setInputActive("amount-input")}
                  onBlur={() => setInputActive("")}
                />
                {fromValue !== maxAmountFormattedFree && (
                  <div className="flex gap-[1rem] items-center">
                    <div
                      className="Exchange-swap-max"
                      onClick={() => {
                        setFromValue(maxAmountFormattedFree);
                      }}
                    >
                      <Trans>Max</Trans>
                    </div>
                    <div className="TokenSelector">
                      <div className="TokenSelector-box">USDC</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <Trans>Available</Trans>
            </div>
            <div className="align-right">{maxAmountFormatted}</div>
          </div>
          <div className="line my-[1.3rem]"></div>
          {orderOption === OrderOption.Stop && modalType === PositionModalType.ADD && (
            <div
              className={cx("Exchange-swap-section-container transition-effect", {
                inputActive: inputActive === "trigger-input",
              })}
            >
              <div className="Exchange-swap-section-container-bottom ">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="Trigger Price"
                    className="Exchange-swap-input"
                    value={triggerPriceValue}
                    onChange={onTriggerPriceChange}
                    onFocus={() => setInputActive("trigger-input")}
                    onBlur={() => setInputActive("")}
                  />
                  <div className="TokenSelector">
                    <div className="TokenSelector-box">USDC</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {renderMinProfitWarning()}
          {renderReceiveSpreadWarning()}
          {shouldShowExistingOrderWarning && renderExistingOrderWarning()}
          <div className="PositionEditor-info-box">
            {minExecutionFeeErrorMessage && (
              <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>
            )}
            {hasPendingProfit && orderOption !== OrderOption.Stop && modalType === PositionModalType.ADD && (
              <div className="PositionEditor-accept-profit-warning">
                <Checkbox isChecked={isProfitWarningAccepted} setIsChecked={setIsProfitWarningAccepted}>
                  <span className="muted">Forfeit profit</span>
                </Checkbox>
              </div>
            )}
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Mark Price</Trans>
              </div>
              <div className="align-right">
                ${formatAmount(position.markPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
              </div>
            </div>
            <div className="PositionEditor-keep-leverage-settings">
              <Checkbox isChecked={keepLeverage} setIsChecked={setKeepLeverage}>
                <span className="muted font-sm">
                  <Trans>Keep leverage at {formatAmount(position.leverage, 4, 2)}x</Trans>
                </span>
              </Checkbox>
            </div>
            {(orderOption === OrderOption.Market || modalType === PositionModalType.CLOSE) && (
              <div className="PositionEditor-allow-higher-slippage">
                <Checkbox isChecked={isHigherSlippageAllowed} setIsChecked={setIsHigherSlippageAllowed}>
                  <span className="muted font-sm">
                    <Trans>Allow up to 1% slippage</Trans>
                  </span>
                </Checkbox>
              </div>
            )}
            {(orderOption === OrderOption.Market || modalType === PositionModalType.CLOSE) && (
              <div>
                <ExchangeInfoRow label={t`Allowed Slippage`} className="mt-0">
                  <Tooltip
                    renderContent={() => {
                      return (
                        <Trans>
                          You can change this in the settings menu on the top right of the page.
                          <br />
                          <br />
                          Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices
                          are volatile.
                        </Trans>
                      );
                    }}
                  >
                    <span className="underline">{`${formatAmount(allowedSlippage, 2, 2)}%`}</span>
                  </Tooltip>
                </ExchangeInfoRow>
              </div>
            )}
            <div className="line my-[1.3rem]"></div>

            {orderOption === OrderOption.Stop && modalType === PositionModalType.ADD && (
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Trigger Price</Trans>
                </div>
                <div className="align-right">
                  {!triggerPriceUsd && "-"}
                  {triggerPriceUsd &&
                    `${triggerPricePrefix} ${formatAmount(
                      triggerPriceUsd,
                      USD_DECIMALS,
                      position.indexToken?.priceDecimals,
                      true
                    )}`}
                </div>
              </div>
            )}

            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Entry Price</Trans>
              </div>
              <div className="align-right">
                ${formatAmount(position.averagePrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Liq. Price</Trans>
              </div>
              <div className="align-right">
                {isClosing && orderOption !== OrderOption.Stop && "-"}
                {(!isClosing || orderOption === OrderOption.Stop) && (
                  <div>
                    {(!nextLiquidationPrice || nextLiquidationPrice.eq(liquidationPrice)) && (
                      <div>{`$${formatAmount(
                        liquidationPrice,
                        USD_DECIMALS,
                        position.indexToken?.priceDecimals,
                        true
                      )}`}</div>
                    )}
                    {nextLiquidationPrice && !nextLiquidationPrice.eq(liquidationPrice) && (
                      <div>
                        <div className="inline-block muted">
                          ${formatAmount(liquidationPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                          <BsArrowRight className="transition-arrow" />
                        </div>
                        ${formatAmount(nextLiquidationPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="line my-[1.3rem]"></div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Size</Trans>
              </div>
              <div className="align-right">
                {position && position.size && fromAmount && (
                  <div>
                    <div className="inline-block muted">
                      ${formatAmount(position.size, USD_DECIMALS, 2, true)}
                      <BsArrowRight className="transition-arrow" />
                    </div>
                    ${formatAmount(position.size.sub(fromAmount), USD_DECIMALS, 2, true)}
                  </div>
                )}
                {position && position.size && !fromAmount && (
                  <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                )}
              </div>
            </div>
            <div className="Exchange-info-row">
              <div>
                <Tooltip
                  renderContent={() => {
                    return <Trans>Initial Collateral (Collateral excluding Borrow Fee).</Trans>;
                  }}
                >
                  <span className="Exchange-info-label">
                    <Trans>Collateral ({TokenUtils.getSymbol(collateralToken)})</Trans>
                  </span>
                </Tooltip>
              </div>

              <div className="align-right">
                {nextCollateral && !nextCollateral.eq(position.collateral) ? (
                  <div>
                    <div className="inline-block muted">
                      ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                      <BsArrowRight className="transition-arrow" />
                    </div>
                    ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                  </div>
                ) : (
                  `$${formatAmount(position.collateral, USD_DECIMALS, 4, true)}`
                )}
              </div>
            </div>
            {!keepLeverage && (
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <Trans>Leverage</Trans>
                </div>
                <div className="align-right">
                  {isClosing && "-"}
                  {!isClosing && (
                    <div>
                      {!nextLeverage && <div>{formatAmount(position.leverage, 4, 2)}x</div>}
                      {nextLeverage && (
                        <div>
                          <div className="inline-block muted">
                            {formatAmount(position.leverage, 4, 2)}x
                            <BsArrowRight className="transition-arrow" />
                          </div>
                          {formatAmount(nextLeverage, 4, 2)}x
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>PnL</Trans>
              </div>
              <div
                className={cx("align-right", {
                  positive: position.deltaPercentage > 0 && !deltaPercentageStr.includes("-"),
                  negative: position.deltaPercentage > 0 && deltaPercentageStr.includes("-"),
                })}
              >
                {deltaStr} ({deltaPercentageStr})
              </div>
            </div>

            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Fees</Trans>
              </div>
              <div className="align-right">
                <FeesTooltip
                  isOpening={false}
                  positionFee={positionFee}
                  fundingFee={`$${formatAmount(fundingFee, USD_DECIMALS, 2, true)}`}
                  totalFees={totalFees}
                  executionFees={{
                    fee: executionFee,
                    feeUSD: executionFeeUsd,
                  }}
                />
              </div>
            </div>
            {showReceiveSpread && (
              <ExchangeInfoRow label={t`Spread`} isWarning={receiveSpreadInfo?.isHigh} isTop>
                {formatAmount((receiveSpreadInfo?.value ?? BigNumber.from(0)).mul(100), USD_DECIMALS, 2, true)}%
              </ExchangeInfoRow>
            )}
          </div>
          <div className="Exchange-swap-button-container">
            <Button variant="primary-action" className="w-100" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </Button>
          </div>
        </ModalWithPortal>
      )}
    </div>
  );
}

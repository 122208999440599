import { Trans, t } from "@lingui/macro";
import { PageTabs, PageTabPanel } from "components/PageTabs/PageTabs";
import { LEADERBOARD_TAB_KEY } from "config/localStorage";
import { shortenAddress } from "lib/legacy";

import FirstPlace from "../../../../img/places/Gold.svg";
import SecondPlace from "../../../../img/places/Silver.svg";
import ThirdPlace from "../../../../img/places/Bronze.svg";

import "./LeaderBoard.css";

const TAB_IDS = {
  CASHOUT: "By Cashout",
  POINTS: "By Points",
} as const;

interface Leader {
  number: number;
  wallet: string;
  nickname: string;
  totalPoints: number;
}

const TAB_OPTION_LABELS = {
  [TAB_IDS.CASHOUT]: {
    label: t`By Cashout`,
  },
  [TAB_IDS.POINTS]: {
    label: t`By Points`,
  },
};

const currentUserData: Leader = {
  number: 5,
  wallet: "0xFa12853A467a3745a88B77f53C2b2b4347c46d7D",
  nickname: "Oleh",
  totalPoints: 15.79,
};

const leadersData: Leader[] = [
  { number: 1, wallet: "0x3412853A467a3745a88B77f53C2b2b4347c46d7D", nickname: "Robin", totalPoints: 15.2 },
  { number: 2, wallet: "0x02b84676fA81D129B1fc4871Ab01234e45Bfda79", nickname: "Mike", totalPoints: 16.71 },
  { number: 3, wallet: "0x9a51F840F1a4083F40FB4c541dD813EbAc0F7518", nickname: "Susan", totalPoints: 11.41 },
  { number: 4, wallet: "0x7A5DE844Edc64504f05fb37eA2Df471CA1df2537", nickname: "Nick", totalPoints: 13.63 },
];

const getPlaceIconOrNumber = (number: number) => {
  if (number === 1) {
    return <img src={FirstPlace} alt="first-place" />;
  } else if (number === 2) {
    return <img src={SecondPlace} alt="second-place" />;
  } else if (number === 3) {
    return <img src={ThirdPlace} alt="third-place" />;
  } else {
    return <span className="ml-2.5">{number}</span>;
  }
};

export const LeaderBoardTab = () => {
  return (
    <div className="z-30">
      <PageTabs
        tabsIds={[TAB_IDS.CASHOUT, TAB_IDS.POINTS]}
        tabsDetails={TAB_OPTION_LABELS}
        storageKey={LEADERBOARD_TAB_KEY}
        classNames="self-start"
      >
        <PageTabPanel tabId={TAB_IDS.CASHOUT}>
          <div>
            <Trans>By Cashout</Trans>
          </div>
        </PageTabPanel>

        <PageTabPanel tabId={TAB_IDS.POINTS}>
          <div>
            <Trans>By Points</Trans>
          </div>
        </PageTabPanel>
      </PageTabs>

      <table className="w-full z-10">
        <thead className="w-full flex mt-[2rem] bg-background-5-v2-only py-[1rem] px-[1rem] border border-border rounded-tl-[2rem] rounded-tr-[2rem] text-[1.3rem] text-inactive font-regular">
          <tr className="w-full flex ">
            <th className="min-w-[8rem] md:min-w-[11rem] text-start pr-[0.5rem]">#</th>
            <th className="w-full text-start pr-[0.5rem]">
              <Trans>User Wallet Address</Trans>
            </th>
            <th className="w-full text-start pr-[0.5rem]">
              <Trans>Nickname</Trans>
            </th>
            <th className="w-1/2 text-start pr-[0.5rem]">
              <Trans>Total Points</Trans>
            </th>
          </tr>
        </thead>
        <tbody className="flex flex-col">
          <tr className="w-full flex text-[1.3rem] text-textColor h-[6rem] items-center pl-[1rem] border-b border-border">
            <td className="min-w-[8rem] md:min-w-[11rem] text-start pr-[0.5rem]">
              <div className="ml-2.5">
                {currentUserData.number}
                <span className="rounded-xxs bg-gradient-to-l from-[#EDEBF7] to-[#FFC9B3] text-[1.2rem] text-black px-[1rem] py-[0.5rem] ml-[1rem]">
                  <Trans>You</Trans>
                </span>
              </div>
            </td>
            <td className="w-full text-start  pr-[0.5rem]">{shortenAddress(currentUserData.wallet, 9)}</td>

            <td className="w-full text-start  pr-[0.5rem]">{currentUserData.nickname}</td>
            <td className="w-1/2 text-start  pr-[0.5rem]">{currentUserData.totalPoints}</td>
          </tr>
          {leadersData.map(({ number, wallet, nickname, totalPoints }) => (
            <tr
              key={wallet}
              className="w-full flex text-[1.3rem] text-textColor h-[6rem] items-center pl-[1rem] border-b border-border"
            >
              <td className="min-w-[8rem] md:min-w-[11rem] text-start pr-[0.5rem] flex items-center">
                {getPlaceIconOrNumber(number)}
              </td>
              <td className="w-full text-start  pr-[0.5rem]">{shortenAddress(wallet, 9)}</td>
              <td className="w-full text-start  pr-[0.5rem]">{nickname}</td>
              <td
                className={`${
                  number === 1 ? "gold" : number === 2 ? "silver" : number === 3 ? "bronze" : ""
                } w-1/2 text-start  pr-[0.5rem]`}
              >
                {totalPoints}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { BASIS_POINTS_DIVISOR, LONG, MIN_ALLOWED_LEVERAGE, SHORT } from "lib/constants";
import { t } from "@lingui/macro";
import longImg from "img/long.svg";
import shortImg from "img/short.svg";

interface LeverageMarks {
  [key: number]: {
    [key: number]: string;
  };
}

export const leverageMarksMap: LeverageMarks = {
  20: {
    2: "2x",
    3: "3x",
    5: "5x",
    8: "8x",
    10: "10x",
    12: "12x",
    15: "15x",
    18: "18x",
    20: "20x",
  },
  50: {
    2: "2x",
    5: "5x",
    10: "10x",
    15: "15x",
    20: "20x",
    25: "25x",
    30: "30x",
    35: "35x",
    40: "40x",
    45: "45x",
    50: "50x",
  },
};

export const SWAP_ICONS = {
  [LONG]: longImg,
  [SHORT]: shortImg,
};
export const SWAP_LABELS = {
  [LONG]: t`Long`,
  [SHORT]: t`Short`,
};

export const MIN_LEVERAGE = MIN_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR;

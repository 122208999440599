import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Trans, t } from "@lingui/macro";
import { ethers } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { BsArrowRight } from "react-icons/bs";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import {
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DUST_BNB,
  getLiquidationPrice,
  getFundingFee,
  USDC_DECIMALS,
} from "lib/legacy";
import { getContractAddress } from "config/contracts";
import Tab from "../Tab/Tab";

import PositionRouter from "abis/PositionRouter.json";
import TraderContractAbi from "abis/Trader.json";
import Token from "abis/common/Token.json";
import { Tooltip } from "components/TooltipV2/Tooltip";

import { getChainName, IS_NETWORK_DISABLED } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { getTokenInfo } from "domain/tokens/utils";
import { approveTokens, shouldRaiseGasError } from "domain/tokens";
import { usePrevious } from "lib/usePrevious";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { ErrorCode, ErrorDisplayType } from "./constants";
import Button from "components/Button/Button";
import FeesTooltip from "./FeesTooltip";
import { TokenUtils } from "components/TokenUtils";
import { cx } from "lib/cx";
import { useFundedTrader } from "hooks/useFundedTrader";
import { useWeb3React } from "@web3-react/core";
import { formatBigAmount } from "lib/formatAmount";

const DEPOSIT = "Deposit";
const WITHDRAW = "Withdraw";
const EDIT_OPTIONS = [DEPOSIT, WITHDRAW];
const MIN_ORDER_USD = expandDecimals(10, USD_DECIMALS);
const { AddressZero, Zero } = ethers.constants;

export default function PositionEditor(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    isVisible,
    setIsVisible,
    infoTokens,
    active,
    account,
    library,
    collateralTokenAddress,
    pendingTxns,
    setPendingTxns,
    getUsd,
    getLeverage,
    savedIsPnlInLeverage,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    chainId,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    refetchBalance,
  } = props;

  const { account: walletAddress } = useWeb3React();

  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [option, setOption] = useState(DEPOSIT);
  const [fromValue, setFromValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const [inputActive, setInputActive] = useState("");

  const { proxyTraderContractAddress, fundedBalance, expectedMinTradeSize } = useFundedTrader();

  const prevIsVisible = usePrevious(isVisible);
  const longOrShortText = position?.isLong ? t`Long` : t`Short`;

  const routerAddress = getContractAddress(chainId, "Router");
  const positionRouterAddress = getContractAddress(chainId, "PositionRouter");

  const { data: tokenAllowance, mutate: refetchAllowance } = useSWR(
    active && [active, chainId, collateralTokenAddress, "allowance", walletAddress, routerAddress],
    contractFetcher(library, Token)
  );

  const isDeposit = option === DEPOSIT;
  const isWithdrawal = option === WITHDRAW;

  const needPositionRouterApproval = !positionRouterApproved;

  let collateralToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;
  let needApproval;

  let convertedAmount;

  let nextLeverage;
  let nextLeverageExcludingPnl;
  let liquidationPrice;
  let nextLiquidationPrice;
  let nextCollateral;

  let title;
  let collateralDelta;
  let fundingFee;

  if (position) {
    title = t`Edit Collateral`;
    collateralToken = position.collateralToken;
    liquidationPrice = getLiquidationPrice(position);
    fundingFee = getFundingFee(position);

    if (isDeposit) {
      fromAmount = parseValue(fromValue, collateralToken.decimals);
      maxAmount = proxyTraderContractAddress
        ? fundedBalance
        : collateralToken
        ? collateralToken.balance
        : bigNumberify(0);
      maxAmountFormatted = formatAmount(maxAmount, collateralToken.decimals, 4, true);
      maxAmountFormattedFree = formatAmountFree(maxAmount, collateralToken.decimals, 8);
      if (fromAmount) {
        convertedAmount = getUsd(fromAmount, position.collateralToken.address, false, infoTokens);
      }
    } else {
      fromAmount = parseValue(fromValue, USD_DECIMALS);

      maxAmount = position.collateralAfterFee.sub(MIN_ORDER_USD).gt(0)
        ? position.collateralAfterFee.sub(MIN_ORDER_USD)
        : bigNumberify(0);

      maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
      maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);
      if (fromAmount) {
        convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice);
      }
    }

    needApproval = isDeposit && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance);

    if (fromAmount) {
      collateralDelta = isDeposit ? convertedAmount : fromAmount;

      nextLeverage = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: savedIsPnlInLeverage,
      });

      nextLeverageExcludingPnl = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: false,
      });

      nextLiquidationPrice = getLiquidationPrice({
        isLong: position.isLong,
        size: position.size,
        collateral: position.collateral,
        averagePrice: position.averagePrice,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        collateralDelta,
        increaseCollateral: isDeposit,
      });

      nextCollateral = isDeposit
        ? position.collateralAfterFee.add(collateralDelta)
        : position.collateralAfterFee.sub(collateralDelta);
    }
  }

  const getError = (): [string, ErrorDisplayType?, ErrorCode?] | [false] => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isDeposit) return [t`Deposit disabled, pending ${getChainName(chainId)} upgrade`];
      return [t`Withdraw disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!fromAmount) {
      return [t`Enter an amount`];
    }

    if (fromAmount.lte(0)) {
      return [t`Amount should be greater than zero`];
    }

    const formattedMinTradeSize = formatBigAmount(expectedMinTradeSize, USDC_DECIMALS);

    if (proxyTraderContractAddress && parseValue(fromValue, USDC_DECIMALS)?.lt(expectedMinTradeSize)) {
      return [t`Minimum Amount: ${formattedMinTradeSize}`];
    }

    if (!isDeposit && fromAmount) {
      if (position.collateralAfterFee.sub(fromAmount).lt(MIN_ORDER_USD)) {
        return [t`Min residual collateral: 10 USD`];
      }
    }

    if (!isDeposit && fromAmount && nextLiquidationPrice) {
      if (position.isLong && position.markPrice.lt(nextLiquidationPrice)) {
        return [t`Invalid liq. price`, ErrorDisplayType.Tooltip, ErrorCode.InvalidLiqPrice];
      }
      if (!position.isLong && position.markPrice.gt(nextLiquidationPrice)) {
        return [t`Invalid liq. price`, ErrorDisplayType.Tooltip, ErrorCode.InvalidLiqPrice];
      }
    }

    if (nextLeverageExcludingPnl && nextLeverageExcludingPnl.lt(1.0 * BASIS_POINTS_DIVISOR)) {
      return [t`Min leverage: 1.0x`];
    }

    if (nextLeverage && nextLeverage.gt(position.indexToken.maxLeverage)) {
      return [
        t`Max leverage: ${
          position.indexToken.maxLeverage &&
          (position.indexToken.maxLeverage.toNumber() / BASIS_POINTS_DIVISOR).toFixed(1)
        }x`,
      ];
    }
    return [false];
  };

  const isPrimaryEnabled = () => {
    const [error] = getError();
    if (error) {
      return false;
    }
    if (isSwapping) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    const [error] = getError();
    if (error) {
      return error;
    }
    if (isSwapping) {
      if (isDeposit) {
        return t`Depositing...`;
      }
      return t`Withdrawing...`;
    }

    if (isApproving) {
      return t`Approving ${TokenUtils.getSymbol(position.collateralToken)}...`;
    }
    if (needApproval) {
      return t`Approve ${TokenUtils.getSymbol(position.collateralToken)}`;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling Leverage`;
    }

    if (isPositionRouterApproving) {
      return t`Enabling Leverage...`;
    }

    if (needPositionRouterApproval) {
      return t`Enable Leverage`;
    }

    if (isDeposit) {
      return t`Deposit`;
    }

    return t`Withdraw`;
  };

  const resetForm = () => {
    setFromValue("");
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const depositCollateral = async () => {
    setIsSwapping(true);
    const indexTokenAddress =
      position.indexToken.address === AddressZero ? nativeTokenAddress : position.indexToken.address;

    const priceBasisPoints = position.isLong ? 11000 : 9000;
    const priceLimit = position.indexToken.maxPrice.mul(priceBasisPoints).div(10000);

    const referralCode = ethers.constants.HashZero;
    // const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd);

    const orderExecutionData = ethers.utils.defaultAbiCoder.encode(
      ["address", "uint256", "uint256", "uint256", "uint256", "uint256", "uint256", "bool"],
      [indexTokenAddress, Zero, Zero, fromAmount, fromAmount, Zero, Zero, position.isLong]
    );

    let params = [
      indexTokenAddress, // _indexToken
      fromAmount, // _amountIn
      0, // _minOut
      BigNumber.from(0), // _sizeDelta
      position.isLong, // _isLong
      priceLimit, // _acceptablePrice
      minExecutionFee, // _executionFee
      referralCode, // _referralCode
      orderExecutionData,
      getContractAddress(chainId, "PositionRouterCallbackReceiverTP"), // _callbackTarget
    ];

    let method = "createIncreasePosition";
    let value = minExecutionFee;

    if (shouldRaiseGasError(getTokenInfo(infoTokens, collateralTokenAddress), fromAmount)) {
      setIsSwapping(false);
      helperToast.error(t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
      return;
    }

    const contract = proxyTraderContractAddress
      ? new ethers.Contract(proxyTraderContractAddress, TraderContractAbi.abi, library.getSigner())
      : new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    callContract(chainId, contract, method, params, {
      value,
      sentMsg: t`Deposit submitted.`,
      successMsg: t`Requested deposit of ${formatAmount(
        fromAmount,
        position.collateralToken.decimals,
        4
      )} ${TokenUtils.getSymbol(position.collateralToken)} into ${TokenUtils.getSymbol(
        position.indexToken
      )} ${longOrShortText}.`,
      failMsg: t`Deposit failed.`,
      setPendingTxns,
      txnSuccessCallback: refetchBalance,
    })
      .then(async (res) => {
        setFromValue("");
        setIsVisible(false);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const withdrawCollateral = async () => {
    setIsSwapping(true);
    const indexTokenAddress =
      position.indexToken.address === AddressZero ? nativeTokenAddress : position.indexToken.address;
    const priceBasisPoints = position.isLong ? 9000 : 11000;
    const priceLimit = position.indexToken.maxPrice.mul(priceBasisPoints).div(10000);

    const withdrawAmount = fromAmount.add(fundingFee || bigNumberify(0));

    const params = [
      indexTokenAddress, // _indexToken
      withdrawAmount, // _collateralDelta
      0, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      AddressZero, // _callbackTarget
    ];

    const method = "createDecreasePosition";

    const contract = proxyTraderContractAddress
      ? new ethers.Contract(proxyTraderContractAddress, TraderContractAbi.abi, library.getSigner())
      : new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    callContract(chainId, contract, method, params, {
      value: minExecutionFee,
      sentMsg: t`Withdrawal submitted.`,
      successMsg: t`Requested withdrawal of ${formatAmount(
        fromAmount,
        USD_DECIMALS,
        2
      )} USD from ${TokenUtils.getSymbol(position.indexToken)} ${longOrShortText}.`,
      failMsg: t`Withdrawal failed.`,
      setPendingTxns,
      txnSuccessCallback: refetchBalance,
    })
      .then(async (res) => {
        setFromValue("");
        setIsVisible(false);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        };
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: collateralTokenAddress,
        spender: routerAddress,
        chainId: chainId,
        infoTokens,
        getTokenInfo,
        pendingTxns,
        setPendingTxns,
        txnSuccessCallback: refetchAllowance,
      });
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: isDeposit ? t`Enable deposit sent.` : t`Enable withdraw sent.`,
        failMsg: isDeposit ? t`Enable deposit failed.` : t`Enable withdraw failed.`,
      });
      return;
    }

    if (isDeposit) {
      depositCollateral();
      return;
    }

    withdrawCollateral();
  };

  const EDIT_OPTIONS_LABELS = {
    [DEPOSIT]: t`Deposit`,
    [WITHDRAW]: t`Withdraw`,
  };
  const ERROR_TOOLTIP_MSG = {
    [ErrorCode.InvalidLiqPrice]: t`Liquidation price would cross mark price.`,
  } as const;

  function renderPrimaryButton() {
    const [errorMessage, errorType, errorCode] = getError();
    const primaryTextMessage = getPrimaryText();
    if (
      errorType === ErrorDisplayType.Tooltip &&
      errorMessage === primaryTextMessage &&
      errorCode &&
      errorCode in ERROR_TOOLTIP_MSG
    ) {
      return (
        <Tooltip
          isHandlerDisabled
          handle={
            <Button
              variant="primary-action"
              className="w-100"
              onClick={onClickPrimary}
              disabled={!isPrimaryEnabled()}
              size="lg"
            >
              {primaryTextMessage}
            </Button>
          }
          className="flex flex-col"
          renderContent={() => ERROR_TOOLTIP_MSG[errorCode]}
        />
      );
    }
    return (
      <Button
        variant="primary-action"
        className="w-100"
        onClick={onClickPrimary}
        disabled={!isPrimaryEnabled()}
        size="lg"
      >
        {primaryTextMessage}
      </Button>
    );
  }

  function getTotalFees() {
    return minExecutionFeeUSD ?? BigNumber.from(0);
  }

  return (
    <div className="PositionEditor tailwind">
      {position && (
        <ModalWithPortal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
          <div>
            <Tab
              type="order"
              options={EDIT_OPTIONS}
              optionLabels={EDIT_OPTIONS_LABELS}
              option={option}
              setOption={setOption}
              onChange={resetForm}
            />
            {(isDeposit || isWithdrawal) && (
              <div>
                <div
                  className={cx("Exchange-swap-section-container transition-effect !mb-[0.5rem]", {
                    inputActive: inputActive === "deposit-input",
                  })}
                >
                  <div className="Exchange-swap-section-container-bottom ">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder={isDeposit ? "Deposit" : "Wihdraw"}
                        className="Exchange-swap-input"
                        value={fromValue}
                        onChange={(e) => setFromValue(e.target.value)}
                        onFocus={() => setInputActive("deposit-input")}
                        onBlur={() => setInputActive("")}
                      />

                      {fromValue !== maxAmountFormattedFree && maxAmount?.gt(0) && (
                        <div className="flex gap-[1rem] items-center">
                          <div
                            className="Exchange-swap-max"
                            onClick={() => {
                              setFromValue(maxAmountFormattedFree);
                            }}
                          >
                            <Trans>Max</Trans>
                          </div>
                          <div className="TokenSelector">
                            <div className="TokenSelector-box">USD</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <Trans>Available</Trans>
                  </div>
                  {maxAmountFormatted ? (
                    <div className="align-right clickable" onClick={() => setFromValue(maxAmountFormattedFree)}>
                      <span>{maxAmountFormatted}</span>
                    </div>
                  ) : (
                    <div className="align-right clickable">-</div>
                  )}
                </div>
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <Trans>Leverage</Trans>
                  </div>
                  <div className="align-right">
                    {!nextLeverage && <div>{formatAmount(position.leverage, 4, 2, true)}x</div>}
                    {nextLeverage && (
                      <div>
                        <div className="inline-block muted">
                          {formatAmount(position.leverage, 4, 2, true)}x
                          <BsArrowRight className="transition-arrow" />
                        </div>
                        {formatAmount(nextLeverage, 4, 2, true)}x
                      </div>
                    )}
                  </div>
                </div>
                <div className="PositionEditor-info-box">
                  {minExecutionFeeErrorMessage && (
                    <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>
                  )}
                  <div className="line my-[1.3rem]"></div>

                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <Trans>Size</Trans>
                    </div>
                    <div className="align-right">{formatAmount(position.size, USD_DECIMALS, 2, true)} USD</div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <Trans>Collateral ({TokenUtils.getSymbol(collateralToken)})</Trans>
                    </div>
                    <div className="align-right">
                      {!nextCollateral && (
                        <div>${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}</div>
                      )}
                      {nextCollateral && (
                        <div>
                          <div className="inline-block muted">
                            ${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}
                            <BsArrowRight className="transition-arrow" />
                          </div>
                          ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <Trans>Fees</Trans>
                    </div>
                    <div className="align-right">
                      <FeesTooltip
                        executionFees={{
                          fee: minExecutionFee,
                          feeUSD: minExecutionFeeUSD,
                        }}
                        totalFees={getTotalFees()}
                      />
                    </div>
                  </div>
                  <div className="line my-[1.3rem]"></div>

                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <Trans>Entry Price</Trans>
                    </div>
                    <div className="align-right">
                      ${formatAmount(position.averagePrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <Trans>Mark Price</Trans>
                    </div>
                    <div className="align-right">
                      ${formatAmount(position.markPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">
                      <Trans>Liq. Price</Trans>
                    </div>
                    <div className="align-right">
                      {!nextLiquidationPrice && (
                        <div>
                          {!fromAmount &&
                            `$${formatAmount(
                              liquidationPrice,
                              USD_DECIMALS,
                              position.indexToken?.priceDecimals,
                              true
                            )}`}
                          {fromAmount && "-"}
                        </div>
                      )}
                      {nextLiquidationPrice && (
                        <div>
                          <div className="inline-block muted">
                            ${formatAmount(liquidationPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                            <BsArrowRight className="transition-arrow" />
                          </div>
                          ${formatAmount(nextLiquidationPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                        </div>
                      )}
                    </div>
                  </div>

                  {fromAmount?.gt(0) && fundingFee?.gt(0) && (
                    <div className="Exchange-info-row">
                      <div className="Exchange-info-label">
                        <Trans>Borrow Fee</Trans>
                      </div>
                      <div className="align-right">
                        <Tooltip
                          handle={
                            <div className="flex flex-row">
                              <div className="flex flex-row items-center muted">
                                ${formatAmount(fundingFee, USD_DECIMALS, 2, true)}
                                <BsArrowRight className="transition-arrow" />
                              </div>
                              <p>$0</p>
                            </div>
                          }
                          renderContent={() => (
                            <Trans>The pending borrow fee will be charged on this transaction.</Trans>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="Exchange-swap-button-container">{renderPrimaryButton()}</div>
              </div>
            )}
          </div>
        </ModalWithPortal>
      )}
    </div>
  );
}

import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, TESTNET } from "config/chains";
import { formatTVDate, formatTVTime } from "lib/dates";

const RED = "#DD4141";
const GREEN = "#279B6D";
export const DEFAULT_PERIOD = "5m";

const chartStyleOverrides = ["candleStyle", "hollowCandleStyle", "haStyle"].reduce((acc, cv) => {
  acc[`mainSeriesProperties.${cv}.drawWick`] = true;
  acc[`mainSeriesProperties.${cv}.drawBorder`] = false;
  acc[`mainSeriesProperties.${cv}.upColor`] = GREEN;
  acc[`mainSeriesProperties.${cv}.downColor`] = RED;
  acc[`mainSeriesProperties.${cv}.wickUpColor`] = GREEN;
  acc[`mainSeriesProperties.${cv}.wickDownColor`] = RED;
  acc[`mainSeriesProperties.${cv}.borderUpColor`] = GREEN;
  acc[`mainSeriesProperties.${cv}.borderDownColor`] = RED;
  return acc;
}, {});

const chartOverrides = {
  "paneProperties.backgroundType": "solid",
  "paneProperties.vertGridProperties.style": 0,
  "paneProperties.horzGridProperties.style": 0,
  ...chartStyleOverrides,
};

export const disabledFeaturesOnMobile = ["header_saveload", "header_fullscreen_button"];

const disabledFeatures = [
  "header_compare",
  "header_symbol_search",
  "symbol_search_hot_key",
  "header_settings",
  "header_undo_redo",
  "header_screenshot",
  "header_saveload",
  "use_localstorage_for_settings",
  "header_fullscreen_button",
];

export const enabledFeatures = [
  // "timeframes_toolbar",
  "side_toolbar_in_fullscreen_mode",
  "header_in_fullscreen_mode",
  "hide_resolution_in_legend",
  "items_favoriting",
];

export const defaultChartProps = {
  locale: "en",
  library_path: "/charting_library/",
  clientId: "tradingview.com",
  userId: "public_user_id",
  fullscreen: false,
  autosize: true,
  header_widget_dom_node: false,
  overrides: chartOverrides,
  enabled_features: enabledFeatures,
  disabled_features: disabledFeatures,
  custom_css_url: "/tradingview-chart.css",
  loading_screen: {
    backgroundColor: "#16171d",
    foregroundColor: "#735BD3",
  },
  withdateranges: true,
  favorites: {
    intervals: ["1", "2", "5", "15", "60", "240", "1D"],
  },
  custom_formatters: {
    timeFormatter: {
      format: (date) => formatTVTime(date),
    },
    dateFormatter: {
      format: (date) => formatTVDate(date),
    },
  },
};

export const availableNetworksForChart = [ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, TESTNET];

import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const blurWrapperVariants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 3 },
  },
};
type BluredEffectProps = {
  isHidden?: boolean;
};

const BluredEffect = React.memo((props: BluredEffectProps) => {
  return (
    <div className="tailwind">
      <AnimatePresence>
        {!props.isHidden && (
          <motion.div
            className="pointer-events-none"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={blurWrapperVariants}
          >
            <div className="@container/blurred-effect hidden md:flex">
              <div className=" h-[62rem] absolute inset-x-0 top-[30rem] mx-auto max-w-[185rem]">
                <div className="-inset-x-1/2 -top-2/3 absolute">
                  <div className="absolute left-1/2 -translate-x-1/2 -top-2/3 h-[72.2rem] rotate-[105deg] w-[35.2rem] bg-gradient-radial from-[rgba(245,_108,_49,_0.5)] to-[rgba(171,_171,_171,_0)] blur-[10rem]"></div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default BluredEffect;
import React from "react";
import "./LoadingAnimation.css";

export default function SimpleLoadingAnimation() {
  return (
    <div className="simple-spinner">
      <span></span>
    </div>
  );
}

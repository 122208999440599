import { t, Trans } from "@lingui/macro";
import type { ethers } from "ethers";

import { BsArrowRight } from "react-icons/bs";
import ArrowUp from "img/ArrowUp.svg";
import { Tooltip } from "components/TooltipV2/Tooltip";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ExchangeInfoRow from "components/Exchange/ExchangeInfoRow";
import FeesTooltip from "components/Exchange/FeesTooltip";

import type { Token } from "domain/tokens";
import { formatAmount } from "lib/numbers";
import { USD_DECIMALS } from "lib/constants";

type SwapTradePositionProps = {
  isSummaryOpen: boolean;
  hasExistingPosition: boolean;
  toAmount: ethers.BigNumber | undefined;
  existingPosition: any;
  entryMarkPrice: any;
  leverage: ethers.BigNumber;
  savedSlippageAmount: any;
  toToken: Token;
  existingLiquidationPrice: any;
  displayLiquidationPrice: any;
  totalFees: ethers.BigNumber;
  currentExecutionFee: any;
  currentExecutionFeeUsd: ethers.BigNumber | undefined;
  positionFeeUsd: any;
  setIsSummaryOpen: (value: any) => void;
};

export const SwapTradePosition = (props: SwapTradePositionProps) => {
  const {
    isSummaryOpen,
    hasExistingPosition,
    existingPosition,
    toAmount,
    entryMarkPrice,
    leverage,
    savedSlippageAmount,
    toToken,
    existingLiquidationPrice,
    displayLiquidationPrice,
    totalFees,
    currentExecutionFee,
    currentExecutionFeeUsd,
    positionFeeUsd,
    setIsSummaryOpen,
  } = props;

  return (
    <div>
      <div className="line mb-[1rem]"></div>
      <div className="Exchange-swap-market-box-title">
        <Trans>Summary</Trans>
        <button onClick={() => setIsSummaryOpen((prev) => !prev)}>
          <img
            src={ArrowUp}
            alt="arrow"
            style={{
              transform: isSummaryOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </button>
      </div>
      <div
        className={`Swap-info-collapsible ${isSummaryOpen ? "open" : ""}`}
        style={{
          maxHeight: isSummaryOpen ? "500px" : "0",
          overflow: "hidden",
          transition: "max-height 0.6s ease-in-out",
        }}
      >
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">
            <Trans>Leverage</Trans>
          </div>
          <div className="align-right gap-[0.5rem]">
            {hasExistingPosition && toAmount && toAmount.gt(0) && (
              <div className="flex gap-[0.5rem] muted items-center">
                {formatAmount(existingPosition.leverage, 4, 2)}x
                <BsArrowRight className="transition-arrow" />
              </div>
            )}
            {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
            {!toAmount && leverage && leverage.gt(0) && `-`}
            {leverage && leverage.eq(0) && `-`}
          </div>
        </div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">
            <Trans>Entry Price</Trans>
          </div>
          <div className="align-right">
            <Tooltip
              renderContent={() => {
                return (
                  <div>
                    <Trans>
                      The position will be opened at
                      {formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)} USD with a max slippage
                      of {(savedSlippageAmount / 100.0).toFixed(2)}%.
                      <br />
                      <br />
                      The slippage amount can be configured under Settings, found by clicking on your address at the top
                      right of the page after connecting your wallet.
                      <br />
                      <br />
                      <ExternalLink href="https://docs.foxify.trade/">More Info</ExternalLink>
                    </Trans>
                  </div>
                );
              }}
            >
              <span className="underline">
                ${formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)}
              </span>
            </Tooltip>
          </div>
        </div>

        <div className="Exchange-info-row">
          <div className="Exchange-info-label">
            <Trans>Liq. Price</Trans>
          </div>
          <div className="align-right gap-[0.5rem]">
            {hasExistingPosition && toAmount && toAmount.gt(0) && (
              <div className="flex gap-[0.5rem] muted items-center">
                ${formatAmount(existingLiquidationPrice, USD_DECIMALS, toToken?.priceDecimals, true)}
                <BsArrowRight className="transition-arrow" />
              </div>
            )}
            {toAmount &&
              displayLiquidationPrice &&
              `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, toToken?.priceDecimals, true)}`}
            {!toAmount && displayLiquidationPrice && `-`}
            {!displayLiquidationPrice && `-`}
          </div>
        </div>

        <ExchangeInfoRow label={t`Fees`}>
          <div>
            {totalFees.eq(0) && "-"}
            {totalFees.gt(0) && (
              <FeesTooltip
                totalFees={totalFees}
                executionFees={{
                  fee: currentExecutionFee,
                  feeUSD: currentExecutionFeeUsd,
                }}
                positionFee={positionFeeUsd}
              />
            )}
          </div>
        </ExchangeInfoRow>
      </div>
    </div>
  );
};

import React, { useEffect, useRef } from "react";
import { Time, createChart } from "lightweight-charts";
import { t } from "@lingui/macro";
import { useElementSize } from "lib/useElementSize";

const LightweightChart = ({
  historicalData,
  isError,
  isLoading,
  setHoveredPrice,
  lastPrice,
}: {
  historicalData: {
    time: number;
    value: number;
  }[];
  isError: boolean;
  isLoading: boolean;
  setHoveredPrice?: (price: number | undefined) => any;
  lastPrice: number;
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(chartContainerRef);

  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartContainerRef.current || !tooltipRef) {
      return;
    }
    if (!size) {
      return;
    }
    const chart = createChart(chartContainerRef.current, {
      width: 0,
      height: 279.5,
      rightPriceScale: {
        visible: false,
      },
      leftPriceScale: {
        visible: true,
        invertScale: true,
        scaleMargins: {
          top: 1,
          bottom: 0,
        },
      },
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: "center",
        vertAlign: "center",
        color: "rgba(171, 71, 188, 0.5)",
        text: "",
      },
      layout: {
        background: {
          color: "transparent",
        },
        textColor: "#ffffff",
      },
      crosshair: {
        mode: 0,
        vertLine: {
          width: 2,
          color: "transparent",
          style: 2,
          labelBackgroundColor: "rgba(245,199,48,255)",
        },
        horzLine: {
          color: "transparent",
          labelBackgroundColor: "#0f1014",
        },
      },
      grid: {
        horzLines: {
          color: "transparent",
        },
        vertLines: {
          color: "transparent",
        },
      },
      handleScale: false,
      handleScroll: false,
    });

    const areaSeries = chart.addAreaSeries({
      topColor: "rgba(121, 147, 208, 0.56)",
      bottomColor: "rgba(121, 147, 208, 0.04)",
      lineColor: "rgba(245,199,48,255)",
      lineWidth: 2,
    });

    if (historicalData.length > 0 && !isLoading && !isError) {
      areaSeries.setData(historicalData as { time: Time; value: number }[]);
      chart.timeScale().fitContent();
    }

    // const onCrosshairMove = (param) => {
    //   if (!tooltip) return;

    //   if (!param.time || !param.point || param.point.x < 0 || param.point.y < 0) {
    //     tooltip.style.display = "none";
    //   } else {
    //     tooltip.innerHTML = `
    //         <div style="color: var(--pallete-inactive)">Coming Soon</div>
    //       `;

    //     tooltip.style.display = "flex";

    //     // Calculate the tooltip position relative to the viewport
    //     const mouseX = param.point.x + window.pageXOffset;
    //     const mouseY = param.point.y + window.pageYOffset;

    //     tooltip.style.left = `${mouseX}px`;
    //     tooltip.style.top = `${mouseY}px`;
    //   }
    // };

    return () => {
      chart.remove();
    };
  }, [historicalData, isLoading, isError, lastPrice, setHoveredPrice, size]);

  return (
    <div className="flex gap-10 relative">
      <div ref={chartContainerRef} style={{ width: "100%" }} />
      <div
        ref={tooltipRef}
        className="tooltip"
        style={{
          display: "block",
          color: "white",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "1px solid var(--pallete-border)",
          backgroundColor: "var(--pallete-background-4-hover)",
          padding: "8px",
          borderRadius: "10px",
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          position: "absolute",
          pointerEvents: "none", // Make sure tooltip doesn't block mouse events on chart
        }}
      >
        Coming Soon
      </div>

      {isLoading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none text-inactive">
          {t`Loading...`}
        </div>
      )}
    </div>
  );
};

export default LightweightChart;

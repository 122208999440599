import { getTokenInfo } from "../../../domain/tokens";
import { useChartPrices } from "../../../domain/prices";
import { TokenUtils } from "../../TokenUtils";
import { formatAmount } from "../../../lib/numbers";
import { USD_DECIMALS } from "../../../lib/constants";

export const CalculateDeltaPercentage = (option, infoTokens, chainId) => {
  let deltaPrice;
  let deltaPercentageStr;
  let delta;
  let deltaPercentage;
  const tokenInfo = getTokenInfo(infoTokens, option.address);

  const currentAveragePrice =
    tokenInfo.maxPrice && tokenInfo.minPrice ? tokenInfo.maxPrice.add(tokenInfo.minPrice).div(2) : null;
  const [priceData] = useChartPrices(chainId, TokenUtils.getSymbol(option), option.isStable, "1h", currentAveragePrice);

  const now = (Date.now() / 1000).toString();

  const timeThreshold = parseFloat(now) - 24 * 60 * 60;

  if (priceData) {
    for (let i = priceData.length - 1; i > 0; i--) {
      const price = priceData[i];
      if (price.time < timeThreshold) {
        break;
      }

      deltaPrice = price.open;
    }
  }

  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
    delta = average - deltaPrice;
    deltaPercentage = (delta * 100) / average;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  return { deltaPercentage, deltaPrice, deltaPercentageStr, delta };
};

import React, { useState, useEffect } from "react";
import "./Overview.css";
import cx from "classnames";
import { ReactComponent as ArrowIcon } from "img/Arrow.svg";
import Chart from "./Chart";

import PositionsList from "components/Exchange/PositionsList";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import Reader from "abis/Reader.json";
import { getContractAddress } from "config/contracts";
import { getPositionQuery } from "../../Exchange/getPositionQuery";
import { getWhitelistedTokens } from "config/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { useInfoTokens } from "domain/tokens";
import { getPositions } from "pages/Exchange/getPositions";
import { formatAmount, expandDecimals } from "lib/numbers";
import { USD_DECIMALS, GLP_DECIMALS } from "lib/legacy";
import { BigNumber } from "ethers";
import { useTradeHistory } from "components/Exchange/hooks/useTradeHistory";

import foxLogoOrange from "img/Fox_Logo.svg";
import foxLogoBlack from "img/Fox_Logo_Black.svg";
import usdcLogo from "img/USDC.svg";
import { useQuickContracts } from "lib/contracts";
import isEqual from "lodash/isEqual";
import { FOX_DECIMALS } from "lib/constants";
import { useTokenPrices } from "hooks/useStakingTokensPrices";

import { getApyInfo } from "pages/Stake/utils";
import { getProvider } from "lib/rpc";
import { useCurrentBlockNumber } from "hooks/useCurrentBlockNumber";

const Overview = ({ savedIsPnlInLeverage, savedShowPnlAfterFees, connectWallet, account, setMarket }) => {
  const [updatedPositions] = useState({});

  const [pendingPositions, setPendingPositions] = useState({});
  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);

  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trades";

  const flagOrdersEnabled = true;

  const { isActive, provider } = useWeb3React();
  const { chainId } = useChainId();

  const readerAddress = getContractAddress(chainId, "Reader");
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const vaultAddress = getContractAddress(chainId, "Vault");
  const whitelistedTokens = getWhitelistedTokens(chainId);

  const { infoTokens, offChainTokenPrices } = useInfoTokens();
  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress, offChainTokenPrices);

  const { data: positionData, error: positionDataError } = useSWR(
    isActive &&
      positionQuery.offChainTokenPrices.filter((p) => !!p).length > 0 && [
        isActive,
        chainId,
        readerAddress,
        "getPositions",
        vaultAddress,
        account,
      ],
    contractFetcher(provider, Reader, [
      positionQuery.indexTokens,
      positionQuery.offChainTokenPrices,
      positionQuery.isLong,
    ])
  );

  const positionsDataIsLoading = isActive && !positionData && !positionDataError;

  const LIST_SECTIONS = [POSITIONS, flagOrdersEnabled && ORDERS, TRADES].filter(Boolean);

  let [, setListSection] = useLocalStorageByChainId(chainId, "List-section-v2", LIST_SECTIONS[0]);

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  );

  const longPositions = positions.filter((position) => position.isLong);
  const shortPositions = positions.filter((position) => !position.isLong);

  const totalSizeLong = longPositions
    .map((longPosition) => BigNumber.from(longPosition.size))
    .reduce((accumulator, size) => accumulator.add(size), BigNumber.from(0));

  const totalSizeShort = shortPositions
    .map((shortPosition) => BigNumber.from(shortPosition.size))
    .reduce((accumulator, size) => accumulator.add(size), BigNumber.from(0));

  const totalTrades = useTradeHistory(account);

  const totalVolume = totalTrades
    .map((trade) => BigNumber.from(trade.size))
    .reduce((accumulator, size) => accumulator.add(size), BigNumber.from(0));

  const colleteralUsage = positions
    .map((position) => BigNumber.from(position.collateralAfterFee))
    .reduce((accumulator, collateralAfterFee) => accumulator.add(collateralAfterFee), BigNumber.from(0));

  const totalNetValue = positions
    .map((position) => BigNumber.from(position.netValue))
    .reduce((accumulator, netValue) => accumulator.add(netValue), BigNumber.from(0));

  const calcCurrentPnL = positions
    .map((position) => parseFloat(position.deltaStr.replace("$", "")))
    .reduce((accumulator, delta) => accumulator + delta, 0);

  const currentPnL = calcCurrentPnL.toFixed(2);

  const [currentPnLPercentage, setCurrentPnLPercentage] = useState(0);

  const totalNetValueNumber = formatAmount(totalNetValue, USD_DECIMALS, 2, true);

  useEffect(() => {
    const totalNetValueNumeric = parseFloat(totalNetValueNumber.replace(/[^0-9.-]+/g, ""));
    const percentage = ((currentPnL / totalNetValueNumeric) * 100).toFixed(2);

    setCurrentPnLPercentage(percentage);
  }, [currentPnL, totalNetValueNumber]);

  const totalOpenPositions =
    parseFloat(formatAmount(totalSizeLong, USD_DECIMALS, 2, true)) +
    parseFloat(formatAmount(totalSizeShort, USD_DECIMALS, 2, true));

  const quickContracts = useQuickContracts();
  const { foxPrice } = useTokenPrices();

  const { data: foxifyMaxiUserInfo } = useSWR(
    ["FoxifyMaxi", "foxifyMaxiUserInfo", isActive, chainId],
    () => quickContracts.fetch("FoxifyMaxi", "userInfo", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const { data: foxBalance } = useSWR(
    ["Fox", "balanceOf", isActive, chainId],
    () => quickContracts.fetch("Fox", "balanceOf", [account?.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const foxifyMaxiUserTotalStaked = foxifyMaxiUserInfo?.totalStaked;

  const foxPriceBigNum = BigNumber.from(String(Math.floor(foxPrice * 10 ** 18)));

  const foxBalanceUsd = foxBalance
    ? foxBalance.mul(foxPriceBigNum).div(expandDecimals(1, GLP_DECIMALS))
    : BigNumber.from(0);

  const [foxAPY, setFoxAPY] = useState(null);
  const [ebFoxAPY, setEbFoxAPY] = useState(null);

  const currentBlockNumber = useCurrentBlockNumber(provider);

  useEffect(() => {
    (async () => {
      const apyProvider = getProvider(undefined, chainId);
      const { apyEbFox, apyFox } = await getApyInfo(apyProvider, chainId, currentBlockNumber);
      setFoxAPY(apyFox);
      setEbFoxAPY(apyEbFox);
    })();
  }, [provider, chainId, currentBlockNumber]);

  const { data: totalRewardsEbFox } = useSWR(
    ["EbFox", "getTotalProfits", isActive, chainId],
    () => quickContracts.fetch("EbFox", "getTotalProfits", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );
  const { data: totalRewardsFoxifyMaxi } = useSWR(
    ["FoxifyMaxi", "getTotalProfits", isActive, chainId],
    () => quickContracts.fetch("FoxifyMaxi", "getTotalProfits", [account.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  useEffect(() => {
    if (totalRewardsFoxifyMaxi !== undefined && totalRewardsEbFox !== undefined) {
      const totalRewards = totalRewardsEbFox.add(totalRewardsFoxifyMaxi);

      const totalRewardsUSD =
        totalRewards && foxPriceBigNum
          ? totalRewards.mul(foxPriceBigNum).div(expandDecimals(1, GLP_DECIMALS))
          : BigNumber.from(0);

      setTotalRewards(totalRewards);
      setTotalRewardsUSD(totalRewardsUSD);
    }
  }, [totalRewardsEbFox, totalRewardsFoxifyMaxi, foxPriceBigNum]);

  const [totalRewards, setTotalRewards] = useState(0);
  const [totalRewardsUSD, setTotalRewardsUSD] = useState(0);

  const [activeInterval, setActiveInterval] = useState("24H");

  const handleIntervalClick = (button) => {
    setActiveInterval(button);
  };

  const connectWalletAction = () => {
    if (!isActive) {
      connectWallet();
      return;
    }
  };
  return (
    <div className="default-container tailwind w-full pt-[5rem]  flex flex-col pb-[10rem] gap-[2rem]">
      <div className="flex flex-col gap-[0.5rem]">
        <span className="text-textColor text-[2.4rem] font-medium">Welcome</span>
      </div>
      <div className="flex flex-col md:flex-row  gap-[2rem] h-auto md:h-[42rem]">
        <div className="flex flex-col h-full w-full md:w-[50rem] items-center justify-between bg-background-5-v2-only rounded-md py-[2rem] px-[1.6rem] border border-border">
          <div className="flex flex-row justify-between w-full">
            <p className="text-[1.4rem] font-medium">Overview</p>
          </div>
          <div className="flex flex-col gap-[0.5rem] w-full">
            <div className="flex flex-col gap-[0.5rem] w-full">
              <span className="muted">Current PnL</span>
              <div className="flex flex-row w-full items-center justify-between">
                <div className="text-[2.4rem] leading-[1.4em] flex flex-row gap-[1rem] items-center text-center">
                  <p className="h-auto"> {currentPnL}</p>

                  <img src={usdcLogo} alt="USDC" className="w-[2.5rem]" />
                </div>

                {currentPnLPercentage !== 0 && positions.length >= 1 && (
                  <div
                    className={cx(
                      "w-[9rem] h-[3rem] px-[1rem] flex flex-row items-center rounded-sm justify-between",
                      currentPnL > 0 ? "bg-green/[0.1] text-green" : "bg-red/[0.1] text-red"
                    )}
                  >
                    <ArrowIcon
                      className={cx("h-7", currentPnL > 0 ? "fill-green" : "fill-red", {
                        "transform rotate-90": currentPnL < 0,
                      })}
                    />
                    <span className="text-[12px]">{`${currentPnLPercentage}%`}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="line my-[1rem]"></div>
            <div className="flex flex-col gap-[0.5rem] w-full">
              <span className="muted">Total Open Positions</span>
              <div className="flex flex-row w-full items-center justify-between">
                <span className="text-[2.4rem] leading-[1.4em]">${totalOpenPositions.toFixed(2)}</span>
              </div>
              <span className="muted text-[1.2rem]">
                {longPositions.length} Long, {shortPositions.length} Short
              </span>
            </div>

            <div className="line my-[1rem]"></div>
            <div className="flex flex-col gap-[0.5rem] w-full">
              <span className="muted">1CT Wallet</span>
              <div className="flex flex-row w-full items-center justify-between">
                <span className="text-[2.4rem] leading-[1.4em]">--</span>
              </div>
              <div className="flex gap-[1rem] flex-row w-full">
                <button className="text-textColor bg-background-3 h-[4rem] rounded-[0.8rem] w-full border-0">
                  Coming Soon
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-full w-full  items-center justify-between bg-background-5-v2-only rounded-md py-[2rem] px-[1.6rem] border border-border">
          <div className="flex w-full flex-col gap-[1rem] justify-between">
            <div className="w-full flex flex-col gap-[1rem]">
              <div className="flex justify-between w-full">
                <span className="muted whitespace-nowrap">Net Profit & Loss</span>

                <div className="w-full justify-end flex gap-[1rem] items-start">
                  <button
                    className={cx("muted cursor-pointer", { "text-white": activeInterval === "24h" })}
                    onClick={() => handleIntervalClick("24H")}
                  >
                    24H
                  </button>
                  <button
                    className={cx("muted cursor-pointer", { "text-white": activeInterval === "7D" })}
                    onClick={() => handleIntervalClick("7D")}
                  >
                    7D
                  </button>
                  <button
                    className={cx("muted cursor-pointer", { "text-white": activeInterval === "30D" })}
                    onClick={() => handleIntervalClick("30D")}
                  >
                    30D
                  </button>

                  <button
                    className={cx("muted cursor-pointer", { "text-white": activeInterval === "All" })}
                    onClick={() => handleIntervalClick("All")}
                  >
                    All
                  </button>
                </div>
              </div>
              <div className="flex flex-row gap-[1rem]">
                <span className="text-[2.4rem] leading-[1.4em] font-medium whitespace-nowrap	mb-[5rem] md:mb-0">
                  +0.00 USDC
                </span>

                <div
                  className={cx(
                    " h-[3rem] px-[1rem] gap-[1rem] flex flex-row items-center rounded-sm justify-between bg-green/[0.1] text-green"
                  )}
                >
                  <ArrowIcon className={cx("h-7 fill-green", {})} />
                  <span className="text-[12px]">0%</span>
                </div>
              </div>
            </div>
          </div>

          {/* this is just an placeholder chart until we have Closed Positions data ready */}

          {isActive ? (
            <Chart />
          ) : (
            <div className="flex w-full items-start justify-center min-h-[16rem] md:min-h-[24rem]">
              <div className="flex flex-col w-auto h-auto bg-background-2 rounded-[1.5rem] border border-border p-[2rem] items-center gap-[2rem]">
                <span className="muted">Connect your wallet and start trading</span>
                <button className="Exchange-inactive" onClick={connectWalletAction}>
                  <p className="Exchange-connect-wallet">Connect Wallet</p>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-[2rem]">
        <div className="flex md:hidden flex-col gap-[0.5rem]">
          <span className="text-textColor text-[2.4rem]">Stats</span>
        </div>
        <div className="flex h-auto w-full  items-center justify-between bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">Collateral Usage</span>
            <span className="text-[2.4rem] leading-[1.4em] font-medium">
              {formatAmount(colleteralUsage, USD_DECIMALS, 2, true)} USDC
            </span>
          </div>
        </div>
        <div className="flex h-auto w-full  items-center justify-between bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">My Total Trades</span>
            <span className="text-[2.4rem] leading-[1.4em] font-medium">{totalTrades.length}</span>
          </div>
        </div>
        <div className="flex h-auto w-full  items-center justify-between bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">My Total Volume</span>
            <span className="text-[2.4rem] leading-[1.4em] font-medium">
              {formatAmount(totalVolume, USD_DECIMALS, 2, true)} USDC
            </span>
          </div>
        </div>
      </div>

      <div className="mt-[2rem] gap-[3rem] flex flex-col">
        <div className="flex flex-col gap-[0.5rem]">
          <span className="text-textColor text-[2.4rem]">Earning Overview</span>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-[2rem]">
          <div className="flex h-auto w-full  items-center justify-between bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border">
            <div className="flex flex-col gap-[1rem]">
              <span className="muted">FOX Staked</span>

              <div className="flex flex-row gap-[1rem]">
                <span className="text-[2.4rem] leading-[1.4em] font-medium">
                  {foxifyMaxiUserTotalStaked ? formatAmount(foxifyMaxiUserTotalStaked, FOX_DECIMALS, 2, true) : "0.00"}{" "}
                  FOX
                </span>
                <span className="muted flex items-center">
                  ~${foxBalanceUsd ? formatAmount(foxBalanceUsd, FOX_DECIMALS, 2, true) : "0.00"}
                </span>
              </div>
              <div className="flex flex-row bg-[#00C075]/[0.1] p-[0.5rem] px-[1rem] text-green items-center justify-center rounded-[50rem] text-[1.2rem] w-fit">
                <span>APY: {foxAPY !== null && !isNaN(foxAPY) ? foxAPY.toFixed(2) : "0.00"}%</span>
              </div>
            </div>
            <div className="h-[5rem] w-[5rem] rounded-[50%] bg-black flex items-center justify-center">
              <img className="w-[3rem]" src={foxLogoOrange} alt="FOX" />
            </div>
          </div>
          <div className="flex h-auto w-full  items-center justify-between bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border">
            <div className="flex flex-col gap-[1rem]">
              <span className="muted">ebFOX Staked</span>

              <div className="flex flex-row gap-[1rem]">
                <span className="text-[2.4rem] leading-[1.4em] font-medium">
                  {foxifyMaxiUserTotalStaked ? formatAmount(foxifyMaxiUserTotalStaked, FOX_DECIMALS, 2, true) : "0.00"}{" "}
                  FOX
                </span>
                <span className="muted flex items-center">
                  ~${foxBalanceUsd ? formatAmount(foxBalanceUsd, FOX_DECIMALS, 2, true) : "0.00"}
                </span>
              </div>
              <div className="flex flex-row bg-[#00C075]/[0.1] p-[0.5rem] px-[1rem] text-green items-center justify-center rounded-[50rem] text-[1.2rem] w-fit">
                <span>APY: {ebFoxAPY !== null && !isNaN(ebFoxAPY) ? ebFoxAPY.toFixed(2) : "0.00"}%</span>
              </div>
            </div>
            <div className="h-[5rem] w-[5rem] rounded-[50%] bg-main flex items-center justify-center">
              <img className="w-[3rem]" src={foxLogoBlack} alt="FOX" />
            </div>
          </div>
          <div className="flex h-auto w-full items-center justify-between bg-background-5-v2-only rounded-md py-[1.6rem] px-[2rem] border border-border">
            <div className="flex flex-col gap-[1rem]">
              <span className="muted">Your Total Earnings</span>

              <div className="flex flex-row gap-[1rem]">
                <span className="text-[2.4rem] leading-[1.4em] font-medium">
                  {totalRewards ? formatAmount(totalRewards, FOX_DECIMALS, 2, true) : "0.00"} FOX
                </span>
                <span className="muted flex items-center">
                  ~${totalRewardsUSD ? formatAmount(totalRewardsUSD, FOX_DECIMALS, 2, true) : "0.00"}
                </span>
              </div>
            </div>
            <div className="h-[5rem] w-[5rem] rounded-[50%] bg-black flex items-center justify-center">
              <img className="w-[3rem]" src={foxLogoOrange} alt="FOX" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[3rem] gap-[3rem] flex flex-col">
        <div className="flex flex-col gap-[0.5rem]">
          <div className="flex gap-[0.5rem] items-center">
            <span className="text-textColor text-[2.4rem]">Open Positions</span>
            {positions.length > 0 && (
              <div className="h-[2.2rem] min-w-[2.2rem] bg-background-3 rounded-full text-center items-center flex justify-center text-sm muted">
                <span>{positions.length}</span>
              </div>
            )}
          </div>

          <span className="muted">View your open positions</span>
        </div>
        <PositionsList
          positionsDataIsLoading={positionsDataIsLoading}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setListSection={setListSection}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          positions={positions}
          positionsMap={positionsMap}
          infoTokens={infoTokens}
          active={isActive}
          account={account}
          library={provider}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          showPnlAfterFees={savedShowPnlAfterFees}
          connectWallet={connectWallet}
          setMarket={setMarket}
        />
      </div>
    </div>
  );
};

export default Overview;

import { ethers } from "ethers";
import DEPLOYED_CONTRACTS from "./contracts.json";

const { AddressZero } = ethers.constants;

export type ContractName =
  | "Vault"
  | "Router"
  | "VaultReader"
  | "Reader"
  | "PlpManager"
  | "PalmRewardRouter"
  | "PlpRewardRouter"
  | "PythPriceFeed"
  | "RewardReader"
  | "NATIVE_TOKEN"
  | "UniswapRouter"
  | "GLP"
  | "PALM"
  | "FeePalmTracker"
  | "FeePlpTracker"
  | "FeePalmRewardDistributor"
  | "FeePlpRewardDistributor"
  | "OrderBook"
  | "OrderBookReader"
  | "PositionRouter"
  | "PositionManager"
  | "ReferralStorage"
  | "ReferralReader"
  | "Multicall"
  | "VestPalm"
  | "PalmTraderNFT"
  | "Fox"
  | "EbFox"
  | "FoxifyMaxi"
  | "Trade2Earn"
  | "FoxifyVestedNFT"
  | "VestingSchedule"
  | "Trader"
  | "FundedVault"
  | "Factory"
  | "PositionRouterCallbackReceiverTP";

type ContractsAddressesConfig<T extends string = string> = Record<
  T,
  typeof AddressZero | Omit<string, typeof AddressZero>
>;

const CONTRACTS = {
  ...DEPLOYED_CONTRACTS,
} as const satisfies Record<number, ContractsAddressesConfig>;

export function getContractAddress(chainId: number, name: ContractName | Omit<string, ContractName>): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}

export const ContractFactoryImportMapping = {
  Vault: () => import("typechain/factories/Vault__factory").then((el) => el.Vault__factory),
  Router: () => import("typechain/factories/Router__factory").then((el) => el.Router__factory),
  VaultReader: () => import("typechain/factories/VaultReader__factory").then((el) => el.VaultReader__factory),
  Reader: () => import("typechain/factories/Reader__factory").then((el) => el.Reader__factory),
  PlpManager: () => import("typechain/factories/PlpManager__factory").then((el) => el.PlpManager__factory),
  RewardReader: () => import("typechain/factories/RewardReader__factory").then((el) => el.RewardReader__factory),
  PlpRewardRouter: () =>
    import("typechain/factories/PlpRewardRouter__factory").then((el) => el.PlpRewardRouter__factory),
  PalmRewardRouter: () =>
    import("typechain/factories/PalmRewardRouter__factory").then((el) => el.PalmRewardRouter__factory),
  PythPriceFeed: () => import("typechain/factories/PythPriceFeed__factory").then((el) => el.PythPriceFeed__factory),
  NATIVE_TOKEN: () => import("typechain/factories/common/Token__factory").then((el) => el.Token__factory),
  UniswapRouter: () =>
    import("typechain/factories/common/UniswapRouter__factory").then((el) => el.UniswapRouter__factory),
  GLP: () => import("typechain/factories/common/Token__factory").then((el) => el.Token__factory),
  PALM: () => import("typechain/factories/common/Token__factory").then((el) => el.Token__factory),
  FeePalmTracker: () =>
    import("typechain/factories/PalmRewardTracker__factory").then((el) => el.PalmRewardTracker__factory),
  FeePlpTracker: () =>
    import("typechain/factories/PlpRewardTracker__factory").then((el) => el.PlpRewardTracker__factory),
  FeePalmRewardDistributor: () =>
    import("typechain/factories/PalmRewardDistributor__factory").then((el) => el.PalmRewardDistributor__factory),
  FeePlpRewardDistributor: () =>
    import("typechain/factories/PlpRewardDistributor__factory").then((el) => el.PlpRewardDistributor__factory),
  OrderBook: () => import("typechain/factories/OrderBook__factory").then((el) => el.OrderBook__factory),
  OrderBookReader: () =>
    import("typechain/factories/OrderBookReader__factory").then((el) => el.OrderBookReader__factory),
  PositionRouter: () => import("typechain/factories/PositionRouter__factory").then((el) => el.PositionRouter__factory),
  PositionManager: () =>
    import("typechain/factories/PositionManager__factory").then((el) => el.PositionManager__factory),
  ReferralStorage: () =>
    import("typechain/factories/ReferralStorage__factory").then((el) => el.ReferralStorage__factory),
  ReferralReader: () => import("typechain/factories/ReferralReader__factory").then((el) => el.ReferralReader__factory),
  Multicall: () => import("typechain/factories/common/Multicall__factory").then((el) => el.Multicall__factory),
  VestPalm: () => import("typechain/factories/events/VestPalm__factory").then((el) => el.VestPalm__factory),
  PalmTraderNFT: () => import("typechain/factories/common/ERC721__factory").then((el) => el.ERC721__factory),
  Fox: () => import("typechain/factories/common/Fox__factory").then((el) => el.Fox__factory),
  EbFox: () => import("typechain/factories/common/EbFox__factory").then((el) => el.EbFox__factory),
  FoxifyMaxi: () => import("typechain/factories/common/FoxifyMaxi__factory").then((el) => el.FoxifyMaxi__factory),
  FoxifyVestedNFT: () =>
    import("typechain/factories/common/FoxifyVestedNFT__factory").then((el) => el.FoxifyVestedNFT__factory),
  VestingSchedule: () =>
    import("typechain/factories/common/VestingSchedule__factory").then((el) => el.VestingSchedule__factory),
  Trade2Earn: () => import("typechain/factories/Trade2Earn__factory").then((el) => el.Trade2Earn__factory),
  Trader: () => import("typechain/factories/Trader__factory").then((el) => el.Trader__factory),
  FundedVault: () => import("typechain/factories/FundedVault__factory").then((el) => el.FundedVault__factory),
  Factory: () => import("typechain/factories/Factory__factory").then((el) => el.Factory__factory),
  PositionRouterCallbackReceiverTP: () =>
    import("typechain/factories/PositionRouterCallbackReceiverTP__factory").then(
      (el) => el.PositionRouterCallbackReceiverTP__factory
    ),
} as const satisfies Record<ContractName, any>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ContractFactoryImportMapping = typeof ContractFactoryImportMapping;

export type ContractFactoryByName<TContractName extends ContractName> = Awaited<
  ReturnType<ContractFactoryImportMapping[TContractName]>
>;

export type ContractByName<TContractName extends ContractName> = ReturnType<
  ContractFactoryByName<TContractName>["connect"]
>;

export function getContractFactory(name: ContractName) {
  return ContractFactoryImportMapping[name];
}

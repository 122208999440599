import { useCallback, useMemo, useState } from "react";
import { useQuickContracts } from "lib/contracts/useQuickContracts";

export const useVestingClaim = () => {
  const [isMutating, setIsMutating] = useState(false);
  const quickContracts = useQuickContracts();

  const claim = useCallback(
    async (successCb?: () => void) => {
      setIsMutating(true);

      return quickContracts
        .call("VestingSchedule", "claim", [], {
          txnSuccessCallback: async () => {
            setIsMutating(false);
            successCb && successCb();
          },
        })
        .catch(() => {
          setIsMutating(false);
        });
    },
    [quickContracts]
  );

  return useMemo(
    () => ({
      isMutating,
      claim,
    }),
    [claim, isMutating]
  );
};

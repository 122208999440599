import React from "react";
import connectWalletImg from "img/ic_wallet_24.svg";
import { useWeb3React } from "@web3-react/core";
import noPositionIcon from "img/No-position-order.png";

type Props = React.ComponentProps<"div"> & {
  connectWallet: () => void;
  msg: string;
  items?: any[];
};

const EmptyRow = (props: Props) => {
  const { isActive } = useWeb3React();
  const connectWallet = () => {
    if (!isActive) {
      props.connectWallet();
      return;
    }
  };
  if (props.items && props.items.length) {
    return null;
  }

  if (isActive) {
    return (
      <tr>
        <td className="p-0" colSpan={16}>
          <div className="Exchange-empty-positions-list-note Exchange-list-no-position">
            <div className="Exchange-empty-positions">
              <img src={noPositionIcon} alt="No position" className="no-position-icon" />
              <p>{props.msg}</p>
            </div>
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td className="p-0" colSpan={15}>
          <span className="Exchange-empty-positions-list-note Exchange-list-no-position">
            <div className="Exchange-inactive-wrapper">
              <p>Please connect your wallet.</p>
              <button className="Exchange-inactive" onClick={() => connectWallet()}>
                <img src={connectWalletImg} alt="No position" />
                <p className="Exchange-connect-wallet">Connect Wallet</p>
              </button>
            </div>
          </span>
        </td>
      </tr>
    );
  }
};

export default EmptyRow;

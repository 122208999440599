import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import Button from "components/Button/Button";
import { Trans, t } from "@lingui/macro";
import { BigNumber, ethers } from "ethers";
import { expandDecimals, formatAmount } from "lib/numbers";
import { useTokenBalance } from "hooks/blockchain/useTokenBalance";
import { useChainId } from "lib/chains";
import { callContract } from "lib/contracts";
import { getCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { USDC_DECIMALS, USD_DECIMALS } from "lib/constants";
import { useInfoTokens, approveTokens } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import TraderContractAbi from "abis/Trader.json";
import TokenAbi from "abis/common/Token.json";

export const FundAccountModal = ({
  open,
  setOpen,
  label,
  setPendingTxns,
  pendingTxns,
  currentCollateralAmount,
  traderContractAddress,
  refetchChallengeStats,
}) => {
  const [deposit, setDeposit] = useState<string | undefined>();
  const [isDepositing, setIsDepositing] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isCreatingTrader, setIsCreatingTrader] = useState(false);
  const [needApproval, setNeedApproval] = useState(false);

  const { provider: library, isActive, account } = useWeb3React();
  const { chainId } = useChainId();
  const { connectWallet } = useConnectWalletUi();
  const { infoTokens } = useInfoTokens();

  const collateralTokenAddress = getCollateralTokenAddress(chainId);
  const { balance } = useTokenBalance(collateralTokenAddress, account ? account.toLowerCase() : "");
  const usdcInfo = getTokenInfo(infoTokens, collateralTokenAddress);
  const minimumDeposit = BigNumber.from(400);

  const depositBigNumber = deposit ? ethers.utils.parseUnits(deposit?.toString(), USDC_DECIMALS) : BigNumber.from(0);
  const depositInUsd =
    deposit && usdcInfo.maxPrice && usdcInfo.maxPrice.gt(0)
      ? ethers.utils.parseUnits(deposit, USDC_DECIMALS).mul(usdcInfo.maxPrice).div(BigNumber.from(10).pow(USD_DECIMALS))
      : BigNumber.from(0);

  const insufficientCollateralAmount = minimumDeposit.gt(currentCollateralAmount)
    ? minimumDeposit.sub(currentCollateralAmount).mul(expandDecimals(1, USDC_DECIMALS))
    : BigNumber.from(0);

  const isBalanceEnough =
    balance && insufficientCollateralAmount && depositBigNumber
      ? balance?.gt(insufficientCollateralAmount) && balance?.gt(depositBigNumber)
      : false;
  const isDepositEnough = insufficientCollateralAmount
    ? depositBigNumber.gt(insufficientCollateralAmount) || depositBigNumber.eq(insufficientCollateralAmount)
    : false;
  const isBalanceExceeded = balance ? depositBigNumber.gt(balance) : false;

  const onIncreaseBalanceClick = async () => {
    if (!isActive) {
      connectWallet();
      return;
    }

    try {
      setIsCreatingTrader(true);
      const collateralContract = new ethers.Contract(collateralTokenAddress, TokenAbi.abi, library?.getSigner());
      const tokenAllowance = await collateralContract.allowance(account, traderContractAddress);
      const notEnoughAllowance = depositBigNumber.gt(tokenAllowance);

      if (notEnoughAllowance) {
        setNeedApproval(true);
        await approveUsdc(traderContractAddress, library);
        setNeedApproval(false);
      }

      const traderContract = new ethers.Contract(
        traderContractAddress as string,
        TraderContractAbi.abi,
        library?.getSigner()
      );
      setIsDepositing(true);
      callContract(chainId, traderContract, "fundAccount", [depositBigNumber, true], {
        sentMsg: t`Funded creating submitted.`,
        failMsg: t`Funded creating failed.`,
        successMsg: t`Funded creating successfully.`,
        setPendingTxns,
        txnSuccessCallback: () => {
          setIsDepositing(false);
          setDeposit("");
          closeModal();
          refetchChallengeStats();
        },
      });
    } catch (error) {
      setIsCreatingTrader(false);
      setIsDepositing(false);
      setIsApproving(false);
    }
  };

  const approveUsdc = async (fetchedTraderContract: string, library) =>
    new Promise((resolve, reject) => {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: collateralTokenAddress,
        spender: fetchedTraderContract,
        chainId: chainId,
        infoTokens,
        getTokenInfo,
        txnSuccessCallback: () => {
          return resolve(fetchedTraderContract);
        },
        onApproveCancelled: () => reject(new Error(t`Allowance cancelled`)),
        onApproveFailed: () => reject(new Error(t`Allowance failed`)),
        pendingTxns,
        setPendingTxns,
      });
    });

  const getError = () => {
    if (!isBalanceEnough) {
      return [t`You don't have enough USDC`];
    }
    if (!isDepositEnough && deposit && +deposit > 0) {
      return [t`Your deposit less than minimum amount`];
    }
    if (needApproval || isApproving) {
      return [false];
    }
    if (isBalanceExceeded) {
      return [t`Your balance is exceeded`];
    }

    return [false];
  };
  const getPrimaryText = () => {
    if (!isActive) {
      return t`Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isApproving) {
      return t`Approving USDC...`;
    }
    if (needApproval) {
      return t`Approve USDC`;
    }
    if (isDepositing) {
      return t`Funding Account...`;
    }

    return t`Fund Account`;
  };
  const isBtnDisabled = () =>
    (!isDepositEnough || !isBalanceEnough || isBalanceExceeded || isApproving || isDepositing || isCreatingTrader) &&
    isActive;
  const closeModal = () => {
    setOpen(!open);
  };
  return (
    <ModalWithPortal className="tailwind" isVisible={open} setIsVisible={closeModal} label={label}>
      <div className="flex flex-col w-full">
        <div className="border rounded-[1rem] text-inactive text-[1.4rem] py-[1.5rem] px-[1rem] mb-[1.5rem]">
          You’ll need {formatAmount(minimumDeposit, 0, 0, false)} USDC minimum on Arbitrum Network in your Web3 Wallet
          and arbETH for gas.
        </div>
        <div className="flex flex-col gap-[1rem] w-full mb-[2.5rem]">
          <div className="text-white text-[1.4rem]">
            Deposit (Minimum {formatAmount(insufficientCollateralAmount, USDC_DECIMALS, 0, false)} USDC)
          </div>
          <div className="input-wrapper">
            <input
              type={"number"}
              className="Earn-input"
              value={deposit}
              onChange={(e) => {
                setDeposit(e.target.value);
              }}
            />
            <div className="input-end-content">
              <div className="inactive-text">~ ${formatAmount(depositInUsd, USDC_DECIMALS, 2, false)}</div>
            </div>
          </div>

          <div className="inactive-text">
            <Trans>Balance:</Trans> {formatAmount(balance, USDC_DECIMALS, 2, true)} USDC
          </div>
        </div>

        <Button
          variant="primary-action"
          disabled={isBtnDisabled()}
          onClick={onIncreaseBalanceClick}
          className="w-full rounded mt-1"
        >
          {getPrimaryText()}
        </Button>
      </div>
    </ModalWithPortal>
  );
};

import React from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

type BluredEffectProps = {
  isHidden?: boolean;
};

const blurWrapperVariants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 3 },
  },
};

const BluredEffect = React.memo((props: BluredEffectProps) => {
  return (
    <div className="tailwind">
      <AnimatePresence>
        {!props.isHidden && (
          <motion.div
            className="pointer-events-none"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={blurWrapperVariants}
          >
            <div className="@container/blurred-effect">
              <div className="-translate-y-2/3 h-[62rem] absolute inset-x-0 -top-2/3 mx-auto max-w-[185rem]">
                <div className="-inset-x-1/2 -top-2/3 absolute">
                  <div className="absolute left-1/2 -translate-x-1/2 -top-2/3 h-[114rem] rotate-90 w-[48.2rem] bg-gradient-radial from-[rgba(245,_108,_49,_0.8)] to-[#ABABAB] blur-[10rem]"></div>
                </div>

                <div className="h-[62rem] absolute inset-x-0 top-0 mx-auto max-w-[178rem]">
                  <div className="absolute w-1/2 h-1/2 top-1/2 left-0 -rotate-[120deg] -translate-y-1/2 transform-gpu bg-gradient-radial from-[rgba(245,_108,_49,_0.65)] to-black] blur-[7rem]"></div>

                  <div className="absolute w-1/2 h-1/2 top-1/2 right-0 rotate-[120deg] -translate-y-1/2 transform-gpu bg-gradient-radial from-[rgba(245,_160,_49,_0.63)] to-black] blur-[7rem]"></div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

const pagesWhitelist = ["/", "/earn", "/referral", ""];

export const DynamicBlurredEffect = () => {
  const location = useLocation();

  const isPageWhitelisted = React.useMemo(() => {
    return pagesWhitelist.some((page) => page.startsWith(location.pathname));
  }, [location.pathname]);

  return <BluredEffect isHidden={!isPageWhitelisted} />;
};

import { ReactNode } from "react";
import cx from "classnames";
import ButtonLink from "./ButtonLink";

type ButtonVariant =
  | "primary-action"
  | "secondary"
  | "red"
  | "green"
  | "semi-clear"
  | "clear"
  | "white"
  | "silver"
  | "minor"
  | "minor-with-border"
  | "lime"
  | "lime-inverted";

type ButtonProps = Omit<React.ComponentProps<"button">, "size"> & {
  children: ReactNode;
  variant: ButtonVariant;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  to?: string;
  type?: "button" | "submit" | "reset";
  size?: "sm" | "md" | "lg";
  imgInfo?: {
    src: string;
    alt?: string;
  };
  newTab?: boolean;
  colorfulDisabled?: boolean;
};

export default function Button({
  variant,
  disabled,
  onClick,
  children,
  to,
  className,
  imgInfo,
  type,
  size = "md",
  newTab,
  colorfulDisabled,
  ...rest
}: ButtonProps) {
  const classNames = cx([
    "w-full",
    "transition-colors",
    "cursor-pointer inline-flex gap-2 items-center justify-center no-underline py-4 px-8 select-none",
    "disabled:!cursor-not-allowed",
    {
      "disabled:!text-inactive disabled:!bg-background-3": !colorfulDisabled,
      "disabled:!bg-lime/10": colorfulDisabled && variant === "lime-inverted",
      "disabled:!bg-main": colorfulDisabled && variant === "primary-action",
      "disabled:!bg-main/[20%]": colorfulDisabled && variant === "secondary",
      "disabled:!bg-red text-white": colorfulDisabled && variant === "red",
      "disabled:!bg-green text-white": colorfulDisabled && variant === "green",
      "disabled:!bg-lime text-black": colorfulDisabled && variant === "lime",
      "disabled:!bg-lime/10 hover:bg-lime/20 text-lime": colorfulDisabled && variant === "lime-inverted",
      "disabled:!bg-white text-black hover:bg-[#dedede]": colorfulDisabled && variant === "white",
      "disabled:!bg-gradient-to-l from-[#edebf7] bg-[#d2cefe]": colorfulDisabled && variant === "silver",
      "disabled:!bg-[#e2251b]": colorfulDisabled && variant === "semi-clear",
      "disabled:!bg-background-3": colorfulDisabled && (variant === "minor" || variant === "minor-with-border"),
    },
    "data-[disabled]:!cursor-not-allowed data-[disabled]:!text-inactive data-[disabled]:!bg-background-3",
    {
      "h-[3.5rem] rounded-[1rem] text-[1.2rem] leading-[1.8rem] px-6": size === "sm",
      "h-[4rem] rounded-[1rem] text-[1.3rem] leading-[2.4rem] px-8": size === "md",
      "h-[4.5rem] rounded-[1.5rem] text-[1.4rem] leading-[2.4rem] px-8": size === "lg",
    },
    {
      "font-normal border-0 bg-main text-white hover:bg-main-hover active:bg-main-active": variant === "primary-action",
      "font-normal border-0 bg-main/[20%] text-[#F66B31] hover:bg-main hover:text-white transition-effect active:bg-main-active":
        variant === "secondary",
      "font-normal border-0 bg-red text-white": variant === "red",
      "font-normal border-0 bg-green text-white": variant === "green",
      "font-normal border-0 bg-lime text-black": variant === "lime",
      "font-normal border-0 bg-lime/10 hover:bg-lime/20 text-lime": variant === "lime-inverted",
      "font-normal border-0 bg-white text-black hover:bg-[#dedede]": variant === "white",
      "font-medium !transition-[background-image] border-0 text-black bg-gradient-to-l from-[#EDEBF7] to-[#FFC9B3] hover:bg-[#FECAB5] hover:bg-none":
        variant === "silver",
      "font-normal border-0 bg-[#e2251b] text-white": variant === "semi-clear",
      "font-normal border-0 text-white !justify-start": variant === "clear",
      "font-normal border-0 bg-black/10 dark:bg-white/10 hover:bg-background-2 text-textColor": variant === "minor",
      "font-normal bg-background-3 hover:bg-background-2 text-textColor border border-solid":
        variant === "minor-with-border",
    },
    className,
  ]);

  const handleClick = (event?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => {
    if (disabled) {
      event?.preventDefault();
      return;
    }
    onClick?.();
  };

  const btnImageClassName = "inline-flex items-center justify-center max-h-[2.3rem] max-w-[2.3rem] mr-[1.15rem]";

  if (to) {
    return (
      <div className={cx(["tailwind", className])} style={{ position: "relative" }}>
        <ButtonLink
          className={classNames}
          to={to}
          newTab={newTab}
          onClick={handleClick}
          {...rest}
          data-disabled={disabled ? true : undefined}
        >
          {imgInfo && (
            <img
              className={cx({ [btnImageClassName]: variant === "clear" })}
              src={imgInfo.src}
              alt={imgInfo.alt || ""}
            />
          )}
          {children}
        </ButtonLink>
      </div>
    );
  }

  return (
    <div className={cx(["tailwind", className])} style={{ position: "relative" }}>
      <button className={classNames} type={type} onClick={handleClick} disabled={disabled} {...rest}>
        {imgInfo && (
          <img className={cx({ [btnImageClassName]: variant === "clear" })} src={imgInfo.src} alt={imgInfo.alt || ""} />
        )}
        {children}
      </button>
    </div>
  );
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { t, Trans } from "@lingui/macro";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./styles.css";
import cx from "classnames";
import useSWR from "swr";
import { ethers } from "ethers";
import { HiInformationCircle } from "react-icons/hi";
import { getCollateralTokenAddress, useCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import { useExecutionFee } from "domain/fees/useExecutionFee";
import { BigNumber } from "@ethersproject/bignumber";
import { pushAccessNotification } from "components/Exchange/SwapBox/pushAccessNotification";
import { useMedia } from "react-use";
import {
  adjustForDecimals,
  calculatePositionDelta,
  getLeverage,
  getLiquidationPrice,
  getNextToAmount,
  getPositionKey,
  isTriggerRatioInverted,
} from "lib/legacy";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_BNB,
  LEVERAGE_ORDER_OPTIONS,
  LONG,
  MARGIN_FEE_BASIS_POINTS,
  PRECISION,
  SHORT,
  OrderOption,
  SWAP_OPTIONS,
  USD_DECIMALS,
  USDP_DECIMALS,
  USDC_DECIMALS,
} from "lib/constants";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED, isSupportedChain } from "config/chains";
import * as Api from "domain/legacy";
import { getContractAddress } from "config/contracts";
import Tab from "components/Tab/Tab";
import ExchangeTab from "components/Tab/ExchangeTab";
import ConfirmationBox from "components/Exchange/ConfirmationBox";
import OrdersToa from "components/Exchange/OrdersToa";
import PositionRouterAbi from "abis/PositionRouter.json";
import Token from "abis/common/Token.json";

import { useUserReferralCode } from "domain/referrals";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, shouldRaiseGasError } from "domain/tokens";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getToken } from "config/tokens";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ErrorCode, ErrorDisplayType } from "components/Exchange/constants";
import {
  useLeverageOption,
  useLeverageOptionInput,
  fromValueAtom,
  toValueAtom,
  anchorOnFromAmountAtom,
  useOnToValueChange,
  useOnFromValueChange,
  canOpenConfirmingModalAtom,
  isConfirmingAtom,
  MIN_LEVERAGE,
} from "pages/Exchange/state";
import { useAtom } from "jotai";
import { useAppUpdate } from "lib/useAppUpdate";
import { TokenUtils } from "components/TokenUtils";
import { PositionRouter } from "typechain/PositionRouter";
import { Trader } from "typechain/Trader";
import ExchangeModal from "components/Modal/ExchangeModal";
import ArrowUp from "img/ArrowUp.svg";
import arrowSmall from "img/ArrowSmall.svg";
import { isMarketDetailsOpenAtom } from "../state";
import foxLogo from "img/Fox_Logo.svg";

import { getApyInfo } from "pages/Stake/utils";
import { getProvider } from "lib/rpc";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import { useCurrentBlockNumber } from "hooks/useCurrentBlockNumber";
import { GetStartedFoxify } from "./GetStartedFoxify";
import { SWAP_ICONS, SWAP_LABELS, leverageMarksMap } from "./constants";
import { getNextAveragePrice } from "./utils";
import { SwapButton } from "./SwapButton";
import { SwapLeverage } from "./SwapLeverage";
import { SwapInputRange } from "./SwapInputRange";
import { SwapTradePosition } from "./SwapTradePosition";
import { Switch } from "components/Switch/Switch";
import TokenSelector from "../TokenSelector";
import TraderContractAbi from "abis/Trader.json";
import { formatBigAmount } from "lib/formatAmount";

const { AddressZero, Zero } = ethers.constants;

export type ExchangeError = [errorMessage: string | null, errorType?: ErrorDisplayType, errorCode?: ErrorCode];

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Slider.Handle value={value} {...restProps}>
      <div className="text-textColor w-full h-full">{value + "x"}</div>
    </Slider.Handle>
  );
};

const balanceSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Slider.Handle value={value} {...restProps}>
      <div className="text-textColor w-full h-full">{value + "%"}</div>
    </Slider.Handle>
  );
};

export default function SwapBox(props) {
  const {
    pendingPositions,
    setPendingPositions,
    infoTokens,
    active,
    library,
    account,
    curAccount,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    fromTokens,
    toTokens,
    setSwapOption,
    positionsMap,
    pendingTxns,
    setPendingTxns,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    usdpSupply,
    orders,
    savedIsPnlInLeverage,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approvePositionRouter,
    setIsWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isPluginApproving,
    isPositionRouterApproving,
    savedShouldDisableValidationForTesting,
    minExecutionFee,
    tradingLayout,
    fromBalance,
    refetchBalance,
    proxyTraderContractAddress,
    isFundedTrading,
    isFundedAccountActive,
    expectedMinTradeSize,
    expectedMaxTradeSize,
    fromFundedBalance,
    challengeInProgress,
  } = props;

  const [leverageOption, setLeverageOption] = useLeverageOption();
  const toToken = getToken(chainId, toTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
  const { hasLeverageOption, handleLeverageBlur, handleLeverageInput, minLeverage, maxLeverage } =
    useLeverageOptionInput(toTokenInfo);
  const [fromValue, setFromValue] = useAtom(fromValueAtom);
  const [toValue, setToValue] = useAtom(toValueAtom);
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useAtom(anchorOnFromAmountAtom);
  const onToValueChange = useOnToValueChange();
  const onFromValueChange = useOnFromValueChange();
  const [isConfirming, setIsConfirming] = useAtom(isConfirmingAtom);

  //States
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const [inputActive, setInputActive] = useState("");
  const [isLeverageModalVisible, setIsLeverageModalVisible] = useState(false);
  const [fromValuePercentage, setFromValuePercentage] = useState(0);

  const [isSummaryOpen, setIsSummaryOpen] = useState(true);
  const [isLiqInfoOpen, setIsLiqInfoOpen] = useState(true);
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);
  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [isUserTypingTrigger, setIsUserTypingTrigger] = useState(false);
  const [foxAPY, setFoxAPY] = useState(0);
  const [ebFoxAPY, setEbFoxAPY] = useState(0);

  const { provider } = useWeb3React();

  const fromToken = getToken(chainId, fromTokenAddress);
  const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, account);

  const [isTakeProfitChecked, setisTakeProfitChecked] = useState(false);
  const [isStopLossChecked, setIsStopLossChecked] = useState(false);
  const [tpTriggerPrice, setTpTriggerPrice] = useState("");
  const [tpQuantity, setTpQuantity] = useState("");
  const [slTriggerPrice, setSlTriggerPrice] = useState("");
  const [slQuantity, setSlQuantity] = useState("");
  const [isTpQuantityTouched, setIsTpQuantityTouched] = useState(false);
  const [isSlQuantityTouched, setIsSlQuantityTouched] = useState(false);
  const [isTpTriggerPriceTouched, setIsTpTriggerPriceTouched] = useState(false);
  const [isSlTriggerPriceTouched, setIsSlTriggerPriceTouched] = useState(false);

  const isMobile = useMedia("(max-width: 1100px)");

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const isWhitelistedLoading = false;
  const isWhitelisted = true as boolean;

  useEffect(() => {
    if (!account || isWhitelistedLoading) {
      return;
    }

    if (isWhitelisted === false) {
      pushAccessNotification();
    }
  }, [account, isWhitelistedLoading, isWhitelisted]);

  // set defaults for TP/SL
  useEffect(() => {
    if (!isTpQuantityTouched) {
      setTpQuantity(toValue);
    }
    if (!isSlQuantityTouched) {
      setSlQuantity(toValue);
    }
  }, [toValue, isSlQuantityTouched, setTpQuantity, setSlQuantity, isTpQuantityTouched]);

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey<OrderOption>(
    [chainId, "Order-option"],
    OrderOption.Market
  );
  if (!flagOrdersEnabled) {
    orderOption = OrderOption.Market;
  }

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };

  const isMarketOrder = orderOption === OrderOption.Market;
  const orderOptions = LEVERAGE_ORDER_OPTIONS;
  const orderOptionLabels = {
    [OrderOption.Stop]: t`Trigger`,
    [OrderOption.Market]: t`Market`,
    [OrderOption.Limit]: t`Limit`,
  };

  const triggerPriceUsd: BigNumber = isMarketOrder
    ? BigNumber.from(0)
    : parseValue(triggerPriceValue, USD_DECIMALS) ?? BigNumber.from(0);

  const { currentExecutionFee, currentExecutionFeeUsd } = useExecutionFee({ isMarketOrder });
  const collateralTokenAddress = useCollateralTokenAddress();

  let positionKey;
  if (isLong) {
    positionKey = getPositionKey(curAccount, collateralTokenAddress, toTokenAddress, true, nativeTokenAddress);
  }
  if (isShort) {
    positionKey = getPositionKey(curAccount, collateralTokenAddress, toTokenAddress, false, nativeTokenAddress);
  }

  const existingPosition = positionKey ? positionsMap[positionKey] : undefined;
  const hasExistingPosition = Boolean(existingPosition && existingPosition.size && existingPosition.size.gt(0));

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved;
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval);

  const needPositionRouterApproval = (isLong || isShort) && isMarketOrder && !positionRouterApproved;
  const prevNeedPositionRouterApproval = usePrevious(needPositionRouterApproval);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  useEffect(() => {
    if (!needPositionRouterApproval && prevNeedPositionRouterApproval && isWaitingForPositionRouterApproval) {
      setIsWaitingForPositionRouterApproval(false);
      helperToast.success(<div>Leverage enabled!</div>);
    }
  }, [
    needPositionRouterApproval,
    prevNeedPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isWaitingForPositionRouterApproval,
  ]);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  const routerAddress = getContractAddress(chainId, "Router");
  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
  const { data: tokenAllowance } = useSWR<BigNumber>(
    active && [active, chainId, tokenAllowanceAddress, "allowance", account, routerAddress],
    contractFetcher(library, Token)
  );

  const appUpdate = useAppUpdate();

  const shortCollateralToken = getTokenInfo(infoTokens, collateralTokenAddress);

  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);

  const currentBalance = isFundedTrading ? fromFundedBalance : fromBalance;

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);

  const toAmount = parseValue(toValue, toToken && toToken.decimals);

  const bgTpTriggerPrice = parseValue(tpTriggerPrice, USD_DECIMALS);
  const bgTpQuantity = parseValue(tpQuantity, toToken && toToken.decimals);
  const bgSlTriggerPrice = parseValue(slTriggerPrice, USD_DECIMALS);
  const bgSlQuantity = parseValue(slQuantity, toToken && toToken.decimals);

  const tpEstProfit = useMemo(() => {
    if (!bgTpTriggerPrice || !toAmount || !toToken) {
      return Zero;
    }

    const closeSize = bgTpQuantity || toAmount;
    if (!closeSize) {
      return Zero;
    }
    return triggerPriceUsd
      .mul(closeSize)
      .sub(bgTpTriggerPrice.mul(closeSize))
      .div(expandDecimals(1, toToken.decimals))
      .abs();
  }, [triggerPriceUsd, toAmount, toToken, bgTpQuantity, bgTpTriggerPrice]);

  const slEstLoss = useMemo(() => {
    if (!bgSlTriggerPrice || !toAmount || !toToken) {
      return Zero;
    }

    const closeSize = bgSlQuantity || toAmount;
    if (!closeSize) {
      return Zero;
    }
    return triggerPriceUsd
      .mul(closeSize)
      .sub(bgSlTriggerPrice.mul(closeSize))
      .div(expandDecimals(1, toToken.decimals))
      .abs();
  }, [triggerPriceUsd, toAmount, bgSlTriggerPrice, toToken, bgSlQuantity]);

  const needApproval =
    fromTokenAddress !== AddressZero && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance);
  const prevFromTokenAddress = usePrevious(fromTokenAddress);
  const prevNeedApproval = usePrevious(needApproval);

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd);

  const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
  const [triggerRatioValue] = useState("");

  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo);
  }, [toTokenInfo, fromTokenInfo]);

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return BigNumber.from(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS) ?? BigNumber.from(0);

    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  const toTokenSymbol = TokenUtils.getSymbol(toToken);
  const fromTokenSymbol = TokenUtils.getSymbol(fromToken);

  useEffect(() => {
    if (
      fromToken &&
      fromTokenAddress === prevFromTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false);
      helperToast.success(<div>{fromTokenSymbol} approved!</div>);
    }
  }, [
    fromTokenAddress,
    prevFromTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    fromTokenSymbol,
    isWaitingForApproval,
    fromToken,
  ]);

  useEffect(() => {
    if (!toTokens.find((token) => token.address === toTokenAddress) && toTokens.length > 0) {
      setToTokenAddress(swapOption, toTokens[0].address);
    }
  }, [swapOption, toTokens, toTokenAddress, setToTokenAddress]);

  useEffect(() => {
    const updateLeverageAmounts = () => {
      if (!hasLeverageOption) {
        return;
      }
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue("");
          return;
        }

        const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

        if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
          const leverageMultiplier = Math.floor(parseFloat(leverageOption) * BASIS_POINTS_DIVISOR);

          const toTokenPriceUsd = !isMarketOrder && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice;

          const { feeBasisPoints } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            collateralTokenAddress,
            infoTokens,
            undefined,
            undefined,
            usdpSupply
          );

          let fromUsdMinAfterFee = fromUsdMin;

          if (feeBasisPoints) {
            fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);
          }

          const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR);

          const toDenominator = BigNumber.from(MARGIN_FEE_BASIS_POINTS)
            .mul(leverageMultiplier)
            .add(BigNumber.from(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR));
          const nextToUsd = toNumerator.div(toDenominator);

          const nextToAmount = nextToUsd.mul(expandDecimals(1, toToken.decimals)).div(toTokenPriceUsd); //0x00

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);

          setToValue(nextToValue);
        }
        return;
      }

      if (!toAmount) {
        setFromValue("");
        return;
      }

      const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);

      if (fromTokenInfo && fromTokenInfo.minPrice && toUsdMax && toUsdMax.gt(0)) {
        const leverageMultiplier = Math.floor(parseFloat(leverageOption) * BASIS_POINTS_DIVISOR);

        const baseFromAmountUsd = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(leverageMultiplier);

        let _fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);

        const nextFromUsd = baseFromAmountUsd.add(_fees);

        const nextFromAmount = nextFromUsd.mul(expandDecimals(1, fromToken.decimals)).div(fromTokenInfo.minPrice);

        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);

        setFromValue(nextFromValue);
      }
    };

    if (isLong || isShort) {
      updateLeverageAmounts();
    }
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    isLong,
    isShort,
    leverageOption,
    fromUsdMin,
    toUsdMax,
    isMarketOrder,
    triggerPriceUsd,
    triggerRatio,
    hasLeverageOption,
    usdpSupply,
    chainId,
    collateralTokenAddress,
    indexTokenAddress,
    setFromValue,
    setToValue,
  ]);

  let entryMarkPrice;
  if (toTokenInfo) {
    entryMarkPrice = swapOption === LONG ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
  }

  let leverage = BigNumber.from(0);
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const _fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    if (fromUsdMin.sub(_fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(_fees));
    }
  }

  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd;
  if (hasExistingPosition) {
    let nextDelta, nextHasProfit;

    if (isMarketOrder) {
      nextDelta = existingPosition.delta;
      nextHasProfit = existingPosition.hasProfit;
    } else {
      const data = calculatePositionDelta(triggerPriceUsd, existingPosition);
      nextDelta = data.delta;
      nextHasProfit = data.hasProfit;
    }

    nextAveragePrice = getNextAveragePrice({
      size: existingPosition.size,
      sizeDelta: toUsdMax,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
      isLong,
    });
  }

  useEffect(() => {
    const price = isMarketOrder ? entryMarkPrice : triggerPriceUsd;
    if (!price || price.lte(0)) {
      return;
    }
    // add 5% to the current price
    if (!isTpTriggerPriceTouched) {
      const tpTp = price.mul(isLong ? 100 : 95).div(isLong ? 95 : 100);
      setTpTriggerPrice(formatAmount(tpTp, USD_DECIMALS, 2));
    }
    if (!isSlTriggerPriceTouched) {
      const slTp = price.mul(isLong ? 95 : 100).div(isLong ? 100 : 95);
      setSlTriggerPrice(formatAmount(slTp, USD_DECIMALS, 2));
    }
  }, [
    entryMarkPrice,
    isMarketOrder,
    triggerPriceUsd,
    isSlTriggerPriceTouched,
    setSlTriggerPrice,
    setTpTriggerPrice,
    isLong,
    isTpTriggerPriceTouched,
  ]);

  const liquidationPrice = getLiquidationPrice({
    isLong,
    size: hasExistingPosition ? existingPosition.size : BigNumber.from(0),
    collateral: hasExistingPosition ? existingPosition.collateral : BigNumber.from(0),
    averagePrice: nextAveragePrice,
    entryFundingRate: hasExistingPosition ? existingPosition.entryFundingRate : BigNumber.from(0),
    cumulativeFundingRate: hasExistingPosition ? existingPosition.cumulativeFundingRate : BigNumber.from(0),
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
  });

  const entryPriceString = formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true);
  const formattedEntryPriceString = entryPriceString.replace(/,/g, "");

  useEffect(() => {
    //when isUserTypingTrigger is false do not set trigger as mark price
    if (!isUserTypingTrigger) {
      setTriggerPriceValue(formattedEntryPriceString);
    }

    //when input is not active and trigger price is empty set trigger price same as entry price and set isUsertyping to false
    if (inputActive !== "markprice-swapbox" && triggerPriceValue === "") {
      setIsUserTypingTrigger(false);
      setTriggerPriceValue(formattedEntryPriceString);
    }

    //if we change tab between market and Limit we set it back to entry price

    if (orderOption !== OrderOption.Limit) {
      setIsUserTypingTrigger(false);
      setTriggerPriceValue(formattedEntryPriceString);
    }
  }, [
    entryMarkPrice,
    toToken?.priceDecimals,
    isUserTypingTrigger,
    triggerPriceValue,
    inputActive,
    orderOption,
    formattedEntryPriceString,
  ]);

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value);
    setIsUserTypingTrigger(true);
  };

  const onBlurTrigger = () => {
    setIsUserTypingTrigger(false);
    setInputActive("");
  };

  // markprice-swapbox

  const existingLiquidationPrice = existingPosition ? getLiquidationPrice(existingPosition) : undefined;
  let displayLiquidationPrice = liquidationPrice ? liquidationPrice : existingLiquidationPrice;

  if (hasExistingPosition) {
    const collateralDelta = fromUsdMin ? fromUsdMin : BigNumber.from(0);

    const sizeDelta = toUsdMax ? toUsdMax : BigNumber.from(0);
    leverage = getLeverage({
      size: existingPosition.size,
      sizeDelta,
      collateral: existingPosition.collateral,
      collateralDelta,
      increaseCollateral: true,
      entryFundingRate: existingPosition.entryFundingRate,
      cumulativeFundingRate: existingPosition.cumulativeFundingRate,
      increaseSize: true,
      hasProfit: existingPosition.hasProfit,
      delta: existingPosition.delta,
      includeDelta: savedIsPnlInLeverage,
    });
  } else if (hasLeverageOption) {
    leverage = BigNumber.from(Math.floor(parseFloat(leverageOption) * BASIS_POINTS_DIVISOR));
  }

  // TODO: fix types
  const getExchangeError = useCallback<() => ExchangeError>(() => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Leverage disabled, pending ${getChainName(chainId)} upgrade`] satisfies ExchangeError;
    }
    if (appUpdate.haveAppUpdate) {
      return [t`Page outdated, please refresh`] satisfies ExchangeError;
    }

    let toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
    if (toTokenInfo && toTokenInfo.isStable) {
      const SWAP_OPTION_LABEL = {
        [LONG]: "Longing",
        [SHORT]: "Shorting",
      };
      return [t`${SWAP_OPTION_LABEL[swapOption]} ${toTokenSymbol} not supported`] satisfies ExchangeError;
    }

    if (!toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`] satisfies ExchangeError;
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);

    if (isFundedTrading && fromFundedBalance && fromFundedBalance.eq(0)) {
      return [t`Insufficient ${fromTokenSymbol} balance`] satisfies ExchangeError;
    }

    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      currentBalance &&
      fromAmount &&
      fromAmount.gt(currentBalance as BigNumber)
    ) {
      return [t`Insufficient ${fromTokenSymbol} balance`] satisfies ExchangeError;
    }
    if (leverage && leverage.eq(0)) {
      return [t`Enter an amount`] satisfies ExchangeError;
    }
    if (!isMarketOrder && (!triggerPriceValue || triggerPriceUsd.eq(0))) {
      return [t`Enter a price`] satisfies ExchangeError;
    }

    if (!hasExistingPosition && !isFundedTrading && fromAmount && fromAmount.lt(expandDecimals(10, USDC_DECIMALS))) {
      return [t`Min order: 10 USD`] satisfies ExchangeError;
    }

    if (leverage && leverage.lt(MIN_LEVERAGE * BASIS_POINTS_DIVISOR)) {
      return [t`Min leverage: ${MIN_LEVERAGE.toFixed(1)}x`] satisfies ExchangeError;
    }

    if (leverage && leverage.gt(Number(maxLeverage) * BASIS_POINTS_DIVISOR)) {
      return [t`Max leverage: ${Number(maxLeverage).toFixed(1)}x`] satisfies ExchangeError;
    }

    if (!isMarketOrder && entryMarkPrice && !savedShouldDisableValidationForTesting) {
      if (isLong && entryMarkPrice.lt(triggerPriceUsd)) {
        return [t`Price above Mark Price`] satisfies ExchangeError;
      }
      if (!isLong && entryMarkPrice.gt(triggerPriceUsd)) {
        return [t`Price below Mark Price`] satisfies ExchangeError;
      }
    }

    if (isLong) {
      if (fromTokenAddress !== toTokenAddress) {
        if (!toTokenInfo.availableAmount) {
          return [t`Liquidity data not loaded`] satisfies ExchangeError;
        }

        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdpAmount &&
          fromTokenInfo.maxUsdpAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdpAmount
        ) {
          const usdpFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDP_DECIMALS);
          const nextUsdpAmount = fromTokenInfo.usdpAmount.add(usdpFromAmount);
          if (nextUsdpAmount.gt(fromTokenInfo.maxUsdpAmount)) {
            return [
              t`Max ${toTokenSymbol} liquidity exceeded`,
              ErrorDisplayType.Tooltip,
              ErrorCode.MaxUSDP,
            ] satisfies ExchangeError;
          }
        }
      }

      if (toTokenInfo && toTokenInfo.maxPrice && toAmount) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));

        if (toTokenInfo.maxAvailableLong && sizeUsd.gt(toTokenInfo.maxAvailableLong)) {
          return [t`Max ${toTokenSymbol} long exceeded`] satisfies ExchangeError;
        }
      }
    }

    if (isShort) {
      let stableTokenAmount = BigNumber.from(0);
      if (fromTokenAddress !== collateralTokenAddress && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          collateralTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdpSupply
        );
        stableTokenAmount = nextToAmount;
        if (stableTokenAmount.gt(shortCollateralToken.availableAmount ?? BigNumber.from(0))) {
          return [t`Insufficient liquidity`] satisfies ExchangeError;
        }

        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          return [t`Insufficient liquidity`, ErrorDisplayType.Modal, ErrorCode.Buffer] satisfies ExchangeError;
        }

        if (
          fromTokenInfo.maxUsdpAmount &&
          fromTokenInfo.maxUsdpAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdpAmount
        ) {
          const usdpFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDP_DECIMALS);
          const nextUsdpAmount = fromTokenInfo.usdpAmount.add(usdpFromAmount);
          if (nextUsdpAmount.gt(fromTokenInfo.maxUsdpAmount)) {
            return [
              t`Max ${toTokenSymbol} liquidity exceeded`,
              ErrorDisplayType.Tooltip,
              ErrorCode.MaxUSDP,
            ] satisfies ExchangeError;
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return [t`Fetching token info...`] satisfies ExchangeError;
      }

      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice ?? BigNumber.from(0));

      if (!toTokenInfo.maxAvailableShort) {
        return [t`Liquidity data not loaded`] satisfies ExchangeError;
      }

      if (toTokenInfo.maxAvailableShort && sizeUsd.gt(toTokenInfo.maxAvailableShort)) {
        return [t`Max ${toTokenSymbol} short exceeded`] satisfies ExchangeError;
      }

      stableTokenAmount = stableTokenAmount.add(sizeTokens);
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [t`Insufficient liquidity`] satisfies ExchangeError;
      }
    }

    return [null] satisfies ExchangeError;
  }, [
    chainId,
    appUpdate.haveAppUpdate,
    infoTokens,
    toTokenAddress,
    toAmount,
    fromTokenAddress,
    fromFundedBalance,
    isFundedTrading,
    savedShouldDisableValidationForTesting,
    currentBalance,
    fromAmount,
    leverage,
    isMarketOrder,
    triggerPriceValue,
    triggerPriceUsd,
    hasExistingPosition,
    fromUsdMin,
    maxLeverage,
    entryMarkPrice,
    isLong,
    isShort,
    swapOption,
    toTokenSymbol,
    fromTokenSymbol,
    collateralTokenAddress,
    shortCollateralToken,
    usdpSupply,
  ]);

  const swapButtonDisableCondition = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (isStopOrder) {
      return true;
    }
    if (!active) {
      return true;
    }

    if (isFundedTrading && !challengeInProgress) {
      return false;
    }

    const [error, errorType] = getExchangeError();
    if (error && errorType !== ErrorDisplayType.Modal) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }

    if (isFundedTrading && expectedMinTradeSize && fromAmount && fromAmount.lt(expectedMinTradeSize)) {
      return false;
    }

    if (isFundedTrading && expectedMaxTradeSize && fromAmount && fromAmount.gt(expectedMaxTradeSize)) {
      return false;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (!isWhitelisted) {
      return false;
    }

    return true;
  };

  const getSwapButtonText = () => {
    if (isFundedTrading && !challengeInProgress) {
      return t`FUNDED Not Live`;
    }

    if (isStopOrder) {
      return t`Open a position`;
    }
    if (!active) {
      return t`Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect network`;
    }
    if (!isWhitelisted) {
      return t`Not whitelisted`;
    }
    const [error, errorType] = getExchangeError();
    if (error && errorType !== ErrorDisplayType.Modal) {
      return error;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling leverage...`;
    }
    if (isPositionRouterApproving) {
      return t`Enabling leverage...`;
    }

    const formmatedExpectedMinTradeSize = formatBigAmount(expectedMinTradeSize, USDC_DECIMALS);

    if (isFundedTrading && expectedMinTradeSize && fromAmount && fromAmount.lt(expectedMinTradeSize)) {
      return t`Min order: ${formmatedExpectedMinTradeSize} USD`;
    }

    const formmatedExpectedMaxTradeSize = formatBigAmount(expectedMaxTradeSize, USDC_DECIMALS);

    if (isFundedTrading && expectedMaxTradeSize && fromAmount && fromAmount.gt(expectedMaxTradeSize)) {
      return t`Max order: ${formmatedExpectedMaxTradeSize} USD`;
    }

    if (needPositionRouterApproval) {
      return t`Enable leverage`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${fromTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${fromTokenSymbol}`;
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return t`Enabling orders...`;
    }
    if (isPluginApproving) {
      return t`Enabling orders...`;
    }
    if (needOrderBookApproval) {
      return t`Enable orders`;
    }

    if (!isMarketOrder) return t`Create ${orderOption!.charAt(0) + orderOption!.substring(1).toLowerCase()} order`;

    if (isLong) {
      return t`Long ${toTokenSymbol}`;
    }

    return t`Short ${toTokenSymbol}`;
  };

  const [showGetStarted, setShowGetStarted] = useLocalStorageSerializeKey([account], true);

  const onSelectFromToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
    setIsWaitingForApproval(false);
  };

  const onSelectToToken = (token) => {
    setToTokenAddress(swapOption, token.address);
  };

  const createIncreaseOrder = () => {
    let path = [fromTokenAddress];

    const indexToken = getToken(chainId, indexTokenAddress);
    const successMsg = t`
      Created limit order for ${TokenUtils.getSymbol(indexToken)} ${isLong ? "Long" : "Short"}: ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD!
    `;
    return Api.createIncreaseOrder(
      chainId,
      library,
      nativeTokenAddress,
      path,
      fromAmount,
      indexTokenAddress,
      toUsdMax,
      isLong,
      triggerPriceUsd,
      proxyTraderContractAddress,
      {
        pendingTxns,
        setPendingTxns,
        sentMsg: t`Limit order submitted!`,
        successMsg,
        failMsg: t`Limit order creation failed.`,
        txnSuccessCallback: refetchBalance,
      }
    )
      .then(() => {
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  let referralCode = ethers.constants.HashZero;
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode;
  }

  const increasePosition = async () => {
    setIsSubmitting(true);

    const refPrice = (isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice) ?? BigNumber.from(0);
    const priceBasisPoints = isLong ? BASIS_POINTS_DIVISOR + allowedSlippage : BASIS_POINTS_DIVISOR - allowedSlippage;
    const priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);

    const boundedFromAmount = fromAmount ?? BigNumber.from(0);

    let value = minExecutionFee;

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      helperToast.error(
        t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`
      );
      return;
    }

    const contractAddress = proxyTraderContractAddress
      ? proxyTraderContractAddress
      : getContractAddress(chainId, "PositionRouter");
    const contract = proxyTraderContractAddress
      ? new ethers.Contract(proxyTraderContractAddress, TraderContractAbi.abi, library.getSigner())
      : new ethers.Contract(contractAddress, PositionRouterAbi.abi, library.getSigner());

    const indexToken = getTokenInfo(infoTokens, indexTokenAddress);
    const tokenSymbol = TokenUtils.getSymbol(indexToken);
    const longOrShortText = isLong ? t`Long` : t`Short`;
    const successMsg = t`Requested increase of ${tokenSymbol} ${longOrShortText} by ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD.`;

    const slQuantityUsd = getUsd(bgSlQuantity, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd) || Zero;
    const tpQuantityUsd = getUsd(bgTpQuantity, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd) || Zero;
    let sizeAbove = Zero,
      sizeBelow = Zero,
      priceAbove = Zero,
      priceBelow = Zero;
    if (isLong) {
      sizeAbove = isTakeProfitChecked ? tpQuantityUsd : Zero;
      sizeBelow = isStopLossChecked ? slQuantityUsd : Zero;
      priceAbove = bgTpTriggerPrice || Zero;
      priceBelow = bgSlTriggerPrice || Zero;
    } else {
      sizeAbove = isStopLossChecked ? slQuantityUsd : Zero;
      sizeBelow = isTakeProfitChecked ? tpQuantityUsd : Zero;
      priceAbove = bgSlTriggerPrice || Zero;
      priceBelow = bgTpTriggerPrice || Zero;
    }

    const orderExecutionData = ethers.utils.defaultAbiCoder.encode(
      ["address", "uint256", "uint256", "uint256", "uint256", "uint256", "uint256", "bool"],
      [indexTokenAddress, sizeBelow, sizeAbove, fromAmount, fromAmount, priceBelow, priceAbove, isLong]
    );

    callContract(
      chainId,
      contract as PositionRouter | Trader,
      "createIncreasePosition",
      [
        indexTokenAddress, // _indexToken
        boundedFromAmount, // _amountIn
        0, // _minOut
        toUsdMax ?? BigNumber.from(0), // _sizeDelta
        isLong, // _isLong
        priceLimit, // _acceptablePrice
        minExecutionFee, // _executionFee
        referralCode, // _referralCode
        orderExecutionData, // _orderExecutionData for TP/SL feature
        getContractAddress(chainId, "PositionRouterCallbackReceiverTP"), // _callbackTarget
      ],
      {
        value,
        setPendingTxns,
        sentMsg: `${longOrShortText} submitted.`,
        failMsg: `${longOrShortText} failed.`,
        successMsg,
        // for Arbitrum, sometimes the successMsg shows after the position has already been executed
        // hide the success message for Arbitrum as a workaround
        hideSuccessMsg: chainId === ARBITRUM,
        txnSuccessCallback: refetchBalance,
      }
    )
      .then(() => {
        setIsConfirming(false);

        const collateralTokenAddress = getCollateralTokenAddress(chainId);

        const key = getPositionKey(curAccount, collateralTokenAddress, indexTokenAddress, isLong);
        let nextSize = toUsdMax;
        if (hasExistingPosition) {
          nextSize = existingPosition.size.add(toUsdMax);
        }

        pendingPositions[key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  const onSwapOptionChange = (opt) => {
    setSwapOption(opt);
    if (orderOption === OrderOption.Stop) {
      setOrderOption(OrderOption.Market);
    }
  };

  const onConfirmationClick = () => {
    if (!active) {
      props.connectWallet();
      return;
    }

    if (needOrderBookApproval) {
      approveOrderBook();
      return;
    }

    setIsPendingConfirmation(true);

    if (orderOption === OrderOption.Limit) {
      createIncreaseOrder();
      return;
    }

    increasePosition();
  };

  function approveFromToken() {
    // const spenderAddress = routerAddress;
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: fromToken.address,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    });
  }

  const handleSwapButton = () => {
    if (isStopOrder) {
      setOrderOption(OrderOption.Market);
      return;
    }

    if (!active) {
      props.connectWallet();
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: t`Enable leverage sent.`,
        failMsg: t`Enable leverage failed.`,
      });
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  const canOpenConfirmingModal = useMemo(() => {
    if (orderOption !== OrderOption.Market) {
      return false;
    }
    if (!active) {
      return false;
    }
    if (needPositionRouterApproval || needApproval || needOrderBookApproval) {
      return false;
    }
    if (isConfirming) {
      return false;
    }
    const haveError = !!getExchangeError()[0];

    return !haveError;
  }, [
    orderOption,
    active,
    needPositionRouterApproval,
    needApproval,
    needOrderBookApproval,
    isConfirming,
    getExchangeError,
  ]);

  const [, setCanOpenConfirmingModal] = useAtom(canOpenConfirmingModalAtom);

  const currentBlockNumber = useCurrentBlockNumber(provider);

  useEffect(() => {
    (async () => {
      const apyProvider = getProvider(undefined, chainId);
      const response = await getApyInfo(apyProvider, chainId, currentBlockNumber);

      if (response) {
        const { apyEbFox, apyFox } = response as { apyEbFox: number; apyFox: number };
        setFoxAPY(apyFox);
        setEbFoxAPY(apyEbFox);
      } else {
      }
    })();
  }, [provider, chainId, currentBlockNumber]);

  useEffect(() => {
    setCanOpenConfirmingModal(canOpenConfirmingModal);
  }, [setCanOpenConfirmingModal, canOpenConfirmingModal]);

  const fromValueChange = (event) => {
    onFromValueChange(event);

    if (!currentBalance || !fromValue) {
      return;
    } else {
      const fromValueNum = parseFloat(event.target.value);
      const fromBalanceString = formatAmount(currentBalance, fromToken.decimals, 2, true);
      const fromBalanceNum = parseFloat(fromBalanceString.replace(/,/g, ""));

      const percentage = (fromValueNum / fromBalanceNum) * 100;

      setFromValuePercentage(percentage);
    }
  };

  const setFromValueFromButtons = (percentage) => {
    if (!fromToken || !currentBalance) {
      return;
    }
    const newFromValue = currentBalance.mul(percentage).div(100);

    // Update the state with the new value

    setFromValuePercentage(percentage);
    setFromValue(formatAmountFree(newFromValue, fromToken.decimals, fromToken.decimals));
    setAnchorOnFromAmount(true);
  };

  const [, setIsMarketDetailsOpen] = useAtom(isMarketDetailsOpenAtom);

  const isStopOrder = orderOption === OrderOption.Stop;
  const showFromAndToSection = !isStopOrder;
  const showTriggerPriceSection = !isMarketOrder && !isStopOrder;

  let feeBps;
  let positionFeeUsd;

  if (toUsdMax) {
    positionFeeUsd = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);

    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      collateralTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdpSupply
    );
    feeBps = feeBasisPoints;
  }

  const getFilteredLeverageMarks = (marks: { [key: number]: string }): { [key: number]: string } => {
    const entries: [number, string][] = Object.entries(marks)
      .slice(2)
      .map(([key, value]) => [Number(key), value]) as [number, string][];
    return Object.fromEntries(entries);
  };

  const leverageMarks = isFundedAccountActive
    ? getFilteredLeverageMarks(leverageMarksMap[maxLeverage]) || leverageMarksMap["50"]
    : leverageMarksMap[maxLeverage] || leverageMarksMap["50"];

  const ERROR_TOOLTIP_MSG = {
    [ErrorCode.PoolExceeded]: t`GLP doesn't accept this amount of ${fromTokenSymbol}.`,
  };

  const totalFees = (currentExecutionFeeUsd ?? BigNumber.from(0)).add(positionFeeUsd ?? BigNumber.from(0));

  return (
    <div className={cx("Exchange-swap-box tailwind relative", { "border-l-0": tradingLayout === "left" })}>
      {showGetStarted && <GetStartedFoxify setShowGetStarted={setShowGetStarted} />}
      <SwapLeverage
        leverageOption={leverageOption}
        setIsLeverageModalVisible={setIsLeverageModalVisible}
        isSummaryOpen={isSummaryOpen}
      />

      <div className="justify-between	h-full gap-[5rem] flex flex-col">
        <div className="Exchange-swap-box-inner">
          <div>
            <ExchangeTab
              icons={SWAP_ICONS}
              options={SWAP_OPTIONS}
              optionLabels={SWAP_LABELS}
              option={swapOption}
              onChange={onSwapOptionChange}
              className="Exchange-swap-option-tabs"
            />
          </div>

          <ExchangeModal
            isVisible={isLeverageModalVisible}
            setIsVisible={setIsLeverageModalVisible}
            label={t`Leverage`}
            disableBodyScrollLock={true}
          >
            {(isLong || isShort) && !isStopOrder && (
              <div className="Exchange-leverage-box">
                <div className="Exchange-leverage-slider-settings">
                  <div className="muted">Leverage slider</div>

                  <div className="Indicator">
                    <input
                      className="Indicator-input"
                      min={minLeverage}
                      max={maxLeverage}
                      value={leverageOption}
                      onChange={handleLeverageInput}
                      onBlur={handleLeverageBlur}
                    />
                    <span>x</span>
                  </div>
                </div>
                <div
                  className={cx("Exchange-leverage-slider", "App-slider", {
                    positive: isLong,
                    negative: isShort,
                  })}
                >
                  <Slider
                    min={Number(minLeverage)}
                    max={Number(maxLeverage)}
                    step={0.1}
                    marks={leverageMarks}
                    handle={leverageSliderHandle}
                    onChange={(value) => setLeverageOption(value.toFixed(2))}
                    value={parseFloat(leverageOption)}
                  />
                </div>
              </div>
            )}
          </ExchangeModal>

          {flagOrdersEnabled && (
            <Tab
              options={orderOptions}
              optionLabels={orderOptionLabels}
              className="Exchange-list-tabs transition-effect border-b border-border"
              type="table-left"
              option={orderOption}
              onChange={onOrderOptionChange}
            />
          )}

          {showFromAndToSection && (
            <SwapInputRange
              inputActive={inputActive}
              fromValue={fromValue}
              fromTokens={fromTokens}
              infoTokens={infoTokens}
              chainId={chainId}
              fromTokenAddress={fromTokenAddress}
              isShort={isShort}
              isLong={isLong}
              toValue={toValue}
              toTokenAddress={toTokenAddress}
              toTokens={toTokens}
              showTriggerPriceSection={showTriggerPriceSection}
              isMobile={isMobile}
              triggerPriceValue={triggerPriceValue}
              currentBalance={currentBalance}
              fromToken={fromToken}
              fromValuePercentage={fromValuePercentage}
              fromValueChange={fromValueChange}
              setInputActive={setInputActive}
              onSelectFromToken={onSelectFromToken}
              onToValueChange={onToValueChange}
              onSelectToToken={onSelectToToken}
              onTriggerPriceChange={onTriggerPriceChange}
              onBlurTrigger={onBlurTrigger}
              setFromValueFromButtons={setFromValueFromButtons}
              balanceSliderHandle={balanceSliderHandle}
            />
          )}

          {isStopOrder && (
            <div className="Exchange-swap-section Exchange-trigger-order-info">
              <div className="Exchange-trigger-order-info-tooltip">
                <HiInformationCircle />
              </div>
              <div>
                <Trans>
                  Currently, take-profit and stop-loss orders can be set after the position is opened. <br /> <br />
                  There will be a "ADD TP/SL" button on each position row, clicking this will display the option to set
                  Take-profit and Stop-loss orders. <br /> <br />
                  We are working to enable take-profit and stop-loss without opening a position beforehand. <br />{" "}
                  <br />
                  For screenshots and more information, please see the{" "}
                  <ExternalLink href="https://docs.foxify.trade/">docs</ExternalLink>.
                </Trans>
              </div>
            </div>
          )}

          {isMarketOrder && (
            <div className="flex flex-col text-[1.2rem] mb-[1rem]">
              <div className="line mb-[1rem]"></div>
              <div className="Exchange-info-row">
                <p>
                  <Trans>Take Profit</Trans>
                </p>
                <div className="flex w-full h-full justify-end items-center">
                  <Switch
                    checked={isTakeProfitChecked}
                    onChange={() => setisTakeProfitChecked(!isTakeProfitChecked)}
                    checkedColor="#f66b31"
                    uncheckedColor="#1D2024"
                  />
                </div>
              </div>
              <div
                className={`Swap-info-collapsible ${isTakeProfitChecked ? "open" : ""}`}
                style={{
                  maxHeight: isTakeProfitChecked ? "500px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.6s ease-in-out",
                }}
              >
                <div
                  className={cx("Exchange-swap-section-container transition-effect", {
                    inputActive: inputActive === "tp-trigger-price-swapbox",
                  })}
                >
                  <div className="Exchange-swap-section-container-bottom ">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder={t`Trigger Price`}
                        className="Exchange-swap-input"
                        value={tpTriggerPrice}
                        onChange={(e) => {
                          setIsTpTriggerPriceTouched(true);
                          setTpTriggerPrice(e.target.value);
                        }}
                        onFocus={() => setInputActive("tp-trigger-price-swapbox")}
                        onBlur={() => setInputActive("")}
                      />
                    </div>
                    <div className="TokenSelector">
                      <div className="TokenSelector-box">USD</div>
                    </div>
                  </div>
                </div>
                <div
                  className={cx("Exchange-swap-section-container transition-effect", {
                    inputActive: inputActive === "tp-close-quantity-swapbox",
                  })}
                >
                  <div className="Exchange-swap-section-container-bottom ">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder={t`Close Quantity`}
                        className="Exchange-swap-input"
                        value={tpQuantity}
                        onChange={(e) => {
                          setIsTpQuantityTouched(true);
                          setTpQuantity(e.target.value);
                        }}
                        onFocus={() => setInputActive("tp-close-quantity-swapbox")}
                        onBlur={() => setInputActive("")}
                      />
                    </div>
                    <TokenSelector
                      label={t`Close Quantity`}
                      chainId={chainId}
                      tokenAddress={toTokenAddress}
                      onSelectToken={onSelectToToken}
                      tokens={toTokens}
                      infoTokens={infoTokens}
                      showTokenImgInDropdown={true}
                    />
                  </div>
                </div>
                <div className="Exchange-info-row mb-3">
                  <div className="Exchange-info-label">
                    <Trans>Est. Profit</Trans>
                  </div>
                  <div className="align-right gap-[0.5rem] text-green">
                    ${formatAmount(tpEstProfit, USD_DECIMALS, 2)}
                  </div>
                </div>
              </div>
              <div className="Exchange-info-row">
                <p>
                  <Trans>Stop Loss</Trans>
                </p>
                <div className="flex w-full h-full justify-end items-center">
                  <Switch
                    checked={isStopLossChecked}
                    onChange={() => setIsStopLossChecked(!isStopLossChecked)}
                    checkedColor="#f66b31"
                    uncheckedColor="#1D2024"
                  />
                </div>
              </div>
              <div
                className={`Swap-info-collapsible ${isStopLossChecked ? "open" : ""}`}
                style={{
                  maxHeight: isStopLossChecked ? "500px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.6s ease-in-out",
                }}
              >
                <div
                  className={cx("Exchange-swap-section-container transition-effect", {
                    inputActive: inputActive === "sl-trigger-price-swapbox",
                  })}
                >
                  <div className="Exchange-swap-section-container-bottom ">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder={t`Trigger Price`}
                        className="Exchange-swap-input"
                        value={slTriggerPrice}
                        onChange={(e) => {
                          setIsSlTriggerPriceTouched(true);
                          setSlTriggerPrice(e.target.value);
                        }}
                        onFocus={() => setInputActive("sl-trigger-price-swapbox")}
                        onBlur={() => setInputActive("")}
                      />
                    </div>
                    <div className="TokenSelector">
                      <div className="TokenSelector-box">USD</div>
                    </div>
                  </div>
                </div>
                <div
                  className={cx("Exchange-swap-section-container transition-effect", {
                    inputActive: inputActive === "sl-quantity-swapbox",
                  })}
                >
                  <div className="Exchange-swap-section-container-bottom ">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder={t`Close Quantity`}
                        className="Exchange-swap-input"
                        value={slQuantity}
                        onChange={(e) => {
                          setIsSlQuantityTouched(true);
                          setSlQuantity(e.target.value);
                        }}
                        onFocus={() => setInputActive("sl-quantity-swapbox")}
                        onBlur={() => setInputActive("")}
                      />
                    </div>
                    <TokenSelector
                      label={t`Close Quantity`}
                      chainId={chainId}
                      tokenAddress={toTokenAddress}
                      onSelectToken={onSelectToToken}
                      tokens={toTokens}
                      infoTokens={infoTokens}
                      showTokenImgInDropdown={true}
                    />
                  </div>
                </div>
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <Trans>Est. Loss</Trans>
                  </div>
                  <div className="align-right gap-[0.5rem] text-red">${formatAmount(slEstLoss, USD_DECIMALS, 2)}</div>
                </div>
              </div>
            </div>
          )}
          {(isLong || isShort) && (
            <SwapTradePosition
              isSummaryOpen={isSummaryOpen}
              hasExistingPosition={hasExistingPosition}
              toAmount={toAmount}
              existingPosition={existingPosition}
              entryMarkPrice={entryMarkPrice}
              leverage={leverage}
              savedSlippageAmount={savedSlippageAmount}
              toToken={toToken}
              existingLiquidationPrice={existingLiquidationPrice}
              displayLiquidationPrice={displayLiquidationPrice}
              totalFees={totalFees}
              currentExecutionFee={currentExecutionFee}
              currentExecutionFeeUsd={currentExecutionFeeUsd}
              positionFeeUsd={positionFeeUsd}
              setIsSummaryOpen={setIsSummaryOpen}
            />
          )}
          <SwapButton
            tooltipMessage={ERROR_TOOLTIP_MSG}
            getExchangeError={getExchangeError}
            getPrimaryText={getSwapButtonText}
            isLong={isLong}
            handleButton={handleSwapButton}
            disabledCondition={swapButtonDisableCondition}
            active={active}
          />
        </div>

        {(isLong || isShort) && !isMobile && (
          <div className="Exchange-swap-market-box-bottom-wrapper">
            <div className="Exchange-swap-market-box-bottom">
              <Link to="/earn">
                <div className="Exchange-swap-market-apr">
                  <div className="Exchange-swap-market-fox">
                    <img src={foxLogo} alt="foxlogo" />
                  </div>
                  <span className="muted">Stake FOX</span>
                  <span>{foxAPY ? foxAPY.toFixed(2) : "0.00"}%</span>

                  <img src={arrowSmall} alt="arrow" className="arrowSmall" />
                </div>
              </Link>
              <Link to="/earn">
                <div className="Exchange-swap-market-apr">
                  <div className="Exchange-swap-market-fox">
                    <img src={foxLogo} alt="foxlogo" />
                  </div>
                  <span className="muted">
                    <Trans>Stake ebFox</Trans>
                  </span>
                  <span>{ebFoxAPY ? ebFoxAPY.toFixed(2) : "0.00"}%</span>
                  <img src={arrowSmall} alt="arrow" className="arrowSmall" />
                </div>
              </Link>
              <div className="line"></div>
              <div className="Exchange-swap-market-box-title">
                <span>
                  {isLong ? t`Long` : t`Short`}&nbsp;{toTokenSymbol}
                </span>
                <button onClick={() => setIsLiqInfoOpen((prev) => !prev)}>
                  <img
                    src={ArrowUp}
                    alt="arrow"
                    style={{
                      transform: isLiqInfoOpen ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                </button>
              </div>

              <div
                className={`Swap-info-collapsible ${isLiqInfoOpen ? "open" : ""}`}
                style={{
                  maxHeight: isLiqInfoOpen ? "500px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.6s ease-in-out",
                }}
              >
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label underline">
                    <Trans>Available Liquidity</Trans>
                  </div>
                  <div className="align-right">
                    <Tooltip
                      renderContent={() => (
                        <>
                          <StatsTooltipRow
                            label={isLong ? t`Max long capacity` : t`Max short capacity`}
                            value={formatAmount(
                              isLong ? toTokenInfo.maxGlobalLongSize : toTokenInfo.maxGlobalShortSize,
                              USD_DECIMALS,
                              2,
                              true
                            )}
                          />
                          <StatsTooltipRow
                            label={isLong ? t`Current long` : t`Current shorts`}
                            value={formatAmount(
                              isLong ? toTokenInfo.globalLongSize : toTokenInfo.globalShortSize,
                              USD_DECIMALS,
                              2,
                              true
                            )}
                          />
                        </>
                      )}
                    >
                      <span className={`underline`}>
                        $
                        {formatAmount(
                          isLong ? toTokenInfo.maxAvailableLong : toTokenInfo.maxAvailableShort,
                          USD_DECIMALS,
                          2,
                          true
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <button className="Exchange-swap-market-details" onClick={() => setIsMarketDetailsOpen(true)}>
                <div className="Exchange-swap-market-details-left">
                  {toToken && <TokenUtils.Logo token={toToken} className="w-[3.2rem] mr-[0.8rem]" />}
                  <div className="Exchange-swap-market-details-text">
                    <span className="Exchange-swap-market-details-token">{toTokenSymbol}/USDC</span>
                    <span className="muted">Market Details</span>
                  </div>
                </div>
                <div className="Exchange-swap-market-details-right">
                  <img src={arrowSmall} alt="arrow" className="arrowSmall" />
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isConfirming && (
        <ConfirmationBox
          library={library}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          orders={orders}
          isLong={isLong}
          isMarketOrder={isMarketOrder}
          orderOption={orderOption}
          isShort={isShort}
          fromToken={fromToken}
          fromTokenInfo={fromTokenInfo}
          toToken={toToken}
          toTokenInfo={toTokenInfo}
          toAmount={toAmount}
          fromAmount={fromAmount}
          feeBps={feeBps}
          onConfirmationClick={onConfirmationClick}
          setIsConfirming={setIsConfirming}
          hasExistingPosition={hasExistingPosition}
          shortCollateralAddress={collateralTokenAddress}
          shortCollateralToken={shortCollateralToken}
          leverage={leverage}
          existingPosition={existingPosition}
          existingLiquidationPrice={existingLiquidationPrice}
          displayLiquidationPrice={displayLiquidationPrice}
          nextAveragePrice={nextAveragePrice}
          triggerPriceUsd={triggerPriceUsd}
          triggerRatio={triggerRatio}
          positionFeeUsd={positionFeeUsd}
          isSubmitting={isSubmitting}
          isPendingConfirmation={isPendingConfirmation}
          fromUsdMin={fromUsdMin}
          toUsdMax={toUsdMax}
          infoTokens={infoTokens}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          entryMarkPrice={entryMarkPrice}
        />
      )}
    </div>
  );
}

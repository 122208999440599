import { Trans } from "@lingui/macro";
import { Tooltip } from "../../components/TooltipV2/Tooltip";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { formatAmount } from "../../lib/numbers";
import { FOX_DECIMALS } from "../../lib/constants";
import { FOX_TOKEN, UNSTAKE_NFT_ACTION } from "./StakeModal";
import useSWR from "swr";
import isEqual from "lodash/isEqual";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "../../lib/chains";
import { useQuickContracts } from "../../lib/contracts";
import { format } from "date-fns";
import { useNFT } from "../../hooks/useNFT";

export const StakeNFT = ({ handleStakeModal }) => {
  const { isActive, account } = useWeb3React();
  const { chainId } = useChainId();
  const quickContracts = useQuickContracts();
  const { endTimeNFT, totalRewards, totalRemainingVestNFT } = useNFT();

  const { data: NFTbalanceOf } = useSWR(
    ["FoxifyVestedNFT", "NFTbalanceOf", isActive, chainId],
    () => quickContracts.fetch("FoxifyVestedNFT", "balanceOf", [account?.toLowerCase()]),
    {
      compare: isEqual,
    }
  );

  const formattedDate = () => {
    if (!endTimeNFT) return;
    const timestamp = endTimeNFT.toNumber();
    return timestamp ? format(timestamp * 1000, "dd.MM.yyyy") : "-";
  };

  return (
    <div className="Stake-NFT-card">
      <div className="info-block">
        <div className="Stake-NFT-count text-[2.4rem] leading-[1.4] font-bold">
          <Trans>NFT</Trans>
        </div>
        <div className="NFT-count">NFTs count: {NFTbalanceOf ? parseFloat(NFTbalanceOf).toFixed(0) : 0}</div>
      </div>
      <div className="info-block">
        <div className="gap-container">
          <Tooltip
            position="center-bottom"
            renderContent={() => {
              return (
                <div className="Stake-card-infobox-tooltip">
                  <Trans>Remain vesting</Trans>
                </div>
              );
            }}
          >
            <div className="Tooltip-handle-wrapper">
              <div className="inactive-text">
                <Trans>Remain vesting</Trans>
              </div>
              <HiQuestionMarkCircle className="inactive-text" />
            </div>
          </Tooltip>
        </div>
        <div className="info-block-value">
          {totalRemainingVestNFT ? formatAmount(totalRemainingVestNFT, FOX_DECIMALS, 2, true) : "0.00"} FOX
        </div>
      </div>

      <div className="info-block">
        <div className="gap-container">
          <Tooltip
            position="center-bottom"
            renderContent={() => {
              return (
                <div className="Stake-card-infobox-tooltip">
                  <Trans>Rewards</Trans>
                </div>
              );
            }}
          >
            <div className="Tooltip-handle-wrapper">
              <div className="inactive-text">
                <Trans>Rewards</Trans>
              </div>
              <HiQuestionMarkCircle className="inactive-text" />
            </div>
          </Tooltip>
        </div>
        <div className="info-block-value">
          {totalRewards ? formatAmount(totalRewards, FOX_DECIMALS, 2, true) : "0.00"} FOX
        </div>
      </div>

      <div className="info-block">
        <div className="gap-container">
          <Tooltip
            position="center-bottom"
            renderContent={() => {
              return (
                <div className="Stake-card-infobox-tooltip">
                  <Trans>End of vesting period</Trans>
                </div>
              );
            }}
          >
            <div className="Tooltip-handle-wrapper">
              <div className="inactive-text">
                <Trans>End of vesting period</Trans>
              </div>
              <HiQuestionMarkCircle className="inactive-text" />
            </div>
          </Tooltip>
        </div>
        <div className="info-block-value">{formattedDate()}</div>
      </div>
      <button
        className="Stake-button Stake-button-white ml-auto"
        onClick={() => {
          handleStakeModal(FOX_TOKEN, UNSTAKE_NFT_ACTION);
        }}
      >
        - Unstake
      </button>
    </div>
  );
};

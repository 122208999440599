import { useState, useEffect } from "react";
import { ethers } from "ethers";

export const useCurrentBlockNumber = (provider: ethers.providers.Web3Provider | undefined): number | null => {
  const [currentBlockNumber, setCurrentBlockNumber] = useState<number | null>(null);

  useEffect(() => {
    const fetchBlockNumber = async () => {
      try {
        if (provider) {
          const blockNumber = await provider.getBlockNumber();
          setCurrentBlockNumber(blockNumber);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching block number:", error);
      }
    };

    fetchBlockNumber();
  }, [provider]);

  return currentBlockNumber;
};

import React, { MouseEventHandler, useLayoutEffect, useMemo, useState } from "react";
import "./AppHeader.css";
import { Trans } from "@lingui/macro";
import { Link, useHistory } from "react-router-dom";
import cx from "classnames";
import { NamedLink } from "./HeaderLink";
import { Menu } from "components/MenuDropdown";
import useMedia from "react-use/lib/useMedia";
import { useIsDevelopment } from "lib/useIsDevelopment";
import { AccountDrawerSection } from "./AccountDrawerSection";
import { AppHeaderUser } from "./AppHeaderUser";
import Button from "components/Button/Button";
import { useAppUpdate } from "lib/useAppUpdate";
import { AnimatePresence, motion } from "framer-motion";
import Portal from "components/Common/Portal";
import { FiX } from "react-icons/fi";
import shevronDown from "img/shevron-down.svg";
import { isInsideTag } from "components/Header/utils/isInsideTag";
import { useIsTradePage, useIsPortfolioPage } from "components/Header/utils/useIsPage";
import { useBodyScrollHidden } from "components/Header/utils/useBodyScrollHidden";
import Modal from "components/Modal/Modal";
import { getConnectionLabel } from "lib/wallets/connections";
import { useWeb3React } from "@web3-react/core";
import { ReactComponent as MenuIcon } from "img/MenuIcon.svg";
import { ReactComponent as CloseIcon } from "img/CloseIcon.svg";
import Logo from "img/logo.png";
import { useFundedTrader } from "hooks/useFundedTrader";
import { useLocalStorageSerializeKey } from "lib/localStorage";

type Props = {
  disconnectAccountAndCloseSettings: () => void;
  openSettings: () => void;
  savedSlippageAmount: number;
  setSavedSlippageAmount: (value: number) => void;
  savedShouldShowPositionLines: boolean;
  setSavedShouldShowPositionLines: (value: boolean) => void;
  tradingLayout: string;
  setTradingLayout: (value: string) => void;
};

export function AppHeader({
  disconnectAccountAndCloseSettings,
  openSettings,
  savedSlippageAmount,
  setSavedSlippageAmount,
  savedShouldShowPositionLines,
  setSavedShouldShowPositionLines,
  tradingLayout,
  setTradingLayout,
}: Props) {
  const isDevelopment = useIsDevelopment();
  const appUpdate = useAppUpdate();
  const isTradePage = useIsTradePage();
  const isPortfolioPage = useIsPortfolioPage();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isMobileModalVisible, setIsMobileModalVisible] = useState(false);
  useBodyScrollHidden(isDrawerVisible);
  const [offtop, setOfftop] = useState(0);

  const showNavLastMinus3 = useMedia("(min-width: 950px)");
  const showNavLastMinus2 = useMedia("(min-width: 1050px)");
  // Should be used on two last nav elements for better UX
  const showNavLastMinus1 = useMedia("(min-width: 1150px)");
  const { connector, isActive } = useWeb3React();
  const [savedShowFundedInto, setSavedShowFundedIntro] = useLocalStorageSerializeKey(["Show-funded-intro"], true);
  const { isFundedCreated } = useFundedTrader();
  const history = useHistory();

  const showNavItem = React.useMemo(() => {
    return [showNavLastMinus3, showNavLastMinus2, showNavLastMinus1];
  }, [showNavLastMinus3, showNavLastMinus2, showNavLastMinus1]);

  const showFundedIntro = useMemo(() => {
    if (!isActive || isFundedCreated || !savedShowFundedInto) {
      return false;
    }

    return true;
  }, [isFundedCreated, isActive, savedShowFundedInto]);

  const showNavItemAt = (index: number) => showNavItem.slice(index)[0];

  const onDrawerClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isInsideTag(e.target as HTMLElement, ["button", "a"])) {
      setIsDrawerVisible(false);
    }
  };

  useLayoutEffect(() => {
    const handler = () => {
      const diff = window.innerHeight - visualViewport!.height;
      if (diff) {
        setOfftop(visualViewport!.offsetTop);
      } else setOfftop(0);
    };

    let lastScrollTop = 0;

    const handleScroll = () => {
      let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll < lastScrollTop) {
        setOfftop(0);
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };

    visualViewport?.addEventListener("resize", handler);
    window.addEventListener("scroll", handleScroll);

    return () => {
      visualViewport?.removeEventListener("resize", handler);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={cx("tailwind")}>
      <div className="border-b border-border fixed w-full z-50" style={{ marginTop: offtop }}>
        <div
          className={cx(
            "w-full flex items-center h-[5.3rem] gap-[1rem] px-[1.5rem] bg-[rgba(255,_255,_255,_0.05)] backdrop-blur-[5px]",
            {
              "bg-background-4-hover dark:bg-[#101014]": isTradePage || isPortfolioPage,
            }
          )}
        >
          <HeaderTitleAndLogo className="shrink-0 h-full flex items-center" />

          <div className="hidden lg:block flex-auto @container/links">
            <div className="flex items-center gap-[4rem] @[80rem]/links:gap-[4rem]">
              {!isDevelopment && (
                <>
                  <NamedLink.Trade variant="header" />
                  {process.env.REACT_APP_SHOW_EARN_PAGE === "true" && <NamedLink.Earn variant="header" />}

                  {/* <NamedLink.Portfolio variant="header" /> */}

                  {showNavItemAt(-1) && <NamedLink.Referral variant="header" />}
                  <NamedLink.Funded variant="header" />

                  {(!showNavItemAt(-2) || !showNavItemAt(-1)) && (
                    <Menu.Menu placement="bottom-end">
                      <Menu.Button>
                        {({ open, ref }) => (
                          <button
                            ref={ref}
                            className={cx(
                              "inline-flex rounded-sm items-center gap-2 cursor-pointer self-stretch text-white",
                              {
                                "font-bold": open,
                                "hover:opacity-60 focus-visible:opacity-60": !open,
                              }
                            )}
                          >
                            <div className="flex-1">
                              <Trans>More</Trans>
                            </div>

                            <div className="w-[1.4rem] h-[1.4rem] border-0 flex items-center justify-center shrink-0">
                              <img src={shevronDown} alt="icon" />
                            </div>
                          </button>
                        )}
                      </Menu.Button>

                      <Menu.Items>{!showNavItemAt(-1) && <NamedLink.Referral variant="header-menu" />}</Menu.Items>
                    </Menu.Menu>
                  )}
                </>
              )}
              {isDevelopment && (
                <>
                  <NamedLink.Trade variant="header" />
                  <NamedLink.Portfolio variant="header" />

                  {showNavItemAt(-1) && <NamedLink.Referral variant="header" />}

                  <NamedLink.Funded variant="header" />

                  {(!showNavItemAt(-2) || !showNavItemAt(-1)) && (
                    <Menu.Menu placement="bottom-end">
                      <Menu.Button>
                        {({ open, ref }) => (
                          <button
                            ref={ref}
                            className={cx(
                              "inline-flex rounded-sm items-center gap-2 cursor-pointer self-stretch text-white",
                              {
                                "font-bold": open,
                                "hover:opacity-60 focus-visible:opacity-60": !open,
                              }
                            )}
                          >
                            <div className="flex-1">
                              <Trans>More</Trans>
                            </div>

                            <div className="w-[1.4rem] h-[1.4rem] border-0 flex items-center justify-center shrink-0">
                              <img src={shevronDown} alt="icon" />
                            </div>
                          </button>
                        )}
                      </Menu.Button>

                      <Menu.Items>{!showNavItemAt(-1) && <NamedLink.Referral variant="header-menu" />}</Menu.Items>
                    </Menu.Menu>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="max-lg:block hidden flex-auto" />
          {showFundedIntro && (
            <Button
              variant="silver"
              size="sm"
              className="shrink-0 text-[1.4rem] hidden sm:inline-flex"
              onClick={() => history.push("/funded")}
            >
              <Trans>Start your journey to $250k trades</Trans>
              <div
                className="p-1"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setSavedShowFundedIntro(false);
                }}
              >
                <CloseIcon />
              </div>
            </Button>
          )}

          {appUpdate.haveAppUpdate && (
            <Button variant="secondary" size="sm" className="shrink-0 text-[1.4rem]" onClick={appUpdate.updateApp}>
              <Trans>Update Available</Trans>
            </Button>
          )}

          <div className="shrink-0">
            <AppHeaderUser
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              openSettings={openSettings}
              savedSlippageAmount={savedSlippageAmount}
              setSavedSlippageAmount={setSavedSlippageAmount}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
              tradingLayout={tradingLayout}
              setTradingLayout={setTradingLayout}
              isMobileModalVisible={isMobileModalVisible}
              setIsMobileModalVisible={setIsMobileModalVisible}
            />
          </div>

          <div className="h-[3.8rem] aspect-square transition-colors rounded-sm flex bg-transparent hover:bg-background-2 block md:hidden border border-border">
            <button className="flex justify-center items-center w-full" onClick={() => setIsDrawerVisible(true)}>
              <div className="text-textColor text-[2rem] opacity-70 hover:opacity-90">
                <MenuIcon />
              </div>
            </button>
          </div>
        </div>
      </div>

      <Modal
        isVisible={isMobileModalVisible}
        setIsVisible={setIsMobileModalVisible}
        label={`Account (${getConnectionLabel(connector)})`}
      >
        <div className="buttom-container">
          <AccountDrawerSection disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings} />
        </div>
      </Modal>

      <Portal>
        <div className="tailwind">
          <AnimatePresence>
            {isDrawerVisible && (
              <motion.div
                className="fixed inset-0 bg-[rgba(9,_9,_12,_0.65)] h-[100vh]"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  hidden: { opacity: 0, animation: "ease-in" },
                  visible: { opacity: 1, animation: "ease-out" },
                }}
                transition={{ duration: 0.1 }}
                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
              ></motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isDrawerVisible && (
              <motion.div
                className="hidden-scrollbar bg-background-1 fixed inset-0 pb-[env(safe-area-inset-bottom)] w-[30.4rem] overflow-y-auto max-w-full"
                initial="hidden"
                animate="visible"
                exit="hidden"
                onClick={onDrawerClick}
                variants={{
                  hidden: { x: "-100%", animation: "ease-in" },
                  visible: { x: 0, animation: "ease-out" },
                }}
                transition={{ duration: 0.2 }}
              >
                <div className="h-[6.2rem] flex items-center z-[3] ml-[1.6rem] justify-between">
                  <HeaderTitleAndLogo />

                  <div className="ml-[1.2rem] mr-[1.2rem]">
                    <button className="flex justify-center items-center" onClick={() => setIsDrawerVisible(false)}>
                      <div className="text-white text-[2rem] opacity-70 hover:opacity-90">
                        <FiX />
                      </div>
                    </button>
                  </div>
                </div>

                {!isDevelopment && (
                  <>
                    <NamedLink.Trade variant="drawer" />
                    {process.env.REACT_APP_SHOW_EARN_PAGE === "true" && <NamedLink.Earn variant="drawer" />}
                    <NamedLink.Referral variant="drawer" />
                    <NamedLink.Funded variant="drawer" />
                  </>
                )}
                {isDevelopment && (
                  <>
                    <NamedLink.Trade variant="drawer" />
                    {process.env.REACT_APP_SHOW_EARN_PAGE === "true" && <NamedLink.Earn variant="drawer" />}
                    <NamedLink.Referral variant="drawer" />
                    <NamedLink.Funded variant="drawer" />
                  </>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Portal>
    </div>
  );
}

type HeaderTitleAndLogoProps = { className?: string };

const HeaderTitleAndLogo = (props: HeaderTitleAndLogoProps) => {
  return (
    <div className={cx(props.className)}>
      <Link className="no-underline !flex items-center justify-center gap-[0.8rem] select-none mr-[2rem]" to="/">
        <img src={Logo} alt="logo" className="h-16" />
      </Link>
    </div>
  );
};

import { useEffect, useState } from "react";
import {
  BalancerError,
  BalancerErrorCode,
  BalancerSDK,
  BalancerSdkConfig,
  Network,
  SubPoolMeta,
} from "@balancer-labs/sdk";
import { getRpcUrl, ARBITRUM } from "config/chains";
import { useChainId } from "lib/chains";
import { getContractAddress } from "config/contracts";

const POOL_ID = "0x88e2c969e2a1c69c16d1dcd9f8acde4c6ab3838a0002000000000000000004c1";

export const useTokenPrices = () => {
  const [foxPrice, setFoxPrice] = useState<string | SubPoolMeta>("");
  const [ethPrice, setEthPrice] = useState<string | SubPoolMeta>("");
  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false);
  const { chainId } = useChainId();
  // Balancer can give us a price only for the MAINNET tokens addresses
  const foxTokenAddress = getContractAddress(ARBITRUM, "Fox");
  // We don't have the ETH contract, so we need to hard-code ETH token address
  const ethTokenAddress = "0x82af49447d8a07e3bd95bd0d56f35241523fbab1";

  const CONFIG: BalancerSdkConfig = {
    network: Network.ARBITRUM,
    customSubgraphUrl:
      "https://gateway-arbitrum.network.thegraph.com/api/3437df33b3ccd2be31df895b1033cb3c/subgraphs/id/98cQDy6tufTJtshDCuhh9z2kWXsQWBHVh2bqnLHsGAeS",
    rpcUrl: getRpcUrl(chainId) as string,
  };
  const [BALANCER] = useState(new BalancerSDK(CONFIG));

  useEffect(() => {
    if (foxPrice) {
      return;
    }

    (async () => {
      setIsPriceLoading(true);
      const fetchSpotPrice = async () => {
        const pool = await BALANCER.pools.find(POOL_ID);

        if (!pool) {
          throw new BalancerError(BalancerErrorCode.POOL_DOESNT_EXIST);
        }

        const foxPoolToken = pool.tokens.find((token) => token.address.toLowerCase() === foxTokenAddress.toLowerCase());
        const ethPoolToken = pool.tokens.find((token) => token.address.toLowerCase() === ethTokenAddress.toLowerCase());

        if (foxPoolToken) {
          const { latestUSDPrice } = foxPoolToken.token as SubPoolMeta;
          latestUSDPrice && setFoxPrice(latestUSDPrice);
        }
        if (ethPoolToken) {
          const { latestUSDPrice } = ethPoolToken.token as SubPoolMeta;
          latestUSDPrice && setEthPrice(latestUSDPrice);
        }
      };

      foxTokenAddress && ethTokenAddress && (await fetchSpotPrice());
      setIsPriceLoading(false);
    })();
  }, [BALANCER, foxTokenAddress, foxPrice]);

  return {
    foxPrice,
    ethPrice,
    isPriceLoading,
  };
};

import { t, Trans } from "@lingui/macro";
import { useState } from "react";

import Button from "components/Button/Button";

import ModalWithPortal from "components/Modal/ModalWithPortal";
import { usePendingTxns } from "domain/transactions/usePendingTxns";
import { useChainId } from "lib/chains";
import { callContract } from "lib/contracts";
import { BigNumber, Contract } from "ethers";

export default function RestartModal({
  open,
  cashout,
  fundedLevel,
  traderContract,
  refetchChallengeStats,
  closeModal,
}: {
  open: boolean;
  fundedLevel: string;
  traderContract: Contract;
  cashout: BigNumber;
  closeModal: () => void;
  refetchChallengeStats: () => void;
}) {
  const [isRestarting, setIsRestarting] = useState(false);

  const { chainId } = useChainId();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPendingTxns] = usePendingTxns();

  const handleRestart = async () => {
    try {
      if (traderContract) {
        setIsRestarting(true);

        const tx = await callContract(chainId, traderContract, "withdraw", [0], {
          sentMsg: t`Restarting challenge submitted.`,
          failMsg: t`Restarting challenge failed.`,
          setPendingTxns,
        });

        await tx.wait();

        callContract(chainId, traderContract, "fundAccount", [cashout, false], {
          failMsg: t`Restarting challenge failed.`,
          successMsg: t`Restarted challenge successfully.`,
          setPendingTxns,
          txnSuccessCallback: () => {
            refetchChallengeStats();
            setIsRestarting(false);
            closeModal();
          },
        });
      }
    } catch (error) {
      setIsRestarting(false);
    }
  };

  return (
    <ModalWithPortal className="tailwind" isVisible={open} setIsVisible={closeModal} label={t`Restart Challenge`}>
      <div className="text-inactive text-[1.4rem] text-center mb-[1.5rem]">
        <Trans>Do you want to restart challenge at the {fundedLevel} level?</Trans>
      </div>
      <Button variant="green" onClick={handleRestart} disabled={isRestarting}>
        <Trans>Restart {fundedLevel}</Trans>
      </Button>
    </ModalWithPortal>
  );
}

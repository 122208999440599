import useSWR from "swr";
import isEqual from "lodash/isEqual";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "../lib/chains";
import { useQuickContracts } from "../lib/contracts";
import { BigNumber } from "ethers";

export const useNFT = () => {
  const { isActive, account } = useWeb3React();
  const { chainId } = useChainId();
  const quickContracts = useQuickContracts();

  const { data: NFTbalanceOf } = useSWR(
    ["FoxifyVestedNFT", "NFTbalanceOf", isActive, chainId],
    () => quickContracts.fetch("FoxifyVestedNFT", "balanceOf", [account ? account.toLowerCase() : ""]),
    {
      compare: isEqual,
    }
  );

  const indexes = new Array(NFTbalanceOf)?.fill(undefined)?.map((_, index) => index);

  const fetchData = async (contract, method, argsArray) => {
    const fetchPromises = argsArray.map((args) => quickContracts.fetch(contract, method, args));
    return await Promise.all(fetchPromises);
  };

  const { data: arrayOfTokens } = useSWR(
    ["FoxifyVestedNFT", "tokenOfOwnerByIndex", indexes, isActive, chainId],
    () =>
      fetchData(
        "FoxifyVestedNFT",
        "tokenOfOwnerByIndex",
        indexes.map((index) => [account?.toLowerCase(), index])
      ),
    {
      disabled: !NFTbalanceOf,
      compare: isEqual,
    }
  );

  const { data: endTimeNFT } = useSWR(
    ["FoxifyMaxi", "endTimeNFT", isActive, chainId],
    () => quickContracts.fetch("FoxifyMaxi", "endTimeNFT", [0]),
    {
      compare: isEqual,
    }
  );

  const { data: getTotalProfitsNFT } = useSWR(
    ["FoxifyMaxi", "getTotalProfitsNFT", isActive, chainId],
    () =>
      fetchData(
        "FoxifyMaxi",
        "getTotalProfitsNFT",
        indexes.map((index) => [index])
      ),
    {
      compare: isEqual,
    }
  );

  const { data: remainingVestNFT } = useSWR(
    ["FoxifyMaxi", "remainingVestNFT", isActive, chainId],
    () =>
      fetchData(
        "FoxifyMaxi",
        "remainingVestNFT",
        indexes.map((index) => [index])
      ),
    {
      compare: isEqual,
    }
  );

  const { data: pendingVestNFT } = useSWR(
    ["FoxifyMaxi", "pendingVestNFT", isActive, chainId],
    () =>
      fetchData(
        "FoxifyMaxi",
        "pendingVestNFT",
        indexes.map((index) => [index])
      ),
    {
      compare: isEqual,
    }
  );

  const calculateTotalAmount = (data) =>
    data ? data.reduce((item: BigNumber, acc: BigNumber) => acc.add(item), BigNumber.from(0)) : BigNumber.from(0);

  const totalRewards = calculateTotalAmount(getTotalProfitsNFT);
  const totalRemainingVestNFT = calculateTotalAmount(remainingVestNFT);
  const totalClaimableAmount = calculateTotalAmount(pendingVestNFT);
  return {
    arrayOfTokens,
    endTimeNFT,
    totalRewards,
    totalRemainingVestNFT,
    totalClaimableAmount,
  };
};

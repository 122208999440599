import cx from "classnames";

type Props = {
  active?: boolean
}

export const CheckboxItem = ({ active = false }: Props) => {
  return (
    <div className={cx("gs-foxify-checkbox-item", { active })}>
      {active && <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 50 50">
        <path
          d="M42.875 8.625C42.8438 8.6328 42.8125 8.6445 42.7812 8.6562C42.5195 8.7227 42.292 8.8906 42.1562 9.125L21.7188 40.8125L7.65625 28.125C7.41016 27.8125 7 27.6758 6.61328 27.7773C6.22656 27.8789 5.94141 28.2031 5.88281 28.5977C5.82422 28.9922 6.00391 29.3828 6.34375 29.5938L21.25 43.0938C21.4688 43.2852 21.7617 43.3711 22.0508 43.3281C22.3398 43.2852 22.5938 43.1211 22.75 42.875L43.8438 10.1875C44.0742 9.85938 44.0859 9.42578 43.875 9.08594C43.6641 8.74609 43.2695 8.56641 42.875 8.625Z"/>
      </svg>
      }
    </div>
  )
}

import { Trans, t } from "@lingui/macro";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import Button from "components/Button/Button";
import { useChainId } from "lib/chains";
import { usePendingTxns } from "domain/transactions/usePendingTxns";
import { useState } from "react";

import Cashout from "./Tabs/Challenge/Cashout";
import { callContract } from "lib/contracts";
import { BigNumber, Contract } from "ethers";

export default function RolloverModal({
  open,
  cashout,
  isLoading,
  traderContract,
  refetchChallengeStats,
  closeModal,
}: {
  open: boolean;
  cashout: BigNumber;
  isLoading: boolean;
  traderContract: Contract;
  closeModal: () => void;
  refetchChallengeStats: () => void;
}) {
  const { chainId } = useChainId();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPendingTxns] = usePendingTxns();
  const [isRollingover, setIsRollingover] = useState(false);

  const handleClickRollover = async () => {
    try {
      if (traderContract) {
        setIsRollingover(true);
        const tx = await callContract(chainId, traderContract, "withdraw", [BigNumber.from(0)], {
          sentMsg: t`Rollover submitted.`,
          failMsg: t`Rollover failed.`,
          setPendingTxns,
        });

        await tx.wait();

        callContract(chainId, traderContract, "fundAccount", [BigNumber.from(0), true], {
          failMsg: t`Rollover failed.`,
          successMsg: t`Rollover successfully.`,
          setPendingTxns,
          txnSuccessCallback: () => {
            refetchChallengeStats();
            setIsRollingover(false);
            closeModal();
          },
        });
      }
    } catch (error) {
      setIsRollingover(false);
    }
  };

  return (
    <ModalWithPortal className="tailwind" isVisible={open} setIsVisible={closeModal} label="Rollover">
      <div className="text-inactive text-[1.4rem] mb-[1.8rem]">
        <Trans>Increase the trading balance on your next challenge.</Trans>
      </div>

      <Cashout cashout={cashout} isLoading={isLoading} label="Rollover" />
      <Button
        variant="green"
        onClick={handleClickRollover}
        className="w-full rounded !text-black mt-6"
        disabled={isRollingover}
      >
        {cashout.gt(0) ? <Trans>Rollover</Trans> : <Trans>Start next challenge</Trans>}
      </Button>
    </ModalWithPortal>
  );
}

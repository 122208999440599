import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";


const Notification = ({message,show}) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div className="fixed top-[60px] right-4 max-w-[511px] p-4 bg-[#f66b31] border rounded-[10px] z-50">
      <button 
        className="absolute top-[2px] right-2 text-black]"
        onClick={handleClose}
      >
        <RxCross2 size={20}  className="text-white"/>
      </button>
      <p className="text-white max-w-full ">
        {message}
      </p>
    </div>
    )
  );
};

export default Notification;
import { useCallback, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import { useQuickContracts } from "lib/contracts";
import isEqual from "lodash/isEqual";
import useSWR from "swr";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { IS_FUNDED_ACCOUNT_ACTIVE } from "config/localStorage";
import { useLocalStorageReactive } from "../lib/localStorage";
import TraderContractAbi from "abis/Trader.json";

const { AddressZero } = ethers.constants;

export const useFundedTrader = () => {
  const { chainId } = useChainId();
  const { isActive, account, provider } = useWeb3React();
  const quickContracts = useQuickContracts();

  const {
    data: traderContractAddress,
    isLoading: isLoadingTraderContract,
    isValidating: isValidatingTraderContract,
    mutate: refetchTrader,
  } = useSWR(
    account && chainId && isActive ? ["Factory", "traderContractAddress", isActive, chainId] : null,
    () => quickContracts.fetch("Factory", "traderContracts", [account?.toLowerCase() as string]),
    {
      refreshInterval: 0,
      compare: isEqual,
    }
  );

  const traderContract = useMemo(() => {
    return traderContractAddress && traderContractAddress !== AddressZero && provider
      ? new ethers.Contract(traderContractAddress, TraderContractAbi.abi, provider.getSigner())
      : null;
  }, [traderContractAddress, provider]);

  const fetchChallengeStats = useCallback(async () => {
    if (traderContract) {
      const [
        traderChallenge,
        BASIS_POINTS,
        balance,
        startingCapital,
        openPositionsCount,
        challengeStartedAtTimestamp,
        pnl,
      ] = await Promise.all([
        traderContract.traderChallenge(),
        traderContract.BASIS_POINTS(),
        traderContract.balance(),
        traderContract.startingCapital(),
        traderContract.openPositionsCount(),
        traderContract.challengeStartedAtTimestamp(),
        traderContract.pnl(),
      ]);

      // Revert if challenge is in progress
      let challengeInProgress = true;
      try {
        // If challenge started, check if ended
        if (BigNumber.from(challengeStartedAtTimestamp).gt(0)) {
          await traderContract.checkChallengeStatus(false);
        }
        challengeInProgress = false;
      } catch {
        challengeInProgress = true;
      }

      return {
        challengeNumber: BigNumber.from(traderChallenge),
        basePoints: BigNumber.from(BASIS_POINTS),
        fundedBalance: BigNumber.from(balance),
        startingCapital,
        challengeInProgress,
        openPositionsCount: BigNumber.from(openPositionsCount).toNumber(),
        pnl: BigNumber.from(pnl),
      };
    }

    return {
      challengeNumber: BigNumber.from(0),
      basePoints: BigNumber.from(0),
      fundedBalance: BigNumber.from(0),
      startingCapital: BigNumber.from(0),
      challengeInProgress: false,
      openPositionsCount: 0,
      pnl: BigNumber.from(0),
    };
  }, [traderContract]);

  const {
    data,
    isLoading: isLoadingChallengeStats,
    isValidating: isValidatingChallengeStats,
    mutate: refetchChallengeStats,
  } = useSWR<{
    challengeNumber: BigNumber;
    basePoints: BigNumber;
    openPositionsCount: number;
    fundedBalance: BigNumber;
    startingCapital: BigNumber;
    challengeInProgress: boolean;
    pnl: BigNumber;
  }>(traderContractAddress ? ["challengeStats", chainId, traderContractAddress] : null, fetchChallengeStats, {
    fallbackData: {
      challengeNumber: BigNumber.from(0),
      basePoints: BigNumber.from(0),
      fundedBalance: BigNumber.from(0),
      startingCapital: BigNumber.from(0),
      challengeInProgress: false,
      openPositionsCount: 0,
      pnl: BigNumber.from(0),
    },
    refreshInterval: 0,
  });
  const { challengeNumber, basePoints, openPositionsCount, fundedBalance, startingCapital, challengeInProgress, pnl } =
    data || {
      challengeNumber: BigNumber.from(0),
      basePoints: BigNumber.from(0),
      fundedBalance: BigNumber.from(0),
      startingCapital: BigNumber.from(0),
      challengeInProgress: false,
      openPositionsCount: 0,
      pnl: BigNumber.from(0),
    };

  const resChallengeNumber = useMemo(
    () => (challengeNumber && challengeNumber.gt(0) ? challengeNumber.sub(BigNumber.from(1)) : null),
    [challengeNumber]
  );

  const { data: traderChallengeConfig, isLoading: isLoadingChallengeConfig } = useSWR(
    isActive && chainId ? ["Factory", "traderChallengeConfig", isActive, chainId] : null,
    () => quickContracts.fetch("Factory", "traderChallengeConfigs", [resChallengeNumber?.add(1) || BigNumber.from(0)]),
    {
      compare: isEqual,
      refreshInterval: 0,
    }
  );

  const { data: isWhitelistEnabled, isLoading: isLoadingWhitelistEnabled } = useSWR(
    account && isActive && chainId ? ["Factory", "isWhitelistEnabled", isActive, chainId, account] : null,
    () => quickContracts.fetch("Factory", "isWhitelistEnabled", []),
    {
      fallbackData: false,
      compare: isEqual,
      refreshInterval: 0,
    }
  );

  const { data: isWhitelisted, isLoading: isLoadingWhitelisted } = useSWR(
    account && isActive && chainId && isWhitelistEnabled
      ? ["Factory", "whitelistedTraders", isActive, chainId, account]
      : null,
    () => quickContracts.fetch("Factory", "whitelistedTraders", [account!]),
    {
      fallbackData: false,
      compare: isEqual,
      refreshInterval: 0,
    }
  );

  const totalCashout = useMemo(() => {
    const traderProfitShareBp = traderChallengeConfig ? traderChallengeConfig.traderProfitShareBp : 0;

    // If qualification
    if (basePoints && fundedBalance && challengeNumber && challengeNumber.toNumber() === 1) {
      return fundedBalance.mul(traderProfitShareBp).div(basePoints);
    }

    // From silver stage
    if (basePoints && pnl && challengeNumber && challengeNumber.toNumber() > 1) {
      const cashout = pnl.mul(traderProfitShareBp).div(basePoints);

      return cashout.gt(0) ? cashout : BigNumber.from(0);
    }

    return BigNumber.from(0);
  }, [fundedBalance, traderChallengeConfig, basePoints, pnl, challengeNumber]);

  const expectedMinTradeSize = useMemo(() => {
    if (basePoints && traderChallengeConfig && basePoints.gt(0)) {
      return BigNumber.from(startingCapital).mul(traderChallengeConfig.minPositionCollateralBp).div(basePoints);
    }

    return BigNumber.from(0);
  }, [traderChallengeConfig, basePoints, startingCapital]);

  const expectedMaxTradeSize = useMemo(() => {
    if (basePoints && traderChallengeConfig && basePoints.gt(0)) {
      return BigNumber.from(startingCapital).mul(traderChallengeConfig.maxPositionCollateralBp).div(basePoints);
    }
    return BigNumber.from(0);
  }, [traderChallengeConfig, basePoints, startingCapital]);

  const key = JSON.stringify([chainId, IS_FUNDED_ACCOUNT_ACTIVE]);
  const [isFundedAccountActive = false, setIsFundedAccountActive] = useLocalStorageReactive(key, false);

  const isFundedCreated = useMemo(
    () => (traderContractAddress && traderContractAddress !== AddressZero ? true : false),
    [traderContractAddress]
  );

  const isFundedTrading = useMemo(() => {
    return isFundedAccountActive && isFundedCreated && traderContractAddress ? true : false;
  }, [isFundedAccountActive, isFundedCreated, traderContractAddress]);

  const proxyTraderContractAddress = useMemo(() => {
    return isFundedTrading ? traderContractAddress : null;
  }, [traderContractAddress, isFundedTrading]);

  const isLoading = useMemo(() => {
    return (
      isLoadingChallengeConfig ||
      isLoadingChallengeStats ||
      isLoadingTraderContract ||
      isValidatingTraderContract ||
      isValidatingChallengeStats ||
      isLoadingWhitelisted ||
      isLoadingWhitelistEnabled
    );
  }, [
    isLoadingChallengeConfig,
    isLoadingChallengeStats,
    isLoadingTraderContract,
    isValidatingTraderContract,
    isValidatingChallengeStats,
    isLoadingWhitelisted,
    isLoadingWhitelistEnabled,
  ]);

  const isWhitelistedTrader = useMemo(() => {
    return !isWhitelistEnabled || isWhitelisted;
  }, [isWhitelistEnabled, isWhitelisted]);

  return {
    isFundedAccountActive,
    setIsFundedAccountActive,
    traderContractAddress,
    refetchTrader,
    refetchChallengeStats,
    isFundedCreated,
    isFundedTrading,
    proxyTraderContractAddress,
    expectedMinTradeSize,
    expectedMaxTradeSize,
    traderChallengeConfig,
    basePoints,
    traderContract,
    fundedBalance,
    resChallengeNumber,
    totalCashout,
    openPositionsCount,
    challengeInProgress,
    isWhitelisted: isWhitelistedTrader,
    isLoading,
  };
};

import { initializeConnector } from "@web3-react/core";
import { Connection, ConnectionType } from "lib/wallets/connections";
import { SUPPORTED_CHAIN_IDS, getRpcUrl, ARBITRUM, DEFAULT_CHAIN_ID } from "config/chains";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { helperToast } from "lib/helperToast";

export const WALLET_CONNECT_SUPPORTED_CHAINS = [ARBITRUM];

export function assertSupportedWalletConnectChain(chainId: number) {
  if (!WALLET_CONNECT_SUPPORTED_CHAINS.includes(chainId)) {
    throw new Error(`ChainId ${chainId} is not supported by wallet connect`);
  }
}

export function buildWalletConnectConnector(): Connection {
  const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
    (actions) =>
      new WalletConnectV2({
        actions,
        defaultChainId: DEFAULT_CHAIN_ID,
        options: {
          projectId: "1be7e9d5ef3663a554ad46b190d30e84",
          showQrModal: true,
          chains: [SUPPORTED_CHAIN_IDS[0]],
          optionalChains: SUPPORTED_CHAIN_IDS,
          rpcMap: {
            [ARBITRUM]: getRpcUrl(ARBITRUM)!
          },
        },
        onError(error) {
          helperToast.error(error.message);
        },
      })
  );

  return {
    connector: walletConnectV2,
    hooks,
    type: ConnectionType.WALLET_CONNECT,
  };
}

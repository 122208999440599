/* eslint-disable @typescript-eslint/no-redeclare */
import { ethers } from "ethers";
import { expandDecimals } from "./numbers";
import { isDevelopment } from "config/env";

// use a random placeholder account instead of the zero address as the zero address might have tokens
export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address;

export const MIN_PROFIT_TIME = 0;

export const BASIS_POINTS_DIVISOR = 10000;
export const MAX_LEVERAGE = 100 * BASIS_POINTS_DIVISOR;
export const MIN_ALLOWED_LEVERAGE = 1.1 * BASIS_POINTS_DIVISOR;
export const FUNDED_MIN_ALLOWED_LEVERAGE = 10.1 * BASIS_POINTS_DIVISOR;
export const MAX_ALLOWED_LEVERAGE = 50 * BASIS_POINTS_DIVISOR;

export const MAX_PRICE_DEVIATION_BASIS_POINTS = 750;
export const DEFAULT_GAS_LIMIT = 1 * 1000 * 1000;
export const SECONDS_PER_YEAR = 31536000;
export const USDP_DECIMALS = 18;
export const USD_DECIMALS = 30;
export const PLP_DECIMALS = 18;
export const USDT_DECIMALS = 18;
export const USDC_DECIMALS = isDevelopment() ? 18 : 6;
export const PALM_DECIMALS = 18;
export const FOX_DECIMALS = isDevelopment() ? 6 : 18;
export const FOX_STABLE_DECIMALS = 18;
export const TP_DECIMALS = 30;
export const DUST_BNB = "2000000000000000";
export const DUST_USD = expandDecimals(1, USD_DECIMALS);
export const PRECISION = expandDecimals(1, 30);
/**
 *  @deprecated use PLP_DECIMALS instead
 */
export const GLP_DECIMALS = 18;
/**
 *  @deprecated
 */
export const GMX_DECIMALS = 18;
export const DEFAULT_MAX_USDP_AMOUNT = expandDecimals(200 * 1000 * 1000, 18);

export const TAX_BASIS_POINTS = 60;
export const STABLE_TAX_BASIS_POINTS = 5;
export const MINT_BURN_FEE_BASIS_POINTS = 30;
export const SWAP_FEE_BASIS_POINTS = 25;
export const STABLE_SWAP_FEE_BASIS_POINTS = 1;
export const MARGIN_FEE_BASIS_POINTS = 10;

export const LIQUIDATION_FEE = expandDecimals(5, USD_DECIMALS);

export const TRADES_PAGE_SIZE = 100;

export const GLP_COOLDOWN_DURATION = 0;
export const THRESHOLD_REDEMPTION_VALUE = expandDecimals(993, 27); // 0.993
export const FUNDING_RATE_PRECISION = 1000000;

export const INCREASE = "Increase";
export const DECREASE = "Decrease";
export const LONG = "Long";
export const SHORT = "Short";

export const OrderOption = {
  Market: "Market",
  Limit: "Limit",
  Stop: "Stop",
} as const;
export type OrderOption = (typeof OrderOption)[keyof typeof OrderOption];

export const LEVERAGE_ORDER_OPTIONS = [OrderOption.Market, OrderOption.Limit, OrderOption.Stop];
export const SWAP_ORDER_OPTIONS = [OrderOption.Market, OrderOption.Limit];
export const SWAP_OPTIONS = [LONG, SHORT];
export const DEFAULT_SLIPPAGE_AMOUNT = 30;
export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100;

export const REFERRAL_CODE_QUERY_PARAM = "ref";
export const MAX_REFERRAL_CODE_LENGTH = 20;

export const MIN_PROFIT_BIPS = 0;

import { MdOutlineSync } from "react-icons/md";
import { Trans } from "@lingui/macro";

import Button from "components/Button/Button";

import Cashout from "./Cashout";
import { BigNumber } from "ethers";
import { formatAmount } from "lib/formatAmount";
import { MaxTradeSizes } from "components/Funded/types";

export default function ChallengeDemoted({
  cashout,
  isLoading,
  fundedLevel,
  challengeNumber,
  onCashout,
  onRestart,
}: {
  cashout: BigNumber;
  isLoading: boolean;
  fundedLevel: string;
  challengeNumber: number;
  onCashout: () => void;
  onRestart: () => void;
}) {
  const handleRenderButton = () => {
    return (
      <>
        {cashout.gt(0) && (
          <Button variant="primary-action" onClick={onCashout} className="w-full rounded mt-2 !text-black">
            <Trans>Cashout</Trans>
          </Button>
        )}
        <Button variant="primary-action" onClick={onRestart} className="w-full rounded mt-2 !text-black">
          <Trans>Rollover</Trans>
        </Button>
      </>
    );
  };

  return (
    <div>
      <div className="flex items-center text-red text-[2rem] gap-x-3">
        <div className="w-[3.5rem] h-[3.5rem] flex items-center justify-center rounded-full bg-[#FD4040]/[0.15]">
          <MdOutlineSync />
        </div>
        <Trans>Demoted</Trans>
      </div>
      <div className="text-inactive text-[1.4rem] mb-[9.7rem] mt-4">
        <Trans>
          Unlucky, I'm sure you'll get to {fundedLevel} next time and reach $
          {formatAmount(MaxTradeSizes[challengeNumber])} max trade size.
        </Trans>
      </div>

      <Cashout cashout={cashout} isLoading={isLoading} />
      {handleRenderButton()}
    </div>
  );
}

import React, { useState } from "react";

import { CHART_PERIODS, CHART_PERIODS_PLP } from "pages/Buy/constants";
import { formatAmount } from "lib/numbers";
import LightweightChart from "./LightweightChart";
import { BigNumber } from "ethers";
import { USDP_DECIMALS } from "lib/legacy";
import { usePlpStats } from "domain/stats/useGlpStats";
import parse from "parse-duration";
import { GlpStatPeriod } from "domain/stats/types";

const usePlpTokenPrice = () => {
  const { data: plpPriceHistory } = usePlpStats(1);
  const lastSubgraphPrice = plpPriceHistory[0]?.price || BigNumber.from(1);
  const currentPlpPrice = parseFloat(formatAmount(lastSubgraphPrice, USDP_DECIMALS, 3, true));

  return currentPlpPrice;
};

export default function Chart() {
  const plpTokenPrice = usePlpTokenPrice();
  const [, setPlpHoveredPrice] = useState<number | undefined>();
  const [startPeriod] = useState(CHART_PERIODS_PLP[0].value);

  const periodParams = React.useMemo<{ limit: number; period: GlpStatPeriod }>(() => {
    switch (startPeriod) {
      case CHART_PERIODS.DAY: {
        return {
          limit: Math.floor(parse("1 day", "hour") ?? 0),
          period: "hourly",
        };
      }
      case CHART_PERIODS.WEEK: {
        return {
          limit: Math.floor(parse("1 week", "hour") ?? 0),
          period: "hourly",
        };
      }
      case CHART_PERIODS.MONTH: {
        return {
          limit: Math.floor(parse("1 month", "day") ?? 0),
          period: "daily",
        };
      }
      case CHART_PERIODS.YEAR: {
        return {
          limit: Math.floor(parse("1 year", "day") ?? 0),
          period: "daily",
        };
      }
    }
  }, [startPeriod]);

  const { isLoading, haveError } = usePlpStats(periodParams.limit, periodParams.period);

  const today = new Date();
  const historicalData = Array.from({ length: 7 }, (_, index) => ({
    time: Math.floor(today.getTime() / 1000 - index * 24 * 60 * 60), // Unix timestamp of consecutive days
    value: 0,
  }));

  // Sort the historicalData array in ascending order by time
  historicalData.sort((a, b) => a.time - b.time);
  return (
    <div className="w-full flex flex-col gap-[2.5rem]">
      <div className="flex-col flex border-b dark:border-border border-[#D4DAE7]">
        <LightweightChart
          historicalData={historicalData}
          isLoading={isLoading}
          isError={haveError}
          setHoveredPrice={setPlpHoveredPrice}
          lastPrice={plpTokenPrice}
        />
      </div>
      <div className="flex md:justify-end justify-center"></div>
    </div>
  );
}

import { t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import externalLink from "img/ic_new_link_16.svg";
import copy from "img/ic_copy_16.svg";
import Button from "components/Button/Button";
import { helperToast } from "lib/helperToast";
import { useWeb3React } from "@web3-react/core";
import { getAccountUrl, useENS } from "lib/legacy";
import { useChainId } from "lib/chains";
import { useCopyToClipboard } from "react-use";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { Avatar } from "components/Avatar";
import { isBridgeModalVisibleAtom } from "components/Bridge/state";
import { useAtom } from "jotai";
import bridgeIcon from "img/bridgeIcon.svg";

import { Switch } from "components/Switch/Switch";
import { useFundedTrader } from "../../hooks/useFundedTrader";
// import { NavLink } from "react-router-dom";
// import { useAtom } from "jotai";
// import { isBridgeModalVisibleAtom } from "components/Bridge/state";
// import bridgeIcon from "img/bridge.svg";

type AccountDrawerSectionProps = {
  disconnectAccountAndCloseSettings?: () => void;
};

export const AccountDrawerSection = ({ disconnectAccountAndCloseSettings }: AccountDrawerSectionProps) => {
  const { isActive, account } = useWeb3React();
  const { ensName } = useENS(account);
  const { rawChainId } = useChainId();
  const connectWalletUi = useConnectWalletUi();

  const accountUrl = getAccountUrl(rawChainId, account);

  const [, copyToClipboard] = useCopyToClipboard();
  // const [, setIsBridgeModalVisible] = useAtom(isBridgeModalVisibleAtom);
  const accountDisplay = ensName || account;
  const truncatedAccount = `${accountDisplay?.substring(0, 6)}...${accountDisplay?.slice(-5)}`;
  const [, setIsBridgeModalVisible] = useAtom(isBridgeModalVisibleAtom);
  const { isFundedAccountActive, setIsFundedAccountActive } = useFundedTrader();

  return (
    <>
      {isActive ? (
        <div className="flex flex-col items-stretch gap-[1.5rem]">
          <div className="flex items-center justify-between">
            <div className="flex space-between items-center">
              {isActive && account && (
                <Avatar username={account} className="h-[4rem] aspect-square flex items-center justify-start" />
              )}

              <div className="break-all">
                <span className="text-textColor strong text-[1.6rem] leading-[2.4rem]">{truncatedAccount}</span>
              </div>
            </div>

            <div className="flex items-stretch  h-[2.4rem]">
              <div className="flex gap-[.3rem] items-center text-[1.4rem] text-inactive">
                <ExternalLink href={accountUrl} className="no-underline">
                  <div className="h-[2rem] aspect-square flex items-center justify-center">
                    <img src={externalLink} alt="Open address in explorer" />
                  </div>
                </ExternalLink>
              </div>
              <button
                className="bg-transparent border-none"
                onClick={() => {
                  copyToClipboard(account as any);
                  helperToast.success(t`Address copied to your clipboard`);
                }}
              >
                <div className="h-[2rem] aspect-square flex items-center justify-center">
                  <img src={copy} alt="Copy user address" />
                </div>
              </button>
            </div>
          </div>
          {/* <NavLink
            to={"/event"}
            className="rounded-sm px-[1rem] h-[5rem] border inline-flex items-center justify-between gap-4 transition bg-background-2 no-underline"
          >
            <p className="text-inactive text-[1.4rem] leading-[2.4rem]">
              <Trans>Trading Points</Trans>
            </p>
          </NavLink> */}

          {/* <button
            className="w-full h-[4.5rem] bg-main rounded-[1.5rem] flex flex-row justify-between items-center px-[1rem] border-none"
            onClick={() => setIsBridgeModalVisible(true)}
          >
            <div className="flex flex-row text-white text-[1.3rem] items-center gap-[0.5rem]">
              <img src={bridgeIcon} alt="bridgeIcon" className=" w-[1.6rem] h-[1.6rem]" />
              <p>
                <Trans>Bridging (Cross-Chain Deposit)</Trans>
              </p>
            </div>
            <div className="p-[0.5rem] bg-gradient-to-r from-[#D2CEFE] to-[#ECEAF7] text-main rounded-[4px] text-[1.2rem] h-[2.5rem] flex items-center justify-center ">
              <p>New</p>
            </div>
          </button> */}

          <div className="flex justify-between items center pt-[1.5rem]">
            <div className="w-full h-[4rem] bg-background-3 text-center px-[1.5rem] rounded-[1rem] cursor-pointer flex justify-between items-center hover:bg-background-3-hover">
              <span className="text-inactive">FUNDED</span>
              <Switch
                checked={isFundedAccountActive}
                onChange={() => setIsFundedAccountActive(!isFundedAccountActive)}
                checkedColor="#f66b31"
                uncheckedColor="#1D2024"
              />
            </div>
          </div>

          <button
            className="w-full h-[4rem] bg-main text-center px-[1.5rem] border-0 rounded-[1rem] cursor-pointer flex justify-between items-center hover:bg-main-hover"
            onClick={() => setIsBridgeModalVisible(true)}
          >
            <div className="flex gap-[1rem] items-center justify-start">
              <img src={bridgeIcon} alt="bridgeIcon" className="w-[1.6rem] h-[1.6rem]" />
              <span className="text-[1.4rem] leading-[2.4rem] text-black">Bridging (Cross-Chain Deposit)</span>
            </div>
            <div className="flex items-center justify-center bg-gradient-to-l from-[#EDEBF7] to-[#FFC9B3] text-black p-[1rem] text-[1.2rem] fw-medium py-[0.5rem] rounded-[0.5rem]">
              New
            </div>
          </button>

          <Button
            variant="secondary"
            size="lg"
            className="w-full"
            disabled={false}
            type="submit"
            onClick={disconnectAccountAndCloseSettings}
          >
            <Trans>Disconnect</Trans>
          </Button>
        </div>
      ) : (
        <Button
          variant="primary-action"
          size="lg"
          className="w-full"
          disabled={false}
          type="submit"
          onClick={connectWalletUi.connectWallet}
        >
          <Trans>Connect Wallet</Trans>
        </Button>
      )}
    </>
  );
};

import React, { useCallback, useState, useMemo } from "react";
import "./ConfirmationBox.css";
import { calculatePositionDelta } from "lib/legacy";
import {
  USD_DECIMALS,
  PRECISION,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  INCREASE,
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DECREASE,
  OrderOption,
} from "lib/constants";
import cx from "classnames";

import { BsArrowRight } from "react-icons/bs";
import Modal from "../Modal/Modal";
import Checkbox from "../Checkbox/Checkbox";
import ExchangeInfoRow from "./ExchangeInfoRow";
import { cancelDecreaseOrder, handleCancelOrder } from "domain/legacy";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import { expandDecimals, formatAmount } from "lib/numbers";
import { getToken, getWrappedToken } from "config/tokens";
import { Plural, t, Trans } from "@lingui/macro";
import Button from "components/Button/Button";
import { getTokenInfo } from "domain/tokens";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { useCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import FeesTooltip from "components/Exchange/FeesTooltip";
import { BigNumber } from "@ethersproject/bignumber";
import { useExecutionFee } from "domain/fees/useExecutionFee";
import { TokenUtils } from "components/TokenUtils";
import { FIAT_DECIMALS } from "../../config/formatting";
import { useFundedTrader } from "hooks/useFundedTrader";

const HIGH_SPREAD_THRESHOLD = expandDecimals(1, USD_DECIMALS).div(100); // 1%;

function renderAllowedSlippage(allowedSlippage) {
  return (
    <ExchangeInfoRow label={t`Allowed Slippage`}>
      <Tooltip
        renderContent={() => {
          return (
            <Trans>
              You can change this in the settings menu on the top right of the page.
              <br />
              <br />
              Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.
            </Trans>
          );
        }}
      >
        <span className="underline">{`${formatAmount(allowedSlippage, 2, 2)}%`}</span>
      </Tooltip>
    </ExchangeInfoRow>
  );
}

export default function ConfirmationBox(props) {
  const {
    fromToken,
    toToken,
    toTokenInfo,
    isLong,
    isMarketOrder,
    orderOption,
    isShort,
    toAmount,
    fromAmount,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    onConfirmationClick,
    setIsConfirming,
    hasExistingPosition,
    leverage,
    existingPosition,
    existingLiquidationPrice,
    displayLiquidationPrice,
    shortCollateralToken,
    isPendingConfirmation,
    triggerPriceUsd,
    positionFeeUsd,
    isSubmitting,
    fromUsdMin,
    toUsdMax,
    nextAveragePrice,
    chainId,
    orders,
    library,
    setPendingTxns,
    pendingTxns,
    entryMarkPrice,
    infoTokens,
  } = props;

  const { minExecutionFeeErrorMessage, currentExecutionFee, currentExecutionFeeUsd } = useExecutionFee({
    isMarketOrder,
  });

  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isTriggerWarningAccepted, setIsTriggerWarningAccepted] = useState(false);
  const [isLimitOrdersVisible, setIsLimitOrdersVisible] = useState(false);
  const collateralTokenAddress = useCollateralTokenAddress();
  const { proxyTraderContractAddress } = useFundedTrader();

  const onCancelOrderClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, proxyTraderContractAddress, { pendingTxns, setPendingTxns });
    },
    [library, pendingTxns, setPendingTxns, chainId, proxyTraderContractAddress]
  );

  let collateralAfterFees = fromUsdMin;
  if (positionFeeUsd) {
    collateralAfterFees = fromUsdMin.sub(positionFeeUsd);
  }

  const getTitle = () => {
    if (!isMarketOrder) {
      return t`Confirm Limit Order`;
    }
    return isLong ? t`Confirm Long` : t`Confirm Short`;
  };

  const title = getTitle();

  const existingOrder = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId);
    for (const order of orders) {
      if (order.type !== INCREASE) continue;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      if (order.isLong === isLong && sameToken) {
        return order;
      }
    }
  }, [orders, chainId, isLong, toToken.address, toToken.isNative]);

  const existingOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId);
    return orders.filter((order) => {
      if (order.type !== INCREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainId, isLong, toToken.address, toToken.isNative]);

  const existingTriggerOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId);
    return orders.filter((order) => {
      if (order.type !== DECREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainId, isLong, toToken.address, toToken.isNative]);

  const decreaseOrdersThatWillBeExecuted = useMemo(() => {
    return existingTriggerOrders.filter((order) => {
      if (order.triggerAboveThreshold) {
        return existingPosition?.markPrice.gte(order.triggerPrice);
      } else {
        return existingPosition?.markPrice.lte(order.triggerPrice);
      }
    });
  }, [existingPosition, existingTriggerOrders]);

  const getError = () => {
    if (hasExistingPosition && !isMarketOrder) {
      const { delta, hasProfit } = calculatePositionDelta(triggerPriceUsd, existingPosition);
      if (hasProfit && delta.eq(0)) {
        return t`Invalid price, see warning`;
      }
    }
    if (isMarketOrder && hasPendingProfit && !isProfitWarningAccepted) {
      return t`Forfeit profit not checked`;
    }
    return false;
  };

  const getPrimaryText = () => {
    if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
      return t`Accept confirmation of trigger orders`;
    }

    if (!isPendingConfirmation) {
      const error = getError();
      if (error) {
        return error;
      }

      const action = isMarketOrder ? (isLong ? t`Long` : t`Short`) : t`Create Order`;

      if (
        isMarketOrder &&
        MIN_PROFIT_TIME > 0 &&
        hasExistingPosition &&
        existingPosition.delta.eq(0) &&
        existingPosition.pendingDelta.gt(0)
      ) {
        return isLong ? t`Forfeit profit and ${action}` : t`Forfeit profit and Short`;
      }

      return isMarketOrder && MIN_PROFIT_TIME > 0 ? t`Accept minimum and ${action}` : action;
    }

    if (!isMarketOrder) {
      return t`Creating Order...`;
    }
    if (isLong) {
      return t`Longing...`;
    }
    return t`Shorting...`;
  };

  const isPrimaryEnabled = () => {
    if (getError()) {
      return false;
    }
    if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
      return false;
    }
    return !isPendingConfirmation && !isSubmitting;
  };

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const collateralSpreadInfo = useMemo(() => {
    if (!toTokenInfo?.spread || !collateralTokenAddress) {
      return null;
    }

    let totalSpread = toTokenInfo.spread;
    if (toTokenInfo.address === collateralTokenAddress) {
      return {
        value: totalSpread,
        isHigh: toTokenInfo.spread.gt(HIGH_SPREAD_THRESHOLD),
      };
    }

    const collateralTokenInfo = getTokenInfo(infoTokens, collateralTokenAddress);
    if (collateralTokenInfo?.spread) {
      totalSpread = totalSpread.add(collateralTokenInfo.spread);
    }

    return {
      value: totalSpread,
      isHigh: totalSpread.gt(HIGH_SPREAD_THRESHOLD),
    };
  }, [toTokenInfo, collateralTokenAddress, infoTokens]);

  const renderCollateralSpreadWarning = useCallback(() => {
    if (collateralSpreadInfo && collateralSpreadInfo.isHigh) {
      return (
        <div className="Confirmation-box-warning">
          <Trans>
            Transacting with a depegged stable coin is subject to spreads reflecting the worse of current market price
            or $1.00, with transactions involving multiple stablecoins may have multiple spreads.
          </Trans>
        </div>
      );
    }
  }, [collateralSpreadInfo]);

  const hasPendingProfit =
    MIN_PROFIT_TIME > 0 && existingPosition && existingPosition.delta.eq(0) && existingPosition.pendingDelta.gt(0);

  const renderExistingOrderWarning = useCallback(() => {
    if (!existingOrder) {
      return;
    }
    const indexToken = getToken(chainId, existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    );
    const longOrShortText = existingOrder.isLong ? t`Long` : t`Short`;
    if (existingOrders?.length > 1) {
      return (
        <div>
          <div className="Confirmation-box-info">
            <span>
              <Trans>
                You have multiple existing Increase {longOrShortText} {TokenUtils.getSymbol(indexToken)} limit orders{" "}
              </Trans>
            </span>
            <span onClick={() => setIsLimitOrdersVisible((p) => !p)} className="view-orders">
              ({isLimitOrdersVisible ? t`hide` : t`view`})
            </span>
          </div>
          {isLimitOrdersVisible && (
            <ul className="order-list">
              {existingOrders.map((order) => {
                const { account, index, type, triggerAboveThreshold, triggerPrice } = order;
                const id = `${account}-${index}`;
                const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
                const indexToken = getToken(chainId, order.indexToken);

                return (
                  <li key={id} className="font-sm">
                    <p>
                      {type === INCREASE ? t`Increase` : t`Decrease`} {TokenUtils.getSymbol(indexToken)}{" "}
                      {isLong ? t`Long` : t`Short`} &nbsp;{triggerPricePrefix} $
                      {formatAmount(triggerPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
                    </p>
                    <button onClick={() => onCancelOrderClick(order)}>
                      <Trans>Cancel</Trans>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
    }
    return (
      <div className="Confirmation-box-info">
        <Trans>
          You have an active Limit Order to Increase {longOrShortText} {sizeInToken} {TokenUtils.getSymbol(indexToken)}{" "}
          ($
          {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, indexToken?.priceDecimals, true)}) at price $
          {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
        </Trans>
      </div>
    );
  }, [existingOrder, chainId, existingOrders, isLong, isLimitOrdersVisible, onCancelOrderClick]);

  const renderExistingTriggerErrors = useCallback(() => {
    if (decreaseOrdersThatWillBeExecuted?.length < 1) {
      return;
    }
    const existingTriggerOrderLength = decreaseOrdersThatWillBeExecuted.length;
    return (
      <>
        <div className="Confirmation-box-warning">
          <Plural
            value={existingTriggerOrderLength}
            one="You have an active trigger order that might execute immediately after you open this position. Please cancel the order or accept the confirmation to continue."
            other="You have # active trigger orders that might execute immediately after you open this position. Please cancel the orders or accept the confirmation to continue."
          />
        </div>
        <ul className="order-list">
          {decreaseOrdersThatWillBeExecuted.map((order) => {
            const { account, index, type, triggerAboveThreshold, triggerPrice } = order;
            const id = `${account}-${index}`;
            const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
            const indexToken = getToken(chainId, order.indexToken);
            return (
              <li key={id} className="font-sm">
                <p>
                  {type === INCREASE ? t`Increase` : t`Decrease`} {TokenUtils.getSymbol(indexToken)}{" "}
                  {isLong ? t`Long` : t`Short`}
                  &nbsp;{triggerPricePrefix} $
                  {formatAmount(triggerPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
                </p>
                <button
                  onClick={() =>
                    cancelDecreaseOrder(chainId, library, index, proxyTraderContractAddress, {
                      successMsg: t`Order cancelled`,
                      failMsg: t`Cancel failed`,
                      sentMsg: t`Cancel submitted`,
                      pendingTxns,
                      setPendingTxns,
                    })
                  }
                >
                  <Trans>Cancel</Trans>
                </button>
              </li>
            );
          })}
        </ul>
      </>
    );
  }, [
    decreaseOrdersThatWillBeExecuted,
    chainId,
    library,
    pendingTxns,
    setPendingTxns,
    isLong,
    proxyTraderContractAddress,
  ]);

  const renderExistingTriggerWarning = useCallback(() => {
    if (
      existingTriggerOrders.length < 1 ||
      decreaseOrdersThatWillBeExecuted.length > 0 ||
      renderExistingOrderWarning()
    ) {
      return;
    }
    const existingTriggerOrderLength = existingTriggerOrders.length;
    return (
      <div className="Confirmation-box-info">
        <Plural
          value={existingTriggerOrderLength}
          one="You have an active trigger order that could impact this position."
          other="You have # active trigger orders that could impact this position."
        />
      </div>
    );
  }, [existingTriggerOrders, decreaseOrdersThatWillBeExecuted, renderExistingOrderWarning]);

  // TODO: handle unapproved order plugin (very unlikely case)
  const renderMain = useCallback(() => {
    return (
      <div className="Confirmation-box-main">
        <span>
          <Trans>Pay</Trans>&nbsp;{formatAmount(fromAmount, fromToken.decimals, 4, true)}{" "}
          {TokenUtils.getSymbol(fromToken)} ($
          {formatAmount(fromUsdMin, USD_DECIMALS, FIAT_DECIMALS, true)})
        </span>
        <div className="Confirmation-box-main-icon"></div>
        <div>
          {isLong ? t`Long` : t`Short`}&nbsp;
          {formatAmount(toAmount, toToken.decimals, 4, true)} {TokenUtils.getSymbol(toToken)} ($
          {formatAmount(toUsdMax, USD_DECIMALS, FIAT_DECIMALS, true)})
        </div>
      </div>
    );
  }, [fromAmount, fromToken, toToken, fromUsdMin, toUsdMax, isLong, toAmount]);

  const collateralToken = getToken(chainId, collateralTokenAddress);
  const collateralTokenSymbol = TokenUtils.getSymbol(collateralToken);
  const renderAvailableLiquidity = useCallback(() => {
    let availableLiquidity;
    const riskThresholdBps = 5000;
    let isLiquidityRisk;
    const token = isLong ? toTokenInfo : shortCollateralToken;

    if (!token || !token.poolAmount || !token.availableAmount) {
      return null;
    }

    if (isShort) {
      availableLiquidity = token.availableAmount;

      let adjustedMaxGlobalShortSize;

      if (toTokenInfo.maxAvailableShort && toTokenInfo.maxAvailableShort.gt(0)) {
        adjustedMaxGlobalShortSize = toTokenInfo.maxAvailableShort
          .mul(expandDecimals(1, token.decimals))
          .div(expandDecimals(1, USD_DECIMALS));
      }

      if (adjustedMaxGlobalShortSize && adjustedMaxGlobalShortSize.lt(token.availableAmount)) {
        availableLiquidity = adjustedMaxGlobalShortSize;
      }

      const sizeTokens = toUsdMax.mul(expandDecimals(1, token.decimals)).div(token.minPrice);
      isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(sizeTokens);
    } else {
      availableLiquidity = token.availableAmount;

      let adjustedMaxGlobalLongSize;
      if (toTokenInfo.maxAvailableLong && toTokenInfo.maxAvailableLong.gt(0)) {
        adjustedMaxGlobalLongSize = toTokenInfo.maxAvailableLong
          .mul(expandDecimals(1, token.decimals))
          .div(expandDecimals(1, USD_DECIMALS));
      }
      if (adjustedMaxGlobalLongSize && adjustedMaxGlobalLongSize.lt(token.availableAmount)) {
        availableLiquidity = adjustedMaxGlobalLongSize;
      }

      isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(toAmount);
    }

    if (!availableLiquidity) {
      return null;
    }

    return (
      <ExchangeInfoRow label={t`Available Liquidity`}>
        <Tooltip
          renderContent={() =>
            isLiquidityRisk
              ? t`There may not be sufficient liquidity to execute your order when the price conditions are met`
              : t`The order will only execute if the price conditions are met and there is sufficient liquidity`
          }
        >
          <span className={cx([isLiquidityRisk ? "negative" : undefined], "underline")}>
            {formatAmount(availableLiquidity, collateralToken.decimals, 0, true)} {collateralTokenSymbol}
          </span>
        </Tooltip>
      </ExchangeInfoRow>
    );
  }, [
    isLong,
    toTokenInfo,
    shortCollateralToken,
    isShort,
    collateralToken.decimals,
    collateralTokenSymbol,
    toUsdMax,
    toAmount,
  ]);

  const renderMarginSection = useCallback(() => {
    const collateralToken = getToken(chainId, collateralTokenAddress);

    const totalFees = (currentExecutionFeeUsd ?? BigNumber.from(0)).add(positionFeeUsd);

    return (
      <>
        <div>
          {renderMain()}
          {renderCollateralSpreadWarning()}
          {renderExistingOrderWarning()}
          {renderExistingTriggerErrors()}
          {renderExistingTriggerWarning()}
          {minExecutionFeeErrorMessage && <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>}
          {hasPendingProfit && isMarketOrder && (
            <div className="PositionEditor-accept-profit-warning">
              <Checkbox isChecked={isProfitWarningAccepted} setIsChecked={setIsProfitWarningAccepted}>
                <span className="muted">
                  <Trans>Forfeit profit</Trans>
                </span>
              </Checkbox>
            </div>
          )}

          <ExchangeInfoRow label={t`Leverage`}>
            {hasExistingPosition && toAmount && toAmount.gt(0) && (
              <div className="flex items-center justify-center gap-2 muted">
                {formatAmount(existingPosition.leverage, 4, 2)}x
                <BsArrowRight className="transition-arrow" />
              </div>
            )}
            {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
            {!toAmount && leverage && leverage.gt(0) && `-`}
            {leverage && leverage.eq(0) && `-`}
          </ExchangeInfoRow>

          {renderAllowedSlippage(allowedSlippage)}

          {isMarketOrder && (
            <div className="PositionEditor-allow-higher-slippage">
              <Checkbox isChecked={isHigherSlippageAllowed} setIsChecked={setIsHigherSlippageAllowed}>
                <span className="muted font-sm">
                  <Trans>Allow up to 1% slippage</Trans>
                </span>
              </Checkbox>
            </div>
          )}

          {isMarketOrder && (
            <ExchangeInfoRow label={t`Entry Price`} isTop>
              {hasExistingPosition && toAmount && toAmount.gt(0) && (
                <div className="flex items-center justify-center gap-2 muted">
                  ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, toToken?.priceDecimals, true)}
                  <BsArrowRight className="transition-arrow" />
                </div>
              )}
              {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, toToken?.priceDecimals, true)}`}
              {!nextAveragePrice && `-`}
            </ExchangeInfoRow>
          )}

          <ExchangeInfoRow label={t`Liq. Price`} isTop={!isMarketOrder}>
            {hasExistingPosition && toAmount && toAmount.gt(0) && (
              <div className="flex items-center justify-center gap-2 muted">
                ${formatAmount(existingLiquidationPrice, USD_DECIMALS, toToken?.priceDecimals, true)}
                <BsArrowRight className="transition-arrow" />
              </div>
            )}
            {toAmount &&
              displayLiquidationPrice &&
              `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, toToken?.priceDecimals, true)}`}
            {!toAmount && displayLiquidationPrice && `-`}
            {!displayLiquidationPrice && `-`}
          </ExchangeInfoRow>

          {!isMarketOrder && (
            <ExchangeInfoRow label={t`Mark Price`}>
              ${formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)}
            </ExchangeInfoRow>
          )}
          {!isMarketOrder && (
            <ExchangeInfoRow label={t`Limit Price`}>
              ${formatAmount(triggerPriceUsd, USD_DECIMALS, toToken?.priceDecimals, true)}
            </ExchangeInfoRow>
          )}

          <ExchangeInfoRow label={t`Collateral (${TokenUtils.getSymbol(collateralToken)})`} isTop>
            <Tooltip
              renderContent={() => {
                return (
                  <>
                    <Trans>Your position's collateral after deducting fees.</Trans>
                    <br />
                    <br />
                    <StatsTooltipRow
                      label={t`Pay Amount`}
                      value={formatAmount(fromUsdMin, USD_DECIMALS, FIAT_DECIMALS, true)}
                    />
                    <StatsTooltipRow
                      label={t`Fees`}
                      value={formatAmount(positionFeeUsd, USD_DECIMALS, FIAT_DECIMALS, true)}
                    />
                    <div className="Tooltip-divider" />
                    <StatsTooltipRow
                      label={t`Collateral`}
                      value={formatAmount(collateralAfterFees, USD_DECIMALS, FIAT_DECIMALS, true)}
                    />
                  </>
                );
              }}
            >
              <span className="underline">{`$${formatAmount(
                collateralAfterFees,
                USD_DECIMALS,
                FIAT_DECIMALS,
                true
              )}`}</span>
            </Tooltip>
          </ExchangeInfoRow>

          <ExchangeInfoRow label={t`Fees`}>
            <FeesTooltip
              totalFees={totalFees}
              executionFees={{
                fee: currentExecutionFee,
                feeUSD: currentExecutionFeeUsd,
              }}
              positionFee={positionFeeUsd}
            />
          </ExchangeInfoRow>

          {orderOption === OrderOption.Limit && renderAvailableLiquidity()}

          {decreaseOrdersThatWillBeExecuted.length > 0 && (
            <div className="PositionEditor-allow-higher-slippage">
              <Checkbox isChecked={isTriggerWarningAccepted} setIsChecked={setIsTriggerWarningAccepted}>
                <span className="muted font-sm">
                  <Trans>I am aware of the trigger orders</Trans>
                </span>
              </Checkbox>
            </div>
          )}
        </div>
      </>
    );
  }, [
    renderMain,
    nextAveragePrice,
    toAmount,
    toToken,
    hasExistingPosition,
    existingPosition,
    isMarketOrder,
    triggerPriceUsd,
    displayLiquidationPrice,
    existingLiquidationPrice,
    positionFeeUsd,
    leverage,
    chainId,
    hasPendingProfit,
    isProfitWarningAccepted,
    renderAvailableLiquidity,
    orderOption,
    fromUsdMin,
    collateralAfterFees,
    renderExistingOrderWarning,
    renderExistingTriggerWarning,
    renderExistingTriggerErrors,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    isTriggerWarningAccepted,
    decreaseOrdersThatWillBeExecuted,
    minExecutionFeeErrorMessage,
    collateralTokenAddress,
    entryMarkPrice,
    allowedSlippage,
    currentExecutionFeeUsd,
    renderCollateralSpreadWarning,
    currentExecutionFee,
  ]);

  return (
    <div className="Confirmation-box tailwind">
      <Modal
        isVisible={true}
        setIsVisible={() => setIsConfirming(false)}
        label={title}
        allowContentTouchMove
        showHeaderDivider
      >
        {renderMarginSection()}
        <div className="Confirmation-box-row">
          <Button
            variant={isLong ? "green" : "red"}
            onClick={onConfirmationClick}
            className="w-100 mt-[1rem]"
            disabled={!isPrimaryEnabled()}
            size="lg"
          >
            {getPrimaryText()}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

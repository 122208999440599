import Button from "components/Button/Button";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { ErrorDisplayType } from "../constants";
import type { ExchangeError } from ".";

type SwapButtonProps = {
  tooltipMessage: {
    POOL_EXCEEDED: string;
  };
  getExchangeError: () => ExchangeError;
  getPrimaryText: () => string | JSX.Element;
  isLong: boolean;
  handleButton: () => void;
  disabledCondition: () => boolean;
  active: any;
};

export const SwapButton = (props: SwapButtonProps) => {
  const {
    tooltipMessage: ERROR_TOOLTIP_MSG,
    getExchangeError,
    getPrimaryText,
    isLong,
    handleButton,
    disabledCondition,
    active,
  } = props;

  const [errorMessage, errorType, errorCode] = getExchangeError();
  const primaryTextMessage = getPrimaryText();

  if (
    errorType === ErrorDisplayType.Tooltip &&
    errorMessage === primaryTextMessage &&
    errorCode &&
    ERROR_TOOLTIP_MSG[errorCode]
  ) {
    return (
      <Tooltip renderContent={() => ERROR_TOOLTIP_MSG[errorCode]}>
        <Button
          variant={isLong ? "green" : "red"}
          className="w-100"
          onClick={handleButton}
          disabled={!disabledCondition()}
        >
          {primaryTextMessage}
        </Button>
      </Tooltip>
    );
  }

  return (
    <div className="Exchange-swap-button-container mt-[0.5rem]">
      {!active ? (
        <Button
          variant="primary-action"
          className="connect-button"
          onClick={handleButton}
          disabled={!disabledCondition()}
        >
          {primaryTextMessage}
        </Button>
      ) : (
        <Button
          variant={isLong ? "green" : "red"}
          className="w-100"
          onClick={handleButton}
          disabled={!disabledCondition()}
        >
          {primaryTextMessage}
        </Button>
      )}
    </div>
  );
};

import { useQuickMulticall } from "lib/contracts";
import { ContractName } from "config/contracts";

export const useStaking = () => {
  const ebFoxContractName: ContractName = "EbFox";
  const ebFoxTokenMulticallConfig = [
    { contractName: ebFoxContractName, method: "decimals", params: [] },
    { contractName: ebFoxContractName, method: "totalSupply", params: [] },
    { contractName: ebFoxContractName, method: "entryFee", params: [] },
    { contractName: ebFoxContractName, method: "exitFee", params: [] },
  ];

  const foxifyMaxiContractName: ContractName = "FoxifyMaxi";
  const foxifyMaxiTokenMulticallConfig = [
    { contractName: foxifyMaxiContractName, method: "decimals", params: [] },
    { contractName: foxifyMaxiContractName, method: "calculatePrice", params: [] },
    { contractName: foxifyMaxiContractName, method: "totalSupply", params: [] },
  ];

  const { data: ebFoxToken = [] } = useQuickMulticall("ebFoxToken", ebFoxTokenMulticallConfig);
  const { data: foxifyMaxiToken = [] } = useQuickMulticall("foxifyMaxiToken", foxifyMaxiTokenMulticallConfig);

  return { ebFoxToken, foxifyMaxiToken };
};

import { useWeb3React } from "@web3-react/core";
import { AddressDropdown } from "components/Header/AddressDropdown/AddressDropdown";
import { ConnectWalletButton, ConnectWalletButtonMobile } from "components/Common/ConnectWalletButton";
import React, { useCallback, useEffect } from "react";
import connectWalletImg from "img/ic_wallet_24.svg";
import connectWalletMobile from "img/WalletIcon.svg";
import connectWalletMobileInactive from "img/connectWalletInactive.svg";
import { getAccountUrl } from "lib/legacy";
import { Trans } from "@lingui/macro";
import SettingsDropdown from "../SettingsDropdown/SettingsDropdown";
import LanguagePopupHome from "../SettingsDropdown/LanguagePopupHome";
import { Switch } from "components/Switch/Switch";
import { getChainName, MAINNET, TESTNET } from "config/chains";
import { useChainId } from "lib/chains";
import { useFundedTrader } from "hooks/useFundedTrader";
import { isDevelopment } from "config/env";
import { getNetworkIcon } from "config/icons";
import cx from "classnames";
import { switchNetwork } from "lib/wallets/connections";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { ReactComponent as SunIcon } from "img/SunIcon.svg";
import { ReactComponent as MoonIcon } from "img/MoonIcon.svg";
import useThemeSwitcher from "lib/useThemeSwitcher";
import bridgeIconOrange from "img/bridgeIconOrange.svg";

import { THEMES } from "lib/themes";

import { useMedia } from "react-use";
import { useAtom } from "jotai";
import { isBridgeModalVisibleAtom } from "components/Bridge/state";

type Props = {
  openSettings: () => void;
  small?: boolean;
  disconnectAccountAndCloseSettings: () => void;
  savedSlippageAmount: number;
  setSavedSlippageAmount: (value: number) => void;
  savedShouldShowPositionLines: any;
  setSavedShouldShowPositionLines: any;
  tradingLayout: string;
  setTradingLayout: (value: string) => void;
  className?: string;
  isMobileModalVisible: boolean;
  setIsMobileModalVisible: (value: boolean) => void;
};

const NETWORK_OPTIONS = [
  !isDevelopment() && {
    label: getChainName(MAINNET),
    value: MAINNET,
    icon: getNetworkIcon(MAINNET),
    color: "#264f79",
  },
  isDevelopment() && {
    label: getChainName(TESTNET),
    value: TESTNET,
    icon: getNetworkIcon(TESTNET),
    color: "#264f79",
  },
].filter(Boolean);

export function AppHeaderUser({
  openSettings,
  disconnectAccountAndCloseSettings,
  savedSlippageAmount,
  setSavedSlippageAmount,
  savedShouldShowPositionLines,
  setSavedShouldShowPositionLines,
  className,
  tradingLayout,
  setTradingLayout,
  isMobileModalVisible,
  setIsMobileModalVisible,
}: Props) {
  const { rawChainId } = useChainId();
  const { isActive, account, connector } = useWeb3React();
  const showConnectionOptions = true;
  const { setIsWalletModalVisible, connectWallet } = useConnectWalletUi();
  const { isFundedAccountActive, setIsFundedAccountActive, isFundedCreated } = useFundedTrader();

  useEffect(() => {
    if (isActive) {
      setIsWalletModalVisible(false);
    }
  }, [isActive, setIsWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === rawChainId) {
        return;
      }
      return switchNetwork(option.value, connector);
    },
    [rawChainId, connector]
  );

  const rawSelectorLabel = getChainName(rawChainId);

  const { isDarkTheme, isWhiteTheme, setTheme } = useThemeSwitcher();

  const isMobile = useMedia("(max-width: 1100px)");

  const walletButtonFunction = () => {
    if (!isActive || !account) {
      connectWallet();
    } else {
      setIsMobileModalVisible(true);
    }
  };

  const [, setIsBridgeModalVisible] = useAtom(isBridgeModalVisibleAtom);

  if (!isActive || !account) {
    return (
      <div className={cx("", className)}>
        <div className={cx("flex items-center gap-[1rem]")}>
          {isMobile && (
            <>
              <SettingsDropdown
                savedSlippageAmount={savedSlippageAmount}
                networkOptions={NETWORK_OPTIONS}
                selectorLabel={rawSelectorLabel}
                setSavedSlippageAmount={setSavedSlippageAmount}
                onNetworkSelect={onNetworkSelect}
                openSettings={openSettings}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                tradingLayout={tradingLayout}
                setTradingLayout={setTradingLayout}
              />
              <ConnectWalletButtonMobile
                onClick={walletButtonFunction}
                imgSrc={connectWalletMobileInactive}
                isActive={isActive}
              />
            </>
          )}

          {!isMobile && (
            <>
              <ConnectWalletButton onClick={connectWallet} imgSrc={connectWalletImg}>
                <Trans>Connect Wallet</Trans>
              </ConnectWalletButton>
              <SettingsDropdown
                savedSlippageAmount={savedSlippageAmount}
                networkOptions={NETWORK_OPTIONS}
                selectorLabel={rawSelectorLabel}
                setSavedSlippageAmount={setSavedSlippageAmount}
                onNetworkSelect={onNetworkSelect}
                openSettings={openSettings}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                tradingLayout={tradingLayout}
                setTradingLayout={setTradingLayout}
              />

              {isWhiteTheme && (
                <button
                  className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
                  onClick={() => setTheme(THEMES.dark.name)}
                >
                  <MoonIcon className="fill-[#6C7284] w-[1.8rem]" />
                </button>
              )}
              {isDarkTheme && (
                <button
                  className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
                  onClick={() => setTheme(THEMES.light.name)}
                >
                  <SunIcon className="fill-[#6C7284] w-[2rem]" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  const accountUrl = getAccountUrl(rawChainId, account);

  return (
    <div className={cx("flex items-center gap-[1rem]", className)}>
      {showConnectionOptions && (
        <>
          {!isMobile && (
            <>
              <button
                className="rounded-sm px-4 h-[3.6rem] border flex justify-center items-center transition gap-4 hover:bg-[var(--pallete-white-transparent-5)]"
                onClick={() => setIsBridgeModalVisible(true)}
              >
                <img src={bridgeIconOrange} alt="bridgeIcon" />
                <p>Bridge</p>
              </button>

              {isFundedCreated && (
                <div className="rounded-sm px-4 h-[3.6rem] border flex justify-center items-center transition gap-4 hover:bg-[var(--pallete-white-transparent-5)]">
                  <span className="muted">
                    <Trans>Funded</Trans>
                  </span>
                  <Switch
                    checked={isFundedAccountActive as boolean}
                    onChange={() => setIsFundedAccountActive(!isFundedAccountActive)}
                    checkedColor="#f66b31"
                    uncheckedColor="#1D2024"
                  />
                </div>
              )}

              <AddressDropdown
                account={account}
                accountUrl={accountUrl}
                disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              />

              {isWhiteTheme && (
                <button
                  className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
                  onClick={() => setTheme(THEMES.dark.name)}
                >
                  <MoonIcon className="fill-[#6C7284] w-[1.8rem]" />
                </button>
              )}
              {isDarkTheme && (
                <button
                  className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
                  onClick={() => setTheme(THEMES.light.name)}
                >
                  <SunIcon className="fill-[#6C7284] " />
                </button>
              )}

              <SettingsDropdown
                networkOptions={NETWORK_OPTIONS}
                selectorLabel={rawSelectorLabel}
                onNetworkSelect={onNetworkSelect}
                openSettings={openSettings}
                savedSlippageAmount={savedSlippageAmount}
                setSavedSlippageAmount={setSavedSlippageAmount}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                tradingLayout={tradingLayout}
                setTradingLayout={setTradingLayout}
              />
            </>
          )}
          {isMobile && (
            <>
              <SettingsDropdown
                savedSlippageAmount={savedSlippageAmount}
                networkOptions={NETWORK_OPTIONS}
                selectorLabel={rawSelectorLabel}
                setSavedSlippageAmount={setSavedSlippageAmount}
                onNetworkSelect={onNetworkSelect}
                openSettings={openSettings}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
                tradingLayout={tradingLayout}
                setTradingLayout={setTradingLayout}
              />
              <ConnectWalletButtonMobile
                onClick={walletButtonFunction}
                imgSrc={connectWalletMobile}
                isActive={isActive}
              />
            </>
          )}
        </>
      )}
      {!showConnectionOptions && <LanguagePopupHome />}
    </div>
  );
}

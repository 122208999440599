import React, { useState } from "react";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { useMedia } from "react-use";

import { USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import ExchangeTable from "./table/ExchangeTable";
import ExchangeRow from "./table/ExchangeRow";
import ExchangeTokenInfo from "./table/ExchangeTokenInfo";
import EmptyRow from "./table/EmptyRow";
import ClosedPositionsDetailsModal from "./ClosedPositionsDetailsModal";
import { ActionsTableScrollLayout } from "components/Exchange/ActionsTableScrollLayout";
import { getTokenInfo } from "../../domain/tokens";
import { getAddress } from "ethers/lib/utils";

export default function ClosedPositionsList(props) {
  const { infoTokens, connectWallet, positions, className } = props;
  const isMobile = useMedia("(max-width: 1100px)");
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [pickedPosition, setPickedPosition] = useState();

  const pickPosition = (position) => {
    setPickedPosition(position);
    setDetailsOpen(true);
  };

  const renderHead = () => {
    return (
      <tr className={cx("Exchange-list-header", { "Exchange-list-header-portfolio": !props.isExchange })}>
        <th className="Start">
          <Trans>Position</Trans>
        </th>

        <th className="Exchange-list-header-side">
          <Trans>Side</Trans>
        </th>
        {!isMobile && (
          <>
            <th>
              <Trans>Size</Trans>
            </th>
            <th>
              <Trans>Closing Price</Trans>
            </th>
            <th>
              <Trans>Entry Price</Trans>
            </th>
          </>
        )}
        <th className="End">
          <Trans>PnL</Trans>
        </th>
      </tr>
    );
  };

  return (
    <div className={cx(className || "ClosedPositionsList")}>
      <ActionsTableScrollLayout>
        {({ tableClassName }) => (
          <ExchangeTable className={tableClassName}>
            {renderHead()}
            <EmptyRow connectWallet={connectWallet} msg="You have no closed positions." items={positions} />
            {positions.map((item) => {
              const indexToken = getTokenInfo(infoTokens, getAddress(item.token));
              return (
                <ExchangeRow key={`${item.isLong}-${item.id}`} onClick={() => pickPosition(item)}>
                  <td className="Exchange-list-item-type" key={item.id}>
                    <ExchangeTokenInfo infoTokens={infoTokens} token={item.token} leverage={item.leverage} />
                  </td>
                  <td className="Exchange-list-item-type-Side Exchange-list-item-side-positionlist">
                    <div>
                      <span className={cx({ long: item.isLong, short: !item.isLong })}>
                        {item.isLong ? t`Long` : t`Short`}
                      </span>
                    </div>
                  </td>
                  {!isMobile && (
                    <>
                      <td className="Exchange-list-item-type">${formatAmount(item.size, USD_DECIMALS, 4, true)}</td>
                      <td>${formatAmount(item.exitPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}</td>
                      <td>${formatAmount(item.entryPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}</td>
                    </>
                  )}
                  <td className="Exchange-position-end flex items-center justify-right">
                    <span className={item.realisedPnl.gt(0) ? "positive" : "negative"}>
                      ${formatAmount(item.realisedPnl, USD_DECIMALS, 2, true)}
                    </span>
                  </td>
                </ExchangeRow>
              );
            })}
          </ExchangeTable>
        )}
      </ActionsTableScrollLayout>

      {isMobile && (
        <ClosedPositionsDetailsModal
          open={detailsOpen}
          setIsVisible={setDetailsOpen}
          position={pickedPosition}
          infoTokens={infoTokens}
        />
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import cx from "classnames";

type Props = {
  countdownEndTimestamp: Date | number;
  isFunded?: boolean;
  stopped?: boolean;
};

export const Countdown = (props: Props) => {
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const calculateCountdown = () => {
      const currentTimeSeconds = Math.floor(Date.now() / 1000); // Current time in seconds
      const countdownEndSeconds = Math.floor((props.countdownEndTimestamp as number) / 1000); // Countdown end time in seconds
      const remainingSeconds = Math.max(0, countdownEndSeconds - currentTimeSeconds); // Remaining seconds until countdown ends

      const days = Math.floor(remainingSeconds / (24 * 3600)); // Calculate remaining days
      const hours = Math.floor((remainingSeconds % (24 * 3600)) / 3600); // Calculate remaining hours
      const minutes = Math.floor((remainingSeconds % 3600) / 60); // Calculate remaining minutes
      const seconds = remainingSeconds % 60; // Calculate remaining seconds

      setCountdown({ days, hours, minutes, seconds });
    };

    calculateCountdown();

    const interval = setInterval(calculateCountdown, 1000);

    return () => clearInterval(interval);
  }, [props.countdownEndTimestamp]);

  return (
    <>
      {
        <div className="flex flex-row w-full gap-5 items-center justify-center font-mazzard">
          {countdown.days > 0 && (
            <>
              <div className="flex flex-col items-center">
                <p className={cx([props.isFunded ? "text-[2.4rem] font-medium text-white" : "text-[4rem] font-bold"])}>{countdown.days}</p>
                <p className={cx(["leading-[1.5] font-regular font-body", props.isFunded ? "text-[1.2rem]" : "text-[1.4rem]"])}>Days</p>
              </div>
              <div className={cx(["leading-[1.4rem]", props.isFunded ? "text-[1.2rem]" : "text-[2.4rem]"])}>:</div>
            </>
          )}
          <div className="flex flex-col items-center">
            <p className={cx([props.isFunded ? "text-[2.4rem] font-medium text-white" : "text-[4rem] font-bold"])}>{countdown.hours}</p>
            <p className={cx(["leading-[1.5] font-regular font-body", props.isFunded ? "text-[1.2rem]" : "text-[1.4rem]"])}>Hours</p>
          </div>
          <div className={cx(["leading-[1.4rem]", props.isFunded ? "text-[1.2rem] " : "text-[2.4rem]"])}>:</div>
          <div className="flex flex-col items-center">
            <p className={cx([props.isFunded ? "text-[2.4rem] font-medium text-white" : "text-[4rem] font-bold"])}>{countdown.minutes}</p>
            <p className={cx(["leading-[1.5] font-regular font-body", props.isFunded ? "text-[1.2rem]" : "text-[1.4rem]"])}>Minutes</p>
          </div>
          <div className={cx(["leading-[1.4rem]", props.isFunded ? "text-[1.2rem]" : "text-[2.4rem]"])}>:</div>

          <div className="flex flex-col items-center">
            <p className={cx([props.isFunded ? "text-[2.4rem] font-medium text-white" : "text-[4rem] font-bold"])}>{countdown.seconds}</p>
            <p className={cx(["leading-[1.5] font-regular font-body", props.isFunded ? "text-[1.2rem]" : "text-[1.4rem]"])}>Seconds</p>
          </div>
        </div>
      }
    </>
  );
};

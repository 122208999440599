import { Provider } from "@ethersproject/providers";
import { BigNumber, Contract } from "ethers";
import { GAS_PRICE_ADJUSTMENT_MAP, MAX_GAS_PRICE_MAP } from "config/chains";
import { bigNumberify } from "../numbers";
import { BASIS_POINTS_DIVISOR } from "../constants";

export async function setGasPrice(txnOpts: any, provider: Provider, chainId: number, gasPreference?: string) {
  let maxGasPrice = MAX_GAS_PRICE_MAP[chainId];
  const adjustment = GAS_PRICE_ADJUSTMENT_MAP[chainId][gasPreference] || bigNumberify(0);
  const gasPrice = await provider.getGasPrice();

  if (maxGasPrice) {
    if (gasPrice.gt(maxGasPrice)) {
      maxGasPrice = gasPrice;
    }

    const feeData = await provider.getFeeData();

    // the wallet provider might not return maxPriorityFeePerGas in feeData
    // in which case we should fallback to the usual getGasPrice flow handled below
    if (feeData && feeData.maxPriorityFeePerGas) {
      txnOpts.maxFeePerGas = maxGasPrice;
      const premium = feeData.maxPriorityFeePerGas.mul(adjustment).div(BASIS_POINTS_DIVISOR);
      txnOpts.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas.add(premium);
      return;
    }
  }

  txnOpts.gasPrice = gasPrice.add(gasPrice.mul(adjustment).div(BASIS_POINTS_DIVISOR));
  return;
}

const MIN_GAS_LIMIT = BigNumber.from(50000);

export async function getGasLimit<TContract extends Contract, TMethod extends keyof TContract>(
  contract: TContract,
  method: TMethod,
  params: Parameters<TContract[TMethod]>,
  txnOpts: { value?: BigNumber } = {}
) {
  if (!txnOpts.value) {
    txnOpts.value = BigNumber.from(0);
  }

  let gasLimit = await contract.estimateGas[method as string](...params, txnOpts);

  if (gasLimit.lt(MIN_GAS_LIMIT)) {
    gasLimit = MIN_GAS_LIMIT;
  }

  return gasLimit.mul(11000).div(10000); // add a 10% buffer
}

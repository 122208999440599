import React, { useState, useCallback } from "react";
import { t, Trans } from "@lingui/macro";
import cx from "classnames";
import { INCREASE, DECREASE, USD_DECIMALS, getOrderError, getPositionForOrder } from "lib/legacy";
import Checkbox from "../Checkbox/Checkbox";
import { handleCancelOrder } from "domain/legacy";
import { Tooltip } from "components/TooltipV2/Tooltip";
import OrderEditor from "./OrderEditor";
import { useMedia } from "react-use";

import "./OrdersList.css";

import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { formatAmount } from "lib/numbers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import EmptyRow from "./table/EmptyRow";
import OrderDetailsModal from "./OrderDetailsModal";
import { ActionsTableScrollLayout } from "components/Exchange/ActionsTableScrollLayout";
import { TokenUtils } from "components/TokenUtils";
import { useFundedTrader } from "../../hooks/useFundedTrader";

function getOrderTitle(order) {
  const sizeDeltaText = formatAmount(order.sizeDelta, USD_DECIMALS, 2, true);
  return `$${sizeDeltaText}`;
}

export default function OrdersList(props) {
  const {
    account,
    library,
    setPendingTxns,
    pendingTxns,
    infoTokens,
    positionsMap,
    orders,
    hideActions,
    chainId,
    savedShouldDisableValidationForTesting,
    cancelOrderIdList,
    setCancelOrderIdList,
    active,
    isExchange,
    refetchBalance,
  } = props;

  const [editingOrder, setEditingOrder] = useState(null);
  const isMobile = useMedia("(max-width: 1100px)");
  const [pickedOrder, setPickedOrder] = useState();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { proxyTraderContractAddress } = useFundedTrader();

  const pickOrder = (order) => {
    setPickedOrder(order);
    setDetailsOpen(true);
  };

  const onCancelClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, proxyTraderContractAddress, {
        pendingTxns,
        setPendingTxns,
        txnSuccessCallback: refetchBalance,
      });
    },
    [library, pendingTxns, setPendingTxns, chainId, proxyTraderContractAddress, refetchBalance]
  );

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order);
    },
    [setEditingOrder]
  );

  const renderHead = useCallback(() => {
    return (
      <tr className={cx("Exchange-list-header", { "Exchange-list-header-portfolio": !isExchange })}>
        <th className={!hideActions && orders.length > 0 ? "Start" : "Start"}>
          <div>
            <Trans>Position</Trans>
          </div>
        </th>
        <th className="Exchange-list-header-side">
          <div>
            <Trans>Side</Trans>
          </div>
        </th>
        <th className={cx({ End: isMobile })}>
          <div>
            <Trans>Order Type</Trans>
          </div>
        </th>
        {!isMobile && (
          <>
            <th>
              <div>
                <Trans>Size</Trans>
              </div>
            </th>
            <th>
              <div>
                <Trans>Trigger Price</Trans>
              </div>
            </th>
            <th>
              <div>
                <Trans>Mark Price</Trans>
              </div>
            </th>

            <th className="End"></th>
          </>
        )}
      </tr>
    );
  }, [orders, hideActions, isMobile, isExchange]);

  const renderEmptyRow = useCallback(() => {
    const connectWallet = () => {
      if (!active) {
        props.connectWallet();
        return;
      }
    };
    return <EmptyRow connectWallet={connectWallet} msg={t`You don't have any orders.`} items={orders} />;
  }, [orders, active, props]);

  const renderActions = useCallback(
    (order) => {
      return (
        <>
          <td className="">
            <div className="Exchange-list-buttons">
              <button
                className="Exchange-list-action bg-background-6 dark:bg-background-3"
                onClick={(e) => {
                  e.stopPropagation();
                  onEditClick(order);
                }}
              >
                <Trans>Edit</Trans>
              </button>

              <button
                className="Exchange-list-action bg-background-6 dark:bg-background-3"
                onClick={(e) => {
                  e.stopPropagation();
                  onCancelClick(order);
                }}
              >
                <Trans>Cancel</Trans>
              </button>
            </div>
          </td>
        </>
      );
    },
    [onEditClick, onCancelClick]
  );

  const renderList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }

    return orders.map((order) => {
      const indexToken = getTokenInfo(infoTokens, order.indexToken);

      // Longs Increase: max price
      // Longs Decrease: min price
      // Short Increase: min price
      // Short Decrease: max price
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);

      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const orderId = `${order.type}-${order.index}`;

      const error = (getOrderError as any)(account, order, positionsMap);
      const orderTitle = getOrderTitle(order);

      const orderText = (
        <>
          {error ? (
            <Tooltip
              className="order-error"
              handle={orderTitle}
              handleClassName="plain"
              renderContent={() => <span className="negative">{error}</span>}
            />
          ) : (
            orderTitle
          )}
        </>
      );

      return (
        <tr className="Exchange-list-body" key={`${order.isLong}-${order.type}-${order.index}`}>
          <td className="clickable Exchange-list-item-type" key={`${order.type}-${order.index}`}>
            <div className="Exchange-list-image-and-title">
              {!hideActions && !isMobile && (
                <div>
                  <Checkbox
                    isChecked={cancelOrderIdList?.includes(orderId)}
                    setIsChecked={() => {
                      setCancelOrderIdList((prevState) => {
                        if (prevState.includes(orderId)) {
                          return prevState.filter((i) => i !== orderId);
                        } else {
                          return prevState.concat(orderId);
                        }
                      });
                    }}
                  />
                </div>
              )}
              <TokenUtils.Logo token={indexToken} className="w-[3rem] mr-[.5rem]"></TokenUtils.Logo>

              <div className="Exchange-list-leverage" onClick={() => pickOrder(order)}>
                <div className="Exchange-list-title">
                  <TokenUtils.Symbol token={indexToken}></TokenUtils.Symbol>
                </div>

                <div className="Exchange-list-info-label" onClick={() => pickOrder(order)}>
                  {order.leverage && (
                    <span className="muted">{"Leverage " + formatAmount(order.leverage, 4, 2, true)}x&nbsp;</span>
                  )}
                </div>
              </div>
            </div>
          </td>
          <td
            className="Exchange-list-item-type-Side Exchange-list-item-side-positionlist"
            onClick={() => pickOrder(order)}
          >
            <div>
              <span className={cx({ long: order.isLong, short: !order.isLong })}>
                {order.isLong ? t`Long` : t`Short`}
              </span>
            </div>
          </td>
          <td className={cx("Exchange-list-item-type", { End: isMobile })} onClick={() => pickOrder(order)}>
            {order.type === INCREASE ? t`Limit` : t`Trigger`}
          </td>
          {!isMobile && (
            <>
              <td onClick={() => pickOrder(order)}>
                {order.type === DECREASE ? (
                  orderText
                ) : (
                  <Tooltip
                    handle={orderText}
                    renderContent={() => {
                      const collateralTokenInfo = getTokenInfo(infoTokens, order.collateralToken);
                      const collateralUSD = getUsd(order.tokenAmount, order.collateralToken, false, infoTokens);
                      return (
                        <StatsTooltipRow
                          label={t`Collateral`}
                          value={`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (${formatAmount(
                            order.tokenAmount,
                            collateralTokenInfo.decimals,
                            4,
                            true
                          )}
                        ${TokenUtils.getSymbol(collateralTokenInfo)})`}
                        />
                      );
                    }}
                  />
                )}
              </td>
              <td onClick={() => pickOrder(order)}>
                {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
              </td>
              <td onClick={() => pickOrder(order)}>
                <Tooltip
                  handle={formatAmount(markPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
                  renderContent={() => {
                    return (
                      <Trans>
                        <p>
                          The price that orders can be executed at may differ slightly from the chart price, as market
                          orders update oracle prices, while limit/trigger orders do not.
                        </p>
                        <p>
                          This can also cause limit/triggers to not be executed if the price is not reached for long
                          enough. <ExternalLink href="https://docs.foxify.trade/">Read more</ExternalLink>.
                        </p>
                      </Trans>
                    );
                  }}
                />
              </td>
              {!hideActions && renderActions(order)}
            </>
          )}
        </tr>
      );
    });
  }, [
    orders,
    renderActions,
    infoTokens,
    positionsMap,
    cancelOrderIdList,
    setCancelOrderIdList,
    hideActions,
    account,
    isMobile,
  ]);

  return (
    <React.Fragment>
      <div className={cx("tailwind", { OrdersList: isExchange })}>
        <ActionsTableScrollLayout>
          {({ tableClassName }) => (
            <table className={cx(tableClassName, "Exchange-list Orders App-box")}>
              <tbody>
                {renderHead()}
                {renderEmptyRow()}
                {renderList()}
              </tbody>
            </table>
          )}
        </ActionsTableScrollLayout>

        <OrderDetailsModal
          open={detailsOpen}
          order={pickedOrder}
          setIsVisible={setDetailsOpen}
          infoTokens={infoTokens}
          account={account}
          positionsMap={positionsMap}
          getOrderTitle={getOrderTitle}
          onEditClick={onEditClick}
          onCancelClick={onCancelClick}
        />

        {editingOrder && (
          <OrderEditor
            account={account}
            order={editingOrder}
            setEditingOrder={setEditingOrder}
            infoTokens={infoTokens}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            getPositionForOrder={getPositionForOrder}
            positionsMap={positionsMap}
            library={library}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
          />
        )}
      </div>
    </React.Fragment>
  );
}

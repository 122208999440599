import { useChainId } from "lib/chains";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";
import { useTokenContract } from "hooks/blockchain/contracts/useTokenContract";
import { BigNumber } from "ethers";
import React from "react";

export const useTokenBalance = (tokenAddress: string, holderAddress: string | undefined) => {
  const { chainId } = useChainId();
  const { isActive, account } = useWeb3React();
  const tokenContract = useTokenContract(tokenAddress);

  const fetcherKey = React.useMemo(() => {
    return account && holderAddress ? [`Token:allowance:${isActive}`, chainId, tokenAddress, holderAddress] : null;
  }, [account, isActive, chainId, tokenAddress, holderAddress]);

  const { data, mutate: refetchBalance } = useSWR<{ balance: BigNumber }>(
    fetcherKey,
    async ([_key, _chainId, _tokenAddress, _holderAddress]: [
      key: string,
      chainId: number,
      tokenAddress: string,
      holderAddress: string
    ]) => {
      if (holderAddress) {
        const [balance] = await Promise.all([tokenContract.balanceOf(holderAddress)]);

        return {
          balance,
        };
      }

      return { balance: BigNumber.from(0) };
    }
  );

  const balance = React.useMemo(() => {
    return data?.balance;
  }, [data]);

  return {
    balance,
    refetchBalance,
  };
};

import React from "react";
import { atom, useAtom } from "jotai";
import { useWeb3React } from "@web3-react/core";

const connectWalletUiAtom = atom(false);

export const useConnectWalletUi = () => {
  const { isActive } = useWeb3React();
  const [isWalletModalVisible, setIsWalletModalVisible] = useAtom(connectWalletUiAtom);

  const connectWallet = React.useCallback(() => {
    setIsWalletModalVisible(true);
  }, [setIsWalletModalVisible]);

  const isNotConnected = React.useMemo(() => {
    return !isActive;
  }, [isActive]);

  return {
    isWalletModalVisible,
    setIsWalletModalVisible,
    connectWallet,
    isNotConnected,
  };
};

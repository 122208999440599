import { formatAmount } from "lib/numbers";
import React from "react";
import { getAddress } from "ethers/lib/utils";
import { getTokenInfo, InfoTokens } from "domain/tokens";
import { BigNumberish } from "ethers";
import { TokenUtils } from "components/TokenUtils";

type Props = React.ComponentProps<"div"> & {
  infoTokens: InfoTokens;
  token: string;
  leverage?: BigNumberish | null;
};

const ExchangeTokenInfo = (props: Props) => {
  const indexToken = getTokenInfo(props.infoTokens, getAddress(props.token));

  return (
    <div className="Exchange-list-image-and-title">
      <TokenUtils.Logo token={indexToken} className="w-[3rem] mr-[.5rem]"></TokenUtils.Logo>

      <div className="Exchange-list-leverage">
        <div className="Exchange-list-title">
          <TokenUtils.Symbol token={indexToken}></TokenUtils.Symbol>
        </div>

        <div className="Exchange-list-info-label">
          {props.leverage ? (
            <span className="muted">{"Lvrg. " + formatAmount(props.leverage, 4, 2, true)}x&nbsp;</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ExchangeTokenInfo;

import { useWeb3React } from "@web3-react/core";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "config/localStorage";
import { SUPPORTED_CHAIN_IDS, DEFAULT_CHAIN_ID } from "config/chains";
import { ChainId } from "config/tokens";
import { useMemo } from "react";

export function useChainId() {
  const { chainId: walletChainId } = useWeb3React();

  return useMemo(() => {
    let chainId = walletChainId;
    if (!walletChainId) {
      const chainIdFromLocalStorage = localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY);
      if (chainIdFromLocalStorage) {
        chainId = parseInt(chainIdFromLocalStorage);
        if (!chainId) {
          // localstorage value is invalid
          localStorage.removeItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY);
        }
      }
    }

    const rawChainId = chainId;

    if (!chainId || !SUPPORTED_CHAIN_IDS.includes(chainId)) {
      chainId = DEFAULT_CHAIN_ID;
    }

    return { rawChainId, chainId: chainId as ChainId };
  }, [walletChainId]);
}

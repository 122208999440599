import ArrowUp from "img/ArrowUp.svg";
import { Trans } from "@lingui/macro";

type SwapLeverageProps = {
  leverageOption: string;
  setIsLeverageModalVisible: (value: boolean) => void;
  isSummaryOpen: boolean;
};

export const SwapLeverage = (props: SwapLeverageProps) => {
  const { leverageOption, setIsLeverageModalVisible, isSummaryOpen } = props;
  return (
    <div className="Exchange-swap-leverage">
      <button className="Exchange-swap-leverage-button" onClick={() => setIsLeverageModalVisible(true)}>
        <span>
          <Trans>Leverage</Trans> {leverageOption}x
        </span>
        <img
          src={ArrowUp}
          alt="arrow"
          style={{
            transform: isSummaryOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </button>
    </div>
  );
};

import React from "react";
import { Tab } from "@headlessui/react";
import cx from "classnames";
import { useLocalStorage } from "react-use";

type PageTabsPanelProps<TTabId extends string> = {
  tabId: TTabId;
  children: React.ReactNode;
};

export const PageTabPanel = <TTabId extends string>(props: PageTabsPanelProps<TTabId>) => {
  return <Tab.Panel id={props.tabId}>{props.children}</Tab.Panel>;
};

type PageTabsProps<TTabsId extends string> = {
  tabsDetails: Record<TTabsId, { label: string }>;
  tabsIds: TTabsId[];
  children: React.ReactNode;
  storageKey?: string;
  classNames?: string;
};

export const PageTabs = <TTabsId extends string>(props: PageTabsProps<TTabsId>) => {
  const [tabIndex, setTabIndex] = useLocalStorage<number | undefined>(props.storageKey || "");

  return (
    <div className="w-full flex flex-col items-stretch ">
      <Tab.Group
        defaultIndex={tabIndex || 0}
        onChange={(index) => {
          setTabIndex(index);
        }}
      >
        <Tab.List
          className={cx(
            "flex rounded-sm dark:bg-white/[.05] bg-black/[.05] p-2",
            props.classNames && props.classNames
          )}
        >
          {props.tabsIds.map((id) => (
            <Tab
              key={id}
              className={({ selected }) =>
                cx("text-[1.4rem] leading-[2.1rem] h-[4rem] px-12 rounded-sm", selected ? "bg-white text-black" : "")
              }
            >
              {props.tabsDetails[id].label}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-8">{props.children}</Tab.Panels>
      </Tab.Group>
    </div>
  );
};

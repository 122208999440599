import React, { useState } from "react";
import cx from "classnames";
import "./Sidebar.css";
import { ReactComponent as OverviewIcon } from "img/Overview.svg";
import { ReactComponent as PositionIcon } from "img/Position.svg";
import { ReactComponent as OrderIcon } from "img/Orders.svg";
import { ReactComponent as HistoryIcon } from "img/History.svg";
import { ReactComponent as Collapseicon } from "img/Collapse.svg";

const Sidebar = ({ setActivePage, activePage }) => {
  const handleSetActivePage = (page) => {
    setActivePage(page);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div
      className={cx(
        "tailwind flex flex-col w-full items-center place-content-evenly md:items-start justify-between md:justify-start md:w-[22rem] bg-background-5-v2-only p-[0.5rem] md:p-[1rem] border-t md:border-t-0 bottom-0 md:relative min-h-[5rem] md:h-[calc(100vh-6.2rem)] fixed border-r z-10 transition-effect",
        { "md:w-[6.5rem]": isCollapsed }
      )}
    >
      <div className="flex relative my-[1rem] pl-[0.5rem] items-center justify-start w-full transition-effect hidden md:flex">
        <div className={cx("muted md:flex hidden h-[2.1rem]")}>
          <span
            className={cx("", {
              "opacity-0 transition-opacity duration-300": isCollapsed,
              "opacity-100 transition-opacity duration-300": !isCollapsed,
            })}
          >
            Navigation
          </span>
        </div>

        <div
          className=" w-[3rem] h-[3rem] bg-background-5-v2-only absolute flex items-center justify-center rounded-[0.8rem] border border-border cursor-pointer hover:bg-background-3 transition-effect right-[-2.5rem] hover:bg-background-3 hover:text-textColor"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Collapseicon className={cx("transition-effect", { "transform rotate-180": isCollapsed })} />
        </div>
      </div>
      <div className="flex md:flex-col flex-row w-full text-inactive justify-evenly gap-[0.5rem] ">
        <div
          className={cx(
            "flex flex-col text-inactive md:flex-row h-[5rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm hover:bg-background-3 hover:text-textColor",
            {
              "bg-background-3 text-textColor": activePage === "overview",
            }
          )}
          onClick={() => handleSetActivePage("overview")}
        >
          <div className="flex">
            <OverviewIcon className={activePage === "overview" ? "stroke-[textColor]" : "stroke-[#B3B3B3]"} />
          </div>

          <span
            className={cx("hover:bg-background-3 hover:stroke-[#B3B3B3]", {
              "opacity-0 transition-opacity duration-300": isCollapsed,
              "opacity-100 transition-opacity duration-300": !isCollapsed,
            })}
          >
            Overview
          </span>
        </div>
        <div
          className={cx(
            "flex flex-col text-inactive md:flex-row h-[5rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm hover:bg-background-3 hover:text-textColor",
            {
              "bg-background-3 text-textColor": activePage === "position",
            }
          )}
          onClick={() => handleSetActivePage("position")}
        >
          <div className="flex">
            <PositionIcon className={activePage === "position" ? "stroke-[textColor]" : "stroke-[#B3B3B3]"} />
          </div>
          <span
            className={cx("hover:bg-background-3 hover:stroke-[#B3B3B3]", {
              "opacity-0 transition-opacity duration-300": isCollapsed,
              "opacity-100 transition-opacity duration-300": !isCollapsed,
            })}
          >
            Position
          </span>
        </div>
        <div
          className={cx(
            "flex flex-col text-inactive md:flex-row h-[5rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm hover:bg-background-3 hover:text-textColor",
            {
              "bg-background-3 text-textColor": activePage === "orders",
            }
          )}
          onClick={() => handleSetActivePage("orders")}
        >
          <div className="flex">
            <OrderIcon className={activePage === "orders" ? "stroke-[textColor]" : "stroke-[#B3B3B3]"} />
          </div>
          <span
            className={cx("hover:bg-background-3 hover:stroke-[#B3B3B3]", {
              "opacity-0 transition-opacity duration-300": isCollapsed,
              "opacity-100 transition-opacity duration-300": !isCollapsed,
            })}
          >
            Orders
          </span>
        </div>
        <div
          className={cx(
            "flex flex-col text-inactive md:flex-row h-[5rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm hover:bg-background-3 hover:text-textColor",
            {
              "bg-background-3 text-textColor": activePage === "history",
            }
          )}
          onClick={() => handleSetActivePage("history")}
        >
          <div className="flex">
            <HistoryIcon
              className={cx("ml-[0.2rem] hover:bg-background-3 ", {
                "stroke-[textColor]": activePage === "history",
                "stroke-[#B3B3B3]": activePage !== "history",
              })}
            />
          </div>
          <span
            className={cx("", {
              "opacity-0 transition-opacity duration-300": isCollapsed,
              "opacity-100 transition-opacity duration-300": !isCollapsed,
            })}
          >
            History
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

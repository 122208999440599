import cx from "classnames";
import { Trans } from "@lingui/macro";
import { CreateFundedModal } from "./CreateFundedModal";
import { useConnectWalletUi } from "lib/useConnectWalletUi";

type FundedTradingStartProps = {
  setPendingTxns: () => void;
  pendingTxns: string;
  isActive: boolean;
  classNames?: string;
  isCreateFundModalOpen: boolean;
  isWhitelisted: boolean;
  setIsCreateFundModalOpen: (value: boolean) => void;
};

const FundedTradingStart = ({
  setPendingTxns,
  pendingTxns,
  isActive,
  classNames,
  isCreateFundModalOpen,
  isWhitelisted,
  setIsCreateFundModalOpen,
}: FundedTradingStartProps) => {
  const { connectWallet } = useConnectWalletUi();

  const handleBtnClick = () => {
    if (!isActive) {
      connectWallet();
    } else {
      setIsCreateFundModalOpen(true);
    }
  };

  const handleRenderButtonText = () => {
    if (!isWhitelisted) {
      return <Trans>Private beta users only</Trans>;
    }

    return <Trans>{!isActive ? "Connect wallet" : "Create & Fund"}</Trans>;
  };

  return (
    <>
      <div
        className={cx([
          "bg-background-5-v2-only rounded-[2.5rem] border py-[2.3rem] px-[2.7rem] w-full flex flex-col lg:flex-row gap-[4.4rem] items-center justify-between mt-[2rem] z-10",
          classNames,
        ])}
      >
        <div>
          <p className="text-[2.4rem]">
            <Trans>Start Your Challenge Now</Trans>
          </p>
          <p className="text-inactive text-[1.4rem]">
            {isActive ? (
              <Trans>You need to create and fund your account.</Trans>
            ) : (
              <Trans>Please, connect your wallet.</Trans>
            )}
          </p>
        </div>
        <button
          className="inline-block text-main px-[2rem] py-[1rem] rounded-[1.5rem] Create-funded-btn"
          onClick={handleBtnClick}
          disabled={!isWhitelisted}
        >
          {handleRenderButtonText()}
        </button>
      </div>

      {isCreateFundModalOpen && (
        <CreateFundedModal
          open={isCreateFundModalOpen}
          setOpen={setIsCreateFundModalOpen}
          label="Create & Fund Account"
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
        />
      )}
    </>
  );
};

export default FundedTradingStart;

import { useState } from "react";
import { Trans, t } from "@lingui/macro";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import Button from "components/Button/Button";
import { callContract } from "lib/contracts";
import { useChainId } from "lib/chains";
import { usePendingTxns } from "domain/transactions/usePendingTxns";

import Cashout from "./Tabs/Challenge/Cashout";
import { BigNumber, Contract } from "ethers";

export default function CashoutModal({
  open,
  cashout,
  isLoading,
  closeModal,
  traderContract,
  refetchChallengeStats,
}: {
  open: boolean;
  cashout: BigNumber;
  isLoading: boolean;
  traderContract: Contract;
  fundedBalance: BigNumber;
  closeModal: () => void;
  refetchChallengeStats: () => void;
}) {
  const { chainId } = useChainId();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPendingTxns] = usePendingTxns();
  const [isCashingout, setIsCashingout] = useState(false);

  const handleClickCashout = async () => {
    try {
      if (traderContract) {
        setIsCashingout(true);
        await callContract(chainId, traderContract, "withdraw", [cashout], {
          sentMsg: t`Cashout submitted.`,
          failMsg: t`Cashout failed.`,
          successMsg: t`Cashout successfully.`,
          setPendingTxns,
          txnSuccessCallback: () => {
            refetchChallengeStats();
            setIsCashingout(false);
            closeModal();
          },
        });
      }
    } catch (error) {
      setIsCashingout(false);
    }
  };

  return (
    <ModalWithPortal className="tailwind" isVisible={open} setIsVisible={closeModal} label="Cashout">
      <div className="text-inactive text-[1.4rem] mb-[1.8rem]">
        <Trans>
          You can cash out your balance.
          {/*  Reminder - You can roll your balance in to your next challenge to increase your
          trading balance.{" "}
          <span className="text-main cursor-pointer" onClick={onRollover}>
            Rollover now
          </span> */}
          .
        </Trans>
      </div>

      <Cashout cashout={cashout} isLoading={isLoading} label="Cashout" />
      <Button
        variant="green"
        onClick={handleClickCashout}
        className="w-full rounded !text-black mt-6"
        disabled={isCashingout}
      >
        <Trans>Cashout</Trans>
      </Button>
    </ModalWithPortal>
  );
}

import { t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import React from "react";
import TextInput from "pages/Referrals/components/TextInput";
import { useWeb3React } from "@web3-react/core";
import { useDebounce } from "lib/useDebounce";
import { helperToast } from "lib/helperToast";
import { registerReferralCode } from "domain/referrals";
import { useReferralCodeTakenStatus } from "pages/Referrals/hooks/useReferralCodeTakenStatus";
import { getCodeError, getReferralCodeTakenStatus } from "pages/Referrals/referralsHelper";

type Props = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  callAfterSuccess?: () => void;
  setPendingTxns?: any;
  pendingTxns?: any;
};

export function CreateReferralCodeModal(props: Props) {
  const [referralCode, setReferralCode] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [error, setError] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const debouncedReferralCode = useDebounce(referralCode, 300);
  const { account, chainId, provider } = useWeb3React();

  React.useEffect(() => {
    setError(getCodeError(referralCode));
  }, [referralCode]);

  const referralCodeCheckStatus = useReferralCodeTakenStatus(account, debouncedReferralCode, error, chainId);

  const buttonError = React.useMemo(() => {
    if (!debouncedReferralCode) {
      return t`Create Referral Code`;
    }
    if (referralCodeCheckStatus === "taken") {
      return t`Code is already taken`;
    }
    if (referralCodeCheckStatus === "checking") {
      return t`Checking code...`;
    }

    return false;
  }, [debouncedReferralCode, referralCodeCheckStatus]);

  const primaryText = React.useMemo(() => {
    if (buttonError) {
      return buttonError;
    }

    if (isProcessing) {
      return t`Creating...`;
    }

    return t`Create`;
  }, [buttonError, isProcessing]);

  const isPrimaryEnabled = React.useMemo(() => {
    if (buttonError) {
      return false;
    }
    if (error || isProcessing) {
      return false;
    }
    return true;
  }, [buttonError, error, isProcessing]);

  const handleSubmit = async () => {
    setIsProcessing(true);
    const { status: takenStatus } = await getReferralCodeTakenStatus(account, referralCode, chainId);
    if (["current"].includes(takenStatus)) {
      setIsProcessing(false);
    }

    if (takenStatus === "none") {
      try {
        const tx = await registerReferralCode(chainId, referralCode, provider, {
          sentMsg: t`Referral code submitted!`,
          failMsg: t`Referral code creation failed.`,
          pendingTxns: props.pendingTxns,
        });

        const receipt = await tx.wait();
        if (receipt.status === 1) {
          if (typeof account !== "string") {
            throw new Error("`account` is not a string");
          }

          helperToast.success(t`Referral code created!`);

          props.setIsVisible(false);
        }
        props.callAfterSuccess?.();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsProcessing(false);
      }
    }
  };

  React.useEffect(() => {
    if (props.isVisible) {
      reset();
    }
  }, [props.isVisible]);

  const reset = () => {
    setReferralCode("");
    setIsProcessing(false);

    inputRef.current?.focus();
  };

  // State to manage if the input is focused
  const [isInputFocused, setInputFocused] = React.useState(false);

  // Handle input focus
  const handleInputFocus = () => {
    setInputFocused(true);
  };

  // Handle input blur
  const handleInputBlur = () => {
    setInputFocused(false);
  };

  return (
    <Modal
      isVisible={props.isVisible}
      setIsVisible={props.setIsVisible}
      label={t`Create Referral Code`}
      isInputFocused={isInputFocused}
    >
      <form onSubmit={handleSubmit} noValidate>
        <fieldset className="flex flex-col items-stretch border-none">
          <TextInput
            ref={inputRef}
            value={referralCode}
            onChange={setReferralCode}
            label={t`Create Referral Code`}
            placeholder={t`Foxify`}
            disabled={isProcessing}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />

          {error && <p className="text-red mt-2">{error}</p>}

          <Button
            variant="primary-action"
            size="lg"
            className="w-full mt-5"
            disabled={!isPrimaryEnabled}
            onClick={handleSubmit}
          >
            {primaryText}
          </Button>
        </fieldset>
      </form>
    </Modal>
  );
}

import React, {useState } from "react";
import "./styles.css";
import { ReactComponent as FavoriteInactive } from "img/favoriteInactive.svg";
import { ReactComponent as VersionIcon } from "img/VersionIcon.svg";
import { ReactComponent as DocsIcon } from "img/FoxifyDocs.svg";
import { ReactComponent as BullishArrow } from "img/BullishArrow.svg";
import { ReactComponent as BearishArrow } from "img/BearishArrow.svg";
import { ReactComponent as AllMarketsIcon } from "img/AllMarkets.svg";

import { LONG, SHORT } from "lib/legacy";
import { getWhitelistedTokens } from "config/tokens";
import { TokenUtils } from "components/TokenUtils";
import { useInfoTokens } from "domain/tokens";
import { cx } from "lib/cx";
// import Marquee from "react-fast-marquee";
import { Menu } from "components/MenuDropdown";
import { Switch } from "components/Switch/Switch";
import { useLocalStorageReactive } from "lib/localStorage";
import { FAVORITES_LOCAL_STORAGE_KEY } from "config/localStorage";
import { useSwapOption } from "pages/Exchange/state";
import { useChainId } from "lib/chains";
import { CalculateDeltaPercentage } from "./CalculateDeltaPercentage";
import { Market } from "./Market";

export default function MarketInformation(props) {
  const { chainId } = useChainId();

  const { infoTokens } = useInfoTokens();
  const [swapOption] = useSwapOption();

  let options;

  const whitelistedTokens = getWhitelistedTokens(chainId);

  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isNative);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;

  if (isLong) {
    options = indexTokens;
  }
  if (isShort) {
    options = shortableTokens;
  }

  const visibleTokens = TokenUtils.filterVisible(options);

  const [showAllMarkets, setShowAllMarkets] = useState(true);
  const [isTopGaining, setIsTopGainig] = useState(false);
  const [isTopLosing, setIsTopLosing] = useState(false);
  const [showSymbol, setShowSymbol] = useState(true);
  const [showPrice, setShowPrice] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const topGainingTokens = visibleTokens
    .map((option) => {
      const { deltaPercentage } = CalculateDeltaPercentage(option, infoTokens, chainId);
      return { option, deltaPercentage };
    })
    .filter((element) => element.deltaPercentage > 0);

  const topLosingTokens = visibleTokens
    .map((option) => {
      const { deltaPercentage } = CalculateDeltaPercentage(option, infoTokens, chainId);
      return { option, deltaPercentage };
    })
    .filter((element) => element.deltaPercentage < 0);

  const [favorites] = useLocalStorageReactive(FAVORITES_LOCAL_STORAGE_KEY, []);

  const favoriteTokens = visibleTokens.filter((token) => favorites?.some((favAddress) => favAddress === token.address));

  return (
    <div className="MarketInformation">
      <div className="MarketInformation-left">
        <div className="MarketInformation-status">
          <div className="dot"></div>
          <span>Operational</span>
        </div>
        <Filter
          isTopGaining={isTopGaining}
          setIsTopGaining={setIsTopGainig}
          isTopLosing={isTopLosing}
          setIsTopLosing={setIsTopLosing}
          showSymbol={showSymbol}
          setShowSymbol={setShowSymbol}
          showPrice={showPrice}
          setShowPrice={setShowPrice}
          setShowFavorites={setShowFavorites}
          showFavorites={showFavorites}
          showAllMarkets={showAllMarkets}
          setShowAllMarkets={setShowAllMarkets}
        />

        {/* <Marquee> */}
        <div className="MarketInformation-markets">
          <div className="MarketInformation-markets">
            {showAllMarkets
              ? visibleTokens.map((value, index) => (
                  <Market
                    key={index}
                    option={value}
                    chainId={chainId}
                    infoTokens={infoTokens}
                    showSymbol={showSymbol}
                    showPrice={showPrice}
                  />
                ))
              : isTopGaining
              ? topGainingTokens.map((value, index) => (
                  <Market
                    key={index}
                    option={value.option}
                    chainId={chainId}
                    infoTokens={infoTokens}
                    showSymbol={showSymbol}
                    showPrice={showPrice}
                  />
                ))
              : isTopLosing
              ? topLosingTokens.map((value, index) => (
                  <Market
                    key={index}
                    option={value.option}
                    chainId={chainId}
                    infoTokens={infoTokens}
                    showSymbol={showSymbol}
                    showPrice={showPrice}
                  />
                ))
              : showFavorites
              ? favoriteTokens.map((value, index) => (
                  <Market
                    key={index}
                    option={value}
                    chainId={chainId}
                    infoTokens={infoTokens}
                    showSymbol={showSymbol}
                    showPrice={showPrice}
                  />
                ))
              : visibleTokens.map((value, index) => (
                  <Market
                    key={index}
                    option={value}
                    chainId={chainId}
                    infoTokens={infoTokens}
                    showSymbol={showSymbol}
                    showPrice={showPrice}
                  />
                ))}
          </div>
        </div>
        {/* </Marquee> */}
      </div>
      <div className="MarketInformation-right">
        <button className="MarketInformation-version">
          <VersionIcon className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]" />
          <span>v.1.1</span>
        </button>
        <a href="https://docs.foxify.trade/" target="_blank" rel="noreferrer">
          <button className="MarketInformation-docs">
            <DocsIcon className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]" />
            <span>Foxify Docs</span>
          </button>
        </a>
      </div>
    </div>
  );
}

function Filter(props) {
  const {
    isTopGaining,
    setIsTopGaining,
    isTopLosing,
    setIsTopLosing,
    showSymbol,
    setShowSymbol,
    showPrice,
    setShowPrice,
    setShowFavorites,
    showFavorites,
    showAllMarkets,
    setShowAllMarkets,
  } = props;

  return (
    <div className={cx("relative")}>
      <div className="hidden lg:block">
        <div className="flex">
          <Menu.Menu placement="bottom-end">
            <Menu.Button>
              {({ ref }) => (
                <button className="MarketInformation-filter" ref={ref}>
                  {showFavorites && (
                    <>
                      <FavoriteInactive className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]" />
                      <span>Favorites</span>
                    </>
                  )}
                  {isTopGaining && (
                    <>
                      <BullishArrow className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]" />
                      <span>Top Gaining</span>
                    </>
                  )}
                  {isTopLosing && (
                    <>
                      <BearishArrow className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]" />
                      <span>Top Losing</span>
                    </>
                  )}
                  {showAllMarkets && (
                    <>
                      <AllMarketsIcon className="menu-item-favorite stroke-[#00000033] dark:stroke-[#FFFFFF33]" />
                      <span>All Markets</span>
                    </>
                  )}
                </button>
              )}
            </Menu.Button>

            <Menu.Items className="w-[14rem]  ![py-2rem] !bg-background-4-hover">
              <div className="MarketInformation-Menu-Items">
                <div className="MarketInformation-Menu-Filter-Wrapper">
                  <button
                    className="MarketInformation-Menu-Filter"
                    onClick={() => {
                      setShowAllMarkets(true);
                      setShowFavorites(false);
                      setIsTopGaining(false);
                      setIsTopLosing(false);
                    }}
                  >
                    <AllMarketsIcon className="menu-item-favorite stroke-[#B3B3B3] dark:stroke-[#B3B3B3]" />
                    <span>All Markets</span>
                  </button>
                  <button
                    className="MarketInformation-Menu-Filter"
                    onClick={() => {
                      setShowFavorites(true);
                      setIsTopGaining(false);
                      setIsTopLosing(false);
                      setShowAllMarkets(false);
                    }}
                  >
                    <FavoriteInactive className="menu-item-favorite stroke-[#B3B3B3] dark:stroke-[#B3B3B3]" />
                    <span>Favorites</span>
                  </button>
                  <button
                    className="MarketInformation-Menu-Filter"
                    onClick={() => {
                      setIsTopGaining(true);
                      setIsTopLosing(false);
                      setShowFavorites(false);
                      setShowAllMarkets(false);
                    }}
                  >
                    <BullishArrow />
                    <span>Top gaining</span>
                  </button>
                  <button
                    className="MarketInformation-Menu-Filter"
                    onClick={() => {
                      setIsTopLosing(true);
                      setIsTopGaining(false);
                      setShowFavorites(false);
                      setShowAllMarkets(false);
                    }}
                  >
                    <BearishArrow />
                    <span>Top losing</span>
                  </button>
                </div>

                <div className="MarketInformation-Menu-Display-Options">
                  <div className="MarketInformation-Diplay-Option">
                    <span>Show Price</span>
                    <Switch
                      checked={showPrice}
                      onChange={() => {
                        setShowPrice(!showPrice);
                      }}
                      checkedColor="#f66b31"
                      uncheckedColor="#1D2024"
                      className="!w-[2.5rem] !h-[1.5rem]"
                      isSmallSwitch={true}
                    />
                  </div>
                  <div className="MarketInformation-Diplay-Option">
                    <span>Show Icon</span>
                    <Switch
                      checked={showSymbol}
                      onChange={() => {
                        setShowSymbol(!showSymbol);
                      }}
                      checkedColor="#f66b31"
                      uncheckedColor="#1D2024"
                      className="!w-[2.5rem] !h-[1.5rem]"
                      isSmallSwitch={true}
                    />
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Menu.Menu>
        </div>
      </div>
    </div>
  );
}

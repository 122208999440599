import { useEffect, useMemo, useRef, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { toJpeg } from "html-to-image";
import cx from "classnames";
import { useCopyToClipboard } from "react-use";
import { QRCodeSVG } from "qrcode.react";
import { getHomeUrl, getTwitterIntentURL, USD_DECIMALS } from "lib/legacy";
import { getGatewayApiUrl } from "lib/api/getGatewayApiUrl";
import { useAffiliateCodes } from "domain/referrals";
import SpinningLoader from "../Common/SpinningLoader";
import useLoadImage from "lib/useLoadImage";
import shareBgImg from "img/position-share-bg.jpg";
import { helperToast } from "lib/helperToast";
import { formatAmount } from "lib/numbers";
import downloadImage from "lib/downloadImage";
import Button from "components/Button/Button";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import { TokenUtils } from "components/TokenUtils";
import { useElementSize } from "lib/useElementSize";

const ROOT_SHARE_URL = getGatewayApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/share/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/share";

type ImageInfo = {
  id: number;
};
async function uploadImg(imageBase64: string): Promise<ImageInfo> {
  const imageInfo = (await fetch(UPLOAD_URL, { method: "POST", body: imageBase64 }).then((res) =>
    res.json()
  )) as ImageInfo;

  return imageInfo;
}

function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref.success && ref.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}

function PositionShare({ setIsPositionShareModalOpen, isPositionShareModalOpen, positionToShare, account, chainId }) {
  const userAffiliateCode = useAffiliateCodes(chainId, account);

  const [uploadedImageInfo, setUploadedImageInfo] = useState<null | ImageInfo>(null);
  const [uploadedImageError, setUploadedImageError] = useState("");
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImg);
  const sharePositionElementRef = useRef<HTMLDivElement>(null);
  const sharePositionElementSize = useElementSize(sharePositionElementRef);

  const toJpegConfig = useMemo(() => {
    if (!sharePositionElementSize) {
      return undefined;
    }
    return {
      quality: 0.95, // "1" quality is significatly slower
      canvasWidth: sharePositionElementSize.width * 2,
      canvasHeight: sharePositionElementSize.height * 2,
      type: "image/jpeg",
    };
  }, [sharePositionElementSize]);

  const tweetLink = getTwitterIntentURL(
    `Latest $${TokenUtils.getSymbol(positionToShare?.indexToken)} trade on @PALMSWAPORG`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  );

  useEffect(() => {
    (async function () {
      const element = sharePositionElementRef.current;

      if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare && toJpegConfig) {
        // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
        // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
        const image = await toJpeg(element, toJpegConfig)
          .then(() => toJpeg(element, toJpegConfig))
          .then(() => toJpeg(element, toJpegConfig));
        try {
          const imageInfo = await uploadImg(image);

          setUploadedImageInfo(imageInfo);
        } catch {
          setUploadedImageInfo(null);
          setUploadedImageError(t`Image generation error, please refresh and try again.`);
        }
      }
    })();
  }, [sharePositionBgImg, positionToShare, userAffiliateCode.success, toJpegConfig, sharePositionElementSize]);

  async function handleDownload() {
    const element = sharePositionElementRef.current;

    if (!element || !toJpegConfig) return;

    const imgBlob = await toJpeg(element, toJpegConfig)
      .then(() => toJpeg(element, toJpegConfig))
      .then(() => toJpeg(element, toJpegConfig));
    downloadImage(imgBlob, "share.jpeg");
  }

  function handleCopy() {
    if (!uploadedImageInfo) return;
    const shareUrl = getShareURL(uploadedImageInfo, userAffiliateCode);

    if (shareUrl) {
      copyToClipboard(shareUrl);
      helperToast.success(t`Link copied to clipboard.`);
    }
  }
  return (
    <ModalWithPortal
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={t`Share Position`}
      noMinWidth
      customWidth
    >
      <div className="tailwind">
        <div className="w-full flex justify-center">
          <PositionShareCard
            userAffiliateCode={userAffiliateCode}
            sharePositionElementRef={sharePositionElementRef}
            position={positionToShare}
            uploadedImageInfo={uploadedImageInfo}
            uploadedImageError={uploadedImageError}
            sharePositionBgImg={sharePositionBgImg}
          />
        </div>

        {uploadedImageError && <div className="text-red mt-4 text-center">{uploadedImageError}</div>}

        <div className="mt-8 @container/buttons">
          <div className="grid gap-4 grid-rows-3 @[32rem]/buttons:grid-rows-none @[32rem]/buttons:grid-cols-3">
            <Button variant="minor-with-border" disabled={!uploadedImageInfo} onClick={handleCopy}>
              <Trans>Copy</Trans>
            </Button>

            <Button variant="minor-with-border" disabled={!uploadedImageInfo} onClick={handleDownload}>
              <Trans>Download</Trans>
            </Button>

            <Button variant="minor-with-border" disabled={!uploadedImageInfo} to={tweetLink}>
              <Trans>Tweet</Trans>
            </Button>
          </div>
        </div>
      </div>
    </ModalWithPortal>
  );
}

function PositionShareCard({
  sharePositionElementRef,
  position,
  userAffiliateCode,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
}) {
  const { code, success } = userAffiliateCode;
  const { deltaAfterFeesPercentageStr, isLong, leverage, indexToken, averagePrice, markPrice } = position;

  const homeURL = getHomeUrl();

  return (
    <div className="relative @container/share-image w-[41.8rem] max-w-full text-white">
      <div
        className={cx({
          "opacity-20": !uploadedImageInfo && !uploadedImageError,
        })}
      >
        <div
          ref={sharePositionElementRef}
          className={cx([
            "p-8 w-full bg-cover bg-right-bottom bg-no-repeat bg-black rounded-[2rem] aspect-[1200/675] flex flex-col items-start",
          ])}
          style={{ backgroundImage: `url(${sharePositionBgImg})` }}
        >
          <div className="flex gap-2 items-center">
            <div className="font-logo text-[1.6rem]  leading-[1.5] uppercase">Foxify</div>
          </div>

          <ul className="mt-4 flex flex-col @[32rem]/share-image:flex-row @[32rem]/share-image:items-center gap-1 @[32rem]/share-image:gap-12 text-[1.2rem] leading-[1.4]">
            <li className="uppercase font-medium flex">
              {isLong ? <span className="text-green">LONG</span> : <span className="text-red">SHORT</span>}
            </li>
            <li>{formatAmount(leverage, 4, 2, true)}x&nbsp;</li>
            <li>{TokenUtils.getSymbol(indexToken)} USDC</li>
          </ul>

          <h3 className="font-bold text-[3rem] leading-[1.4]">{deltaAfterFeesPercentageStr}</h3>

          <div className="mt-2 flex flex-col gap-4 @[32rem]/share-image:flex-row @[32rem]/share-image:gap-14 [32rem]/share-image:items-end">
            <div>
              <p className="text-[1rem] leading-[1.4]">Entry Price</p>
              <p className="text-[2rem] leading-[1.4] font-bold">
                ${formatAmount(averagePrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
              </p>
            </div>

            <div>
              <p className="text-[1rem] leading-[1.4]">Index Price</p>
              <p className="text-[2rem] leading-[1.4] font-bold">
                ${formatAmount(markPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
              </p>
            </div>
          </div>

          <div
            className={cx([
              "mt-4 p-[.8rem] rounded-[1rem] bg-white/[0.07] flex items-center max-w-full",
              {
                "min-w-[22.7rem]": success && code,
              },
            ])}
          >
            <div className="w-[40px] aspect-square bg-white rounded-[.3rem] p-[1px] relative">
              <QRCodeSVG size={38} value={success && code ? `${homeURL}/#/?ref=${code}` : `${homeURL}`} />
            </div>

            {success && code && (
              <div className="min-w-0 ml-3">
                <p className="text-[1.2rem] leading-[1.4] opacity-50">Referral Code:</p>
                <p className="text-[1.4rem] leading-[1.4] font-bold overflow-hidden overflow-ellipsis">{code}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {!uploadedImageInfo && !uploadedImageError && (
        <div className="absolute inset-0 m-auto flex flex-col justify-center items-center gap-4">
          <SpinningLoader />

          <p className="text-[1.4rem] leading-[1.4]">
            <Trans>Generating shareable image...</Trans>
          </p>
        </div>
      )}
    </div>
  );
}

export default PositionShare;

import React, { useState, useMemo } from "react";
import { BsArrowRight } from "react-icons/bs";

import { PRECISION, USD_DECIMALS, DECREASE, INCREASE, calculatePositionDelta, getLiquidationPrice } from "lib/legacy";
import { updateIncreaseOrder, updateDecreaseOrder } from "domain/legacy";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { useChainId } from "lib/chains";
import { t, Trans } from "@lingui/macro";
import Button from "components/Button/Button";
import { cx } from "lib/cx";
import { useFundedTrader } from "hooks/useFundedTrader";

export default function OrderEditor(props) {
  const {
    account,
    order,
    setEditingOrder,
    infoTokens,
    pendingTxns,
    setPendingTxns,
    library,
    getPositionForOrder,
    positionsMap,
    savedShouldDisableValidationForTesting,
  } = props;

  const { chainId } = useChainId();
  const { proxyTraderContractAddress } = useFundedTrader();

  const position = getPositionForOrder(account, order, positionsMap);
  const indexTokenInfo = getTokenInfo(infoTokens, order.indexToken);
  const liquidationPrice = order.type === DECREASE && position ? getLiquidationPrice(position) : null;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputActive, setInputActive] = useState("");

  const triggerRatioInverted = null;

  let initialRatio = 0;
  if (order.triggerRatio) {
    initialRatio = triggerRatioInverted ? PRECISION.mul(PRECISION).div(order.triggerRatio) : order.triggerRatio;
  }
  const triggerRatioValue = formatAmountFree(initialRatio, USD_DECIMALS, 6);

  const [triggerPriceValue, setTriggerPriceValue] = useState(formatAmountFree(order.triggerPrice, USD_DECIMALS, 4));
  const triggerPrice = useMemo(() => {
    return triggerPriceValue ? parseValue(triggerPriceValue, USD_DECIMALS) : 0;
  }, [triggerPriceValue]);

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS);
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  const indexTokenMarkPrice = useMemo(() => {
    return order.isLong ? indexTokenInfo.maxPrice : indexTokenInfo.minPrice;
  }, [indexTokenInfo, order]);

  const onClickPrimary = () => {
    setIsSubmitting(true);

    let func;
    let params;

    if (order.type === DECREASE) {
      func = updateDecreaseOrder;
      params = [
        chainId,
        library,
        order.index,
        order.collateralDelta,
        order.sizeDelta,
        triggerPrice,
        order.triggerAboveThreshold,
        proxyTraderContractAddress,
      ];
    } else if (order.type === INCREASE) {
      func = updateIncreaseOrder;
      params = [
        chainId,
        library,
        order.index,
        order.sizeDelta,
        triggerPrice,
        order.triggerAboveThreshold,
        proxyTraderContractAddress,
      ];
    }

    params.push({
      successMsg: t`Order updated!`,
      failMsg: t`Order update failed.`,
      sentMsg: t`Order update submitted!`,
      pendingTxns,
      setPendingTxns,
    });

    return func(...params)
      .then(() => {
        setEditingOrder(null);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
  };

  const getError = () => {
    if ((!triggerRatio || triggerRatio.eq(0)) && (!triggerPrice || triggerPrice.eq(0))) {
      return t`Enter Price`;
    }
    if (triggerPrice.eq(order.triggerPrice)) {
      return t`Enter new Price`;
    }
    if (position) {
      if (order.type === DECREASE) {
        if (position.isLong && triggerPrice.lte(liquidationPrice)) {
          return t`Price below Liq. Price`;
        }
        if (!position.isLong && triggerPrice.gte(liquidationPrice)) {
          return t`Price above Liq. Price`;
        }
      }

      const { delta, hasProfit } = calculatePositionDelta(triggerPrice, position);
      if (hasProfit && delta.eq(0)) {
        return t`Invalid price, see warning`;
      }
    }

    if (indexTokenMarkPrice && !savedShouldDisableValidationForTesting) {
      if (order.triggerAboveThreshold && indexTokenMarkPrice.gt(triggerPrice)) {
        return t`Price below Mark Price`;
      }
      if (!order.triggerAboveThreshold && indexTokenMarkPrice.lt(triggerPrice)) {
        return t`Price above Mark Price`;
      }
    }
  };

  const isPrimaryEnabled = () => {
    if (isSubmitting) {
      return false;
    }
    const error = getError();
    if (error) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (isSubmitting) {
      return t`Updating Order...`;
    }
    return t`Update Order`;
  };

  const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
  return (
    <Modal
      isVisible={true}
      className="Exchange-list-modal"
      setIsVisible={() => setEditingOrder(null)}
      label={t`Edit order`}
    >
      <div
        className={cx("Exchange-swap-section-container transition-effect !mb-[0.5rem]", {
          inputActive: inputActive === "edit-order-input",
        })}
      >
        <div className="Exchange-swap-section-container-bottom ">
          <div className="Exchange-swap-input-container">
            <input
              type="number"
              min="0"
              placeholder="0.0"
              className="Exchange-swap-input"
              value={triggerPriceValue}
              onChange={onTriggerPriceChange}
              onFocus={() => setInputActive("edit-order-input")}
              onBlur={() => setInputActive("")}
            />
            <div className="TokenSelector">
              <div className="TokenSelector-box">USD</div>
            </div>
          </div>
        </div>
      </div>
      <div className="Exchange-info-row ">
        <div className="Exchange-info-label">
          <Trans>Mark Price</Trans>
        </div>
        <div
          className="align-right clickable"
          onClick={() => {
            setTriggerPriceValue(formatAmountFree(indexTokenMarkPrice, USD_DECIMALS, indexTokenInfo?.priceDecimals));
          }}
        >
          <p> {formatAmount(indexTokenMarkPrice, USD_DECIMALS, indexTokenInfo?.priceDecimals)}</p>
        </div>
      </div>

      <ExchangeInfoRow label={t`Price`}>
        {triggerPrice && !triggerPrice.eq(order.triggerPrice) ? (
          <div className="flex items-center">
            <span className="muted flex">
              {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, indexTokenInfo?.priceDecimals, true)}
            </span>
            &nbsp;
            <BsArrowRight />
            &nbsp;
            {triggerPricePrefix} {formatAmount(triggerPrice, USD_DECIMALS, indexTokenInfo?.priceDecimals, true)}
          </div>
        ) : (
          <span>
            {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, indexTokenInfo?.priceDecimals, true)}
          </span>
        )}
      </ExchangeInfoRow>
      {liquidationPrice && (
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">
            <Trans>Liq. Price</Trans>
          </div>
          <div className="align-right">{`$${formatAmount(
            liquidationPrice,
            USD_DECIMALS,
            indexTokenInfo?.priceDecimals,
            true
          )}`}</div>
        </div>
      )}
      <div className="Exchange-swap-button-container">
        <Button variant="primary-action" className="w-100" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </Button>
      </div>
    </Modal>
  );
}

type NFTRenderItemProps = {
  name: string;
  img?: string;
  id?: string;
};

export const NFTRenderItem = ({ name, id, img }: NFTRenderItemProps) => {
  return (
    <div className="flex flex-col bg-[#101014] rounded-[15px] border border-[rgba(255,255,255,0.08)] p-2.5 flex-1 min-w-[150px] max-w-[270px] basis-[calc(50%-1rem)]">
      <img src={img} alt={name} className="object-cover rounded-[10px] mb-4" />

      <div className="flex justify-between ">
        <p className="text-[20px]">{name}</p>
        <p className="h-[35px] px-[15px] bg-[#202024] overflow-visible content-center flex-nowrap gap-[5px] rounded-[12px]">
          {id}
        </p>
      </div>
      {/* <button
            // onClick={() => setIsModalOpen((prev) => !prev)}
            className="w-full text-main px-[1.7rem] py-[1rem] Create-funded-btn rounded-[1.5rem] h-[4.5rem] mt-4"
          >
          Stake
          </button> */}
    </div>
  );
};

import React, { useEffect, useState, useRef, useMemo, Dispatch, SetStateAction, useLayoutEffect } from "react";
import cx from "classnames";
import { useMedia } from "react-use";

import { USD_DECIMALS, INCREASE, getLiquidationPrice, BASIS_POINTS_DIVISOR } from "lib/legacy";
import { useChartPrices } from "domain/legacy";
import { Trans } from "@lingui/macro";
import ChartTokenSelector from "./ChartTokenSelector";
import { getTokenInfo } from "domain/tokens/utils";
import { formatAmount, numberWithCommas } from "lib/numbers";
import { getToken } from "config/tokens";
import TVChartContainer from "components/TVChartContainer/TVChartContainer";
import { t } from "@lingui/macro";
import { availableNetworksForChart } from "components/TVChartContainer/constants";
import { TVDataProvider } from "domain/tradingview/TVDataProvider";
import { RxTriangleUp, RxTriangleDown } from "react-icons/rx";
import { useHourlyVolumeByToken } from "domain/stats";
import { Tooltip } from "components/TooltipV2/Tooltip";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { TokenInfo } from "domain/tokens";
import { useAtom } from "jotai";
import { isChartFullScreenAtom } from "pages/Exchange/state";
import { BigNumber } from "ethers";
import { TokenUtils } from "components/TokenUtils";
import Modal from "components/Modal/Modal";
import { isMarketDetailsOpenAtom } from "./state";
import marketDetailsIcon from "img/marketDetailsIcon.svg";
import perpetualTutorialIcon from "img/perpetualTutorialIcon.svg";

const PRICE_LINE_TEXT_WIDTH = 15;

export function getChartToken<T>(fromToken: T, toToken: T): T | undefined {
  if (!fromToken || !toToken) {
    return;
  }

  return toToken;
}

export default function ExchangeTVChart({
  swapOption,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  chainId,
  positions,
  savedShouldShowPositionLines,
  orders,
  setToTokenAddress,
}) {
  const [currentSeries] = useState<any>();
  const [isChartFullScreen] = useAtom(isChartFullScreenAtom);

  const dayVolumeData = useHourlyVolumeByToken(toTokenAddress);

  const currentVolumeInfo = dayVolumeData.data?.volume;

  const dataProvider = useRef<any>();
  const isMobile = useMedia("(max-width: 1100px)");

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  const [chartToken, setChartToken] = useState<TokenInfo>();

  useEffect(() => {
    dataProvider.current = new TVDataProvider();
  }, []);

  useEffect(() => {
    setChartToken(getChartToken(fromToken, toToken));
  }, [fromToken, toToken]);

  const currentOrders = useMemo(() => {
    if (!chartToken) {
      return [];
    }

    return orders
      .filter((order) => {
        const indexToken = getToken(chainId, order.indexToken);
        return order.indexToken === chartToken.address || (chartToken.isNative && indexToken.isWrapped);
      })
      .map((order) => {
        const indexToken = getToken(chainId, order.indexToken);
        const longOrShortText = order.isLong ? t`Long` : t`Short`;
        const orderTypeText = order.type === INCREASE ? t`Inc.` : t`Dec.`;
        const tokenSymbol = TokenUtils.getSymbol(indexToken);
        const title = `${orderTypeText} ${tokenSymbol} ${longOrShortText}`;
        return { title, price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 2)), isLong: order.isLong };
      });
  }, [orders, chartToken, chainId]);

  const currentPositions = useMemo(() => {
    if (!positions || !chartToken) {
      return [];
    }

    return positions
      .filter((p) => p.indexToken?.address === chartToken?.address)
      .map((position) => {
        const longOrShortText = position.isLong ? t`Long` : t`Short`;
        return {
          open: {
            price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, position.indexToken.priceDecimals)),
            title: t`Open ${TokenUtils.getSymbol(position.indexToken)} ${longOrShortText}`,
            isLong: position.isLong,
          },
          liquidation: {
            price: parseFloat(
              formatAmount(getLiquidationPrice(position), USD_DECIMALS, position.indexToken.priceDecimals)
            ),
            title: t`Liq. ${TokenUtils.getSymbol(position.indexToken)} ${longOrShortText}`,
            isLiquidation: true,
          },
        };
      });
  }, [chartToken, positions]);

  const chartLines = useMemo(() => {
    const lines: any[] = [];
    if (currentOrders.length > 0) {
      lines.push(...currentOrders);
    }

    if (currentPositions.length > 0) {
      currentPositions.forEach((position) => {
        lines.push(position.open);
        lines.push(position.liquidation);
      });
    }

    return lines;
  }, [currentOrders, currentPositions]);

  const ref = useRef(null);

  const currentAveragePrice =
    chartToken?.maxPrice && chartToken?.minPrice ? chartToken.maxPrice.add(chartToken.minPrice).div(2) : null;
  const [chartPriceData, updatePriceData] = useChartPrices(
    chainId,
    TokenUtils.getSymbol(chartToken),
    chartToken?.isStable,
    "1h",
    currentAveragePrice
  );

  useEffect(() => {
    const interval = setInterval(() => {
      updatePriceData(undefined, true);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [updatePriceData]);

  const priceData = useMemo(() => {
    return chartPriceData;
  }, [chartPriceData]);

  useEffect(() => {
    const lines: any[] = [];
    if (currentSeries && savedShouldShowPositionLines) {
      if (currentOrders && currentOrders.length > 0) {
        currentOrders.forEach((order) => {
          const indexToken = getToken(chainId, order.indexToken);
          const tokenSymbol = TokenUtils.getSymbol(indexToken);

          const title = `${order.type === INCREASE ? "Inc." : "Dec."} ${tokenSymbol} ${
            order.isLong ? "Long" : "Short"
          }`;
          const color = "#3a3e5e";
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 2)),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
      if (currentPositions && currentPositions.length > 0) {
        const color = "#3a3e5e";

        positions.forEach((position) => {
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 2)),
              color,
              title: `Open ${TokenUtils.getSymbol(position.indexToken)} ${position.isLong ? "Long" : "Short"}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                " "
              ),
            })
          );

          const liquidationPrice = getLiquidationPrice(position);
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(liquidationPrice, USD_DECIMALS, 2)),
              color,
              title: `Liq. ${TokenUtils.getSymbol(position.indexToken)} ${position.isLong ? "Long" : "Short"}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                " "
              ),
            })
          );
        });
      }
    }
    return () => {
      lines.forEach((line) => currentSeries.removePriceLine(line));
    };
  }, [currentOrders, currentSeries, chainId, savedShouldShowPositionLines, currentPositions, positions]);

  const [dailyHigh, setDailyHigh] = useState<number>(0);
  const [dailyLow, setDailyLow] = useState<number>(0);
  const [deltaPrice, setDeltaPrice] = useState<number>(0);
  const [delta, setDelta] = useState<number>(0);
  const [deltaPercentage, setDeltaPercentage] = useState<number>(0);
  const [deltaPercentageStr, setDeltaPercentageStr] = useState<string>("");
  const [isMarketDetailsOpen, setIsMarketDetailsOpen] = useAtom(isMarketDetailsOpenAtom);

  const now = Math.floor(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  useEffect(() => {
    if (priceData) {
      let low;
      let high;
      for (let i = priceData.length - 1; i > 0; i--) {
        const price = priceData[i];
        if (price.time < timeThreshold) {
          break;
        }
        if (!low || price.low < low) {
          low = price.low;
        }
        if (!high || price.high > high) {
          high = price.high;
        }

        setDeltaPrice(price.open);
      }
      setDailyLow(low);
      setDailyHigh(high);
    }

    if (deltaPrice !== 0 && currentAveragePrice !== null) {
      const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
      const calculatedDelta = average - deltaPrice;

      setDelta(calculatedDelta);
      setDeltaPercentage((calculatedDelta * 100) / average);

      if (deltaPercentage !== 0 && calculatedDelta !== 0) {
        const formattedDelta = Math.abs(calculatedDelta).toFixed(chartToken?.priceDecimals);
        const formattedDeltaPercentage = Math.abs(deltaPercentage).toFixed(2);
        setDeltaPercentageStr(`$${formattedDelta} (${formattedDeltaPercentage}%)`);
      } else {
        setDeltaPercentageStr("0.00");
      }
    }
  }, [priceData, currentAveragePrice, delta, deltaPercentage, deltaPrice, timeThreshold, chartToken?.priceDecimals]);

  if (!chartToken) {
    return null;
  }

  const onSelectToken = (token) => {
    const tmp = getTokenInfo(infoTokens, token.address);
    setChartToken(tmp);
    setToTokenAddress(swapOption, token.address);
  };

  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

  const borrowFeeTextLong = formatAmount(toTokenInfo.fundingRateLong, 4, 4) + "% / 1h";
  const borrowFeeTextShort = formatAmount(toTokenInfo.fundingRateShort, 4, 4) + "% / 1h";

  return (
    <div
      className={cx(`ExchangeChart tv`, isMobile && "!h-[47.6rem]", "flex flex-col items-stretch")}
      style={{
        height: isChartFullScreen && !isMobile ? "calc(100vh - 150px)" : "100%",
      }}
      ref={ref}
    >
      <Modal
        isVisible={isMarketDetailsOpen}
        setIsVisible={setIsMarketDetailsOpen}
        label={t`Market Details`}
        placement="center"
      >
        <div>
          <div className="line mt-[2rem] mb-[1rem]"></div>
          <div className={cx("App-cta small transparent chart-token-selector")}>
            {chartToken && <TokenUtils.Logo token={chartToken} className="w-[3rem] mr-[0.8rem]"></TokenUtils.Logo>}
            <div className="flex flex-col">
              <span className="text-start text-white">{TokenUtils.getSymbol(chartToken)}/USD</span>
              <span className="text-start muted">{TokenUtils.getName(chartToken)}</span>
            </div>
          </div>
          <div className="mt-[1rem] leading-[2.4rem]">
            <span className="text-start muted">{toToken.description}</span>
          </div>
          <div className="line my-[1rem]" />

          <div className="flex flex-col gap-[0.5rem]">
            <div className="flex place-content-between">
              <span className="muted">Min Order Size</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">10</span>
                <span className="muted">USDC</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Available Liquidity Long</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">{formatAmount(toTokenInfo.maxAvailableLong, USD_DECIMALS, 2, true)}</span>
                <span className="muted">USDC</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Available Liquidity Short</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">{formatAmount(toTokenInfo.maxAvailableShort, USD_DECIMALS, 2, true)}</span>
                <span className="muted">USDC</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Funding</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">Hourly</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Max Leverage</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">{Number(chartToken.maxLeverage) / BASIS_POINTS_DIVISOR}x</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="App-box App-box-border flex-1  md:mt-[0.4rem]">
        {availableNetworksForChart.includes(chainId) && chartToken.symbol && chainId ? (
          <>
            <TVChartContainer
              chartLines={chartLines}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              symbol={TokenUtils.getSymbol(chartToken)}
              chainId={chainId}
              onSelectToken={onSelectToken}
              dataProvider={dataProvider.current}
              deltaPercentage={deltaPercentage}
            />
          </>
        ) : (
          <p className="tv-chart-container">Sorry, chart is not supported on this network yet.</p>
        )}
      </div>

      <div className="bg-background-5-v2-only h-[5rem] md:h-[5.5rem] hidden max-[1100px]:flex items-center border-b border-border md:border-none px-[1rem]  md:px-[0]">
        <ChartTokenStats
          chartToken={chartToken}
          deltaPercentage={deltaPercentage}
          deltaPercentageStr={deltaPercentageStr}
          dailyHigh={dailyHigh}
          dailyLow={dailyLow}
          currentVolumeInfo={currentVolumeInfo}
          borrowFeeTextLong={borrowFeeTextLong}
          borrowFeeTextShort={borrowFeeTextShort}
          isMobile={true}
          setIsMarketDetailsOpen={setIsMarketDetailsOpen}
        ></ChartTokenStats>
      </div>
    </div>
  );
}

export function ExchangeChartTop({
  swapOption,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  chainId,
  positions,
  savedShouldShowPositionLines,
  orders,
  setToTokenAddress,
}) {
  const [currentSeries] = useState<any>();

  const dayVolumeData = useHourlyVolumeByToken(toTokenAddress);

  const currentVolumeInfo = dayVolumeData.data?.volume;

  const dataProvider = useRef<any>();

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  const [chartToken, setChartToken] = useState<TokenInfo>();
  const [offtop, setOfftop] = useState(0);

  useEffect(() => {
    dataProvider.current = new TVDataProvider();
  }, []);

  useEffect(() => {
    setChartToken(getChartToken(fromToken, toToken));
  }, [fromToken, toToken]);

  useLayoutEffect(() => {
    const handler = () => {
      const diff = window.innerHeight - visualViewport!.height;
      if (diff) {
        setOfftop(visualViewport!.offsetTop);
      } else setOfftop(0);
    };

    let lastScrollTop = 0;

    const handleScroll = () => {
      let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll < lastScrollTop) {
        setOfftop(0);
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };

    visualViewport?.addEventListener("resize", handler);
    window.addEventListener("scroll", handleScroll);

    return () => {
      visualViewport?.removeEventListener("resize", handler);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentOrders = useMemo(() => {
    if (!chartToken) {
      return [];
    }

    return orders
      .filter((order) => {
        const indexToken = getToken(chainId, order.indexToken);
        return order.indexToken === chartToken.address || (chartToken.isNative && indexToken.isWrapped);
      })
      .map((order) => {
        const indexToken = getToken(chainId, order.indexToken);
        const longOrShortText = order.isLong ? t`Long` : t`Short`;
        const orderTypeText = order.type === INCREASE ? t`Inc.` : t`Dec.`;
        const tokenSymbol = TokenUtils.getSymbol(indexToken);
        const title = `${orderTypeText} ${tokenSymbol} ${longOrShortText}`;
        return { title, price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 2)), isLong: order.isLong };
      });
  }, [orders, chartToken, chainId]);

  const currentPositions = useMemo(() => {
    if (!positions || !chartToken) {
      return [];
    }

    return positions
      .filter((p) => p.indexToken?.address === chartToken?.address)
      .map((position) => {
        const longOrShortText = position.isLong ? t`Long` : t`Short`;
        return {
          open: {
            price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, position.indexToken.priceDecimals)),
            title: t`Open ${TokenUtils.getSymbol(position.indexToken)} ${longOrShortText}`,
            isLong: position.isLong,
          },
          liquidation: {
            price: parseFloat(
              formatAmount(getLiquidationPrice(position), USD_DECIMALS, position.indexToken.priceDecimals)
            ),
            title: t`Liq. ${TokenUtils.getSymbol(position.indexToken)} ${longOrShortText}`,
            isLiquidation: true,
          },
        };
      });
  }, [chartToken, positions]);

  const ref = useRef(null);

  const currentAveragePrice =
    chartToken?.maxPrice && chartToken?.minPrice ? chartToken.maxPrice.add(chartToken.minPrice).div(2) : null;
  const [chartPriceData, updatePriceData] = useChartPrices(
    chainId,
    TokenUtils.getSymbol(chartToken),
    chartToken?.isStable,
    "1h",
    currentAveragePrice
  );

  useEffect(() => {
    const interval = setInterval(() => {
      updatePriceData(undefined, true);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [updatePriceData]);

  const priceData = useMemo(() => {
    return chartPriceData;
  }, [chartPriceData]);

  useEffect(() => {
    const lines: any[] = [];
    if (currentSeries && savedShouldShowPositionLines) {
      if (currentOrders && currentOrders.length > 0) {
        currentOrders.forEach((order) => {
          const indexToken = getToken(chainId, order.indexToken);
          const tokenSymbol = TokenUtils.getSymbol(indexToken);

          const title = `${order.type === INCREASE ? "Inc." : "Dec."} ${tokenSymbol} ${
            order.isLong ? "Long" : "Short"
          }`;
          const color = "#3a3e5e";
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 2)),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
      if (currentPositions && currentPositions.length > 0) {
        const color = "#3a3e5e";

        positions.forEach((position) => {
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(position.averagePrice, USD_DECIMALS, 2)),
              color,
              title: `Open ${TokenUtils.getSymbol(position.indexToken)} ${position.isLong ? "Long" : "Short"}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                " "
              ),
            })
          );

          const liquidationPrice = getLiquidationPrice(position);
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(liquidationPrice, USD_DECIMALS, 2)),
              color,
              title: `Liq. ${TokenUtils.getSymbol(position.indexToken)} ${position.isLong ? "Long" : "Short"}`.padEnd(
                PRICE_LINE_TEXT_WIDTH,
                " "
              ),
            })
          );
        });
      }
    }
    return () => {
      lines.forEach((line) => currentSeries.removePriceLine(line));
    };
  }, [currentOrders, currentSeries, chainId, savedShouldShowPositionLines, currentPositions, positions]);

  const [dailyHigh, setDailyHigh] = useState<number>(0);
  const [dailyLow, setDailyLow] = useState<number>(0);
  const [deltaPrice, setDeltaPrice] = useState<number>(0);
  const [delta, setDelta] = useState<number>(0);
  const [deltaPercentage, setDeltaPercentage] = useState<number>(0);
  const [deltaPercentageStr, setDeltaPercentageStr] = useState<string>("");
  const [isMarketDetailsOpen, setIsMarketDetailsOpen] = useState(false);

  const now = Math.floor(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  useEffect(() => {
    if (priceData) {
      let low;
      let high;
      for (let i = priceData.length - 1; i > 0; i--) {
        const price = priceData[i];
        if (price.time < timeThreshold) {
          break;
        }
        if (!low || price.low < low) {
          low = price.low;
        }
        if (!high || price.high > high) {
          high = price.high;
        }

        setDeltaPrice(price.open);
      }
      setDailyLow(low);
      setDailyHigh(high);
    }

    if (deltaPrice !== 0 && currentAveragePrice !== null) {
      const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
      const calculatedDelta = average - deltaPrice;

      setDelta(calculatedDelta);
      setDeltaPercentage((calculatedDelta * 100) / average);

      if (deltaPercentage !== 0 && calculatedDelta !== 0) {
        const formattedDelta = Math.abs(calculatedDelta).toFixed(chartToken?.priceDecimals);
        const formattedDeltaPercentage = Math.abs(deltaPercentage).toFixed(2);
        setDeltaPercentageStr(`$${formattedDelta} (${formattedDeltaPercentage}%)`);
      } else {
        setDeltaPercentageStr("0.00");
      }
    }
  }, [priceData, currentAveragePrice, delta, deltaPercentage, deltaPrice, timeThreshold, chartToken?.priceDecimals]);

  if (!chartToken) {
    return null;
  }

  const onSelectToken = (token) => {
    const tmp = getTokenInfo(infoTokens, token.address);
    setChartToken(tmp);
    setToTokenAddress(swapOption, token.address);
  };

  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

  const borrowFeeTextLong = formatAmount(toTokenInfo.fundingRateLong, 4, 4) + "% / 1h";
  const borrowFeeTextShort = formatAmount(toTokenInfo.fundingRateShort, 4, 4) + "% / 1h";

  return (
    <div className={cx(`ExchangeChartTop tv`, "flex flex-col items-stretch")} ref={ref}>
      <div
        className="ExchangeChart-top bg-background-5-v2-only h-[5.5rem] flex items-center"
        style={{ marginTop: offtop }}
      >
        <ChartTokenSelector
          chainId={chainId}
          selectedToken={chartToken}
          swapOption={swapOption}
          infoTokens={infoTokens}
          onSelectToken={onSelectToken}
          className="chart-token-selector shrink-0"
        />

        <div className="shrink-0 h-full flex items-center max-[1100px]:pr-0 max-[1100px]:ml-auto max-[1100px]:text-right">
          <ChartTokenPrice
            chartToken={chartToken}
            className="min-[1100px]:border-r min-[1100px]:border-border min-[1100px]:px-[1.5rem] w-[14rem]"
          />
        </div>

        <ChartTokenStats
          chartToken={chartToken}
          className="flex-1 max-[1100px]:hidden"
          deltaPercentage={deltaPercentage}
          deltaPercentageStr={deltaPercentageStr}
          dailyHigh={dailyHigh}
          dailyLow={dailyLow}
          currentVolumeInfo={currentVolumeInfo}
          borrowFeeTextLong={borrowFeeTextLong}
          borrowFeeTextShort={borrowFeeTextShort}
          isMobile={false}
          setIsMarketDetailsOpen={setIsMarketDetailsOpen}
        />
      </div>

      <Modal
        isVisible={isMarketDetailsOpen}
        setIsVisible={setIsMarketDetailsOpen}
        label={t`Market Details`}
        placement="center"
      >
        <div>
          <div className="line mt-[2rem] mb-[1rem]"></div>
          <div className={cx("App-cta small transparent chart-token-selector")}>
            {chartToken && <TokenUtils.Logo token={chartToken} className="w-[3rem] mr-[0.8rem]"></TokenUtils.Logo>}
            <div className="flex flex-col">
              <span className="text-start text-white">{TokenUtils.getSymbol(chartToken)}/USD</span>
              <span className="text-start muted">{TokenUtils.getName(chartToken)}</span>
            </div>
          </div>
          <div className="mt-[1rem] leading-[2.4rem]">
            <span className="text-start muted">{toToken.description}</span>
          </div>
          <div className="line my-[1rem]" />

          <div className="flex flex-col gap-[0.5rem]">
            <div className="flex place-content-between">
              <span className="muted">Min Order Size</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">10</span>
                <span className="muted">USDC</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Available Liquidity Long</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">{formatAmount(toTokenInfo.maxAvailableLong, USD_DECIMALS, 2, true)}</span>
                <span className="muted">USDC</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Available Liquidity Short</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">{formatAmount(toTokenInfo.maxAvailableShort, USD_DECIMALS, 2, true)}</span>
                <span className="muted">USDC</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Funding</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">Hourly</span>
              </div>
            </div>
            <div className="flex place-content-between">
              <span className="muted">Max Leverage</span>
              <div className="flex flex-row gap-[0.5rem]">
                <span className="text-white">{Number(chartToken.maxLeverage) / BASIS_POINTS_DIVISOR}x</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

type ChartTokenPriceProps = {
  chartToken: TokenInfo;
  className?: string;
};

const ChartTokenPrice = ({ chartToken, className }: ChartTokenPriceProps) => {
  return (
    <div className={cx(className)}>
      <div className="text-[1.6rem] font-bold">
        {chartToken.maxPrice && chartToken.maxPrice.gt(0) ? (
          `$${formatAmount(chartToken.maxPrice, USD_DECIMALS, chartToken.priceDecimals, true)}`
        ) : (
          <div className="skeleton ml-[7rem] md:ml-2" />
        )}
      </div>
      <div className="ExchangeChart-info-label">
        {chartToken.minPrice &&
          chartToken.minPrice.gt(0) &&
          `≈ ${formatAmount(chartToken.minPrice, USD_DECIMALS, chartToken.priceDecimals, true)} USDC`}
      </div>
    </div>
  );
};

const TokenInformations = ({ setIsVisible }) => {
  return (
    <div className="flex flex-row gap-[2rem] ml-auto">
      <div
        className="flex flex-row gap-[0.5rem] items-center cursor-pointer whitespace-nowrap bg-background-3 hover:bg-background-3-hover rounded-[1rem] px-[1rem] w-max"
        onClick={() => {
          setIsVisible(true);
        }}
      >
        <img src={marketDetailsIcon} alt="marketDetails" className="w-[1.5rem]" />

        <span>Market details</span>
      </div>
      <a
        className="flex flex-row gap-[0.5rem] items-center cursor-pointer whitespace-nowrap bg-background-3 hover:bg-background-3-hover rounded-[1rem] px-[1rem] w-max"
        href="https://docs.foxify.trade/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={perpetualTutorialIcon} alt="perpetualtutorial" className="w-[1.5rem]" />

        <span>Perpetual Tutorial</span>
      </a>
    </div>
  );
};

type ChartTokenStatsProps = {
  chartToken?: TokenInfo;
  className?: string;
  deltaPercentage: number;
  deltaPercentageStr: string;
  dailyHigh: number;
  dailyLow: number;
  currentVolumeInfo: BigNumber | undefined;
  borrowFeeTextLong: string;
  borrowFeeTextShort: string;
  isMobile: boolean;
  setIsMarketDetailsOpen: Dispatch<SetStateAction<boolean>>;
};

const ChartTokenStats = ({
  chartToken,
  deltaPercentage,
  deltaPercentageStr,
  dailyHigh,
  dailyLow,
  currentVolumeInfo,
  borrowFeeTextLong,
  borrowFeeTextShort,
  className,
  isMobile,
  setIsMarketDetailsOpen,
}: ChartTokenStatsProps) => {
  return (
    <div className={cx("flex overflow-x-scroll isolate min-w-0 w-full", className)}>
      <div
        className={cx("flex items-center min-w-max", {
          "justify-between": isMobile,
        })}
      >
        <div className="ExchangeChart-additional-info border-r border-border">
          <div className="ExchangeChart-info-label">24h Change</div>

          {deltaPercentage && isFinite(deltaPercentage) ? (
            <div
              className={cx("ExchangeChart-info-percentage", {
                positive: deltaPercentage > 0,
                negative: deltaPercentage < 0,
              })}
            >
              {!deltaPercentageStr && "-"}
              {deltaPercentage > 0 ? <RxTriangleUp /> : <RxTriangleDown />}
              {deltaPercentageStr && deltaPercentageStr}
            </div>
          ) : (
            <div className="skeleton"></div>
          )}
        </div>

        {isMobile && <div className="border-r border-border h-full"></div>}

        <div className="ExchangeChart-additional-info border-r border-border">
          <div className="ExchangeChart-info-label">24h High</div>
          {dailyHigh ? (
            <div>
              {!dailyHigh && "-"}
              {dailyHigh && numberWithCommas(dailyHigh.toFixed(chartToken?.priceDecimals))}
            </div>
          ) : (
            <div className="skeleton"></div>
          )}
        </div>

        {isMobile && <div className="border-r border-border h-full"></div>}

        <div className="ExchangeChart-additional-info border-r border-border">
          <div className="ExchangeChart-info-label">24h Low</div>
          {dailyLow ? (
            <div>
              {!dailyLow && "-"}
              {dailyLow && numberWithCommas(dailyLow.toFixed(chartToken?.priceDecimals))}
            </div>
          ) : (
            <div className="skeleton"></div>
          )}
        </div>

        {isMobile && <div className="border-r border-border h-full"></div>}

        <div className="ExchangeChart-additional-info border-r border-border">
          <div className="ExchangeChart-info-label">24h Volume</div>
          {currentVolumeInfo ? (
            <div>{formatAmount(currentVolumeInfo, USD_DECIMALS, 0, true)}</div>
          ) : (
            <div className="skeleton"></div>
          )}
        </div>

        {isMobile && <div className="border-r border-border h-full"></div>}

        <div className="ExchangeChart-additional-info border-r border-border">
          <div className="ExchangeChart-info-label">Borrow Fee (l)</div>

          {borrowFeeTextLong.includes("-") ? (
            <div className="skeleton"></div>
          ) : (
            <Tooltip
              renderContent={() => {
                return (
                  <div>
                    <Trans>
                      The borrow fee is calculated as (assets borrowed) / (total assets in pool) * 0.01% per hour.
                      <br />
                      <br />
                      <ExternalLink href="https://docs.foxify.trade/">More Info</ExternalLink>
                    </Trans>
                  </div>
                );
              }}
            >
              {borrowFeeTextLong}
            </Tooltip>
          )}
        </div>

        {isMobile && <div className="border-r border-border h-full"></div>}

        <div className="ExchangeChart-additional-info">
          <div className="ExchangeChart-info-label">Borrow Fee (s)</div>

          {borrowFeeTextShort.includes("-") ? (
            <div className="skeleton"></div>
          ) : (
            <Tooltip
              renderContent={() => {
                return (
                  <div>
                    <Trans>
                      The borrow fee is calculated as (assets borrowed) / (total assets in pool) * 0.01% per hour.
                      <br />
                      <br />
                      <ExternalLink href="https://docs.foxify.trade/">More Info</ExternalLink>
                    </Trans>
                  </div>
                );
              }}
            >
              {borrowFeeTextShort}
            </Tooltip>
          )}
        </div>
      </div>
      <TokenInformations setIsVisible={setIsMarketDetailsOpen} />
    </div>
  );
};

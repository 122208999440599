import { BigNumber, ethers } from "ethers";
import { ARBITRUM, ARBITRUM_TESTNET, MAINNET, TESTNET } from "./chains";
import { Token } from "domain/tokens";
import { TokenUtils } from "components/TokenUtils";
import { getSyntheticAssetAddress } from "lib/getSyntheticAssetAddress";
import { BASIS_POINTS_DIVISOR, MAX_ALLOWED_LEVERAGE } from "../lib/constants";

const CHAIN_IDS = [MAINNET, TESTNET, ARBITRUM_TESTNET, ARBITRUM] as const;
export type ChainId = (typeof CHAIN_IDS)[number];

export const TOKENS: Record<ChainId, Token[]> = {
  [MAINNET]: [
    {
      chainId: MAINNET,
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      priceDecimals: 2,
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-bitcoin",
      pythPriceId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Bitcoin (BTC) is a decentralized cryptocurrency created in 2008 by the pseudonymous Satoshi Nakamoto. Launched in January 2009, Bitcoin operates as a peer-to-peer online currency, enabling direct transactions between network participants without intermediaries.",
    },
    {
      chainId: MAINNET,
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      priceDecimals: 2,
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      pythPriceId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Ethereum (ETH) is a decentralized cryptocurrency proposed by Vitalik Buterin in 2013, going live on July 30, 2015. Operating on a peer-to-peer network, Ethereum enables smart contracts, and Ether (ETH) is the native cryptocurrency used for transactions and rewards within its Proof of Stake (PoS) consensus mechanism",
    },
    {
      chainId: MAINNET,
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      priceDecimals: 2,
      address: ethers.constants.AddressZero,
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      isNative: true,
      pythPriceId: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Binance Coin (BNB) is a decentralized cryptocurrency introduced by Binance in July 2017. Operating on the Binance blockchain, BNB facilitates peer-to-peer transactions and serves various roles within the Binance ecosystem, including fee payments, participating in token sales, and supporting the Binance Smart Chain for decentralized applications.",
    },
    {
      chainId: MAINNET,
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      priceDecimals: 2,
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      isShortable: true,
      isWrapped: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/binance-coin",
      baseSymbol: "BNB",
      pythPriceId: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Wrapped Binance Coin (WBNB) is a decentralized cryptocurrency on the Binance Smart Chain (BSC), introduced as a wrapped version of Binance Coin (BNB). Operating peer-to-peer on the BSC, WBNB facilitates direct transactions and seamless transfer of BNB assets between blockchain networks. Widely used in BSC's DeFi applications, WBNB is essential for liquidity provision, yield farming, and various financial activities.",
    },
    {
      chainId: MAINNET,
      name: "Ripple",
      symbol: "XRP",
      decimals: 18,
      priceDecimals: 4,
      address: getSyntheticAssetAddress("XRP"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/xrp",
      pythPriceId: "0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "XRP is a decentralized cryptocurrency on the Ripple network since 2012, emphasizing fast, cost-effective cross-border payments through direct, peer-to-peer transactions. It stands out for enabling efficient real-time international money transfers.",
    },
    {
      chainId: MAINNET,
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      priceDecimals: 2,
      address: getSyntheticAssetAddress("LINK"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/chainlink",
      pythPriceId: "0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Chainlink (LINK) is a decentralized cryptocurrency in the Chainlink network since 2017, enabling direct transactions and secure smart contract interactions with external data. It plays a crucial role in incentivizing participants supplying reliable data to Chainlink's smart contracts.",
    },
    {
      chainId: MAINNET,
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      priceDecimals: 4,
      address: getSyntheticAssetAddress("MATIC"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/polygon",
      pythPriceId: "0x5de33a9112c2b700b8d30b8a3402c103578ccfa2765696471cc672bd5cf6ac52",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Polygon (MATIC) is a decentralized cryptocurrency on the Polygon network, launched in 2017 for enhanced blockchain scalability and interoperability. MATIC facilitates direct transactions, addresses scalability issues, and is utilized for fees, governance, and securing the Polygon blockchain through staking.",
    },
    {
      chainId: MAINNET,
      name: "Solana",
      symbol: "SOL",
      decimals: 18,
      priceDecimals: 2,
      address: getSyntheticAssetAddress("SOL"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/solana",
      pythPriceId: "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Solana (SOL) is a decentralized cryptocurrency on the Solana blockchain since 2020, allowing direct transactions with low fees and rapid confirmation times. SOL is used for transaction fees, network governance, and supporting decentralized applications (DApps) on Solana, known for its high-performance and efficient design, making it popular among developers and users.",
    },
    {
      chainId: MAINNET,
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      priceDecimals: 2,
      address: getSyntheticAssetAddress("AVAX"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/avalanche",
      pythPriceId: "0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Avalanche (AVAX), launched in 2020, enables direct transactions with high throughput and serves multiple functions, including transaction fees and supporting decentralized applications (DApps). Its focus on scalability and flexibility makes AVAX crucial in decentralized finance and blockchain applications.",
    },
    {
      chainId: MAINNET,
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 18,
      priceDecimals: 5,
      address: getSyntheticAssetAddress("DOGE"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
      pythPriceId: "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
      maxLeverage: BigNumber.from(20 * BASIS_POINTS_DIVISOR),
      description:
        "Dogecoin (DOGE), born from a meme in 2013, has become a popular digital asset for direct transactions on a peer-to-peer network. Recognized for its Shiba Inu logo and friendly community, DOGE is widely used for online tipping and small transactions, transcending its humorous origins.",
    },
    // {
    //   chainId: MAINNET,
    //   name: "Binance USD",
    //   symbol: "BUSD",
    //   decimals: 18,
    //   priceDecimals: 2,
    //   address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    //   isStable: true,
    //   coingeckoUrl: "https://www.coingecko.com/en/coins/binance-usd",
    //   // isTempHidden: true,
    // },
    // {
    //   chainId: MAINNET,
    //   name: "USD Coin",
    //   symbol: "USDC",
    //   decimals: 18,
    //   priceDecimals: 2,
    //   address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    //   isStable: true,
    //   coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
    //   // isTempHidden: true,
    // },
    {
      chainId: MAINNET,
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      priceDecimals: 2,
      address: "0x55d398326f99059fF775485246999027B3197955",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      pythPriceId: "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b",
    },
  ],
  [TESTNET]: [
    {
      chainId: TESTNET,
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      priceDecimals: 2,
      address: "0x7e0bBC97333458dD27048753336478DeDd0bB11d",
      isShortable: true,
      pythPriceId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Bitcoin (BTC) is a decentralized cryptocurrency created in 2008 by the pseudonymous Satoshi Nakamoto. Launched in January 2009, Bitcoin operates as a peer-to-peer online currency, enabling direct transactions between network participants without intermediaries.",
    },
    {
      chainId: TESTNET,
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      priceDecimals: 2,
      address: "0x1FEc323e5eD2D39d6bfC8E1F87dF0f8E054C9bAA",
      isShortable: true,
      pythPriceId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Ethereum (ETH) is a decentralized cryptocurrency proposed by Vitalik Buterin in 2013, going live on July 30, 2015. Operating on a peer-to-peer network, Ethereum enables smart contracts, and Ether (ETH) is the native cryptocurrency used for transactions and rewards within its Proof of Stake (PoS) consensus mechanism",
    },
    {
      chainId: TESTNET,
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      priceDecimals: 2,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      pythPriceId: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Binance Coin (BNB) is a decentralized cryptocurrency introduced by Binance in July 2017. Operating on the Binance blockchain, BNB facilitates peer-to-peer transactions and serves various roles within the Binance ecosystem, including fee payments, participating in token sales, and supporting the Binance Smart Chain for decentralized applications.",
    },
    {
      chainId: TESTNET,
      name: "Wrapped Binance Coin",
      symbol: "WBNB",
      decimals: 18,
      priceDecimals: 2,
      address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      isWrapped: true,
      baseSymbol: "BNB",
      isShortable: true,
      pythPriceId: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Wrapped Binance Coin (WBNB) is a decentralized cryptocurrency on the Binance Smart Chain (BSC), introduced as a wrapped version of Binance Coin (BNB). Operating peer-to-peer on the BSC, WBNB facilitates direct transactions and seamless transfer of BNB assets between blockchain networks. Widely used in BSC's DeFi applications, WBNB is essential for liquidity provision, yield farming, and various financial activities.",
    },
    {
      chainId: TESTNET,
      name: "Ripple",
      symbol: "XRP",
      decimals: 18,
      priceDecimals: 4,
      address: getSyntheticAssetAddress("XRP"),
      isShortable: true,
      pythPriceId: "0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "XRP is a decentralized cryptocurrency on the Ripple network since 2012, emphasizing fast, cost-effective cross-border payments through direct, peer-to-peer transactions. It stands out for enabling efficient real-time international money transfers.",
    },
    {
      chainId: TESTNET,
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      priceDecimals: 2,
      address: getSyntheticAssetAddress("LINK"),
      isShortable: true,
      pythPriceId: "0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Chainlink (LINK) is a decentralized cryptocurrency in the Chainlink network since 2017, enabling direct transactions and secure smart contract interactions with external data. It plays a crucial role in incentivizing participants supplying reliable data to Chainlink's smart contracts.",
    },
    {
      chainId: TESTNET,
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      priceDecimals: 4,
      address: getSyntheticAssetAddress("MATIC"),
      isShortable: true,
      pythPriceId: "0x5de33a9112c2b700b8d30b8a3402c103578ccfa2765696471cc672bd5cf6ac52",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Polygon (MATIC) is a decentralized cryptocurrency on the Polygon network, launched in 2017 for enhanced blockchain scalability and interoperability. MATIC facilitates direct transactions, addresses scalability issues, and is utilized for fees, governance, and securing the Polygon blockchain through staking.",
    },
    {
      chainId: TESTNET,
      name: "Solana",
      symbol: "SOL",
      decimals: 18,
      priceDecimals: 2,
      address: getSyntheticAssetAddress("SOL"),
      isShortable: true,
      pythPriceId: "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Solana (SOL) is a decentralized cryptocurrency on the Solana blockchain since 2020, allowing direct transactions with low fees and rapid confirmation times. SOL is used for transaction fees, network governance, and supporting decentralized applications (DApps) on Solana, known for its high-performance and efficient design, making it popular among developers and users.",
    },
    {
      chainId: TESTNET,
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      priceDecimals: 2,
      address: getSyntheticAssetAddress("AVAX"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/avalanche",
      pythPriceId: "0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Avalanche (AVAX), launched in 2020, enables direct transactions with high throughput and serves multiple functions, including transaction fees and supporting decentralized applications (DApps). Its focus on scalability and flexibility makes AVAX crucial in decentralized finance and blockchain applications.",
    },
    {
      chainId: TESTNET,
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 18,
      priceDecimals: 5,
      address: getSyntheticAssetAddress("DOGE"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
      pythPriceId: "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
      maxLeverage: BigNumber.from(20 * BASIS_POINTS_DIVISOR),
      description:
        "Dogecoin (DOGE), born from a meme in 2013, has become a popular digital asset for direct transactions on a peer-to-peer network. Recognized for its Shiba Inu logo and friendly community, DOGE is widely used for online tipping and small transactions, transcending its humorous origins.",
    },
    // TODO: uncommenting this block breaks price calculation and position creation
    // {
    //   chainId: TESTNET,
    //   name: "Binance USD",
    //   symbol: "BUSD",
    //   decimals: 18,
    //   priceDecimals: 2,
    //   address: "0x9431bCa7eC33E90eB5f5F32b96dF15b5681af076",
    //   isStable: true,
    //   // isTempHidden: true,
    // },
    {
      chainId: TESTNET,
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      priceDecimals: 2,
      address: "0x171529efB5fD8AD86Ba8DDBD841bF9FcdF04CC88",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      pythPriceId: "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b",
    },
  ],
  [ARBITRUM_TESTNET]: [
    {
      chainId: ARBITRUM_TESTNET,
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      priceDecimals: 2,
      address: "0xDa751873cd11cCf2EE23651425511f3bf7B3cfeA",
      isShortable: true,
      pythPriceId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Bitcoin (BTC) is a decentralized cryptocurrency created in 2008 by the pseudonymous Satoshi Nakamoto. Launched in January 2009, Bitcoin operates as a peer-to-peer online currency, enabling direct transactions between network participants without intermediaries.",
    },
    {
      chainId: ARBITRUM_TESTNET,
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      priceDecimals: 2,
      address: ethers.constants.AddressZero,
      isShortable: true,
      pythPriceId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Ethereum (ETH) is a decentralized cryptocurrency proposed by Vitalik Buterin in 2013, going live on July 30, 2015. Operating on a peer-to-peer network, Ethereum enables smart contracts, and Ether (ETH) is the native cryptocurrency used for transactions and rewards within its Proof of Stake (PoS) consensus mechanism",
    },
    {
      chainId: ARBITRUM_TESTNET,
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      priceDecimals: 2,
      address: "0xDD47235189d5FB61A16daEd5Bcb12A65B683E395",
      isShortable: true,
      isWrapped: true,
      baseSymbol: "ETH",
      pythPriceId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Ethereum (ETH) is a decentralized cryptocurrency proposed by Vitalik Buterin in 2013, going live on July 30, 2015. Operating on a peer-to-peer network, Ethereum enables smart contracts, and Ether (ETH) is the native cryptocurrency used for transactions and rewards within its Proof of Stake (PoS) consensus mechanism",
    },
    // USDC here is actually USDT on ARB TESTNET it is just renamed
    {
      chainId: ARBITRUM_TESTNET,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      priceDecimals: 2,
      address: "0x2cec5620B4448Fa36F753Db7B496Ce51cC096d08",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
      pythPriceId: "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b",
    },
    {
      chainId: ARBITRUM_TESTNET,
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 18,
      priceDecimals: 5,
      address: getSyntheticAssetAddress("DOGE"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
      pythPriceId: "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
      maxLeverage: BigNumber.from(50 * BASIS_POINTS_DIVISOR),
      description:
        "Dogecoin (DOGE), born from a meme in 2013, has become a popular digital asset for direct transactions on a peer-to-peer network. Recognized for its Shiba Inu logo and friendly community, DOGE is widely used for online tipping and small transactions, transcending its humorous origins.",
    },
  ],
  [ARBITRUM]: [
    {
      chainId: ARBITRUM,
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      priceDecimals: 2,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      pythPriceId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Bitcoin (BTC) is a decentralized cryptocurrency created in 2008 by the pseudonymous Satoshi Nakamoto. Launched in January 2009, Bitcoin operates as a peer-to-peer online currency, enabling direct transactions between network participants without intermediaries.",
    },
    {
      chainId: ARBITRUM,
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      priceDecimals: 2,
      address: ethers.constants.AddressZero,
      isShortable: true,
      pythPriceId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Ethereum (ETH) is a decentralized cryptocurrency proposed by Vitalik Buterin in 2013, going live on July 30, 2015. Operating on a peer-to-peer network, Ethereum enables smart contracts, and Ether (ETH) is the native cryptocurrency used for transactions and rewards within its Proof of Stake (PoS) consensus mechanism",
    },
    {
      chainId: ARBITRUM,
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      priceDecimals: 2,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isShortable: true,
      isWrapped: true,
      baseSymbol: "ETH",
      pythPriceId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Ethereum (ETH) is a decentralized cryptocurrency proposed by Vitalik Buterin in 2013, going live on July 30, 2015. Operating on a peer-to-peer network, Ethereum enables smart contracts, and Ether (ETH) is the native cryptocurrency used for transactions and rewards within its Proof of Stake (PoS) consensus mechanism",
    },
    {
      chainId: ARBITRUM,
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      priceDecimals: 4,
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      isShortable: true,
      isWrapped: false,
      pythPriceId: "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      description:
        "Arbitrum is an Ethereum layer-two (L2) scaling solution. Arbitrum’s native token is called ARB and is used for governance. ARB holders can vote on proposals that affect the features, protocol upgrades, funds allocation and election of a Security Council.",
    },
    {
      chainId: ARBITRUM,
      name: "Solana",
      symbol: "SOL",
      address: "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
      decimals: 9,
      isShortable: true,
      isTempHidden: true,
      priceDecimals: 2,
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      pythPriceId: "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
      description:
        "Solana (SOL) is a decentralized cryptocurrency on the Solana blockchain since 2020, allowing direct transactions with low fees and rapid confirmation times. SOL is used for transaction fees, network governance, and supporting decentralized applications (DApps) on Solana, known for its high-performance and efficient design, making it popular among developers and users.",
    },
    {
      chainId: ARBITRUM,
      name: "Binance Coin",
      symbol: "BNB",
      address: "0x20865e63B111B2649ef829EC220536c82C58ad7B",
      decimals: 18,
      isShortable: true,
      priceDecimals: 2,
      maxLeverage: BigNumber.from(MAX_ALLOWED_LEVERAGE),
      pythPriceId: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
      description:
        "Binance Coin (BNB) is a decentralized cryptocurrency on the Binance Smart Chain (BSC), introduced as a wrapped version of Binance Coin (BNB). Operating peer-to-peer on the BSC, BNB facilitates direct transactions and seamless transfer of BNB assets between blockchain networks. Widely used in BSC's DeFi applications, BNB is essential for liquidity provision, yield farming, and various financial activities.",
    },
    {
      chainId: ARBITRUM,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      priceDecimals: 2,
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      isStable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/usdc",
      pythPriceId: "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
    },
    {
      chainId: ARBITRUM,
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 18,
      priceDecimals: 5,
      address: getSyntheticAssetAddress("DOGE"),
      isShortable: true,
      coingeckoUrl: "https://www.coingecko.com/en/coins/dogecoin",
      pythPriceId: "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
      maxLeverage: BigNumber.from(50 * BASIS_POINTS_DIVISOR),
      description:
        "Dogecoin (DOGE), born from a meme in 2013, has become a popular digital asset for direct transactions on a peer-to-peer network. Recognized for its Shiba Inu logo and friendly community, DOGE is widely used for online tipping and small transactions, transcending its humorous origins.",
    },
  ],
};

// TChainId

export const ICONLINKS = {
  [MAINNET]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      explorerLink: "https://bscscan.com/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      explorerLink: "https://bscscan.com/token/0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=ARBITRUM",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      explorerLink: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      explorerLink: "https://bscscan.com/address/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      explorerLink: "https://bscscan.com/address/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      explorerLink: "https://bscscan.com/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      explorerLink: "https://bscscan.com/address/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      explorerLink: "https://bscscan.com/address/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      explorerLink: "https://bscscan.com/address/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      explorerLink: "https://bscscan.com/address/0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    },
    XRP: {
      coingecko: "https://www.coingecko.com/en/coins/xrp",
    },
  },
  [TESTNET]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      explorerLink: "https://testnet.bscscan.com/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      explorerLink: "https://testnet.bscscan.com/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
    PLP: {
      explorerLink: "https://testnet.bscscan.com/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
    XRP: {
      coingecko: "https://www.coingecko.com/en/coins/xrp",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
    },
    MATIC: {
      coingecko: "https://www.coingecko.com/en/coins/polygon",
    },
    SOL: {
      coingecko: "https://www.coingecko.com/en/coins/solana",
    },
  },
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  MIM: "#9695F8",
  FRAX: "#000",
  DAI: "#FAC044",
  UNI: "#E9167C",
  AVAX: "#E84142",
  LINK: "#3256D6",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token | undefined } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token | undefined } } = {};
export const WRAPPED_TOKENS_BY_BASE_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token | undefined } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];

  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  WRAPPED_TOKENS_BY_BASE_SYMBOL_MAP[chainId] = {};

  let tokens = TOKENS[chainId];

  tokens.forEach((token) => {
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;

    if (token.baseSymbol) {
      WRAPPED_TOKENS_BY_BASE_SYMBOL_MAP[chainId][token.baseSymbol] = token;
    }
  });
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if ((token as Token).isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if ((token as Token).isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string): Token {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address]!;
}

export function getTokenBySymbol(chainId: number, symbol: string, { ignoreWrappedTokens = false } = {}) {
  let token: Token | undefined;

  if (!ignoreWrappedTokens) {
    token = WRAPPED_TOKENS_BY_BASE_SYMBOL_MAP[chainId][symbol];
  }
  if (!token) {
    token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  }
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId];
}

export function getVisibleTokens(chainId: number) {
  return TokenUtils.filterVisible(getWhitelistedTokens(chainId));
}

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  [MAINNET]: ["ETH", "BTC", "BNB", "XRP", "SOL", "MATIC", "LINK", "AVAX", "DOGE"],
  [TESTNET]: ["ETH", "BTC", "BNB", "XRP", "SOL", "MATIC", "LINK", "AVAX", "DOGE"],
  [ARBITRUM_TESTNET]: ["ETH", "BTC", "BNB", "XRP", "SOL", "MATIC", "LINK", "AVAX", "DOGE", "ARB"],
  [ARBITRUM]: ["ETH", "BTC", "BNB", "XRP", "SOL", "MATIC", "LINK", "AVAX", "DOGE", "ARB"],
} satisfies {
  [Key in ChainId]: Array<(typeof TOKENS)[Key][number]["symbol"]>;
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;
  return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(getNormalizedTokenSymbol(tokenSymbol))) ?? false;
}

import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import Button from "components/Button/Button";
import { Trans, t } from "@lingui/macro";
import { BigNumber, ethers } from "ethers";
import { formatAmount } from "lib/numbers";
import { useTokenBalance } from "hooks/blockchain/useTokenBalance";
import { getContractAddress } from "config/contracts";
import { useChainId } from "lib/chains";
import { callContract } from "lib/contracts";
import { getCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { USD_DECIMALS, USDC_DECIMALS } from "lib/constants";
import { useInfoTokens } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import FundedFactoryAbi from "abis/Factory.json";
import { useFundedTrader } from "../../hooks/useFundedTrader";
import { useTokenAllowance } from "../../pages/Event/hooks/blockchain/useTokenAllowance";
import { useHistory } from "react-router-dom";
import { formatBigAmount } from "lib/formatAmount";

const fractions = [
  {
    value: 0.1,
    label: "10%",
  },
  {
    value: 0.25,
    label: "25%",
  },
  {
    value: 0.5,
    label: "50%",
  },
  {
    value: 0.75,
    label: "75%",
  },
  {
    value: 1,
    label: "100%",
  },
];

export const CreateFundedModal = ({ open, setOpen, label, setPendingTxns, pendingTxns }) => {
  const [deposit, setDeposit] = useState<string>("");
  const [fraction, setFraction] = useState<number | undefined>();
  const [isDepositing, setIsDepositing] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isCreatingTrader, setIsCreatingTrader] = useState(false);

  const history = useHistory();
  const { provider: library, isActive, account } = useWeb3React();
  const { chainId } = useChainId();
  const { connectWallet } = useConnectWalletUi();
  const { infoTokens } = useInfoTokens();

  const collateralTokenAddress = getCollateralTokenAddress(chainId);
  const fundedFactoryAddress = getContractAddress(chainId, "Factory");
  const { balance } = useTokenBalance(collateralTokenAddress, account ? account.toLowerCase() : "");
  const { traderChallengeConfig, traderContract, traderContractAddress, isFundedCreated, refetchTrader } =
    useFundedTrader();
  const { haveEnoughAllowance, approve } = useTokenAllowance(
    collateralTokenAddress,
    account || "",
    traderContractAddress || ""
  );

  const usdcInfo = getTokenInfo(infoTokens, collateralTokenAddress);

  const depositInUsd =
    deposit && usdcInfo.maxPrice && usdcInfo.maxPrice.gt(0)
      ? ethers.utils.parseUnits(deposit, USDC_DECIMALS).mul(usdcInfo.maxPrice).div(BigNumber.from(10).pow(USD_DECIMALS))
      : BigNumber.from(0);

  const minimumDeposit = traderChallengeConfig ? traderChallengeConfig.minDepositStable : BigNumber.from(0);

  const isBalanceEnough = balance ? balance?.gte(minimumDeposit) : false;
  const depositBigNumber = deposit ? ethers.utils.parseUnits(deposit?.toString(), USDC_DECIMALS) : BigNumber.from(0);
  const isDepositEnough = depositBigNumber.gte(minimumDeposit);
  const isBalanceExceeded = balance ? depositBigNumber.gt(balance) : false;
  const needsApproval = !haveEnoughAllowance(depositBigNumber);

  const onFundedCreatingClick = async () => {
    if (!isActive) {
      connectWallet();
      return;
    }

    if (!isFundedCreated) {
      try {
        setIsCreatingTrader(true);
        const contract = new ethers.Contract(fundedFactoryAddress, FundedFactoryAbi.abi, library?.getSigner());
        const tx = await callContract(chainId, contract, "createTrader", [], {
          setPendingTxns,
          txnSuccessCallback: () => {
            setIsCreatingTrader(false);
          },
        });
        await tx.wait();
        refetchTrader();
      } catch (error) {
        setIsCreatingTrader(false);
      }
      return;
    }

    if (needsApproval) {
      setIsApproving(true);
      const tx = await approve(depositBigNumber);
      if (tx) {
        await tx.wait();
      }
      setIsApproving(false);
      return;
    }

    if (traderContract) {
      try {
        setIsDepositing(true);
        await callContract(chainId, traderContract, "fundAccount", [depositBigNumber, true], {
          setPendingTxns,
          txnSuccessCallback: () => {
            setIsDepositing(false);
            setDeposit("");
            closeModal();
            history.push("/funded");
          },
        });
      } catch (e) {
        setIsDepositing(false);
      }
    }
  };

  const getError = () => {
    if (!isBalanceEnough) {
      return [t`You don't have enough USDC`];
    }
    if (!isDepositEnough && deposit && +deposit > 0) {
      return [t`Your deposit less than minimum amount`];
    }
    if (isBalanceExceeded) {
      return [t`Your balance is exceeded`];
    }
    if (needsApproval || isApproving) {
      return [false];
    }

    return [false];
  };
  const getPrimaryText = () => {
    if (!isActive) {
      return t`Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isCreatingTrader) {
      return t`Creating Trader Account...`;
    }
    if (!isFundedCreated) {
      return t`Create & Fund Account`;
    }
    if (isApproving) {
      return t`Approving USDC...`;
    }
    if (needsApproval) {
      return t`Approve USDC`;
    }
    if (isDepositing) {
      return t`Depositing USDC...`;
    }

    return t`Fund Account`;
  };
  const isBtnDisabled = () =>
    (!isDepositEnough || !isBalanceEnough || isBalanceExceeded || isApproving || isDepositing || isCreatingTrader) &&
    isActive;
  const closeModal = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (fraction) {
      const formattedBalance = ethers.utils.formatEther(balance as BigNumber);
      const formattedValue = parseFloat((Number(formattedBalance) * fraction)?.toFixed(3));
      setDeposit(formattedValue.toString());
    }
    return () => setFraction(undefined);
  }, [balance, fraction]);
  return (
    <ModalWithPortal className="tailwind" isVisible={open} setIsVisible={closeModal} label={label}>
      <div className="flex flex-col w-full">
        <div className="border rounded-[1rem] text-inactive text-[1.4rem] py-[1.5rem] px-[1rem] mb-[1.5rem]">
          You’ll need {formatBigAmount(minimumDeposit, USDC_DECIMALS)} USDC minimum on Arbitrum Network in your Web3
          Wallet and arbETH for gas.
        </div>
        <div className="flex flex-col gap-[1rem] w-full mb-[2.5rem]">
          <div className="text-white text-[1.4rem]">
            Deposit (Minimum {formatBigAmount(minimumDeposit, USDC_DECIMALS)} USDC)
          </div>
          <div className="input-wrapper">
            <input
              type="number"
              className="Earn-input"
              value={deposit}
              onChange={(e) => {
                setFraction(undefined);
                setDeposit(e.target.value);
              }}
            />
            <div className="input-end-content">
              <div className="inactive-text">~ ${formatAmount(depositInUsd, USDC_DECIMALS, 2, false)}</div>
            </div>
          </div>

          <div className="inactive-text">
            <Trans>Balance:</Trans> {formatAmount(balance, USDC_DECIMALS, 2, true)} USDC
          </div>
          <div className="fraction-selector-wrapper">
            {fractions.map((fraction, i) => (
              <div className="fraction-selector-item" key={i} onClick={() => setFraction(fraction.value)}>
                {fraction.label}
              </div>
            ))}
          </div>
        </div>

        <Button
          variant="primary-action"
          disabled={isBtnDisabled()}
          onClick={onFundedCreatingClick}
          className="w-full rounded mt-1"
        >
          {getPrimaryText()}
        </Button>
      </div>
    </ModalWithPortal>
  );
};

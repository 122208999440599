import { useState, useRef, useEffect } from "react";
import cx from "classnames";
import Modal from "../Modal/Modal";
// import { locales } from "lib/i18n";
import "./Settings.css";
import { importImage } from "lib/legacy";
import { t, Trans } from "@lingui/macro";
import { helperToast } from "lib/helperToast";

import { defaultLocale } from "lib/i18n";
import { MdKeyboardArrowRight } from "react-icons/md";
import LanguageModalContent
  from "../../components/SettingsDropdown/LanguageModalContent";
import {
  LANGUAGE_LOCALSTORAGE_KEY,
  NOTIFICATION_LOCALSTORAGE_POSITION_KEY
} from "config/localStorage";
import { Switch } from "../Switch/Switch";

import { useLocalStorageReactive } from "lib/localStorage";
import GasModalContent from "components/SettingsDropdown/GasModalContent";
import ThemeModalContant from "components/SettingsDropdown/ThemeModalContant";
import Button from "components/Button/Button";
// import { useIsDevelopment } from "lib/useIsDevelopment";
import { useAtom } from "jotai";
import {
  isAdvancedChartEnabledAtom,
  isChartTradingEnabledAtom,
  isChartTradingEnabledOnMobileAtom
} from "pages/Exchange/state";
import useThemeSwitcher from "lib/useThemeSwitcher";
import { useMedia } from "react-use";
import { useGasPreference } from "lib/useGasPreference";
import { THEMES } from "lib/themes";

const LANGUAGE_MODAL_KEY: string = "LANGUAGE";
const GAS_MODAL_KEY: string = "GAS";
const THEME_MODAL_KEY: string = "THEME";

export default function SettingsModal(props: any) {
  const currentLanguage = useRef(localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale);

  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [isLanguageVisible, setLanguageVisible] = useState(false);
  const [isGasVisible, setGasVisible] = useState(false);
  const [isThemeVisible, setIsWhiteThemeVisible] = useState(false);
  // const isDevelopment = useIsDevelopment();
  const [gasPreference, setGasPreference] = useGasPreference();
  const isMobile = useMedia("(max-width: 1100px)");

  const [isChartTradingEnabled, setIsChartTradingEnabled] = useAtom(
    isMobile ? isChartTradingEnabledOnMobileAtom : isChartTradingEnabledAtom
  );

  const [isAdvancedChartEnabled, setIsAdvancedChartEnabled] = useAtom(isAdvancedChartEnabledAtom);
  const { setTheme, theme } = useThemeSwitcher();

  const [notificationPositionLocalStorage, setNotificationPosition] = useLocalStorageReactive(
    NOTIFICATION_LOCALSTORAGE_POSITION_KEY,
    "bottom-right"
  );

  const [notificationDisplayed, setNotificationDisplayed] = useState(false);

  const {
    disabled = false,
    isVisible,
    setIsVisible,
    openSettings,
    savedSlippageAmount,
    setSavedSlippageAmount,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    tradingLayout,
    setTradingLayout
  } = props;

  const getModalContent = (modalName) => {
    const modalComponents = {
      [LANGUAGE_MODAL_KEY]: <LanguageModalContent
        currentLanguage={currentLanguage} />,
      [GAS_MODAL_KEY]: <GasModalContent gasPreference={gasPreference}
                                        setGasPreference={setGasPreference} />,
      [THEME_MODAL_KEY]: <ThemeModalContant />
    };

    return modalComponents[modalName] || null;
  }

  const getModalProps = (modalName: any) => {
    if (modalName === LANGUAGE_MODAL_KEY) {
      return {
        className: "language-popup",
        isSecondVisible: isLanguageVisible,
        setIsSecondVisible: () => setLanguageVisible((prev) => !prev),
        label: t`Select Language`
      };
    }
    if (modalName === GAS_MODAL_KEY) {
      return {
        className: "language-popup",
        isSecondVisible: isGasVisible,
        setIsSecondVisible: () => setGasVisible((prev) => !prev),
        label: t`Gas Preferences`
      };
    }
    if (modalName === THEME_MODAL_KEY) {
      return {
        className: "language-popup",
        isSecondVisible: isThemeVisible,
        setIsSecondVisible: () => setIsWhiteThemeVisible((prev) => !prev),
        label: t`Theme Settings`
      };
    }
    return {};
  };

  const handleNotification = (position) => {
    setNotificationPosition(position);
    setNotificationDisplayed(true);
  };

  const handlePositionToggle = () => {
    setSavedShouldShowPositionLines(!savedShouldShowPositionLines);
    window.scrollTo({
      top: 0
    });
  };

  const handleResetLayout = () => {
    setIsChartTradingEnabled(false);
    setTradingLayout("right");
    setNotificationPosition("bottom-right");
    setIsAdvancedChartEnabled(true);
    setSavedShouldShowPositionLines(true);
    setTheme(THEMES.dark.name);
    setSavedSlippageAmount(30);
    setGasPreference("standard");
  };

  const modalProps = getModalProps(activeModal);
  // const identifiers = Object.keys(locales);
  // const currentIdentifier = identifiers.find((identifier) => locales[identifier] === locales[currentLanguage.current]);
  // const languageImage = importImage(`flag_${currentIdentifier}.svg`);
  const gasPreferenceIcon = importImage("GasPreference.svg");
  const themeSettingsIcon = importImage("ThemeSettings.svg");
  const tradingSettingsIcon = importImage("TradingSettings.svg");
  // const languageIcon = importImage("Language.svg");

  const slippage = parseInt(savedSlippageAmount);

  const basisPoints = slippage / 100;

  useEffect(() => {
    if (notificationDisplayed && notificationPositionLocalStorage !== undefined) {
      helperToast.success(
        <div>
          <Trans>Notification position set
            to {notificationPositionLocalStorage}</Trans>
          <br />
        </div>
      );
    }
  }, [notificationPositionLocalStorage, notificationDisplayed]);

  return (
    <div
      className={cx("TokenSelector tailwind", { disabled }, props.className)}>
      <Modal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label={props.label}
        placement={isMobile ? "center" : "right"}
      >
        <div className="TokenSelector-tokens">
          <div className={cx("Settings-row")}
               onClick={() => setActiveModal(GAS_MODAL_KEY)}>
            <div className="Settings-item" onClick={() => setGasVisible(true)}>
              <div className="Settings-item-title-wrapper">
                <img src={gasPreferenceIcon} alt="gas"
                     className="Settings-item-icon"/>
                <p className="Settings-item-title">Gas Preferences</p>
              </div>

              <div className="Settings-item-sub">
                <Trans>
                  {(gasPreference || "Standard").charAt(0)
                    .toUpperCase() + (gasPreference || "Standard").slice(1)}
                </Trans>

                <MdKeyboardArrowRight fill="#6C7284"/>
              </div>
            </div>
          </div>
          <div className={cx("Settings-row")}
               onClick={() => setActiveModal(THEME_MODAL_KEY)}>
            <div className="Settings-item"
                 onClick={() => setIsWhiteThemeVisible(true)}>
              <div className="Settings-item-title-wrapper">
                <img src={themeSettingsIcon} alt="theme"
                     className="Settings-item-icon"/>

                <p className="Settings-item-title">Theme Settings</p>
              </div>

              <div className="Settings-item-sub">
                <Trans>
                  {(String(theme) || "Dark").charAt(0)
                    .toUpperCase() + String(theme).slice(1).toLowerCase()}
                </Trans>
                <MdKeyboardArrowRight fill="#6C7284"/>
              </div>
            </div>
          </div>
          <div className={cx("Settings-row")} onClick={openSettings}>
            <div className="Settings-item">
              <div className="Settings-item-title-wrapper">
                <img src={tradingSettingsIcon} alt="trading"
                     className="Settings-item-icon"/>

                <p className="Settings-item-title">Trading Settings</p>
              </div>
              <p className="Settings-item-info">{basisPoints}%</p>
            </div>
          </div>
          {/* {isDevelopment && (
            <div className={cx("Settings-row")} onClick={() => setActiveModal(LANGUAGE_MODAL_KEY)}>
              <div className="Settings-item" onClick={() => setLanguageVisible(true)}>
                <div className="Settings-item-title-wrapper">
                  <img src={languageIcon} alt="language" className="Settings-item-icon" />
                  <p className="Settings-item-title">Language</p>
                </div>

                <div className="Settings-item-right">
                  <img
                    className="network-dropdown-icon languageImage"
                    src={languageImage}
                    alt={locales[currentLanguage.current]}
                  />
                  <p className="Settings-item-info">{locales[currentLanguage.current]}</p>
                </div>
              </div>
            </div>
          )} */}

          <div className="line md:flex hidden"/>
          <div
            className="p-[0.8rem] text-inactive text-[1.4rem] md:flex flex-col	 hidden">
            <p className="Settings-Layout-title">Layout</p>
            <p className="Setting-Layout-subtitle">Trading Panel Side</p>
            <div className="Settings-Layout-items">
              <div
                className={cx("Settings-Layout-left", {"Settings-Layout-active": tradingLayout === "left"})}
                onClick={() => setTradingLayout("left")}
              >
                <div className="Settings-Layout-panel"/>
                <p>Left</p>
              </div>
              <div
                className={cx("Settings-Layout-right", {"Settings-Layout-active": tradingLayout === "right"})}
                onClick={() => setTradingLayout("right")}
              >
                <p>Right</p>
                <div className="Settings-Layout-panel"/>
              </div>
            </div>
          </div>
          <div className="line md:flex hidden"/>
          <div
            className="p-[0.8rem] text-inactive text-[1.4rem] md:flex flex-col	 hidden">
            <p className="Setting-Layout-subtitle">Notfication Position</p>

            <div className="Settings-notification-items">
              <div className="Settings-notification-position-top">
                <div
                  className={`Settings-notification-panel ${
                    notificationPositionLocalStorage === "top-left" ? "NotificationSelected" : ""
                  }`}
                  onClick={() => handleNotification("top-left")}
                >
                  <p>Top-Left</p>
                  <div className="Settings-notification-top-left"/>
                </div>
                <div
                  className={`Settings-notification-panel ${
                    notificationPositionLocalStorage === "top-right" ? "NotificationSelected" : ""
                  }`}
                  onClick={() => handleNotification("top-right")}
                >
                  <div className="Settings-notification-top-right"/>
                  <p>Top-Right</p>
                </div>
              </div>
              <div className="Settings-notification-position-bottom">
                <div
                  className={`Settings-notification-panel ${
                    notificationPositionLocalStorage === "bottom-left" ? "NotificationSelected" : ""
                  }`}
                  onClick={() => handleNotification("bottom-left")}
                >
                  <p>Bottom-Left</p>
                  <div className="Settings-notification-bottom-left"/>
                </div>
                <div
                  className={`Settings-notification-panel ${
                    notificationPositionLocalStorage === "bottom-right" ? "NotificationSelected" : ""
                  }`}
                  onClick={() => handleNotification("bottom-right")}
                >
                  <div className="Settings-notification-bottom-right"/>
                  <p>Bottom-Right</p>
                </div>
              </div>
            </div>
          </div>

          <div className="line"/>
          <div className="Settings-Layout">
            <p className="Setting-Layout-subtitle ">Chart</p>
            <div className="Settings-chart-items">
              <div
                className="flex flex-row py-[0.3rem] md:flex justify-between">
                <p>Quick Order</p>
                <Switch
                  checked={isChartTradingEnabled}
                  onChange={() => setIsChartTradingEnabled(!isChartTradingEnabled)}
                />
              </div>

              <div className="Settings-chart-item">
                <p>Use Advanced Chart</p>
                <Switch
                  checked={isAdvancedChartEnabled}
                  onChange={() => setIsAdvancedChartEnabled(!isAdvancedChartEnabled)}
                />
              </div>
              <div className="Settings-chart-item">
                <p>Show Open Positions</p>
                <Switch checked={savedShouldShowPositionLines}
                        onChange={handlePositionToggle}/>
              </div>
            </div>
          </div>

          <div className="line"/>

          <Button variant="secondary" onClick={handleResetLayout}>
            Reset Layout
          </Button>
        </div>
      </Modal>
      {modalProps.isSecondVisible !== undefined && (
        <Modal
          placement={isMobile ? "center" : "right"}
          {...modalProps}
          disableBodyScrollLock={true}
          isVisible={modalProps.isSecondVisible}
          setIsVisible={modalProps.setIsSecondVisible}
          isSecondModal={true}
        >
          {getModalContent(activeModal)}
        </Modal>
      )}
    </div>
  );
}

import noPositionIcon from "img/No-position-order.png";

export function EmptyPlaceholder({ message = "" }) {
  return (
    <div className="flex flex-col items-center justify-center gap-4 h-[16rem]">
      <img src={noPositionIcon} alt="placeholder" className="w-[10rem] pointer-events-none select-none" />

      <p className="text-inactive text-[1.2rem] leading-[1.5]">{message}</p>
    </div>
  );
}

import { t } from "@lingui/macro";
import benefit1 from "img/benefit1.png";
import benefit2 from "img/benefit2.png";
import benefit3 from "img/benefit3.png";
import benefit4 from "img/benefit4.png";
import benefit5 from "img/benefit5.png";

interface FundedBenefit {
  title: string;
  description: string;
  img: string;
}

const FundedTradingBenefits = () => {
  const fundedBenefits: FundedBenefit[] = [
    {
      title: t`Funded within 7 days`,
      description:
        t`Trade for 7 days with at least $400 collateral & meet KPIs to qualify. Retain 100% of your profits.`,
      img: benefit1,
    },
    {
      title: t`80% profit split`,
      description: t`Earn 80% of the profits you generate from your funded account.`,
      img: benefit2,
    },
    {
      title: t`No Paper Trading`,
      description: t`Real trading from day 1, trade top  crypto pairs verifiable on-chain.`,
      img: benefit3,
    },
    {
      title: t`No Drawdown Limit`,
      description: t`Dedicated to your success, we allow you to trade without drawdown limits.`,
      img: benefit4,
    },
    {
      title: t`No Deposit`,
      description: t`Unlike other traditional prop firms, Foxify does not charge any application or one-time fees.`,
      img: benefit5,
    },
  ];
  return (
    <ul className="grid grid-cols-1 lg:grid-cols-5 gap-8">
      {fundedBenefits.map(({ title, description, img }) => (
        <li
          className="bg-background-5-v2-only rounded-[2.5rem] border py-[2.5rem] px-[1.5rem] flex lg:flex-col items-center gap-[2.8rem] w-full"
          key={title}
        >
          <div className="h-[56px]">
            <img src={img} alt="Benefit" width="45" height="auto" />
          </div>
          <div className="lg:text-center">
            <p className="text-[1.8rem] text-main">{title}</p>
            <p className="text-[1.2rem] text-inactive">{description}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FundedTradingBenefits;

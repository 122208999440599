import { TokenUtils } from "components/TokenUtils";
import { TOKENS } from "config/tokens";
import { constants } from "ethers";
import { useChainId } from "lib/chains";
import React from "react";

export const useCollateralTokenAddress = () => {
  const { chainId } = useChainId();

  return React.useMemo(() => {
    return getCollateralTokenAddress(chainId);
  }, [chainId]);
};

export const getCollateralTokenAddress = (chainId: number) => {
  const collateralTokenAddress =
    TOKENS[chainId].find((el) => TokenUtils.getSymbol(el) === "USDC")?.address ?? constants.AddressZero;

  return collateralTokenAddress;
};

import React, { useState } from "react";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { t, Trans } from "@lingui/macro";
import { useMedia } from "react-use";
import { LONG, SHORT, USD_DECIMALS } from "lib/constants";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getTokenInfo } from "domain/tokens/utils";
import { formatAmount } from "lib/numbers";
import { getToken } from "config/tokens";

import { TokenUtils } from "components/TokenUtils";
import ArrowUp from "img/ArrowUp.svg";

export default function GenerallInfos(props) {
  const { infoTokens, toTokenAddress, swapOption, chainId } = props;

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;

  const isMobile = useMedia("(max-width: 1100px)");
  const toToken = getToken(chainId, toTokenAddress);

  const toTokenSymbol = TokenUtils.getSymbol(toToken);
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

  const [isLiqInfoOpen, setIsLiqInfoOpen] = useState(true);

  const openLiqInfo = () => {
    setIsLiqInfoOpen(!isLiqInfoOpen);
  };

  return (
    <div className="bg-background-5-v2-only p-[1.6rem]  border-border border-t ">
      {(isLong || isShort) && isMobile && (
        <div className="Exchange-swap-market-box-bottom">
          <div className="Exchange-swap-market-box-title">
            <span>
              {isLong ? t`Long` : t`Short`}&nbsp;{toTokenSymbol}
            </span>
            <button onClick={openLiqInfo}>
              <img
                src={ArrowUp}
                alt="arrow"
                style={{
                  transform: isLiqInfoOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </button>
          </div>

          <div
            className={`Swap-info-collapsible ${isLiqInfoOpen ? "open" : ""}`}
            style={{
              maxHeight: isLiqInfoOpen ? "500px" : "0",
              overflow: "hidden",
              transition: "max-height 0.6s ease-in-out",
            }}
          >
            <div className="Exchange-info-row">
              <div className="Exchange-info-label underline">
                <Trans>Available Liquidity</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  renderContent={() => (
                    <>
                      <StatsTooltipRow
                        label={isLong ? t`Max long capacity` : t`Max short capacity`}
                        value={formatAmount(
                          isLong ? toTokenInfo.maxGlobalLongSize : toTokenInfo.maxGlobalShortSize,
                          USD_DECIMALS,
                          2,
                          true
                        )}
                      />
                      <StatsTooltipRow
                        label={isLong ? t`Current long` : t`Current shorts`}
                        value={formatAmount(
                          isLong ? toTokenInfo.globalLongSize : toTokenInfo.globalShortSize,
                          USD_DECIMALS,
                          2,
                          true
                        )}
                      />
                    </>
                  )}
                >
                  <span className={`underline`}>
                    $
                    {formatAmount(
                      isLong ? toTokenInfo.maxAvailableLong : toTokenInfo.maxAvailableShort,
                      USD_DECIMALS,
                      2,
                      true
                    )}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

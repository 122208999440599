import React, { useState, useEffect } from "react";
import cx from "classnames";
import Modal from "../Modal/Modal";
import "./TokenSelector.css";
import TooltipWithPortal from "components/TooltipWithPortal";
import { getToken } from "config/tokens";
import { t } from "@lingui/macro";
import InputSearch from "components/InputSearch/InputSearch";
import { TokenUtils } from "components/TokenUtils/index.tsx";

export default function TokenSelector(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const tokenInfo = getToken(props.chainId, props.tokenAddress);
  const {
    tokens,
    infoTokens,
    disabled,
    selectedTokenLabel,
    showTokenImgInDropdown = false,
    showSymbolImage = false,
    getTokenState = () => ({ disabled: false, message: null }),
    disableBodyScrollLock,
  } = props;

  const visibleTokens = TokenUtils.filterVisible(tokens);
  const hasSingleToken = visibleTokens.length === 1;
  const isCaretShown = !hasSingleToken;

  const onSelectToken = (token) => {
    setIsModalVisible(false);
    props.onSelectToken(token);
  };

  useEffect(() => {
    if (isModalVisible) {
      setSearchKeyword("");
    }
  }, [isModalVisible]);

  if (!tokenInfo) {
    return null;
  }

  const onSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const filteredTokens = visibleTokens.filter((item) => {
    return (
      item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
    );
  });

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0]);
    }
  };

  return (
    <div className={cx("TokenSelector", { disabled }, props.className)}>
      <Modal
        disableBodyScrollLock={disableBodyScrollLock}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        label={props.label}
        placement="right"
      >
        <div className="TokenSelector-tokens">
          <div className="TokenSelector-token-input-row">
            <InputSearch
              placeholder={t`Search Token`}
              value={searchKeyword}
              onChange={(e) => onSearchKeywordChange(e)}
              onKeyDown={_handleKeyDown}
              autoFocus={false}
            />
          </div>
          {filteredTokens.map((token, tokenIndex) => {
            let info = infoTokens ? infoTokens[token.address] : {};

            const tokenState = getTokenState(info) || {};

            return (
              <div
                key={token.address}
                className={cx("TokenSelector-token-row", { disabled: tokenState.disabled })}
                onClick={() => !tokenState.disabled && onSelectToken(token)}
              >
                {tokenState.disabled && tokenState.message && (
                  <TooltipWithPortal
                    className="TokenSelector-tooltip"
                    portalClassName="TokenSelector-tooltip-portal"
                    handle={<div className="TokenSelector-tooltip-backing" />}
                    position={tokenIndex < filteredTokens.length / 2 ? "center-bottom" : "center-top"}
                    disableHandleStyle
                    closeOnDoubleClick
                    fitHandleWidth
                    renderContent={() => tokenState.message}
                  />
                )}
                <div className="Token-info">
                  {showTokenImgInDropdown && <TokenUtils.Logo token={token} className="h-[4rem] w-[4rem]"></TokenUtils.Logo>}

                  <div className="Token-symbol">
                    <div className="Token-name">{TokenUtils.getName(token)}</div>
                    <span className="text-accent">{TokenUtils.getSymbol(token)}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      {selectedTokenLabel ? (
        <div
          className={cx("TokenSelector-box", { inactive: !isCaretShown })}
        >
          {selectedTokenLabel}

        </div>
      ) : (
        <div
          className={cx("TokenSelector-box", { inactive: !isCaretShown })}

        >
          <TokenUtils.Symbol token={tokenInfo}></TokenUtils.Symbol>
          {showSymbolImage && <TokenUtils.Logo token={tokenInfo} className="h-[4rem] w-[4rem]"></TokenUtils.Logo>}
  
        </div>
      )}
    </div>
  );
}

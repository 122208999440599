import React from "react";
import cx from "classnames";

import "./Tab.css";

export default function ExchangeTab(props) {
  const { options, option, setOption, onChange, optionLabels } = props;

  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <div className={cx("Tab", "exchange")}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;

        return (
          <div
            className={cx("Tab-option transition-effect", "muted", label, {
              active: opt === option,
            })}
            onClick={() => onClick(opt)}
            key={opt}
          >
            <div className="Tab-option-swapbox">{label}</div>
          </div>
        );
      })}
    </div>
  );
}

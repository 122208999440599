import { BigNumber, ethers } from "ethers";
import { ApolloQueryResult, gql } from "@apollo/client";
import { useCallback } from "react";
import useSWR from "swr";
import { getContractAddress } from "config/contracts";
import { getPalmSubgraph } from "lib/subgraph/clients";
import { bigNumberify } from "lib/numbers";
import { t } from "@lingui/macro";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { PLACEHOLDER_ACCOUNT, PLP_DECIMALS } from "lib/constants";
import isEqual from "lodash/isEqual";
import fromUnixTime from "date-fns/fromUnixTime";
import { atom, useAtom } from "jotai";
import PlpRewardRouter from "abis/PlpRewardRouter.json";
import { callContract } from "lib/contracts";
import { usePendingTxns } from "domain/transactions/usePendingTxns";
import { formatBigAmount } from "lib/formatAmount";
import { produce } from "immer";
import usePythService from "../../../hooks/usePythService";

type PlpStakeRaw = {
  id: string;
  account: string;
  staked: string;
  unstaked: string;
  rewardsEarned: string;
  redeemInfo: PlpRedeemInfoRaw[];
};

type PlpRedeemInfoRaw = {
  id: string;
  index: number;
  amount: string;
  expire: string;
};

type PlpStake = {
  id: string;
  account: string;
  staked: BigNumber;
  unstaked: BigNumber;
  rewardsEarned: BigNumber;
  redeemInfo: PlpRedeemInfo[];
};

type PlpRedeemInfo = {
  id: string;
  index: number;
  amount: BigNumber;
  expire: number;
};

const isMutatingAtom = atom(false);

export const usePlpRedeem = () => {
  const { isActive, account = PLACEHOLDER_ACCOUNT, provider } = useWeb3React();
  const { chainId } = useChainId();
  const [isMutating, setIsMutating] = useAtom(isMutatingAtom);
  const [, setPendingTxns] = usePendingTxns();
  const { priceFeedsUpdateData, updateFee } = usePythService();

  const fetcher = async (): Promise<PlpStake | null> => {
    const query = gql`
      query ($account: String!) {
        plpStakes(where: { id: $account }) {
          id
          account
          staked
          unstaked
          rewardsEarned
          redeemInfo(orderBy: expire, where: { amount_not: "0" }) {
            id
            index
            amount
            expire
          }
        }
      }
    `;
    const { data }: ApolloQueryResult<{ plpStakes: [PlpStakeRaw?] }> = await getPalmSubgraph(chainId, "raw").query({
      query,
      variables: { account: account.toLowerCase() },
    });

    const plpStakeRaw = data.plpStakes[0];

    if (!plpStakeRaw) {
      return null;
    }

    const redeemInfo = plpStakeRaw.redeemInfo.map((el): PlpRedeemInfo => {
      return {
        id: el.id,
        index: el.index,
        amount: bigNumberify(el.amount) ?? BigNumber.from(0),
        expire: fromUnixTime(parseInt(el.expire)).valueOf(),
      } satisfies PlpRedeemInfo;
    });

    const plpStake: PlpStake = {
      id: plpStakeRaw.id,
      account,
      redeemInfo,
      rewardsEarned: bigNumberify(plpStakeRaw.rewardsEarned) ?? BigNumber.from(0),
      staked: bigNumberify(plpStakeRaw.staked) ?? BigNumber.from(0),
      unstaked: bigNumberify(plpStakeRaw.unstaked) ?? BigNumber.from(0),
    };

    return plpStake;
  };

  const plpStakeQuery = useSWR<Awaited<ReturnType<typeof fetcher>>>(
    [`plpRedeem:${account}`, chainId, account, isActive],
    fetcher,
    {
      compare: isEqual,
      isOnline: () => isActive,
      revalidateOnMount: false,
    }
  );

  const redeemAndSell = useCallback(
    (indexes: number[]) => {
      const plpRewardRouterAddress = getContractAddress(chainId, "PlpRewardRouter");
      const contract = new ethers.Contract(plpRewardRouterAddress, PlpRewardRouter.abi, provider!.getSigner());
      const method = "redeemAndWithdrawPlp";

      const redeemingUnits = (plpStakeQuery.data!.redeemInfo ?? []).filter((el) => indexes.includes(el.index));

      const nextPlpStakeData = produce(plpStakeQuery.data!, (draft) => {
        draft.redeemInfo = plpStakeQuery.data!.redeemInfo.filter((el) => !indexes.includes(el.index));
      });

      const minOut = redeemingUnits.reduce((res, el) => {
        return res.add(el.amount);
      }, BigNumber.from(0));
      const params = [priceFeedsUpdateData, indexes, minOut, account];
      setIsMutating(true);
      callContract(chainId, contract, method, params, {
        value: updateFee,
        sentMsg: t`Sell submitted!`,
        failMsg: t`Sell failed.`,
        successMsg: t`${formatBigAmount(minOut, PLP_DECIMALS, undefined, { mantissa: 4 })} PLP sold!`,
        setPendingTxns,
        txnSuccessCallback: () => {
          plpStakeQuery.mutate(nextPlpStakeData, {
            optimisticData: nextPlpStakeData,
            populateCache: true,
            revalidate: false,
          });

          setIsMutating(false);
        },
      }).catch(() => {
        setIsMutating(false);
      });
    },
    [account, chainId, plpStakeQuery, provider, setIsMutating, setPendingTxns, priceFeedsUpdateData, updateFee]
  );

  return {
    plpStake: isActive ? plpStakeQuery.data : null,
    isMutating,
    redeemAndSell,
  };
};

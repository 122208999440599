import { Squid } from "@0xsquid/sdk";
import { ethers } from "ethers";

const squid = new Squid({
  baseUrl: "https://v2.api.squidrouter.com",
  integratorId: "foxify-950d6885-87b2-446e-ab47-e1d0d182aafe",
});

// Initialize Squid instance
squid
  .init()
  .then(() => {
    // eslint-disable-next-line no-console
    console.log("Squid inited");
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error("Error initializing Squid SDK:", error);
    throw error; // Re-throw the error so it can be caught by the component
  });

export const fetchData = async () => {
  return squid;
};

export const fetchBalance = async (account: string, selectedChain: number, selectedFromToken: string) => {
  try {
    const evmBalances = await squid.getEvmBalances({
      userAddress: account,
      chains: [selectedChain],
    });

    const selectedTokenBalance = evmBalances.filter((balance) => balance.address === selectedFromToken);

    return selectedTokenBalance;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching balance:", error);
    return null;
  }
};

export const sendRouteRequest = async (
  fromChain: string,
  fromAmount: string,
  fromToken: string,
  toChain: string,
  toToken: string,
  userAddress: string
) => {
  const params = {
    fromAddress: userAddress,
    fromChain: fromChain,
    fromToken: fromToken,
    fromAmount: fromAmount,
    toChain: toChain,
    toToken: toToken,
    toAddress: userAddress,
    slippageConfig: {
      slippage: 1,
      autoMode: 1,
    },
  };

  const { route, requestId } = await squid.getRoute(params);

  return { route, requestId };
};

export const executeRouteRequest = async (signer, route, chainId, requiredChainId?) => {
  if (requiredChainId) {
    signer = await switchChainHook(requiredChainId);
  }

  const tx = (await squid.executeRoute({
    signer,
    route,
  })) as unknown as ethers.providers.TransactionResponse;
  await tx.wait();

  await switchChainHook(chainId.toString());
};

interface EthereumProvider {
  request: (...args: any[]) => Promise<any>;
}
interface Window {
  ethereum?: EthereumProvider;
}
declare let window: Window;

const switchChainHook = async (requiredChainId: string) => {
  try {
    const ethereum = window.ethereum;
    if (typeof ethereum === "undefined" || !("request" in ethereum)) {
      throw new Error("MetaMask or similar wallet is not available.");
    }

    const chainIdNum = parseInt(requiredChainId, 10);

    const hexChainId = `0x${chainIdNum.toString(16)}`;

    // Use the MetaMask RPC API to switch chains
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: hexChainId }],
    });

    // Build a new provider for the new chain
    const newProvider = new ethers.providers.Web3Provider(ethereum);

    // Return the associated Signer for the new chain
    return newProvider.getSigner();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error switching chains:", error);
    return undefined;
  }
};

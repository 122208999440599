import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { Position } from "domain/positions/types";
import { useChainId } from "lib/chains";
import { useQuickContracts } from "lib/contracts";
import { isAddressZero } from "lib/legacy";
import { HiX } from "react-icons/hi";
import { useMedia } from "react-use";
import useSWR from "swr";

export default function PositionCancelButton({
  position,
  handleCancelPosition,
}: {
  position: Position;
  handleCancelPosition: (position: Position) => void;
}) {
  const { isActive } = useWeb3React();
  const { chainId } = useChainId();
  const quickContracts = useQuickContracts();
  const isMobile = useMedia("(max-width: 1100px)");

  const method = "increasePositionRequests";

  const { data: increasePositionRequests } = useSWR(
    ["PositionRouter", method, position.contractKey, isActive, chainId],
    () => quickContracts.fetch("PositionRouter", method, [position.contractKey!])
  );

  if (isAddressZero(increasePositionRequests?.account) && isAddressZero(increasePositionRequests?.indexToken))
    return null;

  return (
    <button
      className="Exchange-list-action bg-background-6 dark:bg-background-3"
      onClick={() => handleCancelPosition(position)}
      disabled={position.size.eq(0)}
    >
      {!isMobile ? <Trans>Cancel</Trans> : <HiX />}
    </button>
  );
}

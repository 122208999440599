export const THEMES = {
  light: {
    name: "light",
    class: "light",
  },
  dark: {
    name: "dark",
    class: "dark",
  },
};

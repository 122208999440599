import { MdOutlineSync } from "react-icons/md";
import { t, Trans } from "@lingui/macro";
import { BigNumber } from "ethers";
import { useMemo } from "react";

import Button from "components/Button/Button";

import Cashout from "./Cashout";

export default function ChallengeFailed({
  cashout,
  isLoading,
  challengeRank,
  onCashout,
  onRestartQualification,
}: {
  cashout: BigNumber;
  isLoading: boolean;
  challengeRank: number;
  onCashout: () => void;
  onRestartQualification: () => void;
}) {
  const handleRenderButton = () => {
    if (cashout.gt(0)) {
      return (
        <Button variant="primary-action" onClick={onCashout} className="w-full rounded mt-2 !text-black">
          <Trans>Cashout</Trans>
        </Button>
      );
    }

    return (
      <Button variant="primary-action" onClick={onRestartQualification} className="w-full rounded mt-2 !text-black">
        <Trans>Restart Qualification</Trans>
      </Button>
    );
  };

  const title = useMemo(() => {
    if (challengeRank) {
      return t`Lost Funding`;
    }

    return t`Failed Qualification!`;
  }, [challengeRank]);

  return (
    <div>
      <div className="flex items-center text-red text-[2rem] gap-x-3">
        <div className="w-[3.5rem] h-[3.5rem] flex items-center justify-center rounded-full bg-[#FD4040]/[0.15]">
          <MdOutlineSync />
        </div>
        {title}
      </div>
      <div className="text-inactive text-[1.4rem] mb-[9.7rem] mt-4">
        <Trans>Unlucky, I'm sure you will get FUNDED next time!</Trans>
      </div>

      <Cashout cashout={cashout} isLoading={isLoading} />
      {handleRenderButton()}
    </div>
  );
}

export function getEbFoxStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["decimals", "totalSupply", "entryFee", "exitFee"];
  const data = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = stakingInfo[i];
  }

  return data;
}

export function getFoxifyMaxiStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["decimals", "calculatePrice", "totalSupply"];
  const data = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = stakingInfo[i];
  }

  return data;
}

import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import "./GetStartedFoxify.scss";
import { Trans } from "@lingui/macro";
import ArrowUp from "../../../../img/ArrowUp.svg";
import { CheckboxItem } from "./CheckboxItem";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { contractFetcher } from "../../../../lib/contracts";
import Router from "../../../../abis/Router.json";
import { getContractAddress } from "../../../../config/contracts";
import { useChainId } from "../../../../lib/chains";
import { useCollateralTokenAddress } from "../../../../lib/useCollateralTokenAddress";
import { BigNumber, ethers } from "ethers";
import Token from "../../../../abis/common/Token.json";
import { useLocalStorageSerializeKey } from "../../../../lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "../../../../config/localStorage";
import { DEFAULT_SLIPPAGE_AMOUNT } from "../../../../lib/constants";

type GetStartedProps = {
  setShowGetStarted: (arg: boolean) => void
}

const { AddressZero } = ethers.constants;

const accordionContent = [
  {
    title: "Connect wallet",
    description: "Connect Wallet to start trading on Foxify."
  },
  {
    title: "Enable Leverage",
    description: "Grant permission for leverage to begin trading."
  },
  {
    title: "Approve USDC",
    description: "Approve USDC to begin trading."
  },
  {
    title: "Enable Orders",
    description: "Enable order to be able to set limit orders, take profit and stop loss."
  },
  {
    title: "Set trading preferences",
    description: "Customize your trading console to be on the right or left. Adjust your market slippage tolerance."
  }
]

export const GetStartedFoxify = ({ setShowGetStarted }: GetStartedProps) => {
  const [currentAccordionItem, setCurrentAccordionItem] = useState<string | number>(0);

  const openAccordion = (id: string | number) => {
    if (currentAccordionItem === id) {
      setCurrentAccordionItem(0)
    } else setCurrentAccordionItem(id)
  };

  const { chainId } = useChainId();
  const { isActive: isActiveAcc, account, provider } = useWeb3React();

  const routerAddress = getContractAddress(chainId, "Router");
  const positionRouterAddress = getContractAddress(chainId, "PositionRouter");

  const { data: positionRouterApproved } = useSWR(
    isActiveAcc && [isActiveAcc, chainId, routerAddress, "approvedPlugins", account, positionRouterAddress],
    contractFetcher(provider, Router)
  );

  const collateralTokenAddress = useCollateralTokenAddress();

  const { data: tokenAllowance } = useSWR(
    [isActiveAcc, chainId, collateralTokenAddress, "allowance", account, routerAddress],
    contractFetcher(provider, Token)
  );

  const needApproval = React.useMemo(() => {
    if (collateralTokenAddress === AddressZero) {
      return false;
    }
    return tokenAllowance && (tokenAllowance as BigNumber).gt(0);
  }, [collateralTokenAddress, tokenAllowance]);

  const orderBookAddress = getContractAddress(chainId, "OrderBook");
  const { data: orderBookApproved } = useSWR(
    isActiveAcc && [isActiveAcc, chainId, routerAddress, "approvedPlugins", account, orderBookAddress],
    contractFetcher(provider, Router)
  );

  const [savedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT
  );

  const handleHide = () => {
    setShowGetStarted(false)
  }

  const statusVocabulary = {
    "Connect wallet": isActiveAcc,
    "Enable Leverage": positionRouterApproved,
    "Enable Orders": orderBookApproved,
    "Approve USDC": needApproval,
    "Set trading preferences": savedSlippageAmount,
  }

  const progress = Object.values(statusVocabulary).filter(Boolean).length
  const progressNumber = 100 / accordionContent.length * progress

  return (
    <div className="gs-foxify-wrapper">
      <div className="gs-foxify-header">
        <Trans>
          Get started on Foxify
        </Trans>
        <div
          className="Modal-close-button"
          onClick={handleHide}
        >
          <MdClose fontSize={15} className="Modal-close-icon"/>
        </div>
      </div>
      <div className="gs-foxify-progress">
        <svg width="25" height="25"
             viewBox="0 0 25 25"
             className="circular-progress"
             style={{ "--progress": progressNumber } as React.CSSProperties}>
          <circle className="bg"></circle>
          <circle className="fg"></circle>
        </svg>
        <span>{progressNumber}%</span>
        <Trans>
          You're almost ready!
        </Trans>
      </div>
      <div className="line mb-[1rem]"/>

      <div className="gs-foxify-accordion">
        {accordionContent.map((item) => {
          const isActive = currentAccordionItem === item.title
          const isDone = statusVocabulary[item.title]

          return (
            <div className="gs-foxify-accordion-item" key={item.title}>
              <div className="gs-foxify-accordion-controller">
                <CheckboxItem active={isDone}/>
                <Trans>{item.title}</Trans>
                <button onClick={() => openAccordion(item.title)}>
                  <img
                    src={ArrowUp}
                    alt="arrow"
                    style={{
                      transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                </button>
              </div>
              <div
                className={`gs-foxify-accordion-content ${isActive ? "open" : ""}`}
                style={{
                  maxHeight: isActive ? "500px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.6s ease-in-out",
                }}
              >
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <Trans>{item.description}</Trans>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="line mb-[1rem]"/>
      <div onClick={handleHide}>
        <Trans>
          Don't show me this again
        </Trans>
      </div>
    </div>
  )
}

const ProgressBar = ({ progress }) => {
  const formattedProgress = progress ? progress : 0;
  // const bgCondition = formattedProgress > 50 ? 'bg-red' : ' bg-[var(--pallete-green)]'
  //  const bgOpacity = formattedProgress > 50 ? 'bg-red bg-opacity-25' : ' bg-green-opacity'
  return (
    <div className="h-[0.8rem] w-full rounded-full overflow-hidden mt-[1.4rem]  bg-green-opacity">
      <div className="h-full rounded-full bg-[var(--pallete-green)]" style={{ width: `${formattedProgress}%` }}></div>
    </div>
  );
};

export default ProgressBar;

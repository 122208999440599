import React from "react";
import "./Switch.css";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";

type Props = React.ComponentProps<"label"> & {
  checked: boolean;
  onChange: (value: boolean) => void;
  checkedColor?: string; // Color when checked
  uncheckedColor?: string; // Color when unchecked
  isSmallSwitch?: boolean;
  width?: number;
  height?: number;
};

// width: 40px;
// height: 19px;

export const Switch = ({ checked, onChange, className, checkedColor, uncheckedColor, isSmallSwitch }: Props) => {
  const inputId = React.useMemo(() => {
    return uuidv4();
  }, []);

  const sliderStyle = {
    backgroundColor: checked ? checkedColor || "#f66b31" : uncheckedColor || "#b3b1b1",
  };

  return (
    <label htmlFor={inputId} className={cx("switch", className, { isSmallSwitch })}>
      <input
        id={inputId}
        role="switch"
        type="checkbox"
        className={cx({ isSmallSwitch })}
        checked={checked}
        aria-checked={checked}
        onChange={(event) => onChange(event.target.checked)}
      />
      <span className={cx("slider", { isSmallSwitch })} style={sliderStyle} />
    </label>
  );
};

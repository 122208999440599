import { BigNumber } from "ethers";
import { formatUnits } from "@ethersproject/units";
import { PLP_DECIMALS } from "lib/constants";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { getContractAddress } from "config/contracts";
import { PLACEHOLDER_ACCOUNT, USD_DECIMALS } from "lib/legacy";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import PlpManager from "abis/PlpManager.json";
import Reader from "abis/Reader.json";
import { expandDecimals } from "lib/numbers";
import { useInfoTokens } from "domain/tokens";

export const usePlpPrice = () => {
  const { isActive, account, provider } = useWeb3React();
  const { chainId } = useChainId();
  const plpManagerAddress = getContractAddress(chainId, "PlpManager");
  const { offChainTokenPrices } = useInfoTokens();
  const offChainTokenPricesArray = Object.values(offChainTokenPrices);
  const { data: aums } = useSWR(
    offChainTokenPricesArray.length > 0 && [`PlpSwap:getAums:${isActive}`, chainId, plpManagerAddress, "getAums"],
    contractFetcher(provider, PlpManager, [offChainTokenPricesArray])
  );
  const readerAddress = getContractAddress(chainId, "Reader");
  const plpAddress = getContractAddress(chainId, "GLP");
  const tokensForBalanceAndSupplyQuery = [plpAddress];
  const { data: balancesAndSupplies } = useSWR(
    [
      `PlpSwap:getTokenBalancesWithSupplies:${isActive}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    contractFetcher(provider, Reader, [tokensForBalanceAndSupplyQuery])
  );
  const isBuying = true;
  const aum = aums && (isBuying ? aums[0] : aums[1]);
  const plpSupply = balancesAndSupplies ? balancesAndSupplies[1] : BigNumber.from(0);
  const plpPrice =
    aum && aum.gt(0) && plpSupply.gt(0)
      ? aum.mul(expandDecimals(1, PLP_DECIMALS)).div(plpSupply)
      : expandDecimals(1, USD_DECIMALS);

  return {
    amount: plpPrice,
    formatted: formatUnits(plpPrice, USD_DECIMALS),
    decimals: USD_DECIMALS,
  };
};

import React, { useEffect, useState, forwardRef, useImperativeHandle, useMemo } from "react";
import { Trans, t, Plural } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import cx from "classnames";
import { USD_DECIMALS, getPositionKey, useAccountOrders, getPageTitle } from "lib/legacy";
import { getConstant } from "config/chains";
import { approvePlugin, useMinExecutionFee } from "domain/legacy";
import { getContractAddress } from "config/contracts";
import Reader from "abis/Reader.json";
import Router from "abis/Router.json";
import SwapBox from "components/Exchange/SwapBox";
import ExchangeTVChart, { getChartToken } from "components/Exchange/ExchangeTVChart";
import PositionsList from "components/Exchange/PositionsList";
import ClosedPositionsList from "components/Exchange/ClosedPositionsList";
import OrdersList from "components/Exchange/OrdersList";
import TradeHistory from "components/Exchange/TradeHistory";
import ExchangeWalletTokens from "components/Exchange/ExchangeWalletTokens";
import Tab from "components/Tab/Tab";
import "./Exchange.scss";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify, formatAmount } from "lib/numbers";
import { getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import { ActionTab } from "./constants";
import { pushSuccessNotification, pushErrorNotification } from "./pushNotification";
import { getPositions } from "./getPositions";
import { getPositionQuery } from "./getPositionQuery";
import { useTradeHistory } from "components/Exchange/hooks/useTradeHistory";
import { useClosedPositions } from "components/Exchange/hooks/useClosedPositions";
import { getCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import { Switch } from "components/Switch/Switch";
import {
  useSwapOption,
  useTokenSelection,
  useMarket,
  useFromTokenAddress,
  useToTokenAddress,
  isChartFullScreenAtom,
} from "pages/Exchange/state";
import { BigNumber } from "ethers";
import { useAtom } from "jotai";
import { TokenUtils } from "components/TokenUtils";
import { ExchangeChartTop } from "components/Exchange/ExchangeTVChart";
import GenerallInfos from "components/Exchange/GenerallInfos";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useMedia } from "react-use";
import { useFundedTrader } from "hooks/useFundedTrader";
import { useTokenBalance } from "hooks/blockchain/useTokenBalance";

export const Exchange = forwardRef(
  (
    {
      savedIsPnlInLeverage,
      setSavedIsPnlInLeverage,
      savedShowPnlAfterFees,
      savedSlippageAmount,
      pendingTxns,
      setPendingTxns,
      savedShouldShowPositionLines,
      setSavedShouldShowPositionLines,
      connectWallet,
      savedShouldDisableValidationForTesting,
      theme,
      isDarkTheme,
      tradingLayout,
      onMultipleCancelClick,
      setCancelOrderIdList,
      cancelOrderIdList,
      isCancelMultipleOrderProcessing,
    }: Record<string, any>,
    ref
  ) => {
    const { chainId } = useChainId();
    const [swapOption, setSwapOption] = useSwapOption();
    const [tokenSelection, setTokenSelection] = useTokenSelection();
    const [, setMarket] = useMarket();
    const isMobile = useMedia("(max-width: 1100px)");

    const [pendingPositions, setPendingPositions] = useState({});
    const [updatedPositions, setUpdatedPositions] = useState({});

    const {
      traderContractAddress,
      isFundedTrading,
      proxyTraderContractAddress,
      isFundedAccountActive,
      expectedMinTradeSize,
      expectedMaxTradeSize,
      fundedBalance: fromFundedBalance,
      challengeInProgress,
      refetchChallengeStats,
    } = useFundedTrader();

    const { isActive, account, provider } = useWeb3React();

    const curAccount = useMemo(
      () => (isFundedTrading ? traderContractAddress : account),
      [isFundedTrading, traderContractAddress, account]
    );

    const tradeHistory = useTradeHistory(curAccount ?? undefined);
    const ClosedPositions = useClosedPositions(curAccount ?? undefined);
    const currentAccount = curAccount;

    const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");

    const vaultAddress = getContractAddress(chainId, "Vault");
    const positionRouterAddress = getContractAddress(chainId, "PositionRouter");
    const readerAddress = getContractAddress(chainId, "Reader");

    const whitelistedTokens = useMemo(() => getWhitelistedTokens(chainId), [chainId]);

    const { infoTokens, usdpAmount, offChainTokenPrices } = useInfoTokens();
    const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress, offChainTokenPrices);

    const tokens = getTokens(chainId);
    const stableTokens = tokens.filter((token) => {
      return token.isStable && !token.isTempHidden;
    });
    const fromTokens = stableTokens;

    const indexTokens = useMemo(() => whitelistedTokens.filter((token) => !token.isStable), [whitelistedTokens]);
    const shortableTokens = indexTokens.filter((token) => token.isShortable);

    const toTokens = (() => {
      if (swapOption === "Long") {
        return indexTokens;
      } else {
        return shortableTokens;
      }
    })();

    const [fromTokenAddress, setFromTokenAddress] = useFromTokenAddress();
    const [toTokenAddress, setToTokenAddress] = useToTokenAddress();
    const { balance: fromBalance, refetchBalance } = useTokenBalance(fromTokenAddress, account!);

    const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

    const { data: positionData, error: positionDataError } = useSWR(
      isActive &&
        positionQuery.offChainTokenPrices.filter((p) => !!p).length > 0 && [
          isActive,
          chainId,
          readerAddress,
          "getPositions",
          vaultAddress,
          curAccount,
        ],
      contractFetcher(provider, Reader, [
        positionQuery.indexTokens,
        positionQuery.offChainTokenPrices,
        positionQuery.isLong,
      ])
    );

    const positionsDataIsLoading = isActive && !positionData && !positionDataError;

    const orderBookAddress = getContractAddress(chainId, "OrderBook");
    const routerAddress = getContractAddress(chainId, "Router");
    const { data: orderBookApproved } = useSWR(
      isActive && [isActive, chainId, routerAddress, "approvedPlugins", curAccount, orderBookAddress],
      contractFetcher(provider, Router)
    );

    const { data: positionRouterApproved } = useSWR(
      isActive && [isActive, chainId, routerAddress, "approvedPlugins", curAccount, positionRouterAddress],
      contractFetcher(provider, Router)
    );

    const usdpSupply = usdpAmount ?? BigNumber.from(0);

    const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
      provider,
      isActive,
      chainId,
      infoTokens
    );

    useEffect(() => {
      const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
      const toToken = getTokenInfo(infoTokens, toTokenAddress);
      let selectedToken = getChartToken(fromToken, toToken);
      let currentTokenPriceStr = formatAmount(
        selectedToken?.maxPrice,
        USD_DECIMALS,
        selectedToken?.priceDecimals,
        true
      );
      document.title = getPageTitle(
        currentTokenPriceStr + ` | ${TokenUtils.getSymbol(selectedToken)}${selectedToken?.isStable ? "" : "USD"}`
      );
    }, [infoTokens, fromTokenAddress, toTokenAddress]);

    const { positions, positionsMap } = getPositions(
      chainId,
      positionQuery,
      positionData,
      infoTokens,
      savedIsPnlInLeverage,
      savedShowPnlAfterFees,
      curAccount,
      pendingPositions,
      updatedPositions
    );

    useImperativeHandle(ref, () => ({
      onUpdatePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl, markPrice) {
        for (let i = 0; i < positions.length; i++) {
          const position = positions[i];
          if (position.contractKey === key) {
            updatedPositions[position.key] = {
              size: bigNumberify(size),
              collateral: bigNumberify(collateral),
              averagePrice: bigNumberify(averagePrice),
              entryFundingRate: bigNumberify(entryFundingRate),
              reserveAmount: bigNumberify(reserveAmount),
              realisedPnl: bigNumberify(realisedPnl),
              updatedAt: Date.now(),
            };
            setUpdatedPositions({ ...updatedPositions });
            break;
          }
        }
      },
      onClosePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl) {
        for (let i = 0; i < positions.length; i++) {
          const position = positions[i];
          if (position.contractKey === key) {
            updatedPositions[position.key] = {
              size: bigNumberify(0),
              collateral: bigNumberify(0),
              averagePrice: bigNumberify(averagePrice),
              entryFundingRate: bigNumberify(entryFundingRate),
              reserveAmount: bigNumberify(reserveAmount),
              realisedPnl: bigNumberify(realisedPnl),
              updatedAt: Date.now(),
            };
            setUpdatedPositions({ ...updatedPositions });
            break;
          }
        }
      },
      onIncreasePosition(key, curAccount, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
        if (curAccount !== currentAccount) {
          return;
        }

        const indexTokenItem = getToken(chainId, indexToken);
        const tokenSymbol = indexTokenItem.isWrapped
          ? getConstant(chainId, "nativeTokenSymbol")
          : TokenUtils.getSymbol(indexTokenItem);
        const longOrShortText = isLong ? t`Long` : t`Short`;
        let message;
        if (BigNumber.from(sizeDelta).eq(0)) {
          message = t`Deposited ${formatAmount(
            collateralDelta,
            USD_DECIMALS,
            2,
            true
          )} USD into ${tokenSymbol} ${longOrShortText}`;
        } else {
          message = t`Increased ${tokenSymbol} ${longOrShortText}, +${formatAmount(
            sizeDelta,
            USD_DECIMALS,
            2,
            true
          )} USD.`;
        }

        pushSuccessNotification(chainId, message, e);
      },
      onDecreasePosition(key, curAccount, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
        if (curAccount !== currentAccount) {
          return;
        }

        const indexTokenItem = getToken(chainId, indexToken);

        const tokenSymbol = indexTokenItem.isWrapped
          ? getConstant(chainId, "nativeTokenSymbol")
          : TokenUtils.getSymbol(indexTokenItem);
        const longOrShortText = isLong ? t`Long` : t`Short`;

        let message;
        if (BigNumber.from(sizeDelta).eq(0)) {
          message = t`Withdrew ${formatAmount(
            collateralDelta,
            USD_DECIMALS,
            2,
            true
          )} USD from ${tokenSymbol} ${longOrShortText}.`;
        } else {
          message = t`Decreased ${tokenSymbol} ${longOrShortText}, -${formatAmount(
            sizeDelta,
            USD_DECIMALS,
            2,
            true
          )} USD.`;
        }

        pushSuccessNotification(chainId, message, e);
      },
      onCancelIncreasePosition(
        curAccount,
        indexToken,
        amountIn,
        minOut,
        sizeDelta,
        isLong,
        acceptablePrice,
        executionFee,
        blockGap,
        timeGap,
        e
      ) {
        if (curAccount !== currentAccount) {
          return;
        }
        const indexTokenItem = getToken(chainId, indexToken);
        const tokenSymbol = indexTokenItem.isWrapped
          ? getConstant(chainId, "nativeTokenSymbol")
          : TokenUtils.getSymbol(indexTokenItem);
        const longOrShortText = isLong ? t`Long` : t`Short`;

        const message = t`Could not increase ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;
        pushErrorNotification(chainId, message, e);

        const collateralTokenAddress = getCollateralTokenAddress(chainId);

        const key = getPositionKey(curAccount, collateralTokenAddress, indexToken, isLong);
        pendingPositions[key] = {};
        setPendingPositions({ ...pendingPositions });
      },

      onCancelDecreasePosition(
        curAccount,
        indexToken,
        collateralDelta,
        sizeDelta,
        isLong,
        receiver,
        acceptablePrice,
        minOut,
        executionFee,
        blockGap,
        timeGap,
        e
      ) {
        if (curAccount !== currentAccount) {
          return;
        }
        const indexTokenItem = getToken(chainId, indexToken);
        const tokenSymbol = indexTokenItem.isWrapped
          ? getConstant(chainId, "nativeTokenSymbol")
          : TokenUtils.getSymbol(indexTokenItem);
        const longOrShortText = isLong ? t`Long` : t`Short`;

        const message = t`Could not decrease ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

        pushErrorNotification(chainId, message, e);

        const collateralTokenAddress = getCollateralTokenAddress(chainId);

        const key = getPositionKey(curAccount, collateralTokenAddress, indexToken, isLong);
        pendingPositions[key] = {};
        setPendingPositions({ ...pendingPositions });
      },
    }));

    const flagOrdersEnabled = true;
    const [orders] = useAccountOrders(flagOrdersEnabled);

    const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
    const [isChartFullScreen] = useAtom(isChartFullScreenAtom);
    const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
    const [isPluginApproving, setIsPluginApproving] = useState(false);
    const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);

    const approveOrderBook = () => {
      setIsPluginApproving(true);
      return approvePlugin(chainId, orderBookAddress, {
        library: provider,
        setPendingTxns,
        sentMsg: t`Enable orders sent.`,
        failMsg: t`Enable orders failed.`,
      })
        .then(() => {
          setIsWaitingForPluginApproval(true);
        })
        .finally(() => {
          setIsPluginApproving(false);
        });
    };

    const approvePositionRouter = ({ sentMsg, failMsg }) => {
      setIsPositionRouterApproving(true);
      return approvePlugin(chainId, positionRouterAddress, {
        library: provider,
        setPendingTxns,
        sentMsg,
        failMsg,
      })
        .then(() => {
          setIsWaitingForPositionRouterApproval(true);
        })
        .catch((error) => {})
        .finally(() => {
          setIsPositionRouterApproving(false);
        });
    };

    const LIST_SECTIONS: ActionTab[] = [
      ActionTab.POSITIONS_TAB,
      flagOrdersEnabled && ActionTab.ORDERS_TAB,
      ActionTab.TRADES_TAB,
      ActionTab.CLOSED_POSITIONS_TAB,
    ].filter(Boolean);

    let [listSection, setListSection] = useLocalStorageByChainId(chainId, "List-section-v2", LIST_SECTIONS[0]);

    const LIST_SECTIONS_LABELS = {
      [ActionTab.ORDERS_TAB]: orders.length ? t`Orders (${orders.length})` : t`Orders`,
      [ActionTab.POSITIONS_TAB]: positions.length ? t`Positions (${positions.length})` : t`Positions`,
      [ActionTab.TRADES_TAB]: t`Trade Activity`,
      [ActionTab.CLOSED_POSITIONS_TAB]: t`Closed Positions`,
    } satisfies Partial<Record<ActionTab, string>>;

    if (!listSection || !LIST_SECTIONS.includes(listSection)) {
      listSection = LIST_SECTIONS[0];
    }

    if (!getToken(chainId, toTokenAddress)) {
      return null;
    }

    const renderCancelOrderButton = () => {
      if (cancelOrderIdList.length === 0) return;
      return (
        <button
          className="font-base cancel-order-btn"
          disabled={isCancelMultipleOrderProcessing}
          type="button"
          onClick={onMultipleCancelClick}
        >
          <Plural value={cancelOrderIdList.length} one="Cancel order" other="Cancel # orders" />
        </button>
      );
    };

    const getListSection = () => {
      return (
        <>
          <div className="sm:w-full w-screen relative sm:z-0 flex-none overflow-x-scroll md:overflow-x-hidden">
            <div className="sm:w-full md:w-full w-[63.5rem]">
              <div className={cx("Exchange-list-tab-container bg-background-5-v2-only")}>
                <Tab
                  options={LIST_SECTIONS}
                  optionLabels={LIST_SECTIONS_LABELS}
                  option={listSection}
                  onChange={(section) => setListSection(section)}
                  type="table"
                  className="Exchange-list-tabs gap-[1rem] md:gap-[0]"
                />
                <div className="align-right Exchange-should-show-position-lines">
                  {renderCancelOrderButton()}
                  <div className="flex flex-row items-center gap-[1rem] whitespace-nowrap	">
                    <span className="muted">
                      <Trans>Chart positions</Trans>
                    </span>
                    <Switch
                      checked={savedShouldShowPositionLines}
                      onChange={() => setSavedShouldShowPositionLines(!savedShouldShowPositionLines)}
                      checkedColor="#f66b31"
                      uncheckedColor="#1D2024"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {listSection === "POSITIONS" && (
            <PositionsList
              positionsDataIsLoading={positionsDataIsLoading}
              pendingPositions={pendingPositions}
              setPendingPositions={setPendingPositions}
              setListSection={setListSection}
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
              approveOrderBook={approveOrderBook}
              approvePositionRouter={approvePositionRouter}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
              orderBookApproved={orderBookApproved}
              positionRouterApproved={positionRouterApproved}
              positions={positions}
              positionsMap={positionsMap}
              infoTokens={infoTokens}
              active={isActive}
              account={curAccount}
              library={provider}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              flagOrdersEnabled={flagOrdersEnabled}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              chainId={chainId}
              nativeTokenAddress={nativeTokenAddress}
              setMarket={setMarket}
              orders={orders}
              showPnlAfterFees={savedShowPnlAfterFees}
              minExecutionFee={minExecutionFee}
              minExecutionFeeUSD={minExecutionFeeUSD}
              minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
              connectWallet={connectWallet}
              isExchange={true}
              refetchBalance={handleRefetchBalance}
              isFundedTrading={isFundedTrading}
              expectedMinTradeSize={expectedMinTradeSize}
              proxyTraderContractAddress={proxyTraderContractAddress}
            />
          )}
          {listSection === "ORDERS" && (
            <OrdersList
              account={curAccount}
              active={isActive}
              library={provider}
              positions={positions}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              infoTokens={infoTokens}
              positionsMap={positionsMap}
              chainId={chainId}
              orders={orders}
              savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
              cancelOrderIdList={cancelOrderIdList}
              setCancelOrderIdList={setCancelOrderIdList}
              connectWallet={connectWallet}
              isExchange={true}
              refetchBalance={handleRefetchBalance}
            />
          )}
          {listSection === "TRADES" && (
            <TradeHistory
              infoTokens={infoTokens}
              getTokenInfo={getTokenInfo}
              chainId={chainId}
              nativeTokenAddress={nativeTokenAddress}
              connectWallet={connectWallet}
              tradeHistory={tradeHistory}
              isExchange={true}
            />
          )}
          {listSection === "CLOSED_POSITIONS" && (
            <ClosedPositionsList
              account={curAccount}
              infoTokens={infoTokens}
              getTokenInfo={getTokenInfo}
              chainId={chainId}
              connectWallet={connectWallet}
              positions={ClosedPositions}
              isExchange={true}
            />
          )}
        </>
      );
    };

    const onSelectWalletToken = (token) => {
      setFromTokenAddress(swapOption, token.address);
    };

    const renderChart = () => {
      return (
        <ExchangeTVChart
          fromTokenAddress={fromTokenAddress}
          toTokenAddress={toTokenAddress}
          infoTokens={infoTokens}
          swapOption={swapOption}
          chainId={chainId}
          positions={positions}
          savedShouldShowPositionLines={savedShouldShowPositionLines}
          orders={orders}
          setToTokenAddress={setToTokenAddress}
        />
      );
    };

    const renderExchangeTop = () => {
      return (
        <ExchangeChartTop
          fromTokenAddress={fromTokenAddress}
          toTokenAddress={toTokenAddress}
          infoTokens={infoTokens}
          swapOption={swapOption}
          chainId={chainId}
          positions={positions}
          savedShouldShowPositionLines={savedShouldShowPositionLines}
          orders={orders}
          setToTokenAddress={setToTokenAddress}
        />
      );
    };

    const handleRefetchBalance = () => {
      if (isFundedTrading) {
        refetchChallengeStats();
        return;
      }

      refetchBalance();
    };

    return (
      <div className={cx("page-layout tailwind", { "max-h-screen": isChartFullScreen })}>
        {renderExchangeTop()}
        <div
          style={{ height: isChartFullScreen ? "calc(100vh - 150px)" : "auto" }}
          className={cx("Exchange-content", `Exchange-content--layout-${tradingLayout}`)}
        >
          {!isMobile ? (
            <div className="Exchange-chart-column">
              <PanelGroup autoSaveId="example" direction="vertical">
                {!isChartFullScreen ? (
                  <Panel defaultSize={60} minSize={30} maxSize={150}>
                    {renderChart()}
                  </Panel>
                ) : (
                  <>{renderChart()}</>
                )}
                <PanelResizeHandle />
                <Panel>
                  {!isChartFullScreen && (
                    <div className="Exchange-lists max-[1100px]:hidden flex flex-col">{getListSection()}</div>
                  )}
                </Panel>
              </PanelGroup>
            </div>
          ) : (
            <div className="Exchange-chart-column">
              {renderChart()}
              {!isChartFullScreen && (
                <div className="Exchange-lists max-[1100px]:hidden flex flex-col">{getListSection()}</div>
              )}
            </div>
          )}

          <div className="Exchange-form-column">
            <SwapBox
              pendingPositions={pendingPositions}
              setPendingPositions={setPendingPositions}
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
              approveOrderBook={approveOrderBook}
              approvePositionRouter={approvePositionRouter}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
              orderBookApproved={orderBookApproved}
              positionRouterApproved={positionRouterApproved}
              orders={orders}
              flagOrdersEnabled={flagOrdersEnabled}
              chainId={chainId}
              infoTokens={infoTokens}
              active={isActive}
              connectWallet={connectWallet}
              library={provider}
              account={account}
              curAccount={curAccount}
              positionsMap={positionsMap}
              fromTokenAddress={fromTokenAddress}
              setFromTokenAddress={setFromTokenAddress}
              toTokenAddress={toTokenAddress}
              setToTokenAddress={setToTokenAddress}
              swapOption={swapOption}
              fromTokens={fromTokens}
              toTokens={toTokens}
              stableTokens={stableTokens}
              setSwapOption={setSwapOption}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              tokenSelection={tokenSelection}
              setTokenSelection={setTokenSelection}
              isPendingConfirmation={isPendingConfirmation}
              setIsPendingConfirmation={setIsPendingConfirmation}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
              nativeTokenAddress={nativeTokenAddress}
              savedSlippageAmount={savedSlippageAmount}
              usdpSupply={usdpSupply}
              savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
              minExecutionFee={minExecutionFee}
              minExecutionFeeUSD={minExecutionFeeUSD}
              minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
              tradingLayout={tradingLayout}
              fromBalance={fromBalance}
              refetchBalance={handleRefetchBalance}
              proxyTraderContractAddress={proxyTraderContractAddress}
              isFundedAccountActive={isFundedAccountActive}
              expectedMinTradeSize={expectedMinTradeSize}
              expectedMaxTradeSize={expectedMaxTradeSize}
              fromFundedBalance={fromFundedBalance}
              isFundedTrading={isFundedTrading}
              challengeInProgress={challengeInProgress}
            />
            <div className="Exchange-wallet-tokens">
              <div className="Exchange-wallet-tokens-content">
                <ExchangeWalletTokens tokens={tokens} infoTokens={infoTokens} onSelectToken={onSelectWalletToken} />
              </div>
            </div>
          </div>
        </div>

        <div className="Exchange-lists hidden max-[1100px]:block">{getListSection()}</div>
        <div className="block md:hidden">
          <GenerallInfos
            chainId={chainId}
            infoTokens={infoTokens}
            toTokenAddress={toTokenAddress}
            swapOption={swapOption}
          />
        </div>
      </div>
    );
  }
);

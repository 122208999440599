import { Trans } from "@lingui/macro";
import tableImg from '../../../img/ruelstable.png';

export const RulesTab = () => {
  return (
    <div className="container mx-auto p-4 Tab-content text-inactive">
      <div className="min-w-full">
        <img src={tableImg} alt="table" className="w-full "/>
      </div>
      <p className="mt-[1.5rem]">
        <Trans>Progress through different FUNDED levels: Metrics for getting Promoted</Trans>
      </p>

      <div className="mt-[1.5rem]">
        <p className="text-white text-[1.6rem] mb-[0.5rem]">Qualifier Levels:</p>
        <ul className="list-disc flex flex-col gap-[0.5rem] pl-[2rem]">
          <li>
            <Trans>Below 100% collateral: Lose funding, no payout.</Trans>
          </li>
          <li>
            <Trans>100% to 115% collateral: Demotion, keep 80% of gains subject to KPI’s.</Trans>
          </li>
          <li>
            <Trans>115% to 125% collateral: Maintain funding, keep 80% of gains.</Trans>
          </li>
          <li>
            <Trans>Above 125% collateral: Promotion, unless at max funding.</Trans>
          </li>
        </ul>
      </div>
      <div className="mt-[1.5rem]">
        <p className="text-white text-[1.6rem] mb-[0.5rem]">
          <Trans>Non compliance of KPIs will result in demotion, regardless of your PnL.</Trans>
        </p>
        <ul className="list-disc flex flex-col gap-[0.5rem] pl-[2rem]">
          <li>
            <Trans>Traders will be unable to earn their 80% profit share, in the event KPIs are not met.</Trans>
          </li>
          <li>
            <Trans>
              A Trader in qualification, will keep 100% of their profits, even if KPIs are not met. Though they will not
              qualify for funding.
            </Trans>
          </li>
        </ul>
      </div>
    </div>
  );
};

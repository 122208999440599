import { Trans } from "@lingui/macro";
import { HiQuestionMarkCircle } from "react-icons/hi";

import { Tooltip } from "components/TooltipV2/Tooltip";
import { BigNumber } from "ethers";
import { formatBigAmount } from "lib/formatAmount";
import { USDC_DECIMALS } from "lib/constants";

export default function Cashout({
  cashout,
  isLoading,
  label = "Cashout/Rollover",
}: {
  cashout: BigNumber;
  isLoading: boolean;
  label?: string;
}) {
  return (
    <div className="Challenge-timer-subblock">
      <Tooltip
        renderContent={() => {
          return (
            <div className="Stake-card-infobox-tooltip">
              <Trans>{label}</Trans>
            </div>
          );
        }}
      >
        <div className="Tooltip-handle-wrapper">
          <div className="inactive-text">
            <Trans>{label}</Trans>
          </div>
          <HiQuestionMarkCircle className="inactive-text" />
        </div>
      </Tooltip>
      <p className="text-green text-[2rem] mt-[0.4rem]">
        {isLoading ? (
          <div className="skeleton w-[4.5rem] h-[2rem] rounded"></div>
        ) : (
          `$${formatBigAmount(cashout, USDC_DECIMALS)}`
        )}
      </p>
    </div>
  );
}
